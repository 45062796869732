import { useEffect, useCallback, useState } from 'react';
import "./DiseaseCondition.component.css";
import { Button, Grid, IconButton } from '@mui/material';
import { GridColDef, GridSortModel } from '@mui/x-data-grid';
import { DeleteOutlineRounded, Edit } from "@mui/icons-material";
import ConfirmationModalComponent from '../Shared/ConfirmationModal/ConfirmationModal.component';
import { DiseaseConditionsModel } from '../../Api/Model/DiseaseConditions/DiseaseConditionsModel';
import { DiseaseConditionModal } from './DiseaseConditionModal.component';
import StripedDataGrid, { CustomPagination } from '../Shared/StripedDataGrid/StripedDatagrid.component';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useAxios } from '../../hooks/useAxios';
import PageWrapper from '../Shared/Wrapper/PageWrapper.component';

const DiseaseCondition = (props: any) => {

  //#region Column Definitions

  const definedColumns: GridColDef[] = [
    {
      field: "catalogName",
      headerName: "Name",
      headerAlign: "left",
      align: "left",
      flex: 1,
      hideable: false,
      filterable: false
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      headerAlign: "left",
      align: "left",
      hideable: false,
      filterable: false
    },
    {
      field: "action",
      headerName: "Actions",
      sortable: false,
      flex: 0,
      headerAlign: "center",
      align: "center",
      cellClassName: "diseasecondition-actions",
      hideSortIcons: true,
      disableExport: true,
      disableColumnMenu: true,
      hideable: false,
      filterable: false,
      renderCell: (params) => {
        const openEditModal = () => {
          setCurrentDisease({
            catalogId: params.row.catalogId,
            catalogName: params.row.catalogName,
            description: params.row.description
          } as DiseaseConditionsModel);
          setDiseaseConditionType('update');
          setOpenDiseaseConditionModal(true);
        };

        const openConfirmModal = async () => {
          let hasDependencies = await getAsync<Boolean>(`DiseaseCondition/GetDiseaseConditionHasDependencies/${params.row.catalogId}`);
          if (hasDependencies) {
            manageErrorAlert("You cannot delete a disease condition that is registered on a patient");
            return;
          }
          setCurrentDisease({
            catalogId: params.row.catalogId,
            catalogName: params.row.catalogName,
            description: params.row.description
          } as DiseaseConditionsModel);
          setOpenDeleteConfirmation(true);
        };

        return <Grid container>
          <Grid item xs={6}>
            <IconButton onClick={openEditModal} aria-label="edit" className='unbordered' color="primary">
              <Edit />
            </IconButton>
          </Grid>
          <Grid item xs={6}>
            <IconButton onClick={openConfirmModal} aria-label="delete" className='unbordered' color="error">
              <DeleteOutlineRounded />
            </IconButton>
          </Grid>
        </Grid>;
      }
    },
  ];

  //#endregion


  //#region Hooks

  //#region States

  const [diseaseConditions, setDiseaseConditions] = useState<DiseaseConditionsModel[]>([]);
  const [currentDisease, setCurrentDisease] = useState<DiseaseConditionsModel | undefined>(undefined);
  // Delete a disease condition section
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  // Edit / Create a disease condition section
  const [openDiseaseConditionModal, setOpenDiseaseConditionModal] = useState(false);
  const [diseaseConditionType, setDiseaseConditionType] = useState<'create' | 'update'>('create');
  const [refresh, setRefresh] = useState<boolean>(true);
  const [rowCountState, setRowCountState] = useState(1);
  const [sortModel, setSortModel] = useState<GridSortModel>([{field: definedColumns[0].field, sort: "asc"}]);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0
  });
  
  //#endregion
  
  //#region Others
  
  const { getAsync, deleteAsync, updateAsync, postAsync, axiosPagination, axiosLoading, axiosError, axiosSuccess } = useAxios();
  const navigate = useNavigate();
  const { manageErrorAlert, showAlertSnack } = useOutletContext<{ manageErrorAlert: Function, showAlertSnack: Function }>();
  const onCloseConfirmModalHandler = useCallback(
    () => {
      setOpenDeleteConfirmation(false);
    },
    [setOpenDeleteConfirmation],
  );
  const onCloseDiseaseConditionModalHandler = useCallback(
    () => {
      setOpenDiseaseConditionModal(false);
    },
    [setOpenDiseaseConditionModal],
  );
  
  //#endregion

  //#region Effects

  useEffect(() => {
    if (!refresh) {
      return;
    }
    const {pageSize, page} = paginationModel;
    const {field, sort} = sortModel[0] !== undefined ? sortModel[0] : {field: definedColumns[0].field, sort: "asc"};

    getDiseaseConditions(pageSize, page, field, sort || "");
  }, [refresh]);

  useEffect(() => {
    setRowCountState((prevState) =>
      axiosPagination?.TotalRecords !== undefined ? axiosPagination?.TotalRecords : prevState
    );
  }, [axiosPagination?.TotalRecords]);

  useEffect(() => {
    if (sortModel[0] === undefined) {
      setSortModel([{field: definedColumns[0].field, sort: "asc"}])
    }
    setRefresh(true);
  }, [paginationModel, sortModel]);

  useEffect(() => {
    if (axiosError && axiosError?.Code === '401') {
      manageErrorAlert("Unauthorized");
      navigate("/patientlist", { replace: true });
      return;
    }
  }, [axiosError]);

  //#endregion

  //#endregion


  //#region Functions

  //#region Disease Conditions Methods

  const getDiseaseConditions = async (pageSize: number, page: number, field: string, sort: string) => {
    const axiosGet = await getAsync<DiseaseConditionsModel[]>(`DiseaseCondition/GetDiseaseConditions?orderBy=${field}&sort=${sort}`, {
      PageNumber: page + 1 || 1,
      PageSize: pageSize || 0
    });

    setRefresh(false);

    if (!axiosGet) {
      manageErrorAlert(axiosError?.Messages[0]);
      return;
    }
    setDiseaseConditions(axiosGet);
  };

  const addNewDiseaseCondition = (): void => {
    setCurrentDisease(undefined);
    setDiseaseConditionType('create');
    setOpenDiseaseConditionModal(true);
  }

  //#endregion

  //#region Handlers

  //#region Pagination Handlers

  const onPageSizeChangeHandler = (newPageSize: number) => setPaginationModel({...paginationModel, pageSize: newPageSize});
  const onPageChangeHandler = (newPage: number) => setPaginationModel({...paginationModel, page: newPage});

  //#endregion

  //#region Modal Handlers

  const onDeleteConfirmationHandler = async () => {
    await deleteAsync('DiseaseCondition/DeleteDiseaseCondition', currentDisease?.catalogId || '');

    if (axiosSuccess) {
      showAlertSnack("The disease condition was successfully removed.", "success");
      setRefresh(true);
    }
  };

  const onDiseaseConditionModalConfirmHandler = async (diseaseCondition: DiseaseConditionsModel) => {
    if (diseaseConditionType === 'create') {
      await postAsync('DiseaseCondition/AddDiseaseCondition', diseaseCondition);
      if (axiosSuccess) {
        setSortModel([] as GridSortModel);
        showAlertSnack("The disease condition was added successfully.", "success");
        if (paginationModel.page > 0) {
          setPaginationModel({page: 1, pageSize: 0})
        }
        else {
          setRefresh(true);
        }
      }
      else {
        manageErrorAlert(axiosError?.Messages[0]);
      }
      return;
    }

    const updateResult = await updateAsync<DiseaseConditionsModel>('DiseaseCondition/UpdateDiseaseCondition', currentDisease?.catalogId || '', diseaseCondition);
    if (updateResult && axiosSuccess) {
      showAlertSnack("The disease condition was successfully updated.", "success");
      setDiseaseConditions((dcs) => {
        const result = Array.from(dcs);
        const index = dcs.findIndex(dc => dc.catalogId === updateResult?.catalogId);
        result[index] = updateResult;
        return result;
      });
    }
    else {
      manageErrorAlert(axiosError?.Messages[0]);
    }
  }

  //#endregion

  //#endregion

  //#endregion

  
  //#region UI - JSX Components

  const ui_confirm = <ConfirmationModalComponent
    open={openDeleteConfirmation}
    handleClose={onCloseConfirmModalHandler}
    type='deletion'
    onConfirm={onDeleteConfirmationHandler}
  />;

  const ui_diseasecondition_modal = <DiseaseConditionModal
    open={openDiseaseConditionModal}
    handleClose={onCloseDiseaseConditionModalHandler}
    type={diseaseConditionType}
    onConfirm={onDiseaseConditionModalConfirmHandler}
    diseaseConditionParam={currentDisease}
  />

  const ui_addBtn = <Button sx={{ mb: 2 }} variant="contained" color="success" onClick={addNewDiseaseCondition}>Add disease condition</Button>;

  //#endregion

  return <>
    <PageWrapper title="Disease Conditions">
      {ui_addBtn}
      <StripedDataGrid
        sx={{ height: 700 }}
        loading={axiosLoading}
        disableColumnSelector
        disableColumnMenu
        disableSelectionOnClick
        paginationMode="server"
        sortingMode="server"
        rows={diseaseConditions}
        getRowId={(row) => row.catalogId}
        columns={definedColumns}
        rowsPerPageOptions={[5, 10, 20, 50]}
        pageSize={paginationModel.pageSize}
        page={paginationModel.page}
        rowCount={rowCountState}
        onPageChange={onPageChangeHandler}
        onPageSizeChange={onPageSizeChangeHandler}
        components={{ Footer: () => CustomPagination(Math.ceil(rowCountState / (paginationModel ? paginationModel.pageSize! : 1)), paginationModel.page + 1, onPageChangeHandler) }}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
        }
        sortModel={sortModel}
        sortingOrder={["asc", "desc"]}
        onSortModelChange={setSortModel}
      />
    </PageWrapper>
    {ui_confirm}
    {ui_diseasecondition_modal}
  </>;
};

export default DiseaseCondition;