import { Box, Button, Tab, Tabs } from "@mui/material";
import AddPatient from "../addpatient/AddPatient.component";
import PatientInfo from "../patientInfo/PatientInfo.component";
import { useLocation, useNavigate, useOutletContext, useParams } from "react-router-dom";
import { SyntheticEvent, useEffect, useState } from "react";
import AlertHistory from "../alertHistory/AlertHistory.component";
import { PatientTimeTracking } from "../PatientTimeTracking/PatientTimeTracking.component";
import { PatientReadingsSection } from "../PatientSections/Readings/PatientReadingsSection.component";
import { InUsePatientAlert } from "../inusepatientalert/inUsePatientAlert.component";
import { PatientNotesSection } from "../PatientNotes/PatientNotesSection.component";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { TimeTrackingSection } from "../TimeTracking/TimeTrackingSection.component";
import { PatientMessages } from "../PatientMessages/PatientMessages.component";
import { PatientContextProvider } from "../../Context/PatientContext";
import { useFirebaseMessaging } from "../../hooks/useFirebaseMessaging";
import { useAxios } from "../../hooks/useAxios";
import { getSessionUser } from "../../Shared/sessionUser.provider";
import { UserModel } from "../../Api/Model/user/userModel";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const EditPatient = (props: any) => {
  const { manageErrorAlert, showAlertSnack, leavingSessionByTimeout } = useOutletContext<{ manageErrorAlert: Function, showAlertSnack: Function, leavingSessionByTimeout: boolean }>();
  const parameter = useParams();
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const currentUser = getSessionUser();
  const [navigationParams] = useState<any>(useLocation());
  const [isPatientInUse, setIsPatientInUse] = useState(false);
  const [isAllowedToModify, setIsAllowedToModify] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [showNavAlert, setShowNavAlert] = useState(true);
  const [messagesShouldUpdate, setMessagesShouldUpdate] = useState(true);
  const [isOfficeStaff, setIsOfficeStaff] = useState(true);
  const { getAsync,axiosError } = useAxios();

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleBackClick = () => {
    navigate("/patientlist", { replace: true });
  };

  useEffect((() => {
    if (!navigationParams.state || !navigationParams.state.tabIndex) {
      return;
    }
    setValue(+navigationParams.state.tabIndex);
  }), []);

  useEffect((() => {
    setIsAllowedToModify(!isPatientInUse);
  }), [isPatientInUse]);

  const onMessagesUpdate = () => setMessagesShouldUpdate(false);

  const { onMessage, messaging, markNotificationAsNotified } = useFirebaseMessaging();

  onMessage(messaging, (payload) => {
    if (!payload.data) {
      return;
    }
    const patientId = payload.data['patientId']
    if (patientId !== parameter.id) {
      return
    }

    if (value === 4) {
      setMessagesShouldUpdate(true);
      return
    }

    showAlertSnack(payload.notification?.body, 'info')

    markNotificationAsNotified(payload.data['messageId'])
  })

  const handleLostNotifications = () => {
    if (document.hidden) {
      return
    }

    if (value === 4) {
      setMessagesShouldUpdate(true);
      return
    }

    getAsync<number>(`PatientMessages/UnReadMessageCounterByPatientId?patientId=${parameter.id}`)
      .then(resUnreadMessgCount => {
        if (!resUnreadMessgCount || resUnreadMessgCount === 0) {
          return
        }
        showAlertSnack('You have pending messages', 'info')
      });
  }

  useEffect(() => {
    window.addEventListener('focus', handleLostNotifications);
    return () => {
      window.removeEventListener('focus', handleLostNotifications);
    }
  }, [value])

  useEffect(() => {
    checkCurrentUserRole();
  }, [])

  const checkCurrentUserRole= async ()=>{
    const axiosGet = await getAsync<UserModel>(`User/GetUserById/${currentUser.id}`);

    if (!axiosGet) {
      manageErrorAlert(axiosError?.Messages[0]);
      return;
    }
    setIsOfficeStaff(axiosGet.roleName === "OfficeStaff");
  }

  return (
    <PatientContextProvider>
      <Box sx={{ bgcolor: 'background.paper', width: "100%", }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'left' }}>
            <Button
              sx={{ display: "inline-flex", height: 53, marginRight: 1, marginTop: 1 }}
              color="secondary"
              onClick={handleBackClick}
              fullWidth
              variant="contained"
              startIcon={<ArrowBackIosNewIcon />}
            >
              {"Back"}
            </Button>
            <InUsePatientAlert isEditing={isEditing} isPatientInUse={isPatientInUse} setIsPatientInUse={setIsPatientInUse} />
          </Box>
          <Box>
            <PatientTimeTracking IsEditing={isEditing} SetIsEditing={setIsEditing} SetIsAllowedToModify={setIsAllowedToModify} IsPatientInUse={isPatientInUse} IsEnabled={!isPatientInUse} ShowNavAlert={showNavAlert} LeavingSessionByTimeout={leavingSessionByTimeout} />
          </Box>
        </Box>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} centered={true} variant="fullWidth">
            <Tab label="PATIENT INFO" {...a11yProps(0)} className="tabfullWidth" />
            <Tab label="READINGS" {...a11yProps(1)} className="tabfullWidth" />
            {!isOfficeStaff && <Tab label="ALERT HISTORY" {...a11yProps(2)} className="tabfullWidth" />}
            {!isOfficeStaff && <Tab label="TIME TRACKING" {...a11yProps(3)} className="tabfullWidth" />}
            {!isOfficeStaff && <Tab label="MESSAGES" {...a11yProps(4)} className="tabfullWidth" />}
            {!isOfficeStaff && <Tab label="NOTES" {...a11yProps(5)} className="tabfullWidth" />}
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <AddPatient isInUse={!isAllowedToModify} ModuleUbication={true} manageErrorAlert={manageErrorAlert} showAlertSnack={showAlertSnack} patientId={parameter.id} setShowNavAlertHandler={setShowNavAlert} />
          <PatientInfo isInUse={!isAllowedToModify} patientId={parameter.id} manageErrorAlert={manageErrorAlert} showAlertSnack={showAlertSnack} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <PatientReadingsSection patientId={parameter.id} isInUse={!isAllowedToModify} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <AlertHistory isInUse={!isAllowedToModify} manageErrorAlert={manageErrorAlert} showAlertSnack={showAlertSnack} patientId={parameter.id || ""} />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <TimeTrackingSection patientId={parameter.id || ""} />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <PatientMessages patientId={parameter.id} isInUse={!isAllowedToModify} shouldUpdate={messagesShouldUpdate} onMessagesUpdate={onMessagesUpdate} />
        </TabPanel>
        <TabPanel value={value} index={5}>
          <PatientNotesSection patientId={parameter.id} isInUse={!isAllowedToModify} />
        </TabPanel>
      </Box>
    </PatientContextProvider>
  );
};

export default EditPatient;
