import { Box, Tab, Tabs } from "@mui/material"
import { SyntheticEvent, useState } from "react";
import { BloodPressureReadingSection } from "./Sections/BloodPressureReadingSection.component";
import { GlucometerReadingSection } from "./Sections/GlucometerReadingSection.component";
import { OximeterReadingSection } from "./Sections/OximeterReadingSection.component";
import { WeightReadingSection } from "./Sections/WeightReadingSection.component";

type PatientReadingsTabsType = {
    patientId: string | undefined
    initialDate?:Date
    endDate?:Date
    hasBloodPressureRecords: boolean
    hasSpO2Records: boolean
    hasWeightRecords: boolean
    hasGlucoseRecords: boolean
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <Box
            sx={{ height: '100%' }}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ height: '100%' }}>
                    {children}
                </Box>
            )}
        </Box>
    );
}

function allProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

/**
 * Component created to render the readings section tabs 
 * @param props 
 * @returns A rendered tabs containing BLOOD PRESSURE, OXIMETER, WEIGHT, GLUCOMETER
 */
export const PatientReadingsTabSection = (props: PatientReadingsTabsType) => { 
    const bloodPressureIndex = props.hasBloodPressureRecords ? 0 : -1;
    const oximeterIndex = props.hasSpO2Records ? bloodPressureIndex + 1 : bloodPressureIndex;
    const weightIndex = props.hasWeightRecords ? oximeterIndex + 1 : oximeterIndex;
    const glucometerIndex = props.hasGlucoseRecords ? weightIndex + 1 : weightIndex;

    const [currentTab, setCurrentTab] = useState(0);

    const handleTabChange = (event: SyntheticEvent<Element,Event>, newValue: number) => {
        setCurrentTab(newValue);
    };

    const noDaTa = !props.hasBloodPressureRecords && !props.hasSpO2Records && !props.hasGlucoseRecords && !props.hasWeightRecords;

    const noDataComponent = <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        opacity: 0.5,
        height: '100%'
    }}>No readings to display</Box>

    const contentComponent = <Box sx={{ bgcolor: 'background.paper', width: "100%", height: "100%" }}>
        <Box sx={{ borderBottom: 2, borderColor: 'divider' }}>
            <Tabs value={currentTab} onChange={handleTabChange} centered={true} variant="fullWidth">
                {props.hasBloodPressureRecords && <Tab label="BLOOD PRESSURE" {...allProps(bloodPressureIndex)} className="readingTab" />}
                {props.hasSpO2Records && <Tab label="OXIMETER" {...allProps(oximeterIndex)} className="readingTab" />}
                {props.hasWeightRecords && <Tab label="WEIGHT" {...allProps(weightIndex)} className="readingTab" />}
                {props.hasGlucoseRecords && <Tab label="GLUCOMETER" {...allProps(glucometerIndex)} className="readingTab" />}
            </Tabs>
        </Box>
        {props.hasBloodPressureRecords && <TabPanel value={currentTab} index={bloodPressureIndex}>
            <BloodPressureReadingSection initialDate={props.initialDate} endDate={props.endDate} patientId={props.patientId}/>
        </TabPanel>}
        {props.hasSpO2Records && <TabPanel value={currentTab} index={oximeterIndex}>
            <OximeterReadingSection initialDate={props.initialDate} endDate={props.endDate} patientId={props.patientId}/>
        </TabPanel>}
        {props.hasWeightRecords && <TabPanel value={currentTab} index={weightIndex}>
            <WeightReadingSection initialDate={props.initialDate} endDate={props.endDate} patientId={props.patientId}/>
        </TabPanel>}
        {props.hasGlucoseRecords && <TabPanel value={currentTab} index={glucometerIndex}>
            <GlucometerReadingSection initialDate={props.initialDate} endDate={props.endDate} patientId={props.patientId}/>
        </TabPanel>}
    </Box>

    return (<>
        {noDaTa && noDataComponent}
        {!noDaTa && contentComponent}
    </>
    )
}