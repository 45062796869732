import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { GridColDef, GridSortModel } from "@mui/x-data-grid";
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs";
import { isUndefined, isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { ReportFilterModel } from "../../../Api/Model/ReportFilterModel";
import { BillingReportModel } from "../../../Api/Model/Reports/BillingReportModel";
import {
  CustomNoRowsReportOverlay
} from "../../../constants/styles.constants";
import { useAxios } from "../../../hooks/useAxios";
import StripedDataGrid, {
  CustomPagination,
} from "../../Shared/StripedDataGrid/StripedDatagrid.component";
import {
  BillingCode4Setup,
  DateDisplayFormat,
  LocaleStringFormat,
} from "../../../constants/global.constants";
import { BillingCodeModel } from "../../../Api/Model/BillingCode/BillingCodeModel";
import { downloadFileResponse } from "../../../Shared/Files.provider";
import { DepartmentModel } from "../../../Api/Model/DepartmentModel";
import { emptyGUID } from "../../../constants/guid.contast";
import OfficeModel from "../../../Api/Model/DepartmentStructure/OfficeModel";
import ProviderModel from "../../../Api/Model/DepartmentStructure/ProviderModel";
import { sortStringsWithNumbers } from "../../../Shared/Sorting.provider";
import { CleanButton } from "../../../Shared/CleanHandler.provider";
import PageWrapper from "../../Shared/Wrapper/PageWrapper.component";
import { getDateOfToday, getFirstDayOfCurrentMonth, getUTCDate } from "../../../Shared/DateTime.provider";

//#region Definitions

//#region Columns Definitions

const definedColumns: GridColDef[] = [
  {
    field: "patientId",
    headerName: "Patient Id",
    headerAlign: "center",
    align: "left",
    flex: 1,
    hideable: false,
    filterable: false,
  },
  {
    field: "lastName",
    headerName: "Last Name",
    flex: 1,
    headerAlign: "center",
    align: "left",
    hideable: false,
    filterable: false,
  },
  {
    field: "firstName",
    headerName: "First Name",
    flex: 1,
    headerAlign: "center",
    align: "left",
    hideable: false,
    filterable: false,
  },
  {
    field: "dayOfBirth",
    headerName: "Date of Birth",
    flex: 1,
    headerAlign: "center",
    type: "date",
    align: "left",
    hideable: false,
    filterable: false,
    valueGetter: (params) => {
      return getUTCDate(new Date(params.row.dayOfBirth)).toLocaleDateString(LocaleStringFormat);
    },
  },
  {
    field: "provider",
    headerName: "Provider",
    flex: 1,
    headerAlign: "center",
    hideable: false,
    filterable: false
  },
  {
    field: "date99453",
    headerName: "99453 Date",
    flex: 1,
    headerAlign: "center",
    align: "left",
    hideable: false,
    filterable: false,
    valueGetter: (params) => {
      if (
        params.row.billingCode.find(
          (i: billingCodeResponseDetail) => i.code === BillingCode4Setup
        ).numberOfTimes == 0
      ) {
        return "";
      }
      return getUTCDate(new Date(params.row.billingCode
        .find((i: billingCodeResponseDetail) => i.code === BillingCode4Setup)
        .trigeredDate.toString())).toLocaleDateString(LocaleStringFormat);
    },
  },
  {
    field: "99453",
    headerName: "99453",
    flex: 0.5,
    headerAlign: "center",
    align: "center",
    hideable: false,
    filterable: false,
    valueGetter: (params) => {
      return params.row.billingCode.find(
        (i: billingCodeResponseDetail) => i.code === BillingCode4Setup
      ).numberOfTimes;
    },
  },
];

//#endregion

//#region Types Definitions

type billingCodeResponseDetail = {
  code: string;
  trigeredDate: Date;
  numberOfTimes: number;
};

//#endregion

//#endregion

const BillableActivity = (props: any) => {

  //#region Hooks

  //#region States

  const [reportFilterModel, setReportFilterModel] = useState<ReportFilterModel>(
    { departmentId: "", officeId: "", providerId: "" }
  );
  const [exportFilterModel, setExportFilterModel] = useState<ReportFilterModel>(
    {}
  );
  const [billeableActivity, setBilleableActivity] = useState<
    BillingReportModel[]
  >([]);
  const [rowCountState, setRowCountState] = useState(0);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [fullColumns, setFullColumns] = useState<GridColDef[]>([]);
  const [departments, setDepartments] = useState<DepartmentModel[]>([]);
  const [offices, setOffices] = useState<OfficeModel[]>([]);
  const [providers, setProviders] = useState<ProviderModel[]>([]);
  const [datesErrors, setDatesErrors] = useState<boolean>(false);
  const [sortModel, setSortModel] = useState<GridSortModel>([{field: definedColumns[0].field, sort: "asc"}]);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0
  });

  //#endregion

  //#region Others

  const { manageErrorAlert, showAlertSnack } = useOutletContext<{
    manageErrorAlert: Function;
    showAlertSnack: Function;
  }>();

  const {
    getAsync,
    axiosError,
    downloadFileAsync,
    axiosSuccess,
    axiosLoading,
    axiosPagination,
  } = useAxios();

  const { getAsync: getBillingCodesAsync, axiosError: axiosBillingCodeError } =
    useAxios();

  const navigate = useNavigate();

  //#endregion

  //#region Effects

  useEffect(() => {
    setDatesErrors(
      (reportFilterModel.endDate &&
        reportFilterModel.startDate &&
        reportFilterModel.endDate < reportFilterModel.startDate) ||
      false
    );
  }, [reportFilterModel.startDate, reportFilterModel.endDate]);

  useEffect(() => {
    if (!refresh) {
      return;
    }

    const {pageSize, page} = paginationModel;
    const {field, sort} = sortModel[0] !== undefined ? sortModel[0] : {field: definedColumns[0].field, sort: "asc"};

    getBilleableActivityReport(pageSize, page, field, sort || "");
  }, [refresh]);

  useEffect(() => {
    setRowCountState((prevState) =>
      axiosPagination?.TotalRecords !== undefined
        ? axiosPagination?.TotalRecords
        : prevState
    );
  }, [axiosPagination?.TotalRecords]);

  useEffect(() => {
    if (sortModel[0] === undefined) {
      setSortModel([{field: definedColumns[0].field, sort: "asc"}])
    }
    setRefresh(true);
  }, [paginationModel, sortModel]);

  useEffect(() => {
    getBillingCodes();
    getDepartments();
    getOffices();
    getProviders();
  }, []);

  useEffect(() => {
    if (axiosError && axiosError?.Code === '401') {
      manageErrorAlert("Unauthorized");
      navigate("/patientlist", { replace: true });
      return;
    }
  }, [axiosError]);

  //#endregion

  //#endregion


  //#region Handlers

  //#region Pagination Handlers

  const onPageSizeChangeHandler = (newPageSize: number) => setPaginationModel({...paginationModel, pageSize: newPageSize});
  const onPageChangeHandler = (newPage: number) => setPaginationModel({...paginationModel, page: newPage});

  //#endregion

  //#region Department Structure Filter Handlers

  const departmentFilterHandler = (event: any) => {
    setReportFilterModel({
      ...reportFilterModel,
      departmentId: event.target.value,
      officeId: "",
      providerId: "",
    });
    if (event.target.value === "") {
      getOffices();
      getProviders();
      return;
    }
    getFilterOffices(event.target.value);
    getFilterProviders(event.target.value, reportFilterModel.officeId);
  };


  const officeFilterHandler = (event: any) => {
    setReportFilterModel({
      ...reportFilterModel,
      officeId: event.target.value,
      providerId: "",
    });
    getFilterProviders(reportFilterModel.departmentId, event.target.value);
  };

  const providerFilterHandler = (event: any) => {
    setReportFilterModel({
      ...reportFilterModel,
      providerId: event.target.value,
    });
  };

  //#endregion

  //#region Button Handlers

  const onSubmitReportClick = async () => {

    const {pageSize, page} = paginationModel;
    const {field, sort} = sortModel[0] !== undefined ? sortModel[0] : {field: definedColumns[0].field, sort: "asc"};

    await getBilleableActivityReport(pageSize, page, field, sort || "");
    setExportFilterModel(reportFilterModel);
  };

  const onExportReportClick = async () => {
    let response = await downloadFileAsync(
      "Reports/ExportBillingReports",
      `dateIni=${exportFilterModel.startDate?.toLocaleDateString(
        "en-US"
      )}&dateEnd=${exportFilterModel.endDate?.toLocaleDateString("en-US")}${!!exportFilterModel.departmentId
        ? "&departmentId=" + exportFilterModel.departmentId
        : ""
      }${!!exportFilterModel.officeId
        ? "&officeId=" + exportFilterModel.officeId
        : ""
      }${!!exportFilterModel.providerId
        ? "&providerId=" + exportFilterModel.providerId
        : ""
      }`
    );
    if (axiosSuccess) {
      showAlertSnack("Report generated successfully.", "success");
      downloadFileResponse(
        response,
        `BilleableReport ${exportFilterModel.startDate?.toLocaleDateString(
          "en-US"
        )} to ${exportFilterModel.endDate?.toLocaleDateString("en-US")}.xlsx`
      );
    } else {
      manageErrorAlert(axiosError?.Messages[0]);
    }
  };

  //#endregion

  //#endregion


  //#region Component Functions

  const getBillingCodes = async () => {
    const axiosGet = await getBillingCodesAsync<BillingCodeModel[]>(
      "BillingCodes/GetBillingCodesList",
      {
        PageNumber: 0,
        PageSize: 50,
      }
    );

    setRefresh(false);
    if (!axiosGet) {
      manageErrorAlert(axiosBillingCodeError?.Messages[0]);
      return;
    }

    let tempFullCols = [...definedColumns];
    axiosGet.reverse();
    axiosGet.forEach((bc) => {
      if (bc.code == BillingCode4Setup) return;
      tempFullCols = [
        ...tempFullCols,
        {
          field: bc.code || "",
          headerName: bc.code || "",
          flex: 0.5,
          headerAlign: "center",
          align: "center",
          hideable: false,
          filterable: false,
          valueGetter: (params) => {
            return params.row.billingCode.find(
              (i: billingCodeResponseDetail) => i.code === bc.code
            ).numberOfTimes;
          },
        },
      ];
    });
    tempFullCols = [
      ...tempFullCols,
      {
        field: "diagnosisCode",
        headerName: "Diagnosis Code",
        flex: 1,
        headerAlign: "center",
        align: "center",
        hideable: false,
        filterable: false,
      },
    ];
    setFullColumns(tempFullCols);
  };

  const getDepartments = async () => {
    const axiosGet = await getAsync<DepartmentModel[]>(
      "Department/GetDepartments",
      {
        PageNumber: 0,
        PageSize: 0,
      }
    );

    if (!axiosGet) {
      manageErrorAlert(axiosError?.Messages[0]);
      return;
    }

    axiosGet.sort((departmentA, departmentB) =>
      sortStringsWithNumbers(
        departmentA.departmentName,
        departmentB.departmentName
      )
    );
    setDepartments(axiosGet);
  };

  const getOffices = async () => {
    const axiosGet = await getAsync<OfficeModel[]>("Office/GetOffices", {
      PageNumber: 0,
      PageSize: 0,
    });

    if (!axiosGet) {
      manageErrorAlert(axiosError?.Messages[0]);
      return;
    }

    axiosGet.sort((officeA, officeB) =>
      sortStringsWithNumbers(officeA.officeName, officeB.officeName)
    );
    setOffices(axiosGet);
  };

  const getFilterOffices = async (currentDepartment: string | undefined) => {
    const axiosGet = await getAsync<OfficeModel[]>(
      `Office/GetOfficesByDepartmentId?DepartmentId=${currentDepartment}`
    );

    if (!axiosGet) {
      manageErrorAlert(axiosError?.Messages[0]);
      return;
    }

    axiosGet.sort((officeA, officeB) =>
      sortStringsWithNumbers(officeA.officeName, officeB.officeName)
    );
    setOffices(axiosGet);
  };

  const getProviders = async () => {
    const axiosGet = await getAsync<ProviderModel[]>("Provider/GetProviders", {
      PageNumber: 0,
      PageSize: 0,
    });

    if (!axiosGet) {
      manageErrorAlert(axiosError?.Messages[0]);
      return;
    }

    axiosGet.sort((providerA, providerB) =>
      sortStringsWithNumbers(providerA.providerName, providerB.providerName)
    );
    setProviders(axiosGet);
  };

  const getFilterProviders = async (
    currentDepartment: string | undefined,
    currentOffice: string | undefined
  ) => {
    const axiosGet = await getAsync<ProviderModel[]>(
      `Provider/GetProvidersByDepartmentStructure?DepartmentId=${currentDepartment || emptyGUID
      }&OfficeId=${currentOffice || emptyGUID}`,
      {
        PageNumber: 0,
        PageSize: 0,
      }
    );

    if (!axiosGet) {
      manageErrorAlert(axiosError?.Messages[0]);
      return;
    }

    axiosGet.sort((providerA, providerB) =>
      sortStringsWithNumbers(providerA.providerName, providerB.providerName)
    );
    setProviders(axiosGet);
  };

  const getBilleableActivityReport = async (pageSize: number, page: number, field: string, sort: string) => {
    if (!isEmpty(validationErrors)) {
      if (!("startDate" in reportFilterModel)) {
        setReportFilterModel({...reportFilterModel, endDate: getDateOfToday(), startDate: getFirstDayOfCurrentMonth() });
      }
      return;
    }
    const axiosGet = await getAsync<BillingReportModel[]>(
      `Reports/GetBillingReports?` +
      `&dateIni=${reportFilterModel.startDate?.toLocaleDateString(
        "en-US"
      )}&dateEnd=${reportFilterModel.endDate?.toLocaleDateString("en-US")}${!!reportFilterModel.departmentId
        ? "&departmentId=" + reportFilterModel.departmentId
        : ""
      }${!!reportFilterModel.officeId
        ? "&officeId=" + reportFilterModel.officeId
        : ""
      }${!!reportFilterModel.providerId
        ? "&providerId=" + reportFilterModel.providerId
        : ""
      }&orderBy=${field}&sort=${sort}`,
      {
        PageNumber: page + 1,
        PageSize: pageSize
      }
    );
    setRefresh(false);
    if (!axiosGet) {
      manageErrorAlert(axiosError?.Messages[0]);
      return;
    }
    setBilleableActivity(axiosGet);
  };

  //#endregion


  //#region Validations

  const validationErrors = {
    ...(isUndefined(reportFilterModel.startDate) && {
      StartDate: "Start Date is required",
    }),
    ...(reportFilterModel.startDate?.toString() === "Invalid Date" && {
      StartDate: "Start Date must be a valid date",
    }),
    ...(isUndefined(reportFilterModel.endDate) && {
      EndDate: "End Date is required",
    }),
    ...(reportFilterModel.endDate?.toString() === "Invalid Date" && {
      EndDate: "End Date must be a valid date",
    }),
    ...(datesErrors && {
      StartDate: "The Start Date cannot be greater than the End Date",
      EndDate: "The End Date cannot be less than the Start Date",
    }),
  };

  //#endregion

  return (
    <PageWrapper title={"Billing Report"} >
      <Grid container spacing={2} rowSpacing={1} width={"100%"}>
        <Grid item xs={2}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              disableFuture
              onChange={(newValue: Dayjs | null) =>
                setReportFilterModel({
                  ...reportFilterModel,
                  startDate: newValue ? newValue.toDate() : undefined,
                })
              }
              value={reportFilterModel.startDate || null}
              label="Start Date"
              inputFormat={DateDisplayFormat}
              renderInput={(params) => (
                <TextField
                  required
                  inputProps={{
                    tabIndex: 1,
                  }}
                  {...params}
                  fullWidth
                  error={!!validationErrors.StartDate}
                  helperText={validationErrors.StartDate}
                />
              )}
              PopperProps={{
                placement: "bottom-start",
                sx: {zIndex: 1200, paddingBottom: 2}
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={2}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              onChange={(newValue: Dayjs | null) =>
                setReportFilterModel({
                  ...reportFilterModel,
                  endDate: newValue ? newValue.toDate() : undefined,
                })
              }
              value={reportFilterModel.endDate || null}
              label="End Date"
              inputFormat={DateDisplayFormat}
              renderInput={(params) => (
                <TextField
                  required
                  inputProps={{
                    tabIndex: 2,
                  }}
                  {...params}
                  fullWidth
                  error={!!validationErrors.EndDate}
                  helperText={validationErrors.EndDate}
                />
              )}
              PopperProps={{
                placement: "bottom-start",
                sx: {zIndex: 1200, paddingBottom: 2}
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={2}>
          <FormControl fullWidth>
            <InputLabel id="PatientDepartmentLabel" color="primary">
              Patient Department
            </InputLabel>
            <Select
              fullWidth
              id="PatientDepartmentCombo"
              defaultValue={""}
              inputProps={{
                tabIndex: 3,
              }}
              unselectable="on"
              label="Patient Department"
              labelId="PatientDepartmentLabel"
              value={reportFilterModel.departmentId || ""}
              onChange={departmentFilterHandler}
                endAdornment={
                    reportFilterModel?.departmentId !== "" && (
                        CleanButton(departmentFilterHandler)
                    )
                }
            >
              {departments.length === 0 && (
                <MenuItem value="">No departments found...</MenuItem>
              )}
              {departments &&
                departments.map((department: DepartmentModel) => (
                  <MenuItem
                    value={department.departmentId}
                    key={department.departmentId}
                  >
                    {department.departmentName}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <FormControl fullWidth>
            <InputLabel id="PatientOfficeLabel" color="primary">
              Patient Office
            </InputLabel>
            <Select
              fullWidth
              id="PatientOfficeCombo"
              defaultValue={""}
              unselectable="on"
              inputProps={{
                tabIndex: 3,
              }}
              label="Patient Office"
              labelId="PatientOfficeLabel"
              value={reportFilterModel.officeId || ""}
              onChange={officeFilterHandler}
                endAdornment={
                    reportFilterModel?.officeId !== "" && (
                        CleanButton(officeFilterHandler)
                    )
                }
            >
              {offices.length === 0 && (
                <MenuItem value="">No offices found...</MenuItem>
              )}
              {offices &&
                offices.map((office: OfficeModel) => (
                  <MenuItem value={office.officeId} key={office.officeId}>
                    {office.officeName}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <FormControl fullWidth>
            <InputLabel id="PatientProviderLabel" color="primary">
              Patient Provider
            </InputLabel>
            <Select
              fullWidth
              id="PatientProviderCombo"
              defaultValue={""}
              unselectable="on"
              inputProps={{
                tabIndex: 3,
              }}
              label="Patient Provider"
              labelId="PatientProviderLabel"
              value={reportFilterModel.providerId || ""}
              onChange={providerFilterHandler}
                endAdornment={
                    reportFilterModel?.providerId !== "" && (
                        CleanButton(providerFilterHandler)
                    )
                }
            >
              {providers.length === 0 && (
                <MenuItem value="">No providers found...</MenuItem>
              )}
              {providers &&
                providers.map((provider: ProviderModel) => (
                  <MenuItem
                    value={provider.providerId}
                    key={provider.providerId}
                  >
                    {provider.providerName}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={1}>
          <Button
            disabled={!isEmpty(validationErrors)}
            fullWidth
            sx={{ mb: 2, height: 55 }}
            variant="contained"
            color="primary"
            onClick={onSubmitReportClick}
          >
            SUBMIT
          </Button>
        </Grid>
        <Grid item xs={1}>
          <Button
            disabled={
              !exportFilterModel.startDate ||
              !exportFilterModel.endDate ||
              billeableActivity.length < 1
            }
            fullWidth
            sx={{ mb: 2, height: 55 }}
            variant="contained"
            color="primary"
            onClick={onExportReportClick}
          >
            EXPORT
          </Button>
        </Grid>
        <Grid item xs={12}>
          <StripedDataGrid
            sx={{ height: 700 }}
            loading={axiosLoading}
            disableColumnSelector
            disableColumnMenu
            disableSelectionOnClick
            paginationMode="server"
            sortingMode="server"
            rows={billeableActivity}
            columns={fullColumns}
            rowsPerPageOptions={[5, 10, 20, 50]}
            pageSize={paginationModel.pageSize}
            page={paginationModel.page}
            components={{
              NoResultsOverlay: () =>
                CustomNoRowsReportOverlay(
                  !exportFilterModel.startDate ||
                    !exportFilterModel.startDate
                    ? "Please select a date range to display the report"
                    : "No results for the selected date range "
                ),
              NoRowsOverlay: () =>
                CustomNoRowsReportOverlay(
                  !exportFilterModel.startDate ||
                    !exportFilterModel.startDate
                    ? "Please select a date range to display the report"
                    : "No results for the selected date range "
                ),
              Footer: () =>
                CustomPagination(
                  Math.ceil(
                    rowCountState /
                    (paginationModel ? paginationModel.pageSize! : 1)
                  ),
                  paginationModel.page + 1,
                  onPageChangeHandler
                ),
            }}
            rowCount={rowCountState}
            onPageChange={onPageChangeHandler}
            onPageSizeChange={onPageSizeChangeHandler}
            onSortModelChange={setSortModel}
            sortModel={sortModel}
            sortingOrder={["asc", "desc"]}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
            }
          />
        </Grid>
      </Grid>
    </PageWrapper>
  );
};

export default BillableActivity;