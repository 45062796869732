import { Document, StyleSheet, Page, View, Image, Text, Svg } from "@react-pdf/renderer";
import logo from "../../../resources/img/logo.png";
import ItemsTable from "./PDFTable/ItemsTable";
import { SummaryReportModel } from "../../../Api/Model/Reports/SummaryReportModel";
import { getUTCDate } from "../../../Shared/DateTime.provider";
import { LocaleStringFormat } from "../../../constants/global.constants";

const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
    },
    Section: {
        padding: 10,
    },
    centerSection: {
        margin: 8,
        padding: 10,
        alignItems: 'center',
    },
    logo: {
        height: 60,
        width: 250,
        marginTop: 11
    },
    principalHeader: {
        fontSize: 15,
        fontFamily: 'Helvetica-Bold',
        margin: 7,
    },
    textHeaderInformation: {
        fontSize: 10,
        marginBottom: 10,
        width: 150
    },
    textHeaderStatus: {
        fontSize: 10,
        marginBottom: 10,
        width: 190
    },
    text: {
        fontSize: 10,
        marginBottom: 10
    },
    underlinedText: {
        fontSize: 10,
        marginBottom: 10,
        borderColor: 'black',
        borderBottom: 1,
        textAlign: 'center',
        width: 30
    },
    diagnosisCodeUnderline: {
        fontSize: 10,
        marginBottom: 10,
        borderColor: 'black',
        borderBottom: 1,
        textAlign: 'center',
        width: 150
    },
    underlineHeader: {
        paddingLeft: 10,
        fontSize: 12,
        fontFamily: 'Helvetica-Bold',
        textDecoration: 'underline'
    },
    invoiceNoContainer: {
        flexDirection: 'row',
        marginLeft: 20
    },
});

interface pdfReportParams {
    report: SummaryReportModel | undefined;
    reportPeriod: string;
}

export const SummaryReportDocumment = (props: pdfReportParams) => {
    const { report, reportPeriod } = props;
    return (
        !!report ?
            <Document>
                <Page size="A4" style={styles.page}>
                    <View style={styles.centerSection}>
                        <Image src={logo} style={styles.logo}></Image>
                        <Text style={styles.principalHeader}>
                            Remote Patient Monitoring Summary Report
                        </Text>
                        <Text style={styles.text}>
                            Summary Reporting Period:  <Text style={{ "fontFamily": 'Helvetica-Bold', }}>{reportPeriod}</Text>
                        </Text>

                    </View>
                    <View style={[styles.Section]}>
                        <Text style={styles.underlineHeader}>
                            PATIENT INFORMATION
                        </Text>
                    </View>
                    <View style={[styles.invoiceNoContainer]}>
                        <Text style={styles.textHeaderInformation}>
                            Patient Name:
                        </Text>
                        <Text style={[styles.text]}>{report.patientName}</Text>
                    </View>
                    <View style={[styles.invoiceNoContainer]}>
                        <Text style={styles.textHeaderInformation}>
                            Patient DBO:
                        </Text>
                        <Text style={[styles.text]}>{getUTCDate(new Date(report.patientDOB)).toLocaleDateString(LocaleStringFormat)}</Text>
                    </View>
                    <View style={[styles.invoiceNoContainer]}>
                        <Text style={styles.textHeaderInformation}>
                            {'Patient RPM Care Plan(s):'}
                        </Text>
                        <Text style={[styles.text]}>{report.patientCarePlans.join("\n\n")}</Text>
                    </View>
                    <View style={[styles.invoiceNoContainer]}>
                        <Text style={styles.textHeaderInformation}>
                            Provider:
                        </Text>
                        <Text style={[styles.text]}>{report.provider}</Text>
                    </View>
                    <View style={[styles.Section, { marginTop: 30 }]}>
                        <Text style={styles.underlineHeader}>
                            PATIENT STATUS
                        </Text>
                    </View>
                    <View style={[styles.invoiceNoContainer]}>
                        <Text style={styles.textHeaderStatus}>
                            Electronic daily readings / mo.
                        </Text>
                        <Text style={[styles.underlinedText]}>{report.electronicReadings}</Text>
                    </View>
                    <View style={[styles.invoiceNoContainer]}>
                        <Text style={styles.textHeaderStatus}>
                            Manual daily readings / mo.
                        </Text>
                        <Text style={[styles.underlinedText]}>{report.manualReadings}</Text>
                    </View>
                    <View style={[styles.invoiceNoContainer]}>
                        <Text style={styles.textHeaderStatus}>
                            Total Readings
                        </Text>
                        <Text style={[styles.underlinedText]}>{report.totalReadings}</Text>
                    </View>
                    <View style={[styles.invoiceNoContainer]}>
                        <Text style={styles.textHeaderStatus}>
                            Compliant:
                        </Text>
                        <Text style={[styles.underlinedText]}>{report.compliant}</Text>
                    </View>
                    <View style={[styles.invoiceNoContainer]}>
                        <Text style={{ fontSize: 6, marginTop: -10, width: 110, flex: 1 }}>
                            *Compliant if there are 16 or more daily {'\n'} electronic readings in the preceding 30 days.
                        </Text>
                    </View>
                    <View style={[styles.invoiceNoContainer, { marginTop: 30 }]}>
                        <Text style={styles.textHeaderStatus}>
                            Diagnosis Code for RPM Services:
                        </Text>
                        <Text style={[styles.diagnosisCodeUnderline]}>{report.diagnosisCode}</Text>
                    </View>
                    <View style={[styles.invoiceNoContainer, { marginTop: 55 }]}>
                        <Text style={[styles.textHeaderStatus, { width: 148 }]}>
                            Time spent on RPM services:
                        </Text>
                        <Text style={[styles.text, { "fontFamily": 'Helvetica-Bold' }]}>{report.timeSpent}</Text>
                    </View>
                    <ItemsTable data={report.patientVitalsDetail} />
                    <View style={[styles.centerSection, { marginBottom: 1, marginTop: 'auto' }]}>
                        <Svg style={{ textAlign: 'center', paddingHorizontal: '14%' }} viewBox="0 0 0 0" width={'100%'} height={20}>
                            <Text x="10" y="10" style={[{ fontSize: 8 }]}>
                                Prism Care® Remote Patient Monitoring   972-408-2916   2901 Dallas Parkway, Suite #150, Plano Tx 75093.
                            </Text>
                        </Svg>
                    </View>
                </Page>
            </Document>
            : <></>
    )
};