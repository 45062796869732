import { Cancel, Info, Create } from "@mui/icons-material";
import { Chip, IconButton, Popover, Typography } from "@mui/material";
import { useState } from "react";
import { PatientThresholdModel } from "../../../Api/Model/PatientThreshold";

type PatientThresholdChipProps = {
    isPatientInUse: boolean;
    handleEditPatientThreshold: Function;
    handleRemovePatientThreshold: Function;
    patientThreshold: PatientThresholdModel;
    index: number;
  };

export const PatientThresholdChip = ({ isPatientInUse, handleEditPatientThreshold, handleRemovePatientThreshold, patientThreshold, index }: PatientThresholdChipProps) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const openPopover = Boolean(anchorEl);
    const handlePopoverOpen = (event: any) => {
        setAnchorEl(event.currentTarget);
    }
    const handlePopoverClose = () => setAnchorEl(null);
    return (<Chip
        sx={{
            "& .MuiChip-icon": {
                order: 1,
                cursor: "pointer",
            },
            "& .MuiChip-deleteIcon": {
                order: 2,
            },
        }}
        deleteIcon={
            <IconButton disabled={isPatientInUse}>
                {" "}
                <Cancel />{" "}
            </IconButton>
        }
        onDelete={() => handleRemovePatientThreshold(index)}
        label={<>
            <IconButton style={{cursor:'default'}} onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
                <Info color="primary">
                </Info>
                <Popover id={"thresholdPopOver"} sx={{ pointerEvents: 'none'}}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                    open={openPopover}
                    anchorEl={anchorEl}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}>
                    <Typography sx={{margin:0.7}}> {!patientThreshold.modifiedDate ?
                        <>The flag alerts for this measurement will be generated using <b>the default protocols</b></> :
                        <>The flag alerts for this measurement will be generated using <b>this threshold values</b></>}
                    </Typography>
                </Popover>
            </IconButton>

            {`${patientThreshold.measurementName}'s normal range: from ${(patientThreshold.limitValues && patientThreshold.limitValues.length > 0) ? patientThreshold.limitValues[0].minimum : ""
                } to ${(patientThreshold.limitValues && patientThreshold.limitValues.length > 0) ? patientThreshold.limitValues[0].maximum : ""}`}
        </>}
        icon={
            <IconButton
                disabled={isPatientInUse}
                onClick={(e) => {
                    handleEditPatientThreshold(index);
                }}
            >
                {" "}
                <Create />{" "}
            </IconButton>
        }
    />);
}