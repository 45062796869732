/**
 * Including this data is totaly secure, this is a FCM instance rep, this config will be linking the frontend project to firebase
 */
const dev_FirebaseConfig = {
    apiKey: "AIzaSyCIphD7s5XyJ3b9avGGgrPJ8VMVFJ5UoLI",
    authDomain: "prizmcare-73141.firebaseapp.com",
    projectId: "prizmcare-73141",
    storageBucket: "prizmcare-73141.appspot.com",
    messagingSenderId: "640384211610",
    appId: "1:640384211610:web:91d34232ce8dd25deb1d23"
  };

const qa_FirebaseConfig = {
    apiKey: "AIzaSyAHeISz5TwCtg-SJxP_ntB59cBNh7YdTEQ",
    authDomain: "prismcare-4851c.firebaseapp.com",
    projectId: "prismcare-4851c",
    storageBucket: "prismcare-4851c.appspot.com",
    messagingSenderId: "215987350265",
    appId: "1:215987350265:web:0a75bff4a94ad409561028",
    measurementId: "G-PBP7Z24C8S"
};

const firebaseConfig = process.env.NODE_ENV === "development" ? dev_FirebaseConfig : qa_FirebaseConfig;

const dev_VapidKey = 'BOxq_ys3Pj44FyeRzBb00vR4LGUJDr9tSd7MDmJn7Gb5_m1uQPYI_BpVuYB-WYXtqJD_317oWSRCws9ykEJQhjg';

const qa_VapidKey = 'BCiFRhWwQ9BVrd_EbXNtHia0CjqE-KLSSuTl-Q9xuHQhBPZEjwphQdIaTrjBCavNyV_fdsLNzy3FMhBW1W96FZ4';

export const vapidKey = process.env.NODE_ENV === "development" ? dev_VapidKey : qa_VapidKey;

export default firebaseConfig;