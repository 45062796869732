import { useCallback } from "react";
import DepartmentStructureModel from "../Api/Model/DepartmentStructure/DepartmentStructureModel";
import DepartmentStructureRequestModel from "../Api/Model/DepartmentStructure/DepartmentStructureRequestModel";
import DepartmentStructureResultModel from "../Api/Model/DepartmentStructure/DepartmentStructureResultModel";
import OfficeModel from "../Api/Model/DepartmentStructure/OfficeModel";
import ProviderModel from "../Api/Model/DepartmentStructure/ProviderModel";
import { useAxios } from "./useAxios";

const departmentStructreURL = "DepartmentStructure";
const departmentURL = "Department";
const officeURL = "Office";
const providerURL = "Provider";

export const useDepartmentStructure = () => {
    const { getAsync, postAsync, updateAsync, deleteAsync, axiosLoading, axiosSuccess, axiosError } = useAxios();

    const departmentExistsAsync = useCallback(async (department: string, id: string | null) => {
        const response = await postAsync<boolean>(departmentURL + "/Exists", { name: department, id });
        return response;
    }, [postAsync]);

    const addDepartmentAsync = useCallback(
        async (department: string, onError?: () => void) => {
            const response = await postAsync<DepartmentStructureModel>(departmentURL + "/Add", department);

            if (!response || !axiosSuccess) {
                onError && onError();
            }

            return response;
        },
        [postAsync, axiosSuccess]);

    const updateDepartmentAsync = useCallback(async (id: string, department: string, onError?: () => void) => {
        const response = await updateAsync<DepartmentStructureModel>(departmentURL, id, department);

        if (!response || !axiosSuccess) {
            onError && onError();
        }

        return response;
    }, [axiosSuccess, updateAsync]);

    const removeDepartmentAsync = useCallback(
        async (id: string, onError?: () => void) => {
            const response = await deleteAsync<boolean>(departmentURL, id);

            if (!response || !axiosSuccess) {
                onError && onError();
            }

            return response;
        }, [deleteAsync, axiosSuccess],
    );

    const officeExistsAsync = useCallback(async (office: string, id: string | null) => {
        const response = await postAsync<boolean>(officeURL + "/Exists", { name: office, id });
        return response;
    }, [postAsync]);

    const updateOfficeAsync = useCallback(async (id: string, office: string, onError?: () => void) => {
        const response = await updateAsync<OfficeModel>(officeURL, id, office);

        if (!response || !axiosSuccess) {
            onError && onError();
        }

        return response;
    }, [axiosSuccess, updateAsync]);

    const removeOfficeAsync = useCallback(
        async (id: string, onError?: () => void) => {
            const response = await deleteAsync<boolean>(officeURL, id);

            if (!response || !axiosSuccess) {
                onError && onError();
            }

            return response;
        }, [deleteAsync, axiosSuccess],
    );

    const addOfficeAsync = useCallback(async (office: string, onError?: () => void) => {
        const response = await postAsync<OfficeModel>(officeURL + "/Add", office);

        if (!response || !axiosSuccess) {
            onError && onError();
        }

        return response;
    }, [axiosSuccess, postAsync]);
    
    const addProviderAsync = useCallback(async (provider: string, onError?: () => void) => {
        const response = await postAsync<ProviderModel>(providerURL + "/Add", provider);

        if (!response || !axiosSuccess) {
            onError && onError();
        }
        return response;
    }, [axiosSuccess, postAsync]);

    const existsProviderAsync = useCallback(async (provider: string, onError?: () => void) => {
        const response = await postAsync<boolean>(providerURL + "/Exists", provider);

        if (!response || !axiosSuccess) {
            onError && onError();
        }
        return response;
    }, [axiosSuccess, postAsync]);

    const updateProviderAsync = useCallback(async (id: string, provider: string, onError?: () => void) => {
        const response = await updateAsync<ProviderModel>(providerURL, id, provider);

        if (!response || !axiosSuccess) {
            onError && onError();
        }

        return response;
    }, [axiosSuccess, updateAsync]);

    const addDepartmentStructureAsync = useCallback(
        async (payload: DepartmentStructureRequestModel, onError?: () => void) => {
            payload.officeId = payload.officeId || null;
            payload.providerId = payload.providerId || null;

            const response = await postAsync<DepartmentStructureResultModel>(departmentStructreURL + "/Add", payload);
            if (!response || !axiosSuccess) {
                onError && onError();
            }
            return response;
        }, [postAsync, axiosSuccess]
    );

    const updateDepartmentStructureAsync = useCallback(
        async (payloadRemoveModel: DepartmentStructureRequestModel, payloadAddModel: DepartmentStructureRequestModel, onError?: () => void) => {
            payloadRemoveModel.officeId = payloadRemoveModel.officeId || null;
            payloadRemoveModel.providerId = payloadRemoveModel.providerId || null;
            payloadAddModel.officeId = payloadAddModel.officeId || null;
            payloadAddModel.providerId = payloadAddModel.providerId || null;

            const response = await postAsync<DepartmentStructureResultModel>(departmentStructreURL + "/Change", {
                source: payloadRemoveModel,
                destination: payloadAddModel
            });
            if (!response || !axiosSuccess) {
                onError && onError();
            }
            return response;
        }, [postAsync, axiosSuccess]
    );

    const removeDepartmentStructureAsync = useCallback(
        async (payload: DepartmentStructureRequestModel, onError?: () => void) => {
            const response = await postAsync<boolean>(departmentStructreURL + "/Delete", payload);

            if (!response || !axiosSuccess) {
                onError && onError();
            }

            return response;
        }, [postAsync, axiosSuccess],
    );

    const getDepartmentStructureAsync = useCallback(
        async (onError?: () => void) => {
            const response = await getAsync<DepartmentStructureModel[]>(departmentStructreURL + "/GetDepartmentsStructure");

            if (!response || !axiosSuccess) {
                onError && onError();
            }

            return response;
        },
        [getAsync, axiosSuccess]
    );

    const existDepartmentStructureAsync = useCallback(
        async (payload: DepartmentStructureRequestModel, onError?: () => void) => {
            const response = await postAsync<boolean>(departmentStructreURL + "/Exists", payload);

            if (!response || !axiosSuccess) {
                onError && onError();
            }

            return response;
        },
        [postAsync, axiosSuccess]
    );

    return {
        isLoading: axiosLoading,
        getDepartmentStructureAsync,
        addDepartmentStructureAsync,
        updateDepartmentStructureAsync,
        removeDepartmentStructureAsync,
        existDepartmentStructureAsync,
        departmentExistsAsync,
        addDepartmentAsync,
        updateDepartmentAsync,
        removeDepartmentAsync,
        officeExistsAsync,
        addOfficeAsync,
        updateOfficeAsync,
        removeOfficeAsync,
        addProviderAsync,
        updateProviderAsync,
        existsProviderAsync,
        axiosError
    }
};
