import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import RoutesApp from "./Routing/routes";
import { ThemeProvider } from "@emotion/react";
import { fontTheme } from "./PRISMCareTheme";

function App() {
  return (
    <ThemeProvider theme={fontTheme}>
      <Router>
        <RoutesApp />
      </Router>
    </ThemeProvider>
  );
}

export default App;
