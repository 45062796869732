import { Box } from "@mui/system";
import { CustomDialog } from "../Shared/Modal/Modal.component";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import CloseIcon from "@mui/icons-material/Close";
import {
  Avatar,
  Button,
  Divider,
  Grid,
  IconButton,
  InputLabel,
  SelectChangeEvent,
  Select,
  Tab,
  Tabs,
  TextField,
  MenuItem,
  FormControl,
  FormHelperText,
  CircularProgress,
} from "@mui/material";
import { SyntheticEvent, useEffect, useState } from "react";
import { useAxios } from "../../hooks/useAxios";
import { isEmpty, isUndefined } from "lodash";
import { getSessionUser } from "../../Shared/sessionUser.provider";
import { useOutletContext } from "react-router";
import { Circle } from "@mui/icons-material";
import { CleanButton } from "../../Shared/CleanHandler.provider";
import { downloadFileResponse } from "../../Shared/Files.provider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { Dayjs } from "dayjs";
import { DateDisplayFormat } from "../../constants/global.constants";
import {
  DateToStringStandarized,
  removeHoursFromDate,
} from "../../Shared/DateTime.provider";
import { colorsPalette } from "../../constants/styles.constants";

//#region Definitions and constants
type PatientListTasksModalProps = {
  open: boolean;
  handleClose: Function;
  tasks?: Task[];
  setTaskCount: React.Dispatch<React.SetStateAction<number>>;
  taskCount: number;
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface User {
  userId: string;
  firstName: string;
  lastName: string;
}

interface Task {
  id?: string;
  title?: string;
  assignDate?: Date;
  body?: string;
  createdDate?: Date;
  modifiedDate?: Date;
  status?: string;
  userId?: string;
  followUpId?: string | null;
}

interface TaskGroup {
  tasksCompleted: Task[];
  tasksPending: Task[];
  tasksFollowUp: Task[];
}

const taskStatusValues = {
  Green: colorsPalette.green,
  Blue: colorsPalette.blue,
  Yellow: colorsPalette.yellow,
  Red: colorsPalette.red,
}

type taskStatus = keyof typeof taskStatusValues;

const styles = {
  customTitle: {
    padding: 1,
    bgcolor: colorsPalette.blue,
    color: colorsPalette.white,
    fontSize: 34,
    fontWeight: "medium",
  },
  iconCustomTitle: {
    width: 40,
    height: 40,
    marginLeft: 1,
    marginRight: 1,
  },
  closeIconCustomTitle: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
  },
  closeIconStroke: {
    stroke: colorsPalette.white,
    strokeWidth: 2,
  },
  downloadIcon: {
    width: 140,
    marginRight: 1,
    marginLeft: "auto",
    paddingLeft: 2,
    color: colorsPalette.dark,
    textTransform: "none",
    fontWeight: "bold",
    lineHeight: 1.2,
  },
  circularProgressIcon: {
    color: "primary",
    position: "absolute",
    marginTop: 2,
    marginRight: 7,
  },
  coloredDot: {
    marginRight: 1,
    width: 10,
    height: 10,
  },
  createNewTaskTitle: {
    display: "flex",
    justifyContent: "flex-start",
    fontWeight: "bold",
    fontSize: 22,
    marginBottom: 1,
  },
  addCancelButtons: {
    display: "flex",
    justifyContent: "end",
    alignItems: "end",
    paddingBottom: 1,
  }
};
//#endregion

//#region Tabs configuration
function TabPanel(props: TabPanelProps): JSX.Element {
  const { children, value, index, ...other } = props;

  return (
    <Box
      sx={{ height: 550, overflow: "auto", maxHeight: 550, pr: 1 }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ height: "100%" }}>{children}</Box>}
    </Box>
  );
}
//#endregion

export const PatientListTasksModal = (
  props: PatientListTasksModalProps
): JSX.Element => {
  //#region Hooks and local variables
  let { id, name } = getSessionUser();
  const { manageErrorAlert, showAlertSnack } = useOutletContext<{
    manageErrorAlert: Function;
    showAlertSnack: Function;
  }>();

  const [tabIndex, setTabIndex] = useState<number>(0);
  const [taskTitle, setTaskTitle] = useState<string>("");
  const [assignDate, setAssignDate] = useState<Date>();
  const [taskBody, setTaskBody] = useState<string>("");
  const [priority, setPriority] = useState<string>("");
  const [teamMember, setTeamMember] = useState<string>("");
  const [userList, setUserList] = useState<User[]>([]);
  const [followUpList, setFollowUpList] = useState<User[]>([]);
  const [completedTaskList, setCompletedTaskList] = useState<Task[]>([]);
  const [pendingTaskList, setPendingTaskList] = useState<Task[]>([]);
  const [followUpTaskList, setFollowUpTaskList] = useState<Task[]>([]);
  const [refresh, setRefresh] = useState<boolean>(true);
  const [followUp, setFollowUp] = useState<string>("");
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const { getAsync, postAsync, updateAsync, axiosError, axiosSuccess } =
    useAxios();
  const { axiosLoading, downloadFileAsync } = useAxios();

  useEffect(() => {
    getTaskCount();
    if (props.open) {
      getIsUserAdmin();
      getUserList();
      getTaskList();
      getTaskCount();
      if (tabIndex === 2) {
        setTabIndex(0);
      }
      id = getSessionUser().id;
    }
  }, [props.open, refresh]);
  //#endregion

  //#region axios
  const getIsUserAdmin = async () => {
    const axiosGetIsUserAdmin = await getAsync<boolean>(
      `User/GetUserIsAdmin?guid=${id}`
    );
    if (axiosGetIsUserAdmin === undefined) {
      manageErrorAlert(axiosError?.Messages[0]);
      return false;
    }

    setIsAdmin(axiosGetIsUserAdmin);
  };

  const getUserList = async () => {
    const axiosGetUserList = await getAsync<User[]>(`Task/GetAllUserNames`);
    setRefresh(false);
    if (!axiosGetUserList) {
      manageErrorAlert(axiosError?.Messages[0]);
      return;
    }
    setUserList(axiosGetUserList);
    setFollowUpList(axiosGetUserList);
  };

  const getTaskList = async () => {
    const axiosGetTaskList = await getAsync<TaskGroup>(
      `Task/GetTasksGroupedByStatusAndDateByUserId?userId=${id}`
    );
    setRefresh(false);
    if (!axiosGetTaskList) {
      manageErrorAlert(axiosError?.Messages[0]);
      return;
    }
    setCompletedTaskList(axiosGetTaskList.tasksCompleted);
    setPendingTaskList(axiosGetTaskList.tasksPending);
    setFollowUpTaskList(axiosGetTaskList.tasksFollowUp);
  };

  const getTaskCount = async () => {
    const axiosGetTaskCount = await getAsync<number>(
      `Task/CountUserTasks?userId=${id}`
    );
    setRefresh(false);
    props.setTaskCount(axiosGetTaskCount || 0);
  };

  const postTask = async (newTask: Task) => {
    const axiosPostTask = await postAsync<Task>(`Task/AddTask`, newTask);
    setRefresh(true);
    if (axiosSuccess) {
      showAlertSnack("The task was added successfully.", "success");
      cleanModal();
    }
    return axiosPostTask;
  };

  const checkTask = async (checkedTask: Task) => {
    const { id } = checkedTask;
    const axiosCheckTask = await updateAsync<string>(
      `Task/CompleteTask?taskId=`,
      `${id}`,
      checkedTask
    );
    setRefresh(true);
    if (!axiosCheckTask) {
      manageErrorAlert(axiosError?.Messages[0]);
      return;
    }
    if (axiosSuccess) {
      showAlertSnack("The task was completed successfully.", "success");
    }
    return axiosCheckTask;
  };

  const uncheckTask = async (uncheckedTask: Task) => {
    uncheckedTask.assignDate = new Date();
    const { id } = uncheckedTask;

    const axiosUncheckTask = await updateAsync<string>(
      `Task/UncheckTask?taskId=`,
      `${id}`,
      uncheckedTask
    );
    setRefresh(true);
    if (!axiosUncheckTask) {
      manageErrorAlert(axiosError?.Messages[0]);
      return;
    }
    if (axiosSuccess) {
      showAlertSnack("The task was unchecked successfully.", "success");
    }
    return axiosUncheckTask;
  };
  //#endregion

  //#region task methods
  const validationErrors = {
    ...(isEmpty(taskTitle?.trim()) && {
      Title: "Title is required",
    }),
    ...(isUndefined(assignDate) && {
      AssignDate: "Assign Date is required",
    }),
    ...(assignDate?.toString() === "Invalid Date" && {
      AssignDate: "Assign Date must be a valid date",
    }),
    ...(!!assignDate
      ? assignDate < removeHoursFromDate(new Date()) && {
          AssignDate: "Start Date cannot be before today's date",
        }
      : false),
    ...(isEmpty(taskBody?.trim()) && {
      Description: "Description is required",
    }),
    ...(isEmpty(teamMember?.trim()) &&
      isAdmin && {
        TeamMember: "Team member is required",
      }),
    ...(isEmpty(priority?.trim()) && {
      Priority: "Priority is required",
    }),
  };

  const saveTask = (): void => {
    const task: Task = {
      title: taskTitle,
      assignDate: assignDate,
      body: taskBody,
      status: remapColorToStatus(priority || ""),
      createdDate: new Date(),
      userId: isAdmin ? teamMember : id,
      followUpId: followUp === "" ? null : followUp,
    };

    postTask(task);
  };
  //#endregion

  //#region Modal methods
  const remapStatusToColor = (status: string): string => {
    let color: string = "";
    switch (status) {
      case "Green":
        color = taskStatusValues.Green;
        break;
      case "Blue":
        color = taskStatusValues.Blue;
        break;
      case "Yellow":
        color = taskStatusValues.Yellow;
        break;
      case "Red":
        color = taskStatusValues.Red;
        break;
      default:
        break;
    }
    return color;
  };

  const remapColorToStatus = (status: string): string => {
    switch (status) {
      case taskStatusValues.Green:
        status = "Green";
        break;
      case taskStatusValues.Blue:
        status = "Blue";
        break;
      case taskStatusValues.Yellow:
        status = "Yellow";
        break;
      case taskStatusValues.Red:
        status = "Red";
        break;
      default:
        break;
    }
    return status;
  };

  const cleanModal = (moveToStart: boolean = true): void => {
    setTaskTitle("");
    setAssignDate(undefined);
    setTaskBody("");
    setTeamMember("");
    setPriority("");
    setFollowUp("");
    moveToStart && setTabIndex(0);
  };

  const closeModal = (): void => {
    props.handleClose();
    cleanModal();
  };

  const tabMenuChangeHandler = (event: SyntheticEvent, newValue: number) =>
    setTabIndex(newValue);

  const setTextFieldTaskTitleHandler = (event: any) =>
    setTaskTitle(event.target.value);

  const setTextFieldTaskBodyHandler = (event: any) =>
    setTaskBody(event.target.value);

  const changeFollowUpHandler = (event: any) => setFollowUp(event.target.value);

  const changePriorityHandler = (event: SelectChangeEvent) =>
    setPriority(event.target.value as taskStatus);

  const changeTeamMemberHandler = (event: SelectChangeEvent) =>
    setTeamMember(event.target.value as string);

  const exportTaskHandler = async () => {
    let response = await downloadFileAsync("Task/ExportTasks", `userId=${id}`);
    if (axiosSuccess) {
      downloadFileResponse(response, `TaskHistory_${name}.xlsx`);
    } else {
      manageErrorAlert(axiosError?.Messages[0]);
    }
  };
  //#endregion

  //#region UI components
  const ui_CustomTitle: JSX.Element = (
    <>
      <Box sx={styles.customTitle}>
        <Grid container>
          <Grid item xs={11}>
            <AssignmentOutlinedIcon sx={styles.iconCustomTitle} />
            Task Dashboard
          </Grid>
          <Grid item xs={1} sx={styles.closeIconCustomTitle}>
            <IconButton onClick={closeModal}>
              <CloseIcon sx={styles.closeIconStroke} />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
    </>
  );

  function allProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  function ui_taskStatus(task: Task): JSX.Element {
    const { status, id } = task;
    const color = remapStatusToColor(status || "");
    const handleTaskClicked = (): void => {
      status !== "Green" ? checkTask(task || "") : uncheckTask(task || "");
    };

    return (
      <Avatar
        key={id}
        sx={{ bgcolor: color, width: 50, height: 50, borderRadius: 3 }}
        variant={"rounded"}
      >
        {color === taskStatusValues.Green ? (
          <>
            <IconButton
              onClick={handleTaskClicked}
              sx={{ "&:hover": { bgcolor: color } }}
            >
              <DoneOutlinedIcon sx={{ stroke: colorsPalette.white, strokeWidth: 3 }} />
            </IconButton>
          </>
        ) : (
          <>
            <IconButton
              onClick={handleTaskClicked}
              sx={{
                stroke: color,
                strokeWidth: 3,
                "&:hover": { stroke: colorsPalette.white },
              }}
            >
              {/* // Same color of bg to make it invisible */}
              <DoneOutlinedIcon />
            </IconButton>
          </>
        )}
      </Avatar>
    );
  }

  function ui_taskList(tasksGroups: Task[]): JSX.Element {
    const getColorTitle = (taskStatusCode: string = "Green"): string =>
      remapStatusToColor(taskStatusCode) === taskStatusValues.Green
        ? colorsPalette.gray
        : colorsPalette.dark;

    const getColorBody = (taskStatusCode: string = "Green"): string =>
      remapStatusToColor(taskStatusCode) === taskStatusValues.Green
        ? colorsPalette.gray
        : colorsPalette.darkGray;

    const taskGroupList = tasksGroups.map((t) => (
      <Grid container key={t.id} sx={{ overflow: "hidden" }}>
        <Grid item xs={12}>
          <Grid container key={t.id} sx={{ marginBottom: 2 }}>
            {ui_taskStatus(t)}
            <Grid
              item
              xs={10}
              sx={{ justifyContent: "start", marginLeft: 2, minWidth: "88%" }}
            >
              <Grid container>
                <Grid item xs={8}>
                  <Box
                    sx={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "106%",
                      maxWidth: "105%",
                      fontWeight: "bold",
                      color: getColorTitle(t.status),
                      whiteSpace: "nowrap",
                      fontSize: 24,
                      marginTop: -1,
                    }}
                  >
                    {t.title}
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    marginTop: -0.5,
                  }}
                >
                  <Box
                    sx={{ fontWeight: "bold", color: getColorBody(t.status) }}
                  >
                    {t.status === remapColorToStatus(taskStatusValues.Green)
                      ? `completed: ${DateToStringStandarized(
                          new Date(t.modifiedDate || new Date())
                        )}`
                      : `assigned: ${DateToStringStandarized(
                          new Date(t.assignDate || new Date())
                        )}`}
                  </Box>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    wordWrap: "break-word",
                    width: "106%",
                    maxWidth: "105%",
                    maxHeight: 150,
                    overflowY: "auto",
                    color: getColorBody(t.status),
                    whiteSpace: "pre-line",
                  }}
                >
                  {t.body}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Divider orientation="horizontal" flexItem sx={{ marginBottom: 2 }} />
      </Grid>
    ));

    return <>{taskGroupList}</>;
  }

  function ui_tasksListTabs(): JSX.Element {
    function ui_label(label: string): JSX.Element {
      return <span className={"TabLabel"}>{label}</span>;
    }
    return (
      <>
        <Grid container sx={{ marginBottom: 2 }}>
          <Grid item xs={9}>
            <Tabs
              value={tabIndex}
              onChange={tabMenuChangeHandler}
              centered={true}
              variant="fullWidth"
              classes={{ indicator: "MuiTabs-indicator-Task" }}
            >
              <Tab label={ui_label("Active")} {...allProps(0)} />
              <Tab label={ui_label("Completed")} {...allProps(1)} />
              <Tab label={ui_label("Add Task")} {...allProps(2)} />
              <Tab label={ui_label("Follow Up")} {...allProps(3)} />
            </Tabs>
          </Grid>
          <Grid item xs={3} sx={{ display: "flex", justifyContent: "end" }}>
            <Button
              onClick={exportTaskHandler}
              disabled={
                axiosLoading ||
                (completedTaskList && completedTaskList.length < 1)
              }
              size="medium"
              startIcon={
                <FileDownloadOutlinedIcon className="BiggerIconButton " />
              }
              sx={styles.downloadIcon}
            >
              {!axiosLoading ? "Download Archive" : "Downloading..."}
            </Button>
            {axiosLoading && (
              <CircularProgress size={24} sx={styles.circularProgressIcon} />
            )}
          </Grid>
        </Grid>
        <TabPanel value={tabIndex} index={0} key={"pending tasks"}>
          {!!pendingTaskList && pendingTaskList.length !== 0
            ? ui_taskList(pendingTaskList)
            : "There is no tasks, try adding one!"}
        </TabPanel>
        <TabPanel value={tabIndex} index={1} key={"completed tasks"}>
          {!!completedTaskList && completedTaskList.length !== 0
            ? ui_taskList(completedTaskList)
            : "There is no tasks, try completing one!"}
        </TabPanel>
        <TabPanel value={tabIndex} index={2} key={"add tasks"}>
          {ui_createTask}
        </TabPanel>
        <TabPanel value={tabIndex} index={3} key={"follow up tasks"}>
          {!!followUpTaskList && followUpTaskList.length !== 0
            ? ui_taskList(followUpTaskList)
            : "There is no follow up tasks for you"}
        </TabPanel>
      </>
    );
  }

  const ui_selectTeamMember: JSX.Element = (
    <FormControl
      fullWidth
      size="small"
      sx={{ marginBottom: 1 }}
      error={!!validationErrors.TeamMember}
    >
      <InputLabel id="TeamMemberSelectLabel">Assign Team Member</InputLabel>
      <Select
        labelId="TeamMemberSelectLabel"
        id="TeamMemberSelect"
        value={teamMember}
        label="Assign Team Member"
        onChange={changeTeamMemberHandler}
        error={!!validationErrors.TeamMember && props.open && isAdmin}
        endAdornment={
          teamMember !== "" && (
            CleanButton(changeTeamMemberHandler)
          )
        }
      >
        {userList.length === 0 && (
          <MenuItem value="">No users found...</MenuItem>
        )}
        {userList.map(({ userId, firstName, lastName }) => (
          <MenuItem
            key={userId}
            value={userId}
          >{`${firstName} ${lastName}`}</MenuItem>
        ))}
      </Select>
      <FormHelperText error={isAdmin && !!validationErrors.TeamMember}>
        {validationErrors.TeamMember || " "}
      </FormHelperText>
    </FormControl>
  );

  const ui_selectPriority: JSX.Element = (
    <>
      <FormControl
        fullWidth
        size="small"
        sx={{ marginBottom: 1 }}
        error={!!validationErrors.Priority}
      >
        <InputLabel id="prioritySelectLabel">Priority</InputLabel>
        <Select
          labelId="prioritySelectLabel"
          id="prioritySelect"
          value={priority}
          label="Priority"
          onChange={changePriorityHandler}
          error={!!validationErrors.Priority && props.open}
          endAdornment={
            priority !== "" && (
              CleanButton(changePriorityHandler)
            )
          }
        >
          <MenuItem key={taskStatusValues.Blue} value={taskStatusValues.Blue}>
            <Circle
              htmlColor={colorsPalette.blue}
                sx={styles.coloredDot}
            />
            Low
          </MenuItem>
          <MenuItem key={taskStatusValues.Yellow} value={taskStatusValues.Yellow}>
            <Circle
              htmlColor={colorsPalette.yellow}
              sx={{ marginRight: 1, width: 10, height: 10 }}
            />
            Medium
          </MenuItem>
          <MenuItem key={taskStatusValues.Red} value={taskStatusValues.Red}>
            <Circle
              htmlColor={colorsPalette.red}
              sx={{ marginRight: 1, width: 10, height: 10 }}
            />
            High
          </MenuItem>
        </Select>
        <FormHelperText error={!!validationErrors.Priority}>
          {validationErrors.Priority || " "}
        </FormHelperText>
      </FormControl>
    </>
  );

  const ui_followUpSelector: JSX.Element = (
    <FormControl fullWidth size="small" sx={{ marginBottom: 1 }}>
      <InputLabel id="FollowUpSelectLabel">Add to follow up</InputLabel>
      <Select
        labelId="FollowUpSelectLabel"
        id="FollowUpSelect"
        value={followUp}
        label="Add to follow up"
        onChange={changeFollowUpHandler}
        endAdornment={
          followUp !== "" && (
            CleanButton(changeFollowUpHandler)
          )
        }
      >
        {followUpList.length === 0 && (
          <MenuItem value="">No follow up users found...</MenuItem>
        )}
        {followUpList.map(({ userId, firstName, lastName }) => (
          <MenuItem
            key={userId}
            value={userId}
          >{`${firstName} ${lastName}`}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  const ui_createTask: JSX.Element = (
    <Grid container sx={{ height: "100%", overflow: "hidden" }} spacing={1}>
      <Grid item xs={12}>
        <Box
          sx={styles.createNewTaskTitle}
        >
          Create New Task
        </Box>
      </Grid>
      <Grid item xs={6}>
        <TextField
          id="taskTitle"
          type={"text"}
          onChange={setTextFieldTaskTitleHandler}
          value={taskTitle || ""}
          error={!!validationErrors.Title && props.open}
          helperText={validationErrors.Title || " "}
          inputProps={{
            minLength: 1,
            autoComplete: "off",
          }}
          fullWidth
          size="small"
          label="Title"
          variant="outlined"
          sx={{ marginBottom: 1 }}
        />
      </Grid>
      <Grid item xs={6}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            disablePast
            onChange={(newDate: Dayjs | null) =>
              setAssignDate(newDate?.toDate() || new Date())
            }
            value={assignDate || null}
            inputFormat={DateDisplayFormat}
            renderInput={(params) => {
              return (
                <TextField
                  id="AssignDate"
                  {...params}
                  label="Assign Date"
                  error={!!validationErrors.AssignDate}
                  helperText={validationErrors.AssignDate || " "}
                  size="small"
                  fullWidth
                  InputProps={{
                    endAdornment: params.InputProps?.endAdornment,
                  }}
                />
              );
            }}
            PopperProps={{
              placement: "bottom-start",
              sx: {paddingBottom: 2}
            }}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} sx={{ marginBottom: 1 }}>
        <TextField
          id="taskBody"
          type={"text"}
          onChange={setTextFieldTaskBodyHandler}
          value={taskBody || ""}
          error={!!validationErrors.Description && props.open}
          helperText={validationErrors.Description || " "}
          inputProps={{
            minLength: 1,
            autoComplete: "off",
          }}
          fullWidth
          size="small"
          label="Task Description"
          variant="outlined"
          multiline
          rows={5}
          className={"MuiTaskTextInput Background"}
        />
      </Grid>
      <Grid item xs={6}>
        {ui_selectPriority}
      </Grid>
      <Grid item xs={6}>
        {ui_followUpSelector}
      </Grid>
      <Grid item xs={6}>
        {isAdmin && ui_selectTeamMember}
      </Grid>
      <Grid
        item
        xs={12}
        sx={styles.addCancelButtons}
      >
        <Button
          variant="contained"
          size="small"
          onClick={saveTask}
          disabled={Object.keys(validationErrors).length > 0}
          sx={{ width: 250 }}
        >
          Add New
        </Button>
        <Button
          size="small"
          variant="contained"
          color="inherit"
          sx={{ marginLeft: 1 }}
          onClick={() => cleanModal(false)}
        >
          Cancel
        </Button>
      </Grid>
    </Grid>
  );

  const ui_taskModalBody: JSX.Element = (
    <>
      <Grid container sx={{ overflow: "hidden" }}>
        <Grid item xs={12}>
          {ui_tasksListTabs()}
        </Grid>
      </Grid>
    </>
  );

  const ui_dialog: JSX.Element = (
    <>
      <CustomDialog
        isOkButtonDisabled={false}
        open={props.open}
        onConfirm={closeModal}
        handleClose={props.handleClose}
        size="md"
        customTitle={ui_CustomTitle}
        hideButtons
      >
        {ui_taskModalBody}
      </CustomDialog>
    </>
  );
  //#endregion

  return ui_dialog;
};
