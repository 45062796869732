  import {TextField} from "@mui/material";
  import { useEffect, useState } from "react";
  import { isEmpty } from "lodash";
  import { CustomDialog } from "../Shared/Modal/Modal.component";
  import { PatientAlertModel } from "../../Api/Model/Patient/PatientAlerts/PatientAlertModel";

  type AlertHistoryModalProps = {
    open: boolean;
    handleClose: Function;
    onConfirm: Function;
    patientAlertParam?: PatientAlertModel;
  };
  
  export const AlertHistoryModal = (props: AlertHistoryModalProps) => {
    const [patientAlert, setPatientAlert] = useState<PatientAlertModel>(
      {} as PatientAlertModel
    );
  
    const closeModal = () => props.handleClose();
  
    const onConfirm = () => {
      closeModal();
      !!props.onConfirm && props.onConfirm(patientAlert);
    };
  
    useEffect(() => {
      if (!!props.patientAlertParam) {
        setPatientAlert({...props.patientAlertParam,alertBody:''});
      } else {
        setPatientAlert({} as PatientAlertModel);
      }
    }, [props.open]);
  
    let validationErrors = {
      ...(isEmpty(patientAlert.alertBody?.trim()) && {
        alertBody: "Alert body is required",
      }),
      ...(!isEmpty(patientAlert.alertBody?.trim()) &&
        (patientAlert.alertBody?.trim().length || 0) <= 4 && {
            alertBody: "Alert body must be longer than 5 characters",
        }),
    };
  
    const alertBodySetHandler = (event: any) => {
      let fieldS = event.target.id.toString();
      setPatientAlert((dc: PatientAlertModel) => {
        return { ...dc, [fieldS]: event.target.value };
      });
    };
  
    return (
      <CustomDialog
        isOkButtonDisabled={Object.keys(validationErrors).length > 0}
        open={props.open}
        onConfirm={onConfirm}
        confirmText={'Reset Flag'}
        handleClose={props.handleClose}
        title={"Update Alert"}
        size="sm"
      >
            <TextField
              id="alertBody"
              type={"text"}
              onChange={alertBodySetHandler}
              label="Alert Body"
              className="mb-2"
              value={patientAlert.alertBody || ""}
              error={!!validationErrors.alertBody}
              helperText={validationErrors.alertBody}
              variant="outlined"
              inputProps={{
                tabIndex: 0,
                minLength: 5
              }}
              sx={{ marginTop: "5px" }}
              rows={7}
              multiline
              required
              fullWidth
            />
      </CustomDialog>
    );
  };
  