import { Cancel } from "@mui/icons-material";
import {ListItem, Chip,IconButton,Button,Autocomplete,Grid,TextField, createFilterOptions, FilterOptionsState } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { DiseaseConditionsModel } from "../../../Api/Model/DiseaseConditions/DiseaseConditionsModel";
import { PatientDiseaseConditionsModel } from "../../../Api/Model/PatientDiseaseConditions";
import { useAxios } from "../../../hooks/useAxios";
import { PatientInfoComponentParams } from "../../../Shared/PatientInfoComponentParams";
import { PatientContext } from "../../../Context/PatientContext";
import { emptyGUID } from "../../../constants/guid.contast";

const filter = createFilterOptions<DiseaseConditionsModel>();

export const PatientDiseaseConditions = (params: PatientInfoComponentParams) => {
  const { showAlertSnack, isPatientInUse, manageErrorAlert } = params;
  const [patientDiseaseConditions, setPatientDiseaseConditions] = useState<PatientDiseaseConditionsModel[]>([]);
  const [diseaseConditions, setDiseaseConditions] = useState<DiseaseConditionsModel[]>([]);
  const [selectedDiseaseCondition, setSelectedDiseaseCondition] = useState('');
  const { deleteAsync, postAsync, getAsync, axiosError, axiosSuccess } = useAxios();  
  const { currentPatient, patientIsReady } = useContext(PatientContext);

  const getDiseaseConditionsCatalog = async () => {
    const axiosGet = await getAsync<DiseaseConditionsModel[]>(
      "DiseaseCondition/GetDiseaseConditions",
      {
        PageNumber: 1,
        PageSize: 50,
      }
    );

    if (!axiosGet) {
      manageErrorAlert(axiosError?.Messages[0]);
      return;
    }

    setDiseaseConditions(axiosGet);
  };

  const savePatientDiseaseConditionsHandler = async (event: any) => {
    if (patientDiseaseConditions.length === 0) {
      await deleteAsync(
        "PatientDiseaseCondition/DeletePatientDiseaseConditions",
        currentPatient?.patientId || emptyGUID
      );
      if (axiosSuccess) {
        showAlertSnack(
          "Disease conditions were removed successfully.",
          "success"
        );
      }
      return;
    }
    await postAsync(
      "PatientDiseaseCondition/AddPatientDiseaseConditions",
      patientDiseaseConditions
    );
    if (axiosSuccess) {
      showAlertSnack("Disease conditions saved successfully", "success");
    } else {
      manageErrorAlert(axiosError?.Messages[0]);
    }
  };

  const cancelPatientDiseaseConditionsHandler = (event: any) => {
    getPatientDiseaseConditions(currentPatient?.patientId || emptyGUID);
  };

  const addDiseaseConditionToListHandler = (event: any, value: any) => {
    // Filter patient diseases in type groups to validate against them separetely
    const patientDiseaseConditionsFromCatalog = patientDiseaseConditions.filter(pdc => pdc.diseaseId && pdc.diseaseDescription);
    const patientDiseaseConditionsFromDataImport = patientDiseaseConditions.filter(pdc => ( !pdc.diseaseId || pdc.diseaseId === emptyGUID) && pdc.diseaseName && pdc.diseaseDescription);
    const patientDiseaseConditionsFromCustom = patientDiseaseConditions.filter(pdc => !pdc.diseaseId && !pdc.diseaseDescription);
    
    // Get the disease name
    let receivedDiseaseName = value.description || value;
    receivedDiseaseName = receivedDiseaseName.trim().toUpperCase();

    // Check if the disease already exists in the catalog
    var foundDisease = value.catalogId
      ? diseaseConditions.find(pdc => pdc.catalogId === value.catalogId) // search by id
      : diseaseConditions.find(pdc => `${pdc.catalogName.trim().toUpperCase()}-${pdc.description.trim().toUpperCase()}` === receivedDiseaseName); // search by "name-description"
    
    // ! CATALOG TYPE DISEASE DETECTED SCENARIO
    if (foundDisease) {
      setSelectedDiseaseCondition(foundDisease.catalogId);

      // Create disease condition model based on the existing one found in the catalog
      let newPatientDiseaseCondition: PatientDiseaseConditionsModel = {
        patientId: currentPatient?.patientId || emptyGUID,
        diseaseId: foundDisease.catalogId,
        isActive: true,
        diseaseDescription: foundDisease.description,
        diseaseName: foundDisease.catalogName
      };

      // ? CHECKING CATALOG TYPE DISEASE DOES NOT REPEAT WITH ANY...
      // * Custom diseases
      if (patientDiseaseConditionsFromCustom.find(dcfcus => dcfcus.diseaseName.trim().toUpperCase() === `${newPatientDiseaseCondition.diseaseName.trim().toUpperCase()}-${newPatientDiseaseCondition.diseaseDescription.trim().toUpperCase()}`)) {
        return;
      }

      // * Catalog diseases
      if (patientDiseaseConditionsFromCatalog.find(dcfcat => dcfcat.diseaseId!.trim().toUpperCase() === newPatientDiseaseCondition.diseaseId!.trim().toUpperCase()) ) {
        return;
      }

      // * DataImport diseases
      if (patientDiseaseConditionsFromDataImport.find(dcfdi => dcfdi.diseaseName.trim().toUpperCase() === newPatientDiseaseCondition.diseaseName.trim().toUpperCase() || dcfdi.diseaseDescription.trim().toUpperCase() === newPatientDiseaseCondition.diseaseDescription.trim().toUpperCase())) {
        return;
      }

      // Save the new catalog type disease in the patient's existing diseases list
      setPatientDiseaseConditions([
        ...patientDiseaseConditions,
        newPatientDiseaseCondition, 
      ]);
    
      return;
    }

    // ! CUSTOM DISEASE SCENARIO
    if (!value.catalogId) {
      // If a custom disease does not match any catalog disease ...
      let newUnregistedDisease = value.description || value;
      setSelectedDiseaseCondition(newUnregistedDisease);

      // ... create a custom disease condition model
      let newPatientDiseaseCondition: PatientDiseaseConditionsModel = {
        patientId: currentPatient?.patientId || emptyGUID,
        diseaseId: undefined,  // custom diseases has neither diseaseId
        isActive: true,
        diseaseDescription: "", // nor description
        diseaseName: newUnregistedDisease.trim()
      };

      // ? CHECKING CUSTOM TYPE DISEASE DOES NOT REPEAT WITH ANY...
      // * Custom diseases
      if (patientDiseaseConditionsFromCustom.find(dcfcus => dcfcus.diseaseName.trim().toUpperCase() === newPatientDiseaseCondition.diseaseName.trim().toUpperCase())) {
        return;
      }

      // * Catalog diseases
      if (patientDiseaseConditionsFromCatalog.find(dcfcat => `${dcfcat.diseaseName.trim().toUpperCase()}-${dcfcat.diseaseDescription.trim().toUpperCase()}` === newPatientDiseaseCondition.diseaseName.trim().toUpperCase())) {
        // ? it might never enter here since the "catalog type disease detected" should catch this, but is better to double check...
        return;
      }

      // * DataImport diseases
      if (patientDiseaseConditionsFromDataImport.find(dcfdi => `${dcfdi.diseaseName.trim().toUpperCase()}-${dcfdi.diseaseDescription.trim().toUpperCase()}` === newPatientDiseaseCondition.diseaseName.trim().toUpperCase())) {
        return;
      }
      
      // Save the new custom disease in the patient's existing diseases list
      setPatientDiseaseConditions([
        ...patientDiseaseConditions,
        newPatientDiseaseCondition,
      ]);
      return;
    }
  };

  const removePatientDiseaseConditionFromListHandler = (index: any) => {
    let data = [...patientDiseaseConditions];
    data.splice(index, 1);
    setPatientDiseaseConditions(data);
  };

  const getPatientDiseaseConditions = async (patientId:string) => {
    if (!patientId) {
      return;
    }
    const axiosGet = await getAsync<PatientDiseaseConditionsModel[]>(
      `PatientDiseaseCondition/GetPatientDiseaseConditions?patientId=${patientId}`
    );
    if (!axiosGet) {
      setPatientDiseaseConditions([]);
      return;
    }
    setPatientDiseaseConditions(axiosGet);
  };

  useEffect(() => {
    if(currentPatient === undefined || !patientIsReady) {
      return;
    }
    getDiseaseConditionsCatalog();
    getPatientDiseaseConditions(currentPatient.patientId || emptyGUID);
  }, [patientIsReady]);

  const customDiseaseConditionFilterHandler = (options: DiseaseConditionsModel[], params: FilterOptionsState<DiseaseConditionsModel>) => {
    const filtered = filter(options, params);
    const { inputValue } = params;
    const isExisting = options.some((option) => inputValue.trim().toUpperCase() === (option.catalogName.trim().toUpperCase() + "-" + option.description.trim().toUpperCase()));
    if (inputValue !== '' && !isExisting) { 
      filtered.push({ description: inputValue, catalogName: `Add unregistered disease condition: "${inputValue}"`, } as DiseaseConditionsModel);
    }
    return filtered;
  }

  useEffect(() => {
    setSelectedDiseaseCondition('');
  }, [selectedDiseaseCondition])

  const diseaseconditions_ui = (
    <>
      <Grid
        container
        spacing={1}
        rowSpacing={1}
        justifyContent="center"
        alignItems="center"
        marginTop={1}
        key={"form-DiseaseConditions"}
      >
        {patientDiseaseConditions && patientDiseaseConditions.map(
          (input: PatientDiseaseConditionsModel, index: any) => (
            <Grid item xs={12} key={"form-actualDiseaseCondition" + index}>
              <ListItem>
                <Chip
                  deleteIcon={
                    <IconButton disabled={isPatientInUse}>
                      {" "}
                      <Cancel />{" "}
                    </IconButton>
                  }
                  onDelete={() => removePatientDiseaseConditionFromListHandler(index)}
                  label={`${(input.diseaseName) + (!input.diseaseDescription ? "" : "-" + input.diseaseDescription)}`}
                />
              </ListItem>
            </Grid>
          )
        )}

        <Grid item xs={12} key={"DiseaseConditions"}>
          <Autocomplete
            onChange={(event, value) => addDiseaseConditionToListHandler(event, value)}
            value={null}
            key={selectedDiseaseCondition}
            disabled={isPatientInUse}
            options={diseaseConditions}
            filterOptions={(options, params) => customDiseaseConditionFilterHandler(options, params)}
            blurOnSelect={true}
            clearOnBlur={true}
            freeSolo={true}
            getOptionLabel={(option: any) => {
              return typeof option === 'string' ? option : !!option.inputValue ? option.inputValue
                : !option.catalogId ? option.catalogName : option.catalogName + "-" + option.description;
            }}
            renderInput={(params) => (
              <TextField {...params} label="Add disease condition" />
            )}
            componentsProps={{
              popper: {
                sx: {
                  paddingBottom: 2
                }
              }
            }}
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        rowSpacing={1}
        justifyContent="end"
        alignItems="center"
        marginTop={1}
        key={"SaveDiseaseConditions"}
      >
        <Grid item xs={1}>
          <Button
            disabled={isPatientInUse}
            onClick={savePatientDiseaseConditionsHandler}
            color="success"
            variant="contained"
            fullWidth
          >
            Save
          </Button>
        </Grid>
        <Grid item xs={1}>
          <Button
            disabled={isPatientInUse}
            onClick={cancelPatientDiseaseConditionsHandler}
            color="secondary"
            variant="contained"
            fullWidth
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
    </>
  );

  return diseaseconditions_ui;
};
