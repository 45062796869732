import { Backdrop, Box, Button, Fade, Grid, Modal, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { DiseaseConditionsModel } from '../../Api/Model/DiseaseConditions/DiseaseConditionsModel';
import { confirmationModalStyle } from "../../constants/styles.constants";
import { isEmpty } from "lodash";
import { useAxios } from '../../hooks/useAxios';

type DiseaseConditionModalProps = {
    open: boolean,
    handleClose: Function,
    onConfirm: Function,
    type: "create" | "update",
    description?: string,
    name?: string,
    diseaseConditionParam?: DiseaseConditionsModel
};

export const DiseaseConditionModal = (props: DiseaseConditionModalProps) => {
    const [diseaseCondition, setDiseaseCondition] = useState<DiseaseConditionsModel>({} as DiseaseConditionsModel);
    const [diseaseExists, setDiseaseExists] = useState(false);
    const { getAsync, axiosLoading } = useAxios();

    const closeModal = () => props.handleClose();
    const onConfirm = async () => {
        if (props.type === 'update') {
            closeModal();
            !!props.onConfirm && props.onConfirm(diseaseCondition);
            return;
        }

        const diseaseExist = await getAsync<boolean>('DiseaseCondition/Exists/' + diseaseCondition.catalogName);
        if (!diseaseExist) {
            closeModal();
            !!props.onConfirm && props.onConfirm(diseaseCondition);
        }
        else {
            setDiseaseExists(true);
        }
    }

    useEffect(() => {
        if (!props.open) {
            return;
        }

        if (!!props.diseaseConditionParam) {
            setDiseaseCondition(props.diseaseConditionParam);
        }
        else {
            setDiseaseCondition({} as DiseaseConditionsModel);
        }
    }, [props.open]);

    const validationErrors = {
        ...(diseaseExists) && {
            CatalogName: "The disease already exists",
        },
        ...(isEmpty(diseaseCondition.catalogName?.trim()) && {
            CatalogName: "Name is required",
        }),
        ...(diseaseCondition.catalogName?.trim().length <= 2 && {
            CatalogName: "Name must have more than 2 characters",
        }),
        ...(isEmpty(diseaseCondition.description?.trim()) && {
            Description: "Description is required",
        })
    };

    const handleSetTextField = (event: any) => {
        setDiseaseExists(false);
        let fieldS = event.target.name.toString();
        setDiseaseCondition((dc: DiseaseConditionsModel) => { return { ...dc, [fieldS]: event.target.value } });
    };

    return <Modal
        open={props.open}
        onClose={closeModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}>
        <Fade in={props.open}>
            <Box sx={confirmationModalStyle}>
                <Typography className='mb-4' id="modal-title" variant="h6" component="h2">{props.type === 'create' ? 'Create' : 'Update'} Disease Condition</Typography>

                <Grid container spacing={2} width={"100%"}>
                    <Grid item xs={12}>
                        <TextField
                            name="catalogName"
                            type={"text"}
                            onChange={handleSetTextField}
                            label="Name"
                            className='mb-2'
                            value={diseaseCondition.catalogName || ""}
                            error={!!validationErrors.CatalogName}
                            helperText={validationErrors.CatalogName}
                            variant="outlined"
                            required
                            fullWidth
                            inputProps={{
                                tabIndex: 0,
                                maxLength: 50,
                                minLength: 2,
                            }}
                            autoComplete='off'
                            disabled={props.type === 'update'}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="description"
                            type={"text"}
                            onChange={handleSetTextField}
                            value={diseaseCondition.description || ""}
                            label="Description"
                            error={!!validationErrors.Description}
                            helperText={validationErrors.Description}
                            variant="outlined"
                            inputProps={{
                                maxLength: 100,
                                minLength: 2,
                            }}
                            required
                            fullWidth
                            autoComplete='off'
                        />
                    </Grid>
                </Grid>

                <Box className="mt-4" sx={{ display: 'flex', justifyContent: 'flex-end', gap: '5px' }}>
                    <Button onClick={closeModal} variant="contained" color="inherit" size="small">Cancel</Button>
                    <Button onClick={onConfirm} variant="contained" color='success' size="small" disabled={!isEmpty(validationErrors) || axiosLoading}>{axiosLoading ? '. . . ' : 'Save'}</Button>
                </Box>
            </Box>
        </Fade>
    </Modal>
}
