import { CloseOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";

type ModalProps = {
  children: JSX.Element;
  open: boolean;
  handleClose: Function;
  onConfirm: Function;
  isOkButtonDisabled: boolean;
  size: "xs" | "sm" | "md" | "lg" | "xl";
  fullscreen?: boolean;
  title?: string;
  subTitle?: string;
  hideButtons?: boolean;
  fullWidth?: boolean;
  confirmText?: string;
  shouldWaitForConfirm?: boolean;
  customTitle?: JSX.Element;
  showCloseButton?: boolean;
  unlockCloseModal?: boolean;
};

export const CustomDialog = (props: ModalProps) => {
  const closeModal = () => props.handleClose();

  const onConfirm = () => {
    !!props.onConfirm && props.onConfirm();
    (props.shouldWaitForConfirm === undefined || !props.shouldWaitForConfirm) &&
      closeModal();
  };

  const handleClose = (event: any, reason: any) => {
    if (props.unlockCloseModal === undefined || !props.unlockCloseModal) {
      return;
    }
    props.handleClose();
  };

  return (
    <Dialog
      open={props.open}
      scroll="paper"
      fullScreen={props.fullscreen ?? false}
      transitionDuration={800}
      maxWidth={props.size}
      disableEscapeKeyDown={
        props.unlockCloseModal === undefined ? true : !props.unlockCloseModal
      }
      fullWidth={props.fullWidth ?? true}
      onClose={handleClose}
    >
      {props.customTitle !== undefined && (
        <Box>
          {props.customTitle
            ? props.customTitle
            : props.title && <DialogTitle>{props.title}</DialogTitle>}
        </Box>
      )}
      {props.showCloseButton && props.customTitle === undefined && (
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <DialogTitle>{props.title}</DialogTitle>
          {props.showCloseButton !== undefined && props.showCloseButton && (
            <IconButton
              onClick={closeModal}
              sx={{ position: "absolute", right: 3, top: 3, m: 1 }}
            >
              <CloseOutlined />
            </IconButton>
          )}
        </Box>
      )}
      <DialogContent>
        {props.subTitle && (
          <DialogContentText sx={{ mb: 2, textAlign: "center" }}>
            {props.subTitle}
          </DialogContentText>
        )}
        {props.children}
      </DialogContent>
      {!props.hideButtons && (
        <DialogActions>
          <Button
            onClick={closeModal}
            variant="contained"
            color="inherit"
            size="small"
          >
            Cancel
          </Button>
          <Button
            onClick={onConfirm}
            variant="contained"
            color="success"
            size="small"
            disabled={props.isOkButtonDisabled}
          >
            {props.confirmText || "Save"}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};
