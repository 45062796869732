import { useEffect, useState } from "react";

type TimerTypes = {
    minutes: number,
    isActive: boolean,
    /**
     * When the timer reaches the 20 minutes this function gets executed, might be usefull or not, this feature will be figured out on a further ticket
     */
    callback?: Function 
}

export function useTimer(props: TimerTypes) {
    const { minutes, isActive, callback } = props;
    const targetTime = minutes * 60; // To get seconds
    const [time, setTime] = useState(0);

    const clockTimeToSeconds = (value : string) => {
        const values = value.split(':');

        const seconds = (parseInt(values[0]) * 60) + parseInt(values[1]);
        return seconds;
    }

    const getTime = () => {
        const minutes = Math.floor(time / 60);
        const seconds = time - minutes * 60;
        return `${minutes < 10 ? "0" + minutes : minutes}:${seconds < 10 ? "0" + seconds : seconds}`;
    }

    const setTimer = (totalSeconds: number) => {
        setTime(totalSeconds);
    }

    const setTimerWithString = (minutes: string) => {
        const seconds = clockTimeToSeconds(minutes); 
        
        if(isNaN(seconds))
            return;
        setTime(seconds);
    }

    useEffect(() => {
        if (isActive && targetTime > 1) {
            const timer = setInterval(() => {
                if(time === targetTime){
                    callback && callback();
                }
                setTime(state => state + 1)
            }, 1000);
            return () => clearInterval(timer);
        }
    }, [time, isActive]);

return {setTimer, setTimerWithString, getTime, clockTimeToSeconds, isActive, totalSeconds: time };
}