export const downloadFileResponse=(response:any, fileName:string)=> {
    const url= window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement("a");
      link.href=url;
      link.setAttribute("download",fileName);
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(url);
}