import {useEffect, useState} from "react";
import { addEventListeners,  removeEventListeners } from './util/eventListenerUtil'
import { useNavigate } from "react-router-dom";
import TimeoutWarningModal from "./TimeoutWarningModal.component";
import { useAxios } from "../../hooks/useAxios";
import ConfigurationSettingModel from "../../Api/Model/ConfigurationSetting/ConfigurationSettingModel";
    
export const TimeoutLogic = (props: any) => { 
    const [isWarningModalOpen, setWarningModalOpen] = useState(false);
    const navigate = useNavigate();
    const { getAsync } = useAxios();
    const { setLeavingSessionByTimeout } = props;
    const [sessionTimeout, setSessionTimeout] = useState({} as ConfigurationSettingModel);
    const [timeBeforeCloseSession, setTimeBeforeCloseSession] = useState({} as ConfigurationSettingModel);

    const getSessionTimeout = async () => {
        const axiosGet = await getAsync<ConfigurationSettingModel>(
            "ConfigurationSettings/GetSessionTimeoutConfigSetting"
        );
        if (axiosGet) {
            setSessionTimeout(axiosGet);
        }
    };

    const getTimeBeforeCloseSession = async () => {
        const axiosGet = await getAsync<ConfigurationSettingModel>(
            "ConfigurationSettings/GetTimeBeforeCloseSessionConfigSetting"
        );
        if (axiosGet) {
            setTimeBeforeCloseSession(axiosGet);
        }
    };

    useEffect(() => {
        getSessionTimeout();
        getTimeBeforeCloseSession();
    }, []);
    
    useEffect(() => {
        if(sessionTimeout.numberValue !== undefined && timeBeforeCloseSession.numberValue !== undefined){
            const createTimeout1 = () => setTimeout(() => { 
                setWarningModalOpen(true);
            }, (sessionTimeout.numberValue?? 1) * 60000);

            const createTimeout2 = () => setTimeout(() => {
                setLeavingSessionByTimeout(true);
                sessionStorage.clear();
                navigate("/logout",{replace: true});
            },(timeBeforeCloseSession.numberValue?? 1) * 60000);
    
            const listener = () => {
                if(!isWarningModalOpen){
                    clearTimeout(timeout)
                    timeout = createTimeout1();
                }
            } 

            let timeout = isWarningModalOpen  ? createTimeout2() : createTimeout1()
            addEventListeners(listener);
            
            return () => {
                setLeavingSessionByTimeout(false);
                removeEventListeners(listener);
                clearTimeout(timeout);
            }
        }
    },[isWarningModalOpen, sessionTimeout, timeBeforeCloseSession]);

    return (
        <>
            {isWarningModalOpen && (
                <TimeoutWarningModal 
                    isOpen={isWarningModalOpen}
                    onRequestClose={() => {setWarningModalOpen(false); setLeavingSessionByTimeout(false);}}
                    setLeavingSessionByTimeout={setLeavingSessionByTimeout}
                />
            )}
        </>
    );
}