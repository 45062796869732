import { Outlet } from "react-router-dom";
import { useMenu } from "../hooks/useMenu";
import { useError } from "../hooks/useError";
import { Alert, Box, Container, CssBaseline, Slide, Snackbar, styled } from "@mui/material";
import { colorsPalette } from "../constants/styles.constants";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { useEffect, useState } from "react";
import SideBar from "../components/sidebar/SideBar.component";
import TopBar from "../components/topbar/TopBar.component";
import { TimeoutLogic } from "../components/TimeoutWarningModal/TimeoutLogic.component";

const alertAutoHideDuration = 6000;
const drawerWidth = 250;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
    open?: boolean;
    }>(({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(2),
    backgroundColor: colorsPalette.white,
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }),
}));

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
    })<AppBarProps>(({ theme, open }) => ({
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open && {
            zIndex: '1280',
            width: `100%`,
            transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
            }),
        }),
}));

const MainLayout = (props: any) => {
    document.body.style.backgroundColor = colorsPalette.white;

    const { openAlertMessage, setOpenAlertMessage, alertType, alertMessage, manageErrorAlert, showAlertSnack } = useError();
    const { roleMenu, isLoading } = useMenu({ manageErrorAlert });
    
    const [open, setOpen] = useState(false);
    const [leavingSessionByTimeout, setLeavingSessionByTimeout] = useState(false);

    const handleDrawerOpen = () => setOpen(true);
    
    const handleDrawerClose = () => setOpen(false);

    useEffect(() => {
        const isMenuShowing = sessionStorage.getItem('menuOpen');
        setOpen(isMenuShowing ? JSON.parse(isMenuShowing) : false);
    }, []);

    useEffect(() => {
        sessionStorage.setItem('menuOpen', `${open}`);
    }, [open]);

    return (
        <Box sx={{ display: "flex", bgcolor: colorsPalette.darkGray }} >
            {openAlertMessage && (
                <Snackbar TransitionComponent={(props) => (
                    <Slide {...props} direction="right" />
                )}
                    autoHideDuration={alertAutoHideDuration}
                    open={openAlertMessage}
                    onClose={() => {
                        setOpenAlertMessage(false);
                    }}
                >
                    <Alert onClose={() => { setOpenAlertMessage(false); }}
                        severity={alertType || 'error'}
                        sx={{ width: "100%" }}
                    >
                        {alertMessage}
                    </Alert>
                </Snackbar>
            )}
            <CssBaseline />
            <AppBar position="fixed" open={open}>
                <TopBar open={open} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose}/>
            </AppBar>
            <SideBar roleMenu={roleMenu} isLoading={isLoading} open={open}/>
            <Main open={open}>
                <Container maxWidth={false} sx={{
                        px: '10px',
                        m: '10px',
                        height: '100%',
                        paddingTop: '55px'
                    }}
                        className="containerContent">
                        <Outlet context={{ manageErrorAlert, showAlertSnack, leavingSessionByTimeout }} />
                </Container>
            </Main>

            <TimeoutLogic setLeavingSessionByTimeout={setLeavingSessionByTimeout}/>
        </Box>
    );
};

export default MainLayout;