import { Button, FormControl, FormControlLabel, FormLabel, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useEffect, useState } from 'react';
import { colorsPalette } from "../../../constants/styles.constants";
import { isEmpty, isUndefined } from "lodash";
import { PatientCarePlanModel } from '../../../Api/Model/PatientCarePlan';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Dayjs } from 'dayjs';
import { CatalogModel } from '../../../Api/Model/GenericCatalog/CatalogModelAxios';
import { useAxios } from '../../../hooks/useAxios';
import { DeviceTypeCatalogName, GlobalFontSize } from '../../../constants/global.constants';
import { CustomDialog } from '../../Shared/Modal/Modal.component';
import { CleanButton } from '../../../Shared/CleanHandler.provider';
 
type PatientInfoPCarePlanModalProps = {
  open: boolean,
  handleClose: Function,
  onConfirm: Function,
  type: "create" | "update",
  description?: string,
  name?: string,
  patientCarePlanParam?: PatientCarePlanModel,
  currentPatientId?: string
};

export const PatientCarePlanModal = (props: PatientInfoPCarePlanModalProps) => {
  const [patientCarePlan, setPatientCarePlan] = useState<PatientCarePlanModel>({} as PatientCarePlanModel);
  const [deviceTypes, setDeviceTypes] = useState<CatalogModel[]>([]);
  const { getAsync } = useAxios();
  const [currentWeekSelected, setCurrentWeekSelected] = useState<string[]>([]);
  const [currentTimeSelected, setCurrentTimeSelected] = useState<string[]>([]);
  const [dateError, setDateError] = useState(false);
  const closeModal = () => props.handleClose();
  const onConfirm = () => {
    closeModal();
    !!props.onConfirm && props.onConfirm(patientCarePlan);
  }

  const handleMeasurementTypeChange = (event: any) => {
    if (event.target.value === "") {
      setPatientCarePlan((dc: PatientCarePlanModel) => {
        return {
          ...dc,
          patientDeviceType: event.target.value as string
        };
      });
      return;
    }
    let ThresholdDefalutFound = deviceTypes[deviceTypes.findIndex(item => item.catalogName === event.target.value)];
    setPatientCarePlan((dc: PatientCarePlanModel) => { return { ...dc, patientDeviceType: event.target.value as string, deviceTypeId: ThresholdDefalutFound.catalogId } });
  };

  const handlePatientCarePlanDurationChange = (event: any) => {
    setPatientCarePlan((dc: PatientCarePlanModel) => { return { ...dc, repeatUntilCancelled: JSON.parse((event.target as HTMLInputElement).value) } });
  };

  const handlePatientCarePlanTimeChange = (event: any, newValues: string[]) => {
    if (newValues.length === 0) {
      return;
    }
    let currentCarePlan = patientCarePlan;
    currentCarePlan.morning = newValues.indexOf("morning") != -1;
    currentCarePlan.afternoon = newValues.indexOf("afternoon") != -1;
    currentCarePlan.evening = newValues.indexOf("evening") != -1;
    currentCarePlan.anyTime = newValues.indexOf("anyTime") != -1;
    setPatientCarePlan(currentCarePlan);
    setCurrentTimeSelected(newValues);
  }

  const handleWeekCarePlanChange = (event: any, newValues: string[]) => {
    if (newValues.length === 0) {
      return;
    }
    let currentCarePlan = patientCarePlan;
    currentCarePlan.monday = newValues.indexOf("monday") != -1;
    currentCarePlan.thursday = newValues.indexOf("thursday") != -1;
    currentCarePlan.wednesday = newValues.indexOf("wednesday") != -1;
    currentCarePlan.tuesday = newValues.indexOf("tuesday") != -1;
    currentCarePlan.friday = newValues.indexOf("friday") != -1;
    currentCarePlan.saturday = newValues.indexOf("saturday") != -1;
    currentCarePlan.sunday = newValues.indexOf("sunday") != -1;
    setPatientCarePlan(currentCarePlan);
    setCurrentWeekSelected(newValues);
  }

  const handleSelectAllWeekendCarePlan = (params: any) => {
    let currentCarePlan = patientCarePlan;
    currentCarePlan.monday = true;
    currentCarePlan.thursday = true;
    currentCarePlan.wednesday = true;
    currentCarePlan.tuesday = true;
    currentCarePlan.friday = true;
    currentCarePlan.saturday = true;
    currentCarePlan.sunday = true;
    setPatientCarePlan(currentCarePlan);
    setCurrentWeekSelected(["monday", "thursday", "wednesday", "tuesday", "friday", "saturday", "sunday"]);
  }
  const getDevicesTypes = async () => {
    const axiosGet = await getAsync<CatalogModel[]>(
      `Catalogs/GetSubCatalogsByCatalogName/${DeviceTypeCatalogName}`
    );

    if (axiosGet) {
      setDeviceTypes(axiosGet);
    }

  }
  useEffect(() => {
    if (props.open) {
      getDevicesTypes();
      if (!!props.patientCarePlanParam) {
        setPatientCarePlan({ ...props.patientCarePlanParam, repeatUntil: props.patientCarePlanParam.repeatUntil?.toString() === '0001-01-01T00:00:00' ? undefined : props.patientCarePlanParam.repeatUntil });
        let currentPatientWeekDays: string[] = [];
        let currentPatientTimes: string[] = [];
        if (props.patientCarePlanParam.monday) {
          currentPatientWeekDays.push("monday");
        }
        if (props.patientCarePlanParam.thursday) {
          currentPatientWeekDays.push("thursday");
        }
        if (props.patientCarePlanParam.wednesday) {
          currentPatientWeekDays.push("wednesday");
        }
        if (props.patientCarePlanParam.tuesday) {
          currentPatientWeekDays.push("tuesday");
        }
        if (props.patientCarePlanParam.friday) {
          currentPatientWeekDays.push("friday");
        }
        if (props.patientCarePlanParam.saturday) {
          currentPatientWeekDays.push("saturday");
        }
        if (props.patientCarePlanParam.sunday) {
          currentPatientWeekDays.push("sunday");
        }
        setCurrentWeekSelected(currentPatientWeekDays);

        if (props.patientCarePlanParam.morning) {
          currentPatientTimes.push("morning");
        }
        if (props.patientCarePlanParam.afternoon) {
          currentPatientTimes.push("afternoon");
        }
        if (props.patientCarePlanParam.evening) {
          currentPatientTimes.push("evening");
        }
        if (props.patientCarePlanParam.anyTime) {
          currentPatientTimes.push("anyTime");
        }
        setCurrentTimeSelected(currentPatientTimes);
      }
      else {
        setPatientCarePlan({ PatientId: props.currentPatientId, instructions: "", morning: true, monday: true, repeatUntilCancelled: true } as PatientCarePlanModel);
        setCurrentWeekSelected(["monday"]);
        setCurrentTimeSelected(["morning"]);
      }
    }
  }, [props.open]);

  useEffect(() => {
    let today = new Date();
    today.setDate(today.getDate() - 1);
    if (!patientCarePlan.repeatUntilCancelled && patientCarePlan.repeatUntil && (patientCarePlan.repeatUntil) < today) {
      setDateError(true);
      return;
    }
    setDateError(false);
  }, [patientCarePlan.repeatUntil, patientCarePlan.repeatUntilCancelled]);

  const validationErrors = {
    ...(isEmpty(patientCarePlan.patientDeviceType?.trim()) && {
      PatientThresholdType: "Measurement is required",
    }),
    ...((isUndefined(patientCarePlan.repeatUntil)) && !patientCarePlan.repeatUntilCancelled) && {
      RepeatUntil: "Repeat Until is required",
    }, ...((patientCarePlan.repeatUntil?.toString() === "Invalid Date") && !patientCarePlan.repeatUntilCancelled) && {
      RepeatUntil: "Repeat Until must be a valid date",
    }, ...(dateError) && {
      RepeatUntil: "Date cannot be less than today",
    }
  };

  const handleSetTextField = (event: any) => {
    let fieldS = event.target.id.toString();
    setPatientCarePlan((dc: PatientCarePlanModel) => { return { ...dc, [fieldS]: event.target.value } });
  };

  return (
    <CustomDialog
      isOkButtonDisabled={!isEmpty(validationErrors)}
      open={props.open}
      onConfirm={onConfirm}
      handleClose={closeModal}
      size="sm"
      title={(props.type === 'create' ? 'Create' : 'Update') + ' Patient Care Plan'}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="MeasurementTypeCombo" color="primary">
              Measurement type
            </InputLabel>
            <Select
              id="patientThresholdType"
              fullWidth
              label="Measurement type"
              defaultValue={""}
              displayEmpty
              multiple={false}
              native={false}
              error={!!validationErrors.PatientThresholdType}
              onChange={handleMeasurementTypeChange}
              sx={{ bgcolor: colorsPalette.white }}
              labelId="MeasurementTypeCombo"
              value={patientCarePlan.patientDeviceType || ""}
              endAdornment={
                !!patientCarePlan.patientDeviceType &&
                CleanButton(handleMeasurementTypeChange)
              }
            >
              {deviceTypes.map((patientDevice: CatalogModel) => (
                <MenuItem
                  value={patientDevice.catalogName}
                  key={patientDevice.catalogId}
                >
                  {patientDevice.catalogName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item sx={{ display: 'flex', alignItems: 'center', fontSize: GlobalFontSize }} xs={2}>
          Frequency
        </Grid>
        <Grid item xs={8}>
          <ToggleButtonGroup onChange={handleWeekCarePlanChange} value={currentWeekSelected} size='small'>
            <ToggleButton value="monday">Mon</ToggleButton>
            <ToggleButton value="tuesday">Tue</ToggleButton>
            <ToggleButton value="wednesday">Wed</ToggleButton>
            <ToggleButton value="thursday">Thu</ToggleButton>
            <ToggleButton value="friday">Fri</ToggleButton>
            <ToggleButton value="saturday">Sat</ToggleButton>
            <ToggleButton value="sunday">Sun</ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <Grid item xs={2}>
          <Button
            sx={{
                height: 35,
                bgcolor: colorsPalette.darkGray,
            }}
            onClick={handleSelectAllWeekendCarePlan}
            fullWidth
            variant="contained"
          >
            All
          </Button>
        </Grid>
        <Grid item sx={{ display: 'flex', alignItems: 'center', fontSize: GlobalFontSize }} xs={2}>
          Time of Day
        </Grid>
        <Grid sx={{ alignItems: "justify-start" }} item xs={10}>
          <ToggleButtonGroup defaultChecked={true} onChange={handlePatientCarePlanTimeChange} value={currentTimeSelected} size='small'>
            <ToggleButton value="morning">Morning</ToggleButton>
            <ToggleButton value="afternoon">Afternoon</ToggleButton>
            <ToggleButton value="evening">Evening</ToggleButton>
            <ToggleButton value="anyTime">Any Time</ToggleButton>

          </ToggleButtonGroup>
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="instructions"
            type={"text"}
            onChange={handleSetTextField}
            value={patientCarePlan.instructions || ""}
            label="Instructions"
            variant="outlined"
            inputProps={{
              tabIndex: 0,
              maxLength: 150,
              minLength: 5,
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl>
            <FormLabel id="durationCarePlanRadioGroup">Duration</FormLabel>
            <RadioGroup aria-labelledby='durationCarePlanRadioGroup' value={patientCarePlan.repeatUntilCancelled ? "true" : "false"} onChange={handlePatientCarePlanDurationChange}>
              <FormControlLabel value={"true"} control={<Radio />} label="Repeat until cancelled"></FormControlLabel>
              <FormControlLabel value={"false"} control={<Radio />} label="Repeat until"></FormControlLabel>
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item sx={{ display: 'flex', alignItems: "end" }} xs={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              disablePast
              onChange={(newValue: Dayjs | null) =>
                setPatientCarePlan({ ...patientCarePlan, repeatUntil: newValue?.toDate() })
              }
              disabled={patientCarePlan.repeatUntilCancelled}
              value={patientCarePlan.repeatUntil || null}
              label="Repeat Until"
              inputFormat="MM/DD/YYYY"
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  error={!!validationErrors.RepeatUntil}
                  helperText={validationErrors.RepeatUntil}
                  fullWidth

                />
              )}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
    </CustomDialog>
  );
}
