import {
  Button,
  Grid,
  TextField,
} from "@mui/material";
import { GridColDef, GridSortModel } from "@mui/x-data-grid";
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs";
import { isEmpty, isUndefined } from "lodash";
import { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { ReportFilterModel } from "../../../Api/Model/ReportFilterModel";
import { CustomNoRowsReportOverlay } from "../../../constants/styles.constants";
import { useAxios } from "../../../hooks/useAxios";
import StripedDataGrid, { CustomPagination } from "../../Shared/StripedDataGrid/StripedDatagrid.component";
import { TimeTrackingReportModel } from "../../../Api/Model/Reports/TimeTrackingReportModel";
import { DateDisplayFormat } from "../../../constants/global.constants";
import { downloadFileResponse } from "../../../Shared/Files.provider";
import PageWrapper from "../../Shared/Wrapper/PageWrapper.component";
import { getDateOfToday, getFirstDayOfCurrentMonth } from "../../../Shared/DateTime.provider";


//#region Columns definitions

const definedColumns: GridColDef[] = [
  {
    field: "patientId",
    headerName: "Patient Id",
    headerAlign: "center",
    align: "left",
    flex: 1,
    hideable: false,
    filterable: false
  },
  {
    field: "patientLastName",
    headerName: "Last Name",
    flex: 1,
    headerAlign: "center",
    align: "left",
    hideable: false,
    filterable: false
  },
  {
    field: "patientFirstName",
    headerName: "First Name",
    flex: 1,
    headerAlign: "center",
    align: "left",
    hideable: false,
    filterable: false
  },
  {
    field: "billingCode",
    headerName: "Billing Code",
    flex: 1,
    headerAlign: "center",
    align: "left",
    hideable: false,
    filterable: false
  },
  {
    field: "duration",
    headerName: "Duration",
    flex: 1,
    headerAlign: "center",
    align: "left",
    hideable: false,
    filterable: false
  },
];

//#endregion


const TimeTracking = (props: any) => {

  //#region Hooks

  //#region States
  const [reportFilterModel, setReportFilterModel] = useState<ReportFilterModel>({});
  const [exportFilterModel, setExportFilterModel] = useState<ReportFilterModel>({});
  const [timeTrackingReport, setTimeTrackingReport] = useState<TimeTrackingReportModel[]>([]);
  const [rowCountState, setRowCountState] = useState(0);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [datesErrors, setDatesErrors] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>([{field : definedColumns[0].field, sort: "asc"}]);
  
  //#endregion
  
  //#region Others
  
  const { getAsync, axiosError, downloadFileAsync, axiosSuccess, axiosLoading, axiosPagination } = useAxios();
  const navigate = useNavigate();
  const { manageErrorAlert, showAlertSnack } = useOutletContext<{ manageErrorAlert: Function, showAlertSnack: Function }>();

  //#endregion

  //#region Effects

  useEffect(() => {
    setDatesErrors(reportFilterModel.endDate && reportFilterModel.startDate && (reportFilterModel.endDate < reportFilterModel.startDate) || false);
  }, [reportFilterModel.startDate, reportFilterModel.endDate]);

  useEffect(() => {
    if (axiosError && axiosError?.Code === '401') {
      manageErrorAlert("Unauthorized");
      navigate("/patientlist", { replace: true });
      return;
    }
  }, [axiosError]);

  useEffect(() => {
    if (!refresh) {
      return;
    }

    const {pageSize, page} = paginationModel;
    const {field, sort} = sortModel[0] !== undefined ? sortModel[0] : {field: "", sort: ""};

    getTimeTrackingReport(pageSize, page, field, sort || "");
  }, [refresh]);

  useEffect(() => {
    setRowCountState((prevState) =>
      axiosPagination?.TotalRecords !== undefined ? axiosPagination?.TotalRecords : prevState
    );
  }, [axiosPagination?.TotalRecords]);

  useEffect(() => {
    setRefresh(true);
  }, [paginationModel, sortModel]);

  //#endregion

  //#endregion


  //#region Form Validations

  const validationErrors = {
    ...((isUndefined(reportFilterModel.startDate))) && {
      StartDate: "Start Date is required",
    },
    ...((reportFilterModel.startDate?.toString() === "Invalid Date")) && {
      StartDate: "Start Date must be a valid date",
    },
    ...((isUndefined(reportFilterModel.endDate))) && {
      EndDate: "End Date is required",
    },
    ...((reportFilterModel.endDate?.toString() === "Invalid Date")) && {
      EndDate: "End Date must be a valid date",
    },
    ...(datesErrors) && {
      StartDate: "Date cannot be greater than End Date",
      EndDate: "Date cannot be less than Start Date",
    }
  };

  //#endregion


  //#region Axios Methods

  const getTimeTrackingReport = async (pageSize: number, page: number, field: string, sort: string) => {
    if (!isEmpty(validationErrors)) {
      if (!("startDate" in reportFilterModel)) {
        setReportFilterModel({endDate:getDateOfToday(),startDate:getFirstDayOfCurrentMonth()});
      }
      return;
    }
    const axiosGet = await getAsync<TimeTrackingReportModel[]>(`Reports/GetTimeTrackingRealReports?` +
      `PageNumber=${page + 1}&PageSize=${pageSize}&type=Real` +
      `&dateIni=${reportFilterModel.startDate?.toLocaleDateString('en-US')}` +
      `&dateEnd=${reportFilterModel.endDate?.toLocaleDateString('en-US')}` +
      `&orderBy=${field}&sort=${sort}`);
    setRefresh(false);
    if (!axiosGet) {
      manageErrorAlert(axiosError?.Messages[0]);
      return;
    }

    setTimeTrackingReport(axiosGet);
  }

  //#endregion


  //#region Handlers

  //#region Pagination Handlers

  const onPageSizeChangeHandler = (newPageSize: number) => setPaginationModel({...paginationModel, pageSize: newPageSize});
  const onPageChangeHandler = (newPage: number) => setPaginationModel({...paginationModel, page: newPage});

  //#endregion

  //#region Button Handlers

  const onSubmitReportClick = async () => {
    const {pageSize, page} = paginationModel;
    const {field, sort} = sortModel[0] !== undefined ? sortModel[0] : {field: "", sort: ""};
    getTimeTrackingReport(pageSize, page, field, sort || "");
    setExportFilterModel(reportFilterModel);
  };

  const onExportReportClick = async () => {
    let response = await downloadFileAsync('Reports/ExportTimeTrackingReport',
      `type=Real&dateIni=${exportFilterModel.startDate?.toLocaleDateString('en-US')}&dateEnd=${exportFilterModel.endDate?.toLocaleDateString('en-US')}`);
    if (axiosSuccess) {
      showAlertSnack("Report generated successfully.", "success");
      downloadFileResponse(response, `TimeTrackingReport ${exportFilterModel.startDate?.toLocaleDateString('en-US')} to ${exportFilterModel.endDate?.toLocaleDateString('en-US')}.xlsx`);
    }
    else {
      manageErrorAlert(axiosError?.Messages[0]);
    }
  };

  //#endregion

  //#endregion


  return <PageWrapper title={"Time Tracking Report"}>
    <Grid container spacing={2} rowSpacing={1} width={"100%"}>
      <Grid item xs={2}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            disableFuture
            onChange={(newValue: Dayjs | null) =>
              setReportFilterModel({
                ...reportFilterModel,
                startDate: newValue ? newValue.toDate() : undefined,
              })
            }
            value={reportFilterModel.startDate || null}
            label="Start Date"
            inputFormat={DateDisplayFormat}
            renderInput={(params) => (
              <TextField
                required
                inputProps={{
                  tabIndex: 1,
                }}
                {...params}
                error={!!validationErrors.StartDate}
                helperText={validationErrors.StartDate}
                fullWidth
              />
            )}
            PopperProps={{
              placement: "bottom-start",
              sx: {zIndex: 1200, paddingBottom: 2}
            }}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={2}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            onChange={(newValue: Dayjs | null) =>
              setReportFilterModel({
                ...reportFilterModel,
                endDate: newValue ? newValue.toDate() : undefined,
              })
            }
            value={reportFilterModel.endDate || null}
            label="End Date"
            inputFormat={DateDisplayFormat}
            renderInput={(params) => (
              <TextField
                required
                inputProps={{
                  tabIndex: 2,
                }}
                {...params}
                error={!!validationErrors.EndDate}
                helperText={validationErrors.EndDate}
                fullWidth
              />
            )}
            PopperProps={{
              placement: "bottom-start",
              sx: {zIndex: 1200, paddingBottom: 2}
            }}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={1}>
        <Button disabled={!isEmpty(validationErrors)} fullWidth sx={{ mb: 2, height: 55 }} variant="contained" color="primary" onClick={onSubmitReportClick}>SUBMIT</Button>
      </Grid>
      <Grid item xs={1}>
        <Button disabled={!exportFilterModel.startDate || !exportFilterModel.endDate || timeTrackingReport.length < 1} fullWidth sx={{ mb: 2, height: 55 }} variant="contained" color="primary" onClick={onExportReportClick}>EXPORT</Button>
      </Grid>
      <Grid item xs={12}>
        <StripedDataGrid
          sx={{ height: 700 }}
          loading={axiosLoading}
          disableColumnSelector
          disableColumnMenu
          disableSelectionOnClick
          paginationMode="server"
          sortingMode="server"
          rows={timeTrackingReport}
          columns={definedColumns}
          rowsPerPageOptions={[5, 10, 20, 50]}
          pageSize={paginationModel.pageSize}
          page={paginationModel.page}
          components={{
            NoResultsOverlay: () => CustomNoRowsReportOverlay(!exportFilterModel.startDate || !exportFilterModel.startDate ? "Please select a date range to display the report" : "No results for the selected date range "),
            NoRowsOverlay: () => CustomNoRowsReportOverlay(!exportFilterModel.startDate || !exportFilterModel.startDate ? "Please select a date range to display the report" : "No results for the selected date range "),
            Footer: () => CustomPagination(Math.ceil(rowCountState / (paginationModel ? paginationModel.pageSize! : 1)), paginationModel.page + 1, onPageChangeHandler)
          }}
          rowCount={rowCountState}
          onPageChange={onPageChangeHandler}
          onPageSizeChange={onPageSizeChangeHandler}
          onSortModelChange={setSortModel}
          sortModel={sortModel}
          sortingOrder={["asc", "desc"]}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
          }
        />
      </Grid>
    </Grid>
  </PageWrapper>;
};

export default TimeTracking;
