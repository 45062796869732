import { useEffect, useState } from "react";
import "./TopBar.css";
import {
  Box,
  IconButton,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  OutlinedInput,
  Toolbar,
  Typography,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import logo from "../../resources/img/logo.png";
import Cookies from "universal-cookie";
import { AuthUserModel } from "../../Api/Model/user/authModel";
import { Search } from "@mui/icons-material";
import { CleanButton } from "../../Shared/CleanHandler.provider";
import { colorsPalette } from "../../constants/styles.constants";
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import { useNavigate } from "react-router-dom";
import { version } from '../../version'

interface ToolBarParams {
  open: boolean;
  handleDrawerOpen: () => void;
  handleDrawerClose: () => void;
}

const TopBar = (params: ToolBarParams) => {
  const cookies = new Cookies();
  const sessionUser = cookies.get("sessionUser") as AuthUserModel;
  const actualPatient = sessionStorage.getItem("currentPatient");
  const [date, setDate] = useState(new Date());
  const [searchText, setSearchText] = useState("");
  const { open, handleDrawerOpen, handleDrawerClose } = params;
  const navigate = useNavigate();

  useEffect(() => {
    let interval = setInterval(() => setDate(new Date()), 1000);
    return function cleanup() {
      clearInterval(interval);
    };
  }, [date]);

  const searchTextHandler = (event: any) => setSearchText(event.target.value);

  const isEditingPatient = (): boolean => window.location.pathname.startsWith("/patientlist/editpatient/");

  const topbarLabelStyle_ui = {
    icon: {
      display: "inline-flex",
      width: 42,
      height: 42,
      margintop: 14,
      marginLeft: 2,
    },
    font: {
      fontWeight: "bold",
      whiteSpace: "wrap",
      overflow: "hidden",
      textOverflow: 'ellipsis',
    }
  }

  const topbarNameLabel_ui = (): JSX.Element => {
    return (
      <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
        {isEditingPatient() &&
          <FolderSharedIcon sx={topbarLabelStyle_ui.icon} />
        }
        <Typography sx={topbarLabelStyle_ui.font}>
          {isEditingPatient() &&
            `Editing: ${actualPatient === null ? 'Loading...' : actualPatient}`
          }
        </Typography>
      </Box>
    );
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openList = Boolean(anchorEl);
  const clickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeHandler = () => setAnchorEl(null);

  const settingsHandler = () => { navigate("/settings", { replace: true }); closeHandler(); };
  const logoutHandler = () => { navigate("/logout", { replace: true }); closeHandler(); };

  const topbarUsernameLabel_ui = (): JSX.Element => {
    return (
      <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
        <Box sx={{ flexGrow: 1, display: "flex", flexDirection:'column', alignItems: "center" }}>
          <Typography sx={topbarLabelStyle_ui.font}>
            {`Hello ${sessionUser.name}!`}
          </Typography>
          <Typography fontSize={8} variant="body2" color={colorsPalette.darkGrayAlt} sx={{ 'fontWeight': 'bold' }}>
            Version: {version}
          </Typography>
        </Box>
        <IconButton
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          sx={topbarLabelStyle_ui.font}
          color="inherit"
          onClick={clickHandler}
        >
          <AccountCircleIcon sx={topbarLabelStyle_ui.icon} />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={openList}
          onClose={closeHandler}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
            style: { backgroundColor: colorsPalette.secondary, color: colorsPalette.darkGrayAlt },
            className: 'cursorPointer'
          }}
        >
          <MenuItem onClick={settingsHandler}>
            <ListItemIcon className="spaceIconSideBar" style={{ color: colorsPalette.darkGrayAlt }}>
              <ManageAccountsIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText sx={{ fontWeight: "bold" }} className="newFontFamily menuTextFormat">ACCOUNT SETTINGS</ListItemText>
          </MenuItem>
          <MenuItem onClick={logoutHandler} >
            <ListItemIcon className="spaceIconSideBar" style={{ color: colorsPalette.darkGrayAlt }}>
              <LogoutIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText sx={{ fontWeight: "bold" }} className="newFontFamily menuTextFormat">LOGOUT</ListItemText>
          </MenuItem>
        </Menu>
      </Box>
    );
  };

  return (
    <Toolbar sx={{ bgcolor: colorsPalette.secondary, color: colorsPalette.darkGrayAlt, width: '100vw', height: '64px' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', pr: 1 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => { !open ? handleDrawerOpen() : handleDrawerClose(); }}
            edge="start"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <img src={logo} alt="logo" className="logo" />
          {sessionUser && topbarNameLabel_ui()}
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box
            sx={{
              paddingRight: "1.5em",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            {date.toLocaleDateString("en", { weekday: "short" })}
            {", "}
            {date.getDate()} {date.toLocaleDateString("en", { month: "short" })}{" "}
            {date.toLocaleTimeString()}{" "}
          </Box>
          <Box sx={{ flexGrow: 0, display: { xs: "inline-flex" }, paddingRight: "1.5em" }}>
            <OutlinedInput
              className={"Topbar-input"}
              name="Search"
              id="Search"
              type={"text"}
              placeholder={"Search..."}
              required={false}
              sx={{
                width: "240px",
                height: "36px",
                borderRadius: "40px",
                bordercolor: colorsPalette.darkGrayAlt,
                bgcolor: colorsPalette.white,
              }}
              value={searchText}
              onChange={searchTextHandler}
              startAdornment={
                <InputAdornment position="start">
                  <IconButton
                    edge="start"
                  >
                    <Search />
                  </IconButton>
                </InputAdornment>
              }
              endAdornment={
                searchText !== "" && (
                  CleanButton(searchTextHandler)
                )
              }
            />
          </Box>
          <Box sx={{ flexGrow: 0, display: { xs: "inline-flex" } }}>
            {sessionUser && topbarUsernameLabel_ui()}
          </Box>
        </Box>
      </Box>
    </Toolbar>
  );
};

export default TopBar;
