import { AddCircleOutlineOutlined } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { TreeItem } from "@nosferatu500/react-sortable-tree";
import { useEffect, useReducer, useState } from "react";
import DepartmentStructureModel from "../../../Api/Model/DepartmentStructure/DepartmentStructureModel";
import OfficeModel from "../../../Api/Model/DepartmentStructure/OfficeModel";
import ProviderModel from "../../../Api/Model/DepartmentStructure/ProviderModel";
import { useDepartmentStructure } from "../../../hooks/useDepartmentStructure";
import { departmentStructureInit, departmentStructureReducer, updateTree, toggleExpanded, initializeTree } from "../../../Reducers/DepartmentStructure.reducer";
import DepartmentStructureTree from "../DepartmentStructureTree/DepartmentStructureTree.component";

interface AssignDepartmentStructureProps {
    height: string;
    onAddNodeClicked: (rowInfo: any) => void;
    structureIsReady: () => void;
    canDrag:(rowInfo: any) => boolean;
    isAdding: boolean;
    treeDataToFilter?: TreeItem[];
    isPatientInUse?: boolean;
}

const AssignDepartmentStructure = (props: AssignDepartmentStructureProps) => {
    const { getDepartmentStructureAsync } = useDepartmentStructure();
    const [{ treeData }, dispatch] = useReducer(departmentStructureReducer, departmentStructureInit);
    const [departmentStructure, setDepartmentStructure] = useState<DepartmentStructureModel[] | undefined>(undefined);
    const [localStructure, setLocalStructure] = useState<DepartmentStructureModel[] | undefined>(undefined);
    
    useEffect(() => {
        const fetchDepartmentStructure = async () => {
            const departmentStructureIntel = await getDepartmentStructureAsync();
            setDepartmentStructure(departmentStructureIntel);
            setLocalStructure(departmentStructureIntel);
            if (departmentStructureIntel) {
                const treeInit = initializeTree(departmentStructureIntel);
                dispatch({ type: treeInit.type, payload: treeInit.payload });
                props.structureIsReady();
            }
        }
        fetchDepartmentStructure();
    }, [])

    useEffect(() => {
        const filteringTree = props.treeDataToFilter || Array<TreeItem>();
        
        if (departmentStructure === undefined) {
            return;
        }

        if(filteringTree.length === 0) {
            setLocalStructure(departmentStructure);
            const treeInit = initializeTree(departmentStructure);
            dispatch({ type: treeInit.type, payload: treeInit.payload });
            return;
        }

        const clonedStructure = JSON.parse(JSON.stringify(departmentStructure)) as DepartmentStructureModel[];

        if(!clonedStructure) {
            return;
        }

        const filteredStructure = clonedStructure
        .map((item: DepartmentStructureModel) => {
            const departmentTreeItem = filteringTree
                .find((fItem: TreeItem) => item.departmentId === fItem.id);

            // If external doesn't have the department, return the department
            if (!departmentTreeItem) {
                return item;
            }

            const departmentTreeItemChildren = departmentTreeItem.children as Array<TreeItem> || Array<TreeItem>();

            const officesStructure = item.offices;
            const officesTreeItem: TreeItem[] = departmentTreeItemChildren.filter((child: TreeItem) => child.type === "office");
            if(officesTreeItem.length > 0) {
                item.offices = officesStructure.map((office: OfficeModel) => {
                    const officeTreeItem = officesTreeItem
                        .find((fItem: TreeItem) => office.officeId === fItem.id);
    
                    if (!officeTreeItem) {
                        return office;
                    }
    
                    const officeTreeItemChildren = officeTreeItem.children as Array<TreeItem> || Array<TreeItem>();
        
                    // If external doesn't have the office children, return the office
                    if(officeTreeItemChildren.length === 0) {
                        return office.providers.length > 0 ? office : undefined;
                    }
    
                    const providersStructure = office.providers;
                    const providersTreeItem: TreeItem[] = officeTreeItemChildren.filter((child: TreeItem) => child.type === "provider"); 
                    office.providers = providersStructure.map((provider: ProviderModel) => {
                        const providerTreeItem = providersTreeItem
                            .find((fItem: TreeItem) => provider.providerId === fItem.id);
    
                        if (!providerTreeItem) {
                            return provider;
                        }
    
                        return undefined;
                    })
                    .filter((structure): structure is ProviderModel => Boolean(structure));
    
                    return office.providers.length > 0 ? office : undefined;
                })
                .filter((structure): structure is OfficeModel => Boolean(structure));
            }            
            
            const providersStructure = item.providers;
            const providersTreeItem: TreeItem[] = departmentTreeItemChildren.filter((child: TreeItem) => child.type === "provider"); 
            
            if(providersTreeItem.length > 0) {
                item.providers = providersStructure.map((provider: ProviderModel) => {
                    const providerTreeItem = providersTreeItem
                        .find((fItem: TreeItem) => provider.providerId === fItem.id);
    
                    if (!providerTreeItem) {
                        return provider;
                    }
    
                    return undefined;
                })
                .filter((structure): structure is ProviderModel => Boolean(structure));
            }

            return item.offices.length > 0 || item.providers.length > 0 ? item : undefined;
        })
        .filter((structure): structure is DepartmentStructureModel => Boolean(structure));

        setLocalStructure(filteredStructure);

        const treeInit = initializeTree(filteredStructure);
        dispatch({ type: treeInit.type, payload: treeInit.payload });
    }, [props.treeDataToFilter]);

    const _handleExpandAll = (expanded: boolean) => {
        const expandedTree = toggleExpanded(expanded);
        dispatch({ type: expandedTree.type, payload: expandedTree.payload });
    }
    const _handleUpdateTreeData = (treeData: any) => {
        const updatedTree = updateTree(treeData);
        dispatch({ type: updatedTree.type, payload: updatedTree.payload });
    }
    // TODO: Implement this function when we have the ability to move nodes in the tree
    const _handleOnMoveNode = () => {}
    const _handleOnAddNode = (rowInfo: any) => {
        if(!props.isAdding){
            props.onAddNodeClicked(rowInfo);
            return;
        }
        
        const clonedStructure = JSON.parse(JSON.stringify(localStructure)) as DepartmentStructureModel[];
        let departmentStructureResult: DepartmentStructureModel = clonedStructure
            ?.find((item: DepartmentStructureModel) => item.departmentId === rowInfo.path[0]) || {} as DepartmentStructureModel;

        switch (rowInfo.node.type) {
            case "office":
                departmentStructureResult.offices = departmentStructureResult?.offices
                    .filter((office: OfficeModel) => office.officeId === rowInfo.node.id);
                departmentStructureResult.providers = [];
                break;
            case "provider":
                const isUnderOffice = rowInfo.path.length > 2;

                if(isUnderOffice) {
                    const office = departmentStructureResult?.offices
                    .find((office: OfficeModel) => office.officeId === rowInfo.path[1]) || {} as OfficeModel;
                    office.providers = office?.providers
                        .filter((provider: ProviderModel) => provider.providerId === rowInfo.node.id);
                    departmentStructureResult.offices = [office];
                    departmentStructureResult.providers = [];
                }
                else {
                    departmentStructureResult.offices = [];
                    departmentStructureResult.providers = departmentStructureResult?.providers
                        .filter((provider: ProviderModel) => provider.providerId === rowInfo.node.id);
                }
                break;
            default:
                break;
        }
        props.onAddNodeClicked(departmentStructureResult);        
    }

    return (
        <DepartmentStructureTree
            height={props.height}
            canDrop={() => false}
            treeData={treeData}
            canDrag={props.canDrag}
            onChange={_handleUpdateTreeData}
            toggleExpand={_handleExpandAll}
            onMoveNode={_handleOnMoveNode}
            generateNodeProps={rowInfo => ({
                buttons: [
                    <IconButton onClick={(_) => _handleOnAddNode(rowInfo)} aria-label="edit" className='unbordered' color="primary" sx={{ px: 0.5 }}>
                        <AddCircleOutlineOutlined />
                    </IconButton>
                ]
            })}
        />
    );
};

export default AssignDepartmentStructure;