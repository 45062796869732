import { Box, Checkbox, FormControlLabel, FormGroup, Tooltip } from '@mui/material';
import "./PatientTimeTracking.component.css";
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import { useTimer } from '../../hooks/useTimer';
import { useContext, useEffect, useState } from 'react';
import { PatientiTimeTrackingModal } from './PatientTimeTrackingModal.component';
import { TimeTrackingMinutes } from '../../constants/global.constants';
import { useAxios } from '../../hooks/useAxios';
import { PatientTimeTrackingModel, TimeTrackingSessionModel, ReasonsForEditingModel } from '../../Api/Model/Patient/PatientTimeTracker/PatientTimeTrackerModel';
import { useCallbackPrompt } from '../../hooks/useCallbackPrompt';
import ConfirmationModalComponent from '../Shared/ConfirmationModal/ConfirmationModal.component';
import { useNavigate } from 'react-router-dom';
import { PatientContext } from '../../Context/PatientContext';
import { emptyGUID } from '../../constants/guid.contast';
import { debounce } from 'lodash';

type PatientTimeTrackingType = {
    IsEnabled: boolean,
    SetIsAllowedToModify: Function,
    SetIsEditing: Function,
    IsEditing: boolean,
    IsPatientInUse: boolean,
    ShowNavAlert: boolean,
    LeavingSessionByTimeout: boolean
}

interface ManualEditinModel {
    Reason: ReasonsForEditingModel,
    CustomReason: string,
    NewValue: number
}

export const PatientTimeTracking = (props: PatientTimeTrackingType) => {
    const [timerIsActive, setTimerIsActive] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [currentTime, setCurrentTime] = useState("");
    const { getAsync, axiosLoading, axiosSuccess } = useAxios();
    const { postAsync } = useAxios();
    const [hasStopped, setHasStopped] = useState(false);
    const [manualEditingValues, setManualEditingValues] = useState<ManualEditinModel>();
    const [setUpCompleted, setSetUpCompleted] = useState(false);
    const [hasOnBoarding, setHasOnBoarding] = useState(false);
    const [hasRecorded, setHasRecorded] = useState(false);
    const navigate = useNavigate();
    const { currentPatient, patientIsReady } = useContext(PatientContext);

    const minutes = props.IsEnabled || props.IsEditing ? TimeTrackingMinutes : 0;
    const disabledClass = (props.IsEnabled || minutes !== 0 || hasRecorded) ? '' : 'disabled';

    const { setTimer, getTime, clockTimeToSeconds, totalSeconds } = useTimer({ minutes, isActive: (timerIsActive && props.IsEnabled && currentPatient !== undefined) });

    const pauseTimerHandler = () => {
        setTimerIsActive((state) => {
            /**This first if is to pause the timer */
            if (state) {
                // const payload = {
                //     PatientId: props.PatientId,
                //     TotalSeconds: totalSeconds
                // }
                // postAsync<PatientTimeTrackingModel>('PatientTimeTracker', payload);
                setManualEditingValues(undefined);
            }
            props.SetIsAllowedToModify(!state);
            props.SetIsEditing(state);
            return !state;
        });
        setHasStopped(false);
    }

    const postTimeDebounce = debounce(async () => {
        const payload = {
            PatientId: currentPatient?.patientId || emptyGUID,
            TotalSeconds: totalSeconds,
            ReasonForEditingId: manualEditingValues?.Reason.id,
            CustomReasonForEditing: manualEditingValues?.CustomReason,
            OnBoardingCompleted: setUpCompleted
        } as TimeTrackingSessionModel;

        await postAsync<PatientTimeTrackingModel>('PatientTimeTracker/AddRecorded', payload);
    }, 300);

    const stopTimerHandler = async () => {
        if(disabledClass === 'disabled'){
            return;
        }

        if (hasStopped && !hasRecorded) {
            postTimeDebounce();

            setTimer(0);

            if (setUpCompleted) {
                setHasOnBoarding(true);
                setSetUpCompleted(false);
            }
            props.SetIsAllowedToModify(true);
            props.SetIsEditing(false);
            setHasRecorded(true);
        }

        if (!hasStopped) {
            setManualEditingValues(undefined);
            setTimerIsActive(false);
            props.SetIsAllowedToModify(false);
            props.SetIsEditing(true);
            setHasStopped(true);
        }
    }

    useEffect(() => {
        if (hasRecorded) {
            setTimerIsActive(false);
            setHasStopped(false);
            navigate("/patientlist", { replace: true });
        }
    }
        , [hasRecorded])

    useEffect(() => {
        if(currentPatient === undefined || !patientIsReady){
            return;
        }

        /** TODO: This commented code is used to get patient's stored time, will stay here until general management request to remove it. */
        // const getExistingTimeTracker = async () => {
        //     if (props.PatientId !== undefined && props.PatientId !== '') {
        //         const currentPatientTime = await getAsync<PatientTimeTrackingModel>(`PatientTimeTracker/${props.PatientId}`);

        //         if (axiosSuccess && currentPatientTime) {
        //             setTimer(currentPatientTime.totalSeconds);
        //         }
        //         else {
        //             setTimer(0);
        //         }

        //         setTimerIsActive(true);
        //     }
        // }

        const getHasOnBoarding = async () => {
            if (currentPatient.patientId !== undefined && currentPatient.patientId !== '') {
                const getOnBoarding = await getAsync<boolean>(`Billing/HasOnBoardingCompleted?patientId=${currentPatient.patientId}`);

                if (axiosSuccess) {
                    setHasOnBoarding(getOnBoarding || false);
                }
                else {
                    setHasOnBoarding(false);
                }
            }
        }

        //getExistingTimeTracker();
        setTimer(0);
        setTimerIsActive(true);
        getHasOnBoarding();
    }, [patientIsReady]);

    useEffect(() => {
        !props.ShowNavAlert && confirmNavigation();
    }, [props.ShowNavAlert])

    const openModalHandler = () => {
        setCurrentTime(getTime());
        setOpenModal(true);
    }

    const closeTimeTrackingModal = () => setOpenModal(false);

    const confirmationTimeTrackingModal = (value: string, reason: ReasonsForEditingModel, customReason: string) => {
        const totalSeconds = clockTimeToSeconds(value);

        if (isNaN(totalSeconds)) {
            return;
        }

        setTimer(totalSeconds);

        setManualEditingValues({
            NewValue: totalSeconds,
            Reason: reason,
            CustomReason: customReason
        });
    };

    const timer_ui = <Box
        gap={1}
        sx={{ display: 'inline-flex', justifyContent: 'flex-end', alignContent: 'stretch', flexWrap: 'wrap' }}
        className="my-2 PatientTimetracking-flexbox">
        <Box sx={{ flexGrow: 1 }}>
            <Box
                sx={{ display: 'flex', flexWrap: 'no-wrap' }}
                className="PatientTimetracking-timer">
                <Box>
                    <TimerOutlinedIcon id='PatientTimetracking-timer-icon' />
                </Box>
                <Box>
                    {axiosLoading ? 'Loading Session' : 'Current Session'}
                </Box>
                <Box>
                    <Tooltip title="Click to update the time manually" disableInteractive={!hasStopped} arrow={true} >
                        <div onClick={openModalHandler} id="PatientTimetracking-timer-inpt" className={hasStopped ? "stopped" : ""}>{getTime()}</div>
                    </Tooltip>
                </Box>
            </Box>
        </Box>
        <Box onClick={stopTimerHandler} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} className={disabledClass}>
            <p>{axiosLoading ? '. . .' : hasStopped ? "Record" : "Stop"}</p>
        </Box>
        <Box onClick={pauseTimerHandler} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} className={disabledClass}>
            <p>{axiosLoading ? '. . .' : timerIsActive ? "Pause" : "Resume"}</p>
        </Box>
    </Box>;

    const timer_modal = <PatientiTimeTrackingModal
        open={openModal}
        handleClose={closeTimeTrackingModal}
        onConfirm={confirmationTimeTrackingModal}
        time={currentTime} />;
        
    const { showPrompt, confirmNavigation, cancelNavigation } = useCallbackPrompt(props.IsPatientInUse || !patientIsReady ? false 
            : (props.LeavingSessionByTimeout) ? false: (timerIsActive || !hasRecorded));
    
    useEffect(() => {
        if(props.LeavingSessionByTimeout){
            confirmNavigation();
        }
    }, [props.LeavingSessionByTimeout]);

    const ui_confirm = <ConfirmationModalComponent
        open={showPrompt && props.ShowNavAlert && !props.LeavingSessionByTimeout}
        handleClose={cancelNavigation}
        type='confirmation'
        onConfirm={confirmNavigation}
        title='Alert!'
        message={!timerIsActive ? 'You have not recorded you time. Are you sure you want to leave?' : 'Time tracking is still running. Are you sure you want to leave?'}
    />;

    return <>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'left' }}>
            {!hasOnBoarding && props.IsEnabled && <FormGroup>
                <FormControlLabel control={<Checkbox
                    color="primary"
                    sx={{ '& .MuiSvgIcon-root': { fontSize: 35 } }}
                    checked={setUpCompleted || false}
                    onChange={(event, checked) => setSetUpCompleted(checked)}
                />}
                    label="Set Up Complete?"
                    sx={{ '& .MuiTypography-root': { fontSize: 18 } }}
                    style={{ paddingTop: "8px", paddingRight: "10px" }} />
            </FormGroup>}
            {timer_ui}
            {timer_modal}
            {ui_confirm}
        </Box>
    </>;
}
