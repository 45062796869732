import { DeleteOutlineRounded } from "@mui/icons-material";
import { Box, Grid, IconButton, SxProps, Typography } from "@mui/material";
import { useContext, useEffect, useReducer, useState } from "react";
import DepartmentStructureModel from "../../../Api/Model/DepartmentStructure/DepartmentStructureModel";
import { useAxios } from "../../../hooks/useAxios";
import { departmentStructureInit, departmentStructureReducer, updateTree, toggleExpanded, initializeTree, updateOnLocalStructure, deleteOnLocalStructure, cleanLocalStructure } from "../../../Reducers/DepartmentStructure.reducer";
import AssignDepartmentStructure from "../../Shared/AssignDepartmentStructure/AssignDepartmentStructure.component";
import ConfirmationModalComponent from "../../Shared/ConfirmationModal/ConfirmationModal.component";
import DepartmentStructureTree from "../../Shared/DepartmentStructureTree/DepartmentStructureTree.component";
import PrismLoading from "../../Shared/Loading/Loading.component";
import { CustomDialog } from "../../Shared/Modal/Modal.component";
import { PatientContext } from "../../../Context/PatientContext";
import { emptyGUID } from "../../../constants/guid.contast";

interface PatientDepartmentAssignmentProps {
    isAdding: boolean;
    onStructureChanged?: (structure: DepartmentStructureModel[]) => void;
    patientStructure?: DepartmentStructureModel[];
    isPatientInUse?: boolean;
}

const AssignGridContentStyle: SxProps = {
    border: "2px solid darkgrey",
    borderRadius: "10px",
    height: "100%",
    overflowX: 'auto',
    p: 2
};

const PatientDepartmentAssignment = (props: PatientDepartmentAssignmentProps) => {
    const [{ treeData, localDepartmentStructure }, dispatch] = useReducer(departmentStructureReducer, departmentStructureInit);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { getAsync, postAsync, axiosSuccess } = useAxios();
    const [shouldReload, setShouldReload] = useState<boolean>(false);
    const [currentRowInfo, setCurrentRowInfo] = useState<any>(null);
    const [shouldFillEmpty, setShouldFillEmpty] = useState<boolean>(true);
    const { setPatientDepartmentHasChanged, currentPatient } = useContext(PatientContext);

    const fetchDepartmentStructure = async (patientId: string) => {
        const departmentStructureIntel = await getAsync<DepartmentStructureModel[]>("PatientDepartmentAssigment/GetPatientDepartmentAssignment/" + patientId);
        if (departmentStructureIntel || axiosSuccess) {
            const treeInit = initializeTree(departmentStructureIntel || []);
            dispatch({ type: treeInit.type, payload: treeInit.payload });
        }
        setShouldReload(false);
    }

    useEffect(() => {
        if (!shouldReload || props.isAdding || currentPatient === undefined) {
            return;
        }

        fetchDepartmentStructure(currentPatient.patientId || emptyGUID);
    }, [shouldReload, currentPatient])

    useEffect(() => {
        if (!shouldReload || props.isAdding || currentPatient === undefined) {
            return;
        }

        fetchDepartmentStructure(currentPatient.patientId || emptyGUID);
    }, [shouldReload, currentPatient])

    useEffect(() => {
        if (!props.isAdding || localDepartmentStructure === undefined) {
            return;
        }

        const treeInit = initializeTree(localDepartmentStructure);
        dispatch({ type: treeInit.type, payload: treeInit.payload });

        props.onStructureChanged && props.onStructureChanged(localDepartmentStructure);
    }, [localDepartmentStructure, props]);

    useEffect(() => {
        if (props.patientStructure === undefined || props.patientStructure.length === 0) {
            if (!shouldFillEmpty) {
                return;
            }
            const cleanTree = cleanLocalStructure();
            dispatch({ type: cleanTree.type, payload: cleanTree.payload });
            setShouldFillEmpty(false);
        } else {
            setShouldFillEmpty(true);
        }
    }, [props.patientStructure])

    const handleLocalAssign = (rowInfo: any): void => {
        const action = updateOnLocalStructure(rowInfo);
        dispatch({ type: action.type, payload: action.payload });
    }
    const handleLocalDelete = (rowInfo: any): void => {
        const action = deleteOnLocalStructure(rowInfo);
        dispatch({ type: action.type, payload: action.payload });
    }

    // Delete a structure
    const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
    const deleteStructure = async () => {
        if (!currentRowInfo) {
            return;
        }
        setIsLoading(true);
        const { node, path } = currentRowInfo;
        const { type } = node;

        const [departmentId, firstDestId, secondDestId] = path;

        const payload = {
            patientId: currentPatient?.patientId || emptyGUID,
            departmentId: departmentId,
            officeId: type === 'office' ? node.id : (type === 'provider' ? (secondDestId ? firstDestId : null) : null),
            providerId: type === 'provider' ? node.id : null,
        }
        const result = await postAsync<boolean>('PatientDepartmentAssigment/DeletePatientDepartmentAssignment', payload);

        if (result) {
            setPatientDepartmentHasChanged(true);
            setShouldReload(true);
        }

        setIsLoading(false);
    }
    const ui_confirm = <ConfirmationModalComponent
        open={openDeleteConfirmation}
        handleClose={() => setOpenDeleteConfirmation(false)}
        type='deletion'
        onConfirm={deleteStructure}
        message={`Are you sure you want to delete this patient's ${currentRowInfo?.node?.type || 'structure'}?`}
        title="Delete Confirmation Required"
    />;

    // TODO: Implement this function when we have the ability to move nodes in the tree
    const _handleOnMoveNode = () => { }
    const _unassignPatientStructure = (rowInfo: any): void => {
        setCurrentRowInfo(rowInfo);
        if (!props.isAdding) {
            setOpenDeleteConfirmation(true);
            return;
        }

        handleLocalDelete(rowInfo);
    }
    const _assignPatientStructure = async (rowInfo: any): Promise<void> => {
        if (props.isAdding) {
            handleLocalAssign(rowInfo);
            return;
        }

        setIsLoading(true);
        setCurrentRowInfo(rowInfo);

        const { node, path } = rowInfo;
        const { type } = node;

        const [departmentId, firstDestId, secondDestId] = path;

        const payload = {
            patientId: currentPatient?.patientId || emptyGUID,
            departmentId: departmentId,
            officeId: type === 'office' ? node.id : (type === 'provider' ? (secondDestId ? firstDestId : null) : null),
            providerId: type === 'provider' ? node.id : null,
        }

        const result = await postAsync<boolean>('PatientDepartmentAssigment/AddPatientDepartmentAssignment', payload);

        if (result) {
            setPatientDepartmentHasChanged(true);
            setShouldReload(true);
        }

        setIsLoading(false);
    }

    const _structureIsReady = () => {
        if (props.isAdding) {
            return;
        }

        fetchDepartmentStructure(currentPatient?.patientId || emptyGUID);
    }

    const loading_modal = <CustomDialog
        open={isLoading}
        handleClose={() => { }}
        hideButtons
        isOkButtonDisabled
        onConfirm={() => { }}
        size='xs'
        fullWidth={false}
    >
        <Box sx={{ display: 'flex', m: 1, alignItems: 'center', justifyContent: 'center' }}>
            <PrismLoading Size={60} />
            <Typography variant="h6" sx={{ whiteSpace: 'nowrap', ml: 5, mr: 1 }}>Loading ...</Typography>
        </Box>
    </CustomDialog>

    const ui_content = <Grid container spacing={3} height={'500px'}>
        <Grid item xs={12} sm={6}>
            <Box sx={AssignGridContentStyle}>
                <DepartmentStructureTree
                    height='390px'
                    canDrop={_ => false}
                    treeData={treeData}
                    canDrag={_ => false}
                    onChange={(treeData: any) => {
                        const updatedTree = updateTree(treeData);
                        dispatch({ type: updatedTree.type, payload: updatedTree.payload });
                    }}
                    toggleExpand={(value: boolean) => {
                        const expandedTree = toggleExpanded(value);
                        dispatch({ type: expandedTree.type, payload: expandedTree.payload });
                    }}
                    onMoveNode={_handleOnMoveNode}
                    generateNodeProps={rowInfo => ({
                        buttons: [
                            <IconButton disabled={props.isPatientInUse} onClick={_ => _unassignPatientStructure(rowInfo)} aria-label="edit" className='unbordered' color="error" sx={{ px: 0.5 }}>
                                <DeleteOutlineRounded />
                            </IconButton>
                        ]
                    })}
                />
            </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
            <Box sx={AssignGridContentStyle}>
                <AssignDepartmentStructure
                    height="390px"
                    onAddNodeClicked={_assignPatientStructure}
                    treeDataToFilter={treeData}
                    structureIsReady={_structureIsReady}
                    isAdding={props.isAdding}
                    isPatientInUse={props.isPatientInUse}
                    canDrag={_ => false} />
            </Box>
        </Grid>
    </Grid>;

    return (
        <>
            {props.isAdding && ui_content}
            {(!props.isAdding && currentPatient) && ui_content}
            {ui_confirm}
            {loading_modal}
        </>
    );
};

export default PatientDepartmentAssignment;