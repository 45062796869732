import { useEffect, useCallback, useState } from 'react';
import "./Roles.component.css";
import { Button, Grid, IconButton } from '@mui/material';
import { GridColDef, GridSortModel } from '@mui/x-data-grid';
import { DeleteOutlineRounded, Edit } from "@mui/icons-material";
import ConfirmationModalComponent from '../Shared/ConfirmationModal/ConfirmationModal.component';
import { RolesModel } from '../../Api/Model/user/RolesModel';
import { RolesModal } from './RolesModal.component';
import StripedDataGrid, { CustomPagination } from '../Shared/StripedDataGrid/StripedDatagrid.component';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useAxios } from '../../hooks/useAxios';
import PageWrapper from '../Shared/Wrapper/PageWrapper.component';

const Roles = (props: any) => {

  //#region Column Definitions

  const definedColumns: GridColDef[] = [
    {
      field: "roleName",
      headerName: "Name",
      headerAlign: "left",
      align: "left",
      flex: 1,
      hideable: false,
      filterable: false
    },
    {
      field: "roleDescription",
      headerName: "Description",
      flex: 1,
      headerAlign: "left",
      align: "left",
      hideable: false,
      filterable: false
    },
    {
      field: "action",
      headerName: "Actions",
      sortable: false,
      flex: 0,
      headerAlign: "center",
      align: "center",
      cellClassName: "roles-actions",
      hideable: false,
      filterable: false,
      renderCell: (params) => {
        const openEditModal = () => {
          setCurrentRole({
            roleId: params.row.roleId,
            roleName: params.row.roleName,
            roleDescription: params.row.roleDescription
          } as RolesModel);
          setRolesType('update');
          setOpenRolesModal(true);
        };

        const openConfirmModal = () => {
          setCurrentRole({
            roleId: params.row.roleId,
            roleName: params.row.roleName,
            roleDescription: params.row.roleDescription
          } as RolesModel);
          setOpenDeleteConfirmation(true)
        };

        return <Grid container>
          <Grid item xs={6}>
            <IconButton onClick={openEditModal} aria-label="edit" className='unbordered' color="primary">
              <Edit />
            </IconButton>
          </Grid>
          <Grid item xs={6}>
            <IconButton onClick={openConfirmModal} aria-label="delete" className='unbordered' color="error">
              <DeleteOutlineRounded />
            </IconButton>
          </Grid>
        </Grid>;
      }
    },
  ];

  //#endregion


  //#region Hooks

  //#region States

  const [roles, setRoles] = useState<RolesModel[]>([]);
  const [currentRole, setCurrentRole] = useState<RolesModel | undefined>(undefined);
  const [refresh, setRefresh] = useState<boolean>(true);
  const [rowCountState, setRowCountState] = useState(1);
  // Edit / Create a Roles section
  const [openRolesModal, setOpenRolesModal] = useState(false);
  // Delete a Roles section
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [rolesType, setRolesType] = useState<'create' | 'update'>('create');
  const [sortModel, setSortModel] = useState<GridSortModel>([{field: definedColumns[0].field, sort: "asc"}]);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0
  });
  
  //#endregion
  
  //#region Others
  
  const { getAsync, deleteAsync, updateAsync, postAsync, axiosPagination, axiosLoading, axiosError, axiosSuccess } = useAxios();
  const navigate = useNavigate();
  const { manageErrorAlert, showAlertSnack } = useOutletContext<{ manageErrorAlert: Function, showAlertSnack: Function }>();
  const onCloseConfirmModalHandler = useCallback(() => {
    setOpenDeleteConfirmation(false);
  }, [setOpenDeleteConfirmation],);

  const onCloseRolesModalHandler = useCallback(() => {
    setOpenRolesModal(false);
  }, [setOpenRolesModal],);
  
  //#endregion

  //#region Effects

  useEffect(() => {
    if (!refresh) {
      return;
    }

    const {pageSize, page} = paginationModel;
    const {field, sort} = sortModel[0] !== undefined ? sortModel[0] : {field: definedColumns[0].field, sort: "asc"};

    getRoles(pageSize, page, field, sort || "");
  }, [refresh]);

  useEffect(() => {
    if (axiosError && axiosError?.Code === '401') {
      manageErrorAlert("Unauthorized");
      navigate("/patientlist", { replace: true });
      return;
    }
  }, [axiosError]);

  useEffect(() => {
    setRowCountState((prevState) =>
      axiosPagination?.TotalRecords !== undefined ? axiosPagination?.TotalRecords : prevState
    );
  }, [axiosPagination?.TotalRecords]);

  useEffect(() => {
    if (sortModel[0] === undefined) {
      setSortModel([{field: definedColumns[0].field, sort: "asc"}])
    }
    setRefresh(true);
  }, [paginationModel, sortModel]);

  //#endregion

  //#endregion


  //#region Functions

  //#region Roles Methods

  const getRoles = async (pageSize: number, page: number, field: string, sort: string) => {
    const axiosGet = await getAsync<RolesModel[]>(`Role/GetRoles` + 
    `?orderBy=${field}&sort=${sort}`,
    {
      PageNumber: page + 1,
      PageSize: pageSize
    });

    setRefresh(false);
    if (!axiosGet) {
      manageErrorAlert(axiosError?.Messages[0]);
      return;
    }

    setRoles(axiosGet);
  };

  const addNewRoles = (): void => {
    setCurrentRole(undefined);
    setRolesType('create');
    setOpenRolesModal(true);
  }

  //#endregion

  //#region Handlers

  //#region Pagination Handlers

  const onPageSizeChangeHandler = (newPageSize: number) => setPaginationModel({...paginationModel, pageSize: newPageSize});
  const onPageChangeHandler = (newPage: number) => setPaginationModel({...paginationModel, page: newPage});

  //#endregion

  //#region Modal Handlers

  const onDeleteConfirmationHandler = async () => {
    await deleteAsync('Role/DeleteRole', currentRole?.roleId || '');

    if (axiosSuccess) {
      showAlertSnack("The Role was successfully removed.", "success");
      setRefresh(true);
    }
  }

  const onRolesModalConfirmHandler = async (roles: RolesModel) => {
    if (rolesType === 'create') {
      await postAsync('Role/AddRole', roles);
      if (axiosSuccess) {
        setSortModel([] as GridSortModel);
        showAlertSnack("The role was added successfully.", "success");
        setPaginationModel({page: 0, pageSize: 10});
        setRefresh(true);
      }
      else {
        manageErrorAlert(axiosError?.Messages[0]);
      }
      return;
    }

    await updateAsync<RolesModel>('Role/UpdateRol', currentRole?.roleId || '', roles);
    if (axiosSuccess) {
      showAlertSnack("The role was successfully updated.", "success");
      setRefresh(true);
    }
    else {
      manageErrorAlert(axiosError?.Messages[0]);
    }
  }

  //#endregion

  //#endregion

  //#endregion


  //#region UI - JSX Components

  const ui_roles_modal = <RolesModal
    open={openRolesModal}
    handleClose={onCloseRolesModalHandler}
    type={rolesType}
    onConfirm={onRolesModalConfirmHandler}
    rolesParam={currentRole}
  />

  const ui_confirm = <ConfirmationModalComponent
    open={openDeleteConfirmation}
    handleClose={onCloseConfirmModalHandler}
    type='deletion'
    onConfirm={onDeleteConfirmationHandler}
  />;

  const ui_addBtn = <Button sx={{ mb: 2 }} variant="contained" color="success" onClick={addNewRoles}>Add role</Button>;

  //#endregion

  return <PageWrapper title={"Roles"}>
    {ui_addBtn}
    <StripedDataGrid
      sx={{ height: 700 }}
      loading={axiosLoading}
      disableColumnSelector
      disableColumnMenu
      disableSelectionOnClick
      paginationMode="server"
      sortingMode="server"
      rows={roles}
      getRowId={(row) => row.roleId}
      columns={definedColumns}
      rowsPerPageOptions={[5, 10, 20, 50]}
      pageSize={paginationModel.pageSize}
      page={paginationModel.page}
      components={{ Footer: () => CustomPagination(Math.ceil(rowCountState / (paginationModel ? paginationModel.pageSize! : 1)), paginationModel.page + 1, onPageChangeHandler) }}
      rowCount={rowCountState}
      onPageChange={onPageChangeHandler}
      onPageSizeChange={onPageSizeChangeHandler}
      sortModel={sortModel}
      onSortModelChange={setSortModel}
      sortingOrder={["asc", "desc"]}
      getRowClassName={(params) =>
        params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
      }
    />
    {ui_confirm}
    {ui_roles_modal}
  </PageWrapper>;
};

export default Roles;