import { Done, Clear } from "@mui/icons-material";
import { GridCellParams, GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid";
import { PatientMeasurementReadingsModel } from "../../../Api/Model/Patient/PatientReadings/PatientReadingModel";
import { DateToString } from "../../../Shared/DateTime.provider";

const getGroupReading = (readings: PatientMeasurementReadingsModel, type: string) => {
    const reading = readings.results.find(r => r.name === type);
    return !!reading?.value ? `${reading?.value}` : '';
}

const getGroupReadingIsOverLimit = (readings: PatientMeasurementReadingsModel, type: string) => {
    const reading = readings.results.find(r => r.name === type);
    return reading?.isOverLimit;
}

export const renderReadingHasMedication = (params: GridRenderCellParams<any, any>): any => {
    const readings = params.row as PatientMeasurementReadingsModel;

    let hasMedication = false;

    readings.results.forEach(result => {
        if (result.hasMedication) {
            hasMedication = true;
        }
    });

    return hasMedication ? <Done color="success" /> : <Clear color="error" />;
}

export const renderReadingDate = (params: GridValueGetterParams<any, any>): any => {
    const date = new Date(params.row.date);
    const readings = params.row as PatientMeasurementReadingsModel;

    let isManualReading = false;
    readings.results.forEach(result => {
        if (result.isManualReading) {
            isManualReading = true;
        }
    })

    return DateToString(date) + (!isManualReading ? '' : " *");
}

export const renderReadingValue = (params: GridValueGetterParams<any, any>, readingName = ""): any => {
    const readings = params.row as PatientMeasurementReadingsModel;
    if (!readingName) {
        return !!readings.results[0]?.value ? `${readings.results[0]?.value}` : '';
    }
    return getGroupReading(readings, readingName);
}

export const renderReadingOutOfLimit = (params: GridCellParams<any, any, any>, readingName = "") => {
    const readings = params.row as PatientMeasurementReadingsModel;
    if (!readingName) {
        return readings.results[0].isOverLimit ? 'measurementOutOfLimit' : '';
    }
    return getGroupReadingIsOverLimit(readings, readingName) ? 'measurementOutOfLimit' : '';
}