import { useNavigate, useOutletContext } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { BillingCodeModel } from "../../Api/Model/BillingCode/BillingCodeModel";
import { Button, Grid, IconButton } from "@mui/material";
import StripedDataGrid, { CustomPagination } from "../Shared/StripedDataGrid/StripedDatagrid.component";
import { DeleteOutlineRounded, Edit } from "@mui/icons-material";
import { GridColDef, GridSortModel } from "@mui/x-data-grid";
import { BillingCodeModal } from "./billingCodeModal.component";
import ConfirmationModalComponent from "../Shared/ConfirmationModal/ConfirmationModal.component";
import { useAxios } from "../../hooks/useAxios";
import PageWrapper from "../Shared/Wrapper/PageWrapper.component";

const BillingCode = (props: any) => {

  //#region Column Definitions

  const definedColumns: GridColDef[] = [
    {
      field: "code",
      headerName: "Name",
      headerAlign: "left",
      align: "left",
      flex: 1,
      hideable: false,
      filterable: false
    },
    {
      field: "description",
      headerName: "Description",
      headerAlign: "left",
      align: "left",
      flex: 1,
      hideable: false,
      filterable: false
    },
    {
      field: "typeName",
      headerName: "Type",
      flex: 1,
      headerAlign: "left",
      align: "left",
      hideable: false,
      filterable: false
    },
    {
      field: "action",
      headerName: "Actions",
      sortable: false,
      flex: 0,
      headerAlign: "center",
      align: "center",
      cellClassName: "",
      hideSortIcons: true,
      disableExport: true,
      disableColumnMenu: true,
      hideable: false,
      filterable: false,
      renderCell: (params) => {
        const openEditModal = () => {
          setCurrentBillingCode(params.row);
          setBillingCodeType('update');
          setOpenBillingCodeModal(true);
        };

        const openConfirmModal = () => {
          setCurrentBillingCode(params.row);
          setOpenDeleteConfirmation(true)
        };

        return <Grid container>
          <Grid item xs={6}>
            <IconButton onClick={openEditModal} aria-label="edit" className='unbordered' color="primary" placeholder="edit">
              <Edit />
            </IconButton>
          </Grid>
          <Grid item xs={6}>
            <IconButton onClick={openConfirmModal} aria-label="delete" className='unbordered' color="error" placeholder="delete">
              <DeleteOutlineRounded />
            </IconButton>
          </Grid>
        </Grid>;
      }
    },
  ];

  //#endregion


  //#region Hooks

  //#region States

  const [billingCodesList, setBillingCodesList] = useState<BillingCodeModel[]>([]);
  const [currentBillingCode, setCurrentBillingCode] = useState<any>(undefined);
  // Delete a billing code
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  // Edit / Create a billing code section
  const [openBillingCodeModal, setOpenBillingCodeModal] = useState(false);
  const [billingCodeType, setBillingCodeType] = useState<'create' | 'update'>('create');
  const [refresh, setRefresh] = useState<boolean>(true);
  const [rowCountState, setRowCountState] = useState(1);
  const [sortModel, setSortModel] = useState<GridSortModel>([{field: definedColumns[0].field, sort: "asc"}]);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0
  });

  //#endregion
  
  //#region Others
  
  const { getAsync, deleteAsync, updateAsync, postAsync, axiosPagination, axiosLoading, axiosError, axiosSuccess } = useAxios();
  const navigate = useNavigate();
  const { manageErrorAlert, showAlertSnack } = useOutletContext<{ manageErrorAlert: Function, showAlertSnack: Function }>();
  const onCloseConfirmModalHandler = useCallback(
    () => {
      setOpenDeleteConfirmation(false);
    },
    [setOpenDeleteConfirmation],
  );


  const onCloseBillingCodeModalHandler = useCallback(
    () => {
      setOpenBillingCodeModal(false);
    },
    [setOpenBillingCodeModal],
  );
  
  //#endregion

  //#region Effects

  useEffect(() => {
    if (axiosError && axiosError?.Code === '401') {
      manageErrorAlert("Unauthorized");
      navigate("/patientlist", { replace: true });
      return;
    }
  }, [axiosError]);

  useEffect(() => {
    if (!refresh) {
      return;
    }

    const {pageSize, page} = paginationModel;
    const {field, sort} = sortModel[0] !== undefined ? sortModel[0] : {field: definedColumns[0].field, sort: "asc"};

    getBillingCodeList(pageSize, page, field, sort || "");
  }, [refresh]);

  useEffect(() => {
    if (sortModel[0] === undefined) {
      setSortModel([{field: definedColumns[0].field, sort: "asc"}])
    }
    setRefresh(true);
  }, [paginationModel, sortModel]);

  useEffect(() => {
    setRowCountState((prevState) =>
      axiosPagination?.TotalRecords !== undefined ? axiosPagination?.TotalRecords : prevState
    );
  }, [axiosPagination?.TotalRecords]);

  //#endregion

  //#endregion


  //#region Functions

  //#region Biilling Codes Methods

  const getBillingCodeList = async (pageSize: number, page: number, field: string, sort: string) => {
    const axiosGet = await getAsync<BillingCodeModel[]>(`BillingCodes/GetBillingCodesList` + 
    `?orderBy=${field}&sort=${sort}`,
    {
      PageNumber: page + 1,
      PageSize: pageSize,
    });

    setRefresh(false);

    if (!axiosGet) {
      manageErrorAlert(axiosError?.Messages[0]);
      return;
    }

    setBillingCodesList(axiosGet);
  };

  const addNewBillingCode = (): void => {
    setCurrentBillingCode(null);
    setBillingCodeType('create');
    setOpenBillingCodeModal(true);
  }

  //#endregion

  //#region Handlers

  //#region Pagination Handlers

  const onPageSizeChangeHandler = (newPageSize: number) => setPaginationModel({...paginationModel, pageSize: newPageSize});
  const onPageChangeHandler = (newPage: number) => setPaginationModel({...paginationModel, page: newPage});

  //#endregion

  //#region Modal Handlers

  const onDeleteConfirmationHandler = async () => {
    await deleteAsync('BillingCodes/DeleteBillingCode', currentBillingCode?.billingCodeId || '');

    if (axiosSuccess) {
      showAlertSnack("The billing code was successfully removed.", "success");
      setRefresh(true);
    }
  }

  const onBillingCodeModalConfirmHandler = async (billingCode: BillingCodeModel) => {
    if (billingCodeType === 'create') {
      await postAsync('BillingCodes/AddBillingCode', billingCode);
      if (axiosSuccess) {
        showAlertSnack("The billing code was added successfully.", "success");
        setRefresh(true);
      }
      else {
        manageErrorAlert(axiosError?.Messages[0]);
      }
      return;
    }

    await updateAsync<BillingCodeModel>('BillingCodes/UpdateBillingCode', billingCode?.billingCodeId || '', billingCode);
    if (axiosSuccess) {
      showAlertSnack("The billing code was successfully updated.", "success");
      setRefresh(true);
    }
    else {
      manageErrorAlert(axiosError?.Messages[0]);
    }
  }

  //#endregion

  //#endregion

  //#endregion


  //#region UI - JSX Components

  const ui_billingCode_modal = <BillingCodeModal
    open={openBillingCodeModal}
    handleClose={onCloseBillingCodeModalHandler}
    type={billingCodeType}
    onConfirm={onBillingCodeModalConfirmHandler}
    billingCodeParam={currentBillingCode}
  />

  const ui_confirm = <ConfirmationModalComponent
    open={openDeleteConfirmation}
    handleClose={onCloseConfirmModalHandler}
    type='deletion'
    onConfirm={onDeleteConfirmationHandler}
  />;

  const ui_addBtn = <Button sx={{ mb: 2 }} variant="contained" color="success" onClick={addNewBillingCode}>Add Billing Code</Button>;

  //#endregion

  return (
    <PageWrapper title={"Billing Codes"} >
      {ui_addBtn}
      <StripedDataGrid
        sx={{ height: 700 }}
        loading={axiosLoading}
        disableColumnSelector
        disableColumnMenu
        disableSelectionOnClick
        paginationMode="server"
        sortingMode="server"
        rows={billingCodesList}
        getRowId={(row) => row.billingCodeId}
        columns={definedColumns}
        rowsPerPageOptions={[5, 10, 20, 50]}
        pageSize={paginationModel.pageSize}
        page={paginationModel.page}
        components={{ Footer: () => CustomPagination(Math.ceil(rowCountState / (paginationModel ? paginationModel.pageSize! : 1)), paginationModel.page + 1, onPageChangeHandler) }}
        rowCount={rowCountState}
        onPageChange={onPageChangeHandler}
        onPageSizeChange={onPageSizeChangeHandler}
        sortModel={sortModel}
        onSortModelChange={setSortModel}
        sortingOrder={["asc", "desc"]}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
        }
      />
      {ui_confirm}
      {ui_billingCode_modal}
    </PageWrapper>
  );
};

export default BillingCode;