import { DeleteOutlineRounded } from "@mui/icons-material";
import { Box, Grid, IconButton, SxProps, Typography } from "@mui/material";
import { useEffect, useReducer, useState } from "react";
import DepartmentStructureModel from "../../../Api/Model/DepartmentStructure/DepartmentStructureModel";
import { useAxios } from "../../../hooks/useAxios";
import { cleanLocalStructure, deleteOnLocalStructure, departmentStructureInit, departmentStructureReducer, initializeTree, toggleExpanded, updateOnLocalStructure, updateTree } from "../../../Reducers/DepartmentStructure.reducer";
import AssignDepartmentStructure from "../../Shared/AssignDepartmentStructure/AssignDepartmentStructure.component";
import ConfirmationModalComponent from "../../Shared/ConfirmationModal/ConfirmationModal.component";
import DepartmentStructureTree from "../../Shared/DepartmentStructureTree/DepartmentStructureTree.component";
import PrismLoading from "../../Shared/Loading/Loading.component";
import { CustomDialog } from "../../Shared/Modal/Modal.component";

interface UserDepartmentAssignmentProps {
    isAdding: boolean;
    userID?: string;
    onStructureChanged?: (structure: DepartmentStructureModel[]) => void;
    userStructure?:DepartmentStructureModel[];
}

const AssignGridContentStyle: SxProps = {
    border: "2px solid darkgrey",
    borderRadius: "10px",
    height: "100%",
    overflowX: 'auto',
    p: 2
};

const UserDepartmentAssignment = (props: UserDepartmentAssignmentProps) => {
    const [{ treeData, localDepartmentStructure }, dispatch] = useReducer(departmentStructureReducer, departmentStructureInit);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { getAsync, postAsync, axiosSuccess } = useAxios();
    const [shouldReload, setShouldReload] = useState<boolean>(false);
    const [shouldFillEmpty, setShouldFillEmpty] = useState<boolean>(true);

    const [currentRowInfo, setCurrentRowInfo] = useState<any>(null);

    const fetchDepartmentStructure = async () => {
        const departmentStructureIntel = await getAsync<DepartmentStructureModel[]>("UserDepartmentAssigment/GetUserDepartmentAssignment/" + props.userID);
        if (departmentStructureIntel || axiosSuccess) {
            const treeInit = initializeTree(departmentStructureIntel || []);
            dispatch({ type: treeInit.type, payload: treeInit.payload });
        }
        setShouldReload(false);
    }

    useEffect(() => {
        if (props.userID === undefined) {
            const cleanTree = cleanLocalStructure();
            dispatch({ type: cleanTree.type, payload: cleanTree.payload });    
        }
    }, [props.userID])

    useEffect(() => {
        if (!shouldReload || props.isAdding) {
            return;
        }

        fetchDepartmentStructure();
    }, [shouldReload])    

    useEffect(() => {
        if(props.userStructure=== undefined || props.userStructure.length === 0){
            if(!shouldFillEmpty){
                return;
            }
            const cleanTree = cleanLocalStructure();
            dispatch({ type: cleanTree.type, payload: cleanTree.payload });
            setShouldFillEmpty(false);
        }else{
            setShouldFillEmpty(true);
        }
    }, [props.userStructure])

    useEffect(() => {
        if (!props.isAdding || localDepartmentStructure === undefined) {
            return;
        }

        const treeInit = initializeTree(localDepartmentStructure);
        dispatch({ type: treeInit.type, payload: treeInit.payload });

        props.onStructureChanged && props.onStructureChanged(localDepartmentStructure);
    }, [localDepartmentStructure]);

    const handleLocalAssign = (rowInfo: any): void => {
        const action = updateOnLocalStructure(rowInfo);
        dispatch({ type: action.type, payload: action.payload });
    }
    const handleLocalDelete = (rowInfo: any): void => {
        const action = deleteOnLocalStructure(rowInfo);
        dispatch({ type: action.type, payload: action.payload });
    }

    // TODO: Implement this function when we have the ability to move nodes in the tree
    const _handleOnMoveNode = () => { }
    const _unassignPatientStructure = (rowInfo: any): void => {
        setCurrentRowInfo(rowInfo);

        if (props.isAdding) {
            handleLocalDelete(rowInfo);
            return;
        }
        setOpenDeleteConfirmation(true);
    }
    const _assignPatientStructure = async (rowInfo: any): Promise<void> => {
        if (props.isAdding) {
            handleLocalAssign(rowInfo);
            return;
        }

        setIsLoading(true);
        setCurrentRowInfo(rowInfo);

        const { node, path } = rowInfo;
        const { type } = node;

        const [departmentId, firstDestId, secondDestId] = path;

        const payload = {
            userID: props.userID,
            departmentId: departmentId,
            officeId: type === 'office' ? node.id : (type === 'provider' ? (secondDestId ? firstDestId : null) : null),
            providerId: type === 'provider' ? node.id : null,
        }

        const result = await postAsync<boolean>('UserDepartmentAssigment/AddUserDepartmentAssignment', payload);

        if (result) {
            setShouldReload(true);
        }

        setIsLoading(false);
    }

    const _structureIsReady = () => {
        if (props.isAdding) {
            return;
        }
        fetchDepartmentStructure();
    }


    // Delete a structure
    const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
    const deleteStructure = async () => {
        if (!currentRowInfo) {
            return;
        }
        setIsLoading(true);
        const { node, path } = currentRowInfo;
        const { type } = node;

        const [departmentId, firstDestId, secondDestId] = path;

        const payload = {
            userID: props.userID,
            departmentId: departmentId,
            officeId: type === 'office' ? node.id : (type === 'provider' ? (secondDestId ? firstDestId : null) : null),
            providerId: type === 'provider' ? node.id : null,
        }
        const result = await postAsync<boolean>('UserDepartmentAssigment/DeleteUserDepartmentAssignment', payload);

        if (result) {
            setShouldReload(true);
        }

        setIsLoading(false);
    }
    const ui_confirm = <ConfirmationModalComponent
        open={openDeleteConfirmation}
        handleClose={() => setOpenDeleteConfirmation(false)}
        type='deletion'
        onConfirm={deleteStructure}
        message={`Are you sure you want to delete this user's ${currentRowInfo?.node?.type || 'structure'}?`}
        title="Delete Confirmation Required"
    />;

    const loading_modal = <CustomDialog
        open={isLoading}
        handleClose={() => { }}
        title=""
        hideButtons
        isOkButtonDisabled
        onConfirm={() => { }}
        size='xs'
        fullWidth={false}
    >
        <Box sx={{ display: 'flex', m: 1, alignItems: 'center', justifyContent: 'center' }}>
            <PrismLoading Size={60} />
            <Typography variant="h6" sx={{ whiteSpace: 'nowrap', ml: 5, mr: 1 }}>Loading ...</Typography>
        </Box>
    </CustomDialog>

    return (
        <>
            <Grid container spacing={3} height={'500px'}>
                <Grid item xs={12} sm={6}>
                    <Box sx={AssignGridContentStyle}>
                        <DepartmentStructureTree
                            height='390px'
                            canDrop={_ => false}
                            treeData={treeData}
                            canDrag={_ => false}
                            onChange={(treeData: any) => {
                                const updatedTree = updateTree(treeData);
                                dispatch({ type: updatedTree.type, payload: updatedTree.payload });
                            }}
                            toggleExpand={(value: boolean) => {
                                const expandedTree = toggleExpanded(value);
                                dispatch({ type: expandedTree.type, payload: expandedTree.payload });
                            }}
                            onMoveNode={_handleOnMoveNode}
                            generateNodeProps={rowInfo => ({
                                buttons: [
                                    <IconButton onClick={_ => _unassignPatientStructure(rowInfo)} aria-label="edit" className='unbordered' color="error" sx={{ px: 0.5 }}>
                                        <DeleteOutlineRounded />
                                    </IconButton>
                                ]
                            })}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box sx={AssignGridContentStyle}>
                        <AssignDepartmentStructure
                            isAdding={props.isAdding}
                            height="390px"
                            onAddNodeClicked={_assignPatientStructure}
                            treeDataToFilter={treeData}
                            structureIsReady={_structureIsReady}
                            canDrag={_ => false} />
                    </Box>
                </Grid>
            </Grid>
            {ui_confirm}
            {loading_modal}
        </>
    );
};

export default UserDepartmentAssignment;