import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import HttpsOutlinedIcon from '@mui/icons-material/HttpsOutlined';
import { useEffect } from "react";
import { useOutletContext } from "react-router-dom";

const UnauthorizedTemplate = (props: any) => {
    const { manageErrorAlert } = useOutletContext<{
        manageErrorAlert: Function;
        showAlertSnack: Function;
    }>();

    useEffect(() => {
        manageErrorAlert("Unauthorized");
    }, []);

    return (
        <Box style={{ display: "flex", flexFlow: "column", height: "100%" }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card sx={{ width: "100%", height: "750px", display: "flex", justifyContent: "center", margin: "auto", flexFlow: "column", boxShadow: "none" }}>
                        <CardContent style={{ display: "flex", justifyContent: "center" }}>
                            <HttpsOutlinedIcon sx={{ fontSize: 200 }} />
                        </CardContent>
                        <CardContent style={{ display: "flex", justifyContent: "center" }}>
                            <Typography variant="h3" gutterBottom>
                                Access Restricted
                            </Typography>
                        </CardContent>
                        <CardContent style={{ display: "flex", justifyContent: "center" }}>
                            <Typography variant="h5" gutterBottom style={{ textAlign: "center" }}>
                                You don't have permission to view this link, or the link may not be available. <br />
                                Please contact your administrator for help.
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
};

export default UnauthorizedTemplate;