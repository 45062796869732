import { DeleteOutlineRounded, Edit } from "@mui/icons-material";
import { IconButton, TableCell, TableRow } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import ProviderAlertsModel, { ProviderAlertModel } from "../../Api/Model/DepartmentStructure/ProviderAlertsModel";
import { useAxios } from "../../hooks/useAxios";
import CollapsibleTable, { CollapsibleTableRowsType } from "../Shared/Tables/CollapsibleTable.component";
import { PaginationResult } from "../../Api/Model/Shared/PaginationResult";
import ConfirmationModalComponent from "../Shared/ConfirmationModal/ConfirmationModal.component";
import { useNavigate, useOutletContext } from "react-router-dom";
import ProviderAlertsModal from "./ProviderAlertsModal.component";
import { ThresholdDefaultModel } from "../../Api/Model/ThresholdDefaultModel";
import "./ProviderAlerts.component.css";
import PageWrapper from "../Shared/Wrapper/PageWrapper.component";

const no_alerts_result = (provider: ProviderAlertsModel): Array<JSX.Element> => [<TableRow key={provider.providerId}>
    <TableCell colSpan={5} align='center' key={provider.providerId}>No alerts for {provider.providerName}</TableCell>
</TableRow>];

const ProviderAlerts = () => {
    const { manageErrorAlert, showAlertSnack } = useOutletContext<{ manageErrorAlert: Function, showAlertSnack: Function }>();
    const [providers, setProviders] = useState<ProviderAlertsModel[]>([]);
    const [providerRows, setProviderRows] = useState<CollapsibleTableRowsType<ProviderAlertsModel>[]>([]);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [pagination, setPagination] = useState<PaginationResult>({ TotalRecords: 1, PageSize: 10, PageNumber: 1 });
    const [rowCountState, setRowCountState] = useState(0);
    const [currentProviderAlert, setCurrentProviderAlert] = useState<ProviderAlertModel>();
    const [currentProvider, setCurrentProvider] = useState<ProviderAlertsModel>();
    const [currentThreshold, setCurrentThreshold] = useState<ThresholdDefaultModel>();

    const { getAsync, axiosPagination, deleteAsync, postAsync, axiosSuccess, axiosError } = useAxios();
    const { deleteAsync: deleteForProviderAsync, axiosSuccess: axiosSuccessForProvider } = useAxios();
    const navigate = useNavigate();

    const _handleAddClick = (row: ProviderAlertsModel) => {
        setOpenModal(true);
        setCurrentProvider(row);
        setCurrentThreshold(undefined);
        setCurrentProviderAlert(undefined);
    };

    const _handleEditClick = (row: ProviderAlertModel) => {
        setOpenModal(true);
        setCurrentProviderAlert(row);
        setCurrentThreshold({
            alertId: row.providerAlertId,
            deviceTypeId: row.deviceTypeId,
            deviceName: row.device,
            measurementName: row.name,
            limitValues: [{ maximum: row.maximum, minimum: row.minimum }]
        } as ThresholdDefaultModel);
        setCurrentProvider(undefined);
    };

    const _handleDeleteClick = (row: ProviderAlertModel) => {
        setCurrentProviderAlert(row);
        setOpenDeleteConfirmation(true);
    };

    const fetchProviderAlerts = useCallback(
        async () => {
            const providersResult = await getAsync<ProviderAlertsModel[]>('Provider/GetProvidersWithAlerts', {
                PageNumber: pagination?.PageNumber || 0,
                PageSize: pagination?.PageSize || 0
            });
            if (providersResult) {
                setProviders(providersResult);
            }
        },
        [getAsync],
    );

    useEffect(() => {
        setRowCountState((prevState) =>
            axiosPagination?.TotalRecords !== undefined ? axiosPagination?.TotalRecords : prevState
        );
    }, [axiosPagination?.TotalRecords]);

    useEffect(() => {
        fetchProviderAlerts();
    }, [pagination]);

    useEffect(() => {
        if (axiosError && axiosError?.Code === '401') {
            manageErrorAlert("Unauthorized");
            navigate("/patientlist", { replace: true });
            return;
        }
    }, [axiosError]);
    
    useEffect(() => {
        if (!providers) {
            return;
        }

        const providersRows = providers.map((provider) => {
            let alerts = no_alerts_result(provider);

            if (provider.alerts && provider.alerts.length > 0) {
                alerts = provider.alerts && provider.alerts.map((alertRow, index) => {
                    return (
                        <TableRow key={alertRow.providerAlertId}>
                            <TableCell>{alertRow.device}</TableCell>
                            <TableCell component="th" scope="row">{alertRow.name}</TableCell>
                            <TableCell align="left">{alertRow.minimum}</TableCell>
                            <TableCell align="left">{alertRow.maximum}</TableCell>
                            <TableCell align="left">
                                <IconButton onClick={() => _handleEditClick(alertRow)} aria-label="edit" className='unbordered' color="primary">
                                    <Edit />
                                </IconButton>
                                <IconButton onClick={() => _handleDeleteClick(alertRow)} aria-label="edit" className='unbordered' color="error">
                                    <DeleteOutlineRounded />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    )
                });
            }

            return {
                id: provider.providerId,
                item: provider,
                headings: [provider.providerName, provider.code],
                row: alerts,
                onAdd: _handleAddClick,
                addButtonName: "Add Alert",
                rowTitle: "Alerts",
                deleteRowElement: <IconButton onClick={() => deleteProviderHandler(provider)} aria-label="edit" className='unbordered' color={provider.isInUse ? 'secondary' : 'error'}>
                    <DeleteOutlineRounded />
                </IconButton>
            } as CollapsibleTableRowsType<ProviderAlertsModel>;
        });
        setProviderRows(providersRows);
    },[providers])

    const [openDeleteProviderConfirmation, setOpenDeleteProviderConfirmation] = useState(false);
    const deleteProviderHandler = (provider: ProviderAlertsModel) => {
        if(provider.isInUse) {
            showAlertSnack(`${provider.providerName} is in use and cannot be deleted.`, "info");
            return;
        }

        setOpenDeleteProviderConfirmation(true);
        setCurrentProvider(provider);
    }
    const onDeleteConfirmationProvider = async () => {
        if(!currentProvider)
        {
            return;
        }
        const deleteResult = await deleteForProviderAsync<boolean>('Provider', currentProvider.providerId) || false;
        if(deleteResult && axiosSuccessForProvider)
        {
            fetchProviderAlerts();
            showAlertSnack("The provider was successfully removed.", "success");
        }
        else
        {
            setProviders(currentProvs => currentProvs.map(p => {
                if(p.providerId === currentProvider.providerId){
                    p.isInUse = true;
                }
                return p;
            }));
            manageErrorAlert("Unable to remove provider, please try again later.");
        }
    }
    const closeConfirmProviderModal = useCallback(
        () => {
            setOpenDeleteProviderConfirmation(false);
        },
        [setOpenDeleteProviderConfirmation],
    );
    const ui_confirm_provider = <ConfirmationModalComponent
        open={openDeleteProviderConfirmation}
        handleClose={closeConfirmProviderModal}
        type='deletion'
        onConfirm={onDeleteConfirmationProvider}
        title='Delete Provider Confirmation'
        message={`Are you sure you want to delete ${currentProvider?.providerName}?`}
    />;

    // Delete a disease condition section
    const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
    const onDeleteConfirmation = async () => {
        const result = await deleteAsync('Provider/DeleteAlert', currentProviderAlert?.providerAlertId || '');

        if (result && axiosSuccess) {
            setProviders((prevState) => {
                const providersEdited = prevState.map(p => {
                    if (p.alerts.some(pa => pa.providerAlertId === currentProviderAlert?.providerAlertId)) {
                        p.alerts = p.alerts.filter(al => al.providerAlertId !== currentProviderAlert?.providerAlertId);
                        return p;
                    }
                    return p;
                })

                return providersEdited || [];
            });
            showAlertSnack("The provider's alert was successfully removed.", "success");
            return;
        }

        manageErrorAlert(axiosError?.Messages[0]);
    }
    const closeConfirmModal = useCallback(
        () => {
            setOpenDeleteConfirmation(false);
        },
        [setOpenDeleteConfirmation],
    );
    const ui_confirm = <ConfirmationModalComponent
        open={openDeleteConfirmation}
        handleClose={closeConfirmModal}
        type='deletion'
        onConfirm={onDeleteConfirmation}
    />;

    const [openModal, setOpenModal] = useState(false);
    const onConfirmAlertModal = useCallback(
        async (alertDefault: ThresholdDefaultModel) => {
            if (currentProvider !== undefined) {
                await _handleAddAlert(alertDefault);
            }
            else {
                await _handleUpdateAlert(alertDefault);
            }

            setCurrentProvider(undefined);
            setCurrentThreshold(undefined);
            setCurrentProviderAlert(undefined);
        },
        [currentProvider]);
    const ui_modal = <ProviderAlertsModal
        open={openModal}
        handleClose={() => setOpenModal(false)}
        provider={currentProvider || {} as ProviderAlertsModel}
        onConfirm={onConfirmAlertModal}
        alertDeafaultParam={currentThreshold}
    />
    const _handleAddAlert = async (alertDefault: ThresholdDefaultModel) => {
        const payload = {
            ...alertDefault,
            providerId: currentProvider?.providerId
        }
        const addResult = await postAsync<ProviderAlertModel>('Provider/AddAlert', payload);
        if (addResult && axiosSuccess) {
            setProviders((prevState) => {
                const providersEdited = prevState.map(p => {
                    if (p.providerId === currentProvider?.providerId) {
                        p.alerts = [...p.alerts, {
                            minimum: addResult.minimum,
                            maximum: addResult.maximum,
                            name: alertDefault.measurementName,
                            device: alertDefault.deviceName,
                            deviceTypeId: alertDefault.deviceTypeId,
                            managementAlertId: addResult.managementAlertId,
                            providerAlertId: addResult.providerAlertId,
                        } as ProviderAlertModel]

                        return p;
                    }
                    return p;
                })

                return providersEdited || [];
            });
            showAlertSnack("The provider's alert was successfully created.", "success");
            return;
        }

        manageErrorAlert(axiosError?.Messages[0]);
    }
    const _handleUpdateAlert = async (alertDefault: ThresholdDefaultModel) => {
        const payload = {
            ...alertDefault,
            providerAlertId: alertDefault.alertId
        }
        const updateResult = await postAsync<boolean>('Provider/UpdateAlert', payload);
        if (updateResult && axiosSuccess) {
            setProviders((prevState) => {
                const providersEdited = prevState.map(p => {
                    if (p.alerts.some(pa => pa.providerAlertId === alertDefault.alertId)) {
                        const alert = p.alerts.find(pa => pa.providerAlertId === alertDefault.alertId);

                        if (alert === undefined) { return p }

                        alert.maximum = alertDefault.limitValues[0].maximum;
                        alert.minimum = alertDefault.limitValues[0].minimum;
                        return p;
                    }
                    return p;
                })

                return providersEdited || [];
            });
            showAlertSnack("The provider's alert was successfully updated.", "success");
            return;
        }
        manageErrorAlert(axiosError?.Messages[0]);
    }

    const _handleChangePage = (event: unknown, newPage: number) => {
        setPagination({ ...pagination, PageNumber: newPage + 1 });
    };

    const _handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPagination({ ...pagination, PageNumber: 1, PageSize: parseInt(event.target.value, 10) });
    };

    return (
        <PageWrapper title={"Provider Alerts"} >
            <CollapsibleTable
                rowsPerPageOptions={[5, 10, 25, 50]}
                page={pagination.PageNumber !== undefined ? pagination.PageNumber - 1 : 1}
                rowsPerPage={rowsPerPage}
                rowCount={rowCountState}
                onPageChange={_handleChangePage}
                onRowsPerPageChange={_handleChangeRowsPerPage}
                tableHeadingCells={['Provider Name', 'Provider Code']}
                tableRowHeadingCells={['Device Type', 'Measurement Type', 'Default Minimum', 'Default Maximum', 'Actions']}
                tableRowsCells={providerRows}
            />
            {ui_confirm}
            {ui_confirm_provider}
            {ui_modal}
        </PageWrapper>
    );
};

export default ProviderAlerts;