// Hook to fetch patient has readings from the API
// and return them in a format
import { isArray } from 'lodash';
import { useState, useEffect } from 'react';
import { LocaleStringFormat } from '../constants/global.constants';
import { downloadFileResponse } from '../Shared/Files.provider';
import { useAxios } from './useAxios';
import { ReportFilterModel } from '../Api/Model/ReportFilterModel';
import { getDateOfToday, getFirstDayOfCurrentMonth } from '../Shared/DateTime.provider';

type PatientReadingsTypes = {
    PatientId: string,
    InitialDate?:Date,
    EndDate?:Date
}

export function usePatientReadings(props: PatientReadingsTypes) {
    const [hasReadings, setHasReadings] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const { getAsync, axiosSuccess, downloadFileAsync, axiosError } = useAxios();

    const [hasBloodPressureRecords, setHasBloodPressureRecords] = useState(false);
    const [hasSpO2Records, setHasSpO2Records] = useState(false);
    const [hasGlucoseRecords, setHasGlucoseRecords] = useState(false);
    const [hasWeightRecords, setHasWeightRecords] = useState(false);

    const [reportFilterModel, setReportFilterModel] = useState<ReportFilterModel>({});
    const [realReportFilterModel, setRealReportFilterModel] = useState<ReportFilterModel>({});
    const [datesErrors, setDatesErrors] = useState(false);

    const fetchForAllReadings = async (initialDate?: Date, endDate?: Date) => {
        setLoading(true);
        const readingsResponse = await getAsync<string[]>(`PatientMeasurementRecords/GetTypesOfReadingAPatientHas?patientId=${props.PatientId}${!!initialDate && !!endDate ? '&initialDate='+initialDate.toLocaleDateString(LocaleStringFormat)+'&endDate='+ endDate.toLocaleDateString(LocaleStringFormat):''}`);

        setHasSpO2Records(axiosSuccess && isArray(readingsResponse) && readingsResponse.includes('Pulse Oximeter'));
        setHasBloodPressureRecords(axiosSuccess && isArray(readingsResponse) && readingsResponse.includes('Blood Pressure'));
        setHasGlucoseRecords(axiosSuccess && isArray(readingsResponse) && readingsResponse.includes('Glucometer'));
        setHasWeightRecords(axiosSuccess && isArray(readingsResponse) && readingsResponse.includes('Weight Scale'));

        setHasReadings(axiosSuccess && isArray(readingsResponse) && readingsResponse.length > 0);
        setLoading(false);
    }

    const downloadReadingsFile = async () => {
        let response = await downloadFileAsync(
            'PatientMeasurementRecords/DownloadPatientMeasurementRecordsByPatientAndType',`patientId=${props.PatientId}&initialDate=${realReportFilterModel.startDate?.toLocaleDateString(LocaleStringFormat)}&endDate=${realReportFilterModel.endDate?.toLocaleDateString(LocaleStringFormat)}`
        );
        setLoading(true);
        if (axiosSuccess) {
            downloadFileResponse(
            response,
            `PatientReadings_${props.PatientId}.xlsx`
            );
        }
        setLoading(false);
    };

    useEffect(() => {
        if (!("startDate" in reportFilterModel)) {
            setReportFilterModel({endDate:getDateOfToday(), startDate:getFirstDayOfCurrentMonth()});
        }

        setRealReportFilterModel({endDate:getDateOfToday(), startDate:getFirstDayOfCurrentMonth()});
        fetchForAllReadings(getFirstDayOfCurrentMonth(), getDateOfToday());
    }, []);

    return {loading
        , hasReadings
        , hasBloodPressureRecords
        , hasSpO2Records
        , hasGlucoseRecords
        , hasWeightRecords
        , fetchForAllReadings
        , downloadReadingsFile
        , axiosError
        , reportFilterModel
        , setReportFilterModel
        , datesErrors
        , setDatesErrors
        , realReportFilterModel
        , setRealReportFilterModel
    };
}