import { View, StyleSheet } from "@react-pdf/renderer";
import VitalsTableHeader from "./tableHeader";
import SummaryTableRow from "./TableRow";
import { colorsPalette } from "../../../../constants/styles.constants";

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginHorizontal:20,
    borderWidth: 1,
    borderColor: colorsPalette.dark,
  },
});

const ItemsTable = (data: any) => (
  <View style={styles.tableContainer}>
    <VitalsTableHeader />
    <SummaryTableRow items={data} />
  </View>
);

export default ItemsTable;