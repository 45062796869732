import { useEffect, useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import {
  BillingCode4Setup,
  DateDisplayFormat,
  LocaleStringFormat,
} from "../../../constants/global.constants";
import { Dayjs } from "dayjs";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { GridColDef, GridSortModel, GridValueGetterParams } from "@mui/x-data-grid";
import { isEmpty, isUndefined } from "lodash";
import { useNavigate, useOutletContext } from "react-router-dom";
import { ReportFilterModel } from "../../../Api/Model/ReportFilterModel";
import { TimeReportModel } from "../../../Api/Model/Reports/TimeReportModel";
import { useAxios } from "../../../hooks/useAxios";
import { downloadFileResponse } from "../../../Shared/Files.provider";
import {
  CustomNoRowsReportOverlay
} from "../../../constants/styles.constants";
import StripedDataGrid, {
  CustomPagination,
} from "../../Shared/StripedDataGrid/StripedDatagrid.component";
import { getDateOfToday, getFirstDayOfCurrentMonth, getUTCDate } from "../../../Shared/DateTime.provider";
import { BillingCodesShort } from "../../../Api/Model/Reports/BilingCodesShort";
import { BillingCodeModel } from "../../../Api/Model/BillingCode/BillingCodeModel";
import { emptyGUID } from "../../../constants/guid.contast";
import { sortStringsWithNumbers } from "../../../Shared/Sorting.provider";
import { CleanButton } from "../../../Shared/CleanHandler.provider";
import PageWrapper from "../../Shared/Wrapper/PageWrapper.component";
import { DepartmentModel } from "../../../Api/Model/DepartmentModel";
import OfficeModel from "../../../Api/Model/DepartmentStructure/OfficeModel";
import ProviderModel from "../../../Api/Model/DepartmentStructure/ProviderModel";

//#region Definitions

//#region Defined Columns

const definedColumns: GridColDef[] = [
  {
    field: "patientId",
    headerName: "Patient Id",
    headerAlign: "center",
    align: "left",
    flex: 1,
    hideable: false,
    filterable: false,
  },
  {
    field: "lastName",
    headerName: "Last Name",
    flex: 1,
    headerAlign: "center",
    align: "left",
    hideable: false,
    filterable: false,
  },
  {
    field: "firstName",
    headerName: "First Name",
    flex: 1,
    headerAlign: "center",
    align: "left",
    hideable: false,
    filterable: false,
  },
  {
    field: "birthDateTime",
    headerName: "DOB",
    flex: 1,
    headerAlign: "center",
    align: "left",
    hideable: false,
    filterable: false,
    sortComparator: (a, b) => dateSort(a, b),
    valueGetter: (params: GridValueGetterParams<any, any>): any => {
      return getUTCDate(new Date(params.row.birthDateTime)).toLocaleDateString(LocaleStringFormat);
    },
  },
  {
    field: "gender",
    headerName: "Sex",
    flex: 0.5,
    headerAlign: "center",
    align: "left",
    hideable: false,
    filterable: false,
  },
  {
    field: "onBoardingDate",
    headerName: "99453 Date",
    flex: 1,
    headerAlign: "center",
    align: "left",
    hideable: false,
    filterable: false,
    valueGetter: (params) => {
      if (
        !params.row.onBoardingDate
      ) {
        return "";
      }
      return getUTCDate(new Date(params.row.onBoardingDate)).toLocaleDateString(LocaleStringFormat);
    },
  },
  {
    field: "manualReadings",
    headerName: "Manual Readings",
    flex: 0.6,
    headerAlign: "center",
    align: "center",
    hideable: false,
    filterable: false,
  },
  {
    field: "automaticReadings",
    headerName: "Automatic Readings",
    flex: 0.6,
    headerAlign: "center",
    align: "center",
    hideable: false,
    filterable: false,
  },
  {
    field: "lastReading",
    headerName: "Last Readings",
    flex: 1,
    headerAlign: "center",
    align: "left",
    hideable: false,
    filterable: false,
    valueGetter: (params: GridValueGetterParams<any, any>): any => {
      if (
        params.row.automaticReadings === 0 &&
        params.row.manualReadings === 0
      ) {
        return "";
      }
      return getUTCDate(new Date(params.row.lastReading)).toLocaleDateString(LocaleStringFormat);
    },
  },
  {
    field: "" + BillingCode4Setup + "",
    headerName: "99453 Time",
    flex: 0.85,
    headerAlign: "center",
    align: "right",
    hideable: false,
    filterable: false,
    valueGetter: (params) => {
      return params.row.billingCodes.find(
        (i: BillingCodesShort) => i.code === BillingCode4Setup
      ).timeTotal;
    },
  },
];

//#endregion

//#region Interfaces Definitions

interface PatientModelSelect {
  patientId: string;
  internalCode: string;
  firstName: string;
  lastName: string;
}

//#endregion

//#region Global Function Definitions

const dateSort = (dateStringA: string, dateStringB: string) =>
  new Date(dateStringA).valueOf() - new Date(dateStringB).valueOf();

//#endregion

//#endregion

const TimeReport = (props: any) => {

  //#region Hooks
  
  //#region States

  const [reportFilterModel, setReportFilterModel] = useState<ReportFilterModel>(
    { departmentId: "", officeId: "", providerId: "", patientId: ""}
  );
  const [timeReports, setTimeReport] = useState<TimeReportModel[]>([]);
  const [departments, setDepartments] = useState<DepartmentModel[]>([]);
  const [offices, setOffices] = useState<OfficeModel[]>([]);
  const [providers, setProviders] = useState<ProviderModel[]>([]);
  const [patients, setPatients] = useState<PatientModelSelect[]>([]);
  const [rowCountState, setRowCountState] = useState(0);
  const [exportFilterModel, setExportFilterModel] = useState<ReportFilterModel>(
    {}
  );
  const [refresh, setRefresh] = useState<boolean>(false);
  const [fullColumns, setFullColumns] = useState<GridColDef[]>([]);
  const [datesErrors, setDatesErrors] = useState(false);
  const [sortModel, setSortModel] = useState<GridSortModel>([{field: definedColumns[0].field, sort: "asc"}]);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0
  });

  //#endregion

  //#region Others

  const { manageErrorAlert, showAlertSnack } = useOutletContext<{
    manageErrorAlert: Function;
    showAlertSnack: Function;
  }>();

  const {
    getAsync,
    axiosError,
    downloadFileAsync,
    axiosSuccess,
    axiosLoading,
    axiosPagination,
  } = useAxios();

  const { getAsync: getAsyncCatalogs, axiosError: axiosErrorCatalogs } =
    useAxios();

  const navigate = useNavigate();

  //#endregion

  //#region Effects

  useEffect(() => {
    setDatesErrors(
      (reportFilterModel.endDate &&
        reportFilterModel.startDate &&
        reportFilterModel.endDate < reportFilterModel.startDate) ||
      false
    );
  }, [reportFilterModel.startDate, reportFilterModel.endDate]);

  useEffect(() => {
    if (axiosError && axiosError?.Code === '401') {
      manageErrorAlert("Unauthorized");
      navigate("/patientlist", { replace: true });
      return;
    }
  }, [axiosError]);

  useEffect(() => {
    getBillingCodes();
    getDepartments();
    getOffices();
    getProviders();
  }, []);

  useEffect(() => {
    setReportFilterModel({ ...reportFilterModel, patientId: "" });
    getPatients();
  }, [
    reportFilterModel.departmentId,
    reportFilterModel.officeId,
    reportFilterModel.providerId,
  ]);

  useEffect(() => {
    if (!refresh) {
      return;
    }

    const {pageSize, page} = paginationModel;
    const {field, sort} = sortModel[0] !== undefined ? sortModel[0] : {field: definedColumns[0].field, sort: "asc"};

    getTimeReport(pageSize, page, field, sort || "");
  }, [refresh]);

  useEffect(() => {
    setRowCountState((prevState) =>
      axiosPagination?.TotalRecords !== undefined
        ? axiosPagination?.TotalRecords
        : prevState
    );
  }, [axiosPagination?.TotalRecords]);

  useEffect(() => {
    if (sortModel[0] === undefined) {
      setSortModel([{field: definedColumns[0].field, sort: "asc"}])
    }
    setRefresh(true);
  }, [paginationModel, sortModel]);

  //#endregion

  //#endregion


  //#region Handlers

  //#region Pagination Handlers

  const onPageSizeChangeHandler = (newPageSize: number) => setPaginationModel({...paginationModel, pageSize: newPageSize});
  const onPageChangeHandler = (newPage: number) => setPaginationModel({...paginationModel, page: newPage});

  //#endregion

  //#region Department Structure Handlers

  const departmentFilterHandler = (event: any) => {
    setReportFilterModel({
      ...reportFilterModel,
      departmentId: event.target.value,
      officeId: "",
      providerId: "",
    });
    if (event.target.value === "") {
      getOffices();
      getProviders();
      return;
    }
    getFilterOffices(event.target.value);
    getFilterProviders(event.target.value, reportFilterModel.officeId);
  };

  const officeFilterHandler = (event: any) => {
    setReportFilterModel({
      ...reportFilterModel,
      officeId: event.target.value,
      providerId: "",
    });
    getFilterProviders(reportFilterModel.departmentId, event.target.value);
  };

  const providerFilterHandler = (event: any) =>
    setReportFilterModel({
      ...reportFilterModel,
      providerId: event.target.value,
    });

  const patientSelectHandler = (event: any) =>
    setReportFilterModel({
      ...reportFilterModel,
      patientId: event.target.value,
    });

  //#endregion

  //#region Button Handlers

  const onSubmitReportClick = async () => {
    const {pageSize, page} = paginationModel;
    const {field, sort} = sortModel[0] !== undefined ? sortModel[0] : {field: definedColumns[0].field, sort: "asc"};

    getTimeReport(pageSize, page, field, sort || "");
    setExportFilterModel(reportFilterModel);
  };

  const onExportReportClick = async () => {

    let response = await downloadFileAsync(
      "Reports/ExportTimeReports",
      `dateIni=${exportFilterModel.startDate?.toLocaleDateString(
        "en-US"
      )}&dateEnd=${exportFilterModel.endDate?.toLocaleDateString(
        "en-US"
      )}${!!reportFilterModel.departmentId
        ? "&departmentId=" + reportFilterModel.departmentId
        : ""
      }${!!reportFilterModel.officeId
        ? "&officeId=" + reportFilterModel.officeId
        : ""
      }${!!reportFilterModel.providerId
        ? "&providerId=" + reportFilterModel.providerId
        : ""
      }${!!reportFilterModel.patientId
        ? "&patientId=" + reportFilterModel.patientId
        : ""
      }`
    );
    if (axiosSuccess) {
      showAlertSnack("Report generated successfully.", "success");
      downloadFileResponse(
        response,
        `TimeReport ${exportFilterModel.startDate?.toLocaleDateString(
          "en-US"
        )} to ${exportFilterModel.endDate?.toLocaleDateString("en-US")}.xlsx`
      );
    } else {
      manageErrorAlert(axiosError?.Messages[0]);
    }
  };

  //#endregion

  //#endregion


  //#region Component Functions

  const getDepartments = async () => {
    const axiosGet = await getAsync<DepartmentModel[]>(
      "Department/GetDepartments",
      {
        PageNumber: 0,
        PageSize: 0,
      }
    );

    if (!axiosGet) {
      manageErrorAlert(axiosError?.Messages[0]);
      return;
    }

    axiosGet.sort((departmentA, departmentB) =>
      sortStringsWithNumbers(
        departmentA.departmentName,
        departmentB.departmentName
      )
    );
    setDepartments(axiosGet);
  };

  const getOffices = async () => {
    const axiosGet = await getAsync<OfficeModel[]>("Office/GetOffices", {
      PageNumber: 0,
      PageSize: 0,
    });

    if (!axiosGet) {
      manageErrorAlert(axiosError?.Messages[0]);
      return;
    }

    axiosGet.sort((officeA, officeB) =>
      sortStringsWithNumbers(officeA.officeName, officeB.officeName)
    );
    setOffices(axiosGet);
  };

  const getFilterOffices = async (currentDepartment: string | undefined) => {
    const axiosGet = await getAsync<OfficeModel[]>(
      `Office/GetOfficesByDepartmentId?DepartmentId=${currentDepartment}`
    );

    if (!axiosGet) {
      manageErrorAlert(axiosError?.Messages[0]);
      return;
    }

    axiosGet.sort((officeA, officeB) =>
      sortStringsWithNumbers(officeA.officeName, officeB.officeName)
    );
    setOffices(axiosGet);
  };

  const getProviders = async () => {
    const axiosGet = await getAsync<ProviderModel[]>("Provider/GetProviders", {
      PageNumber: 0,
      PageSize: 0,
    });

    if (!axiosGet) {
      manageErrorAlert(axiosError?.Messages[0]);
      return;
    }

    axiosGet.sort((providerA, providerB) =>
      sortStringsWithNumbers(providerA.providerName, providerB.providerName)
    );
    setProviders(axiosGet);
  };

  const getFilterProviders = async (
    currentDepartment: string | undefined,
    currentOffice: string | undefined
  ) => {
    const axiosGet = await getAsync<ProviderModel[]>(
      `Provider/GetProvidersByDepartmentStructure?DepartmentId=${currentDepartment || emptyGUID
      }&OfficeId=${currentOffice || emptyGUID}`,
      {
        PageNumber: 0,
        PageSize: 0,
      }
    );

    if (!axiosGet) {
      manageErrorAlert(axiosError?.Messages[0]);
      return;
    }

    axiosGet.sort((providerA, providerB) =>
      sortStringsWithNumbers(providerA.providerName, providerB.providerName)
    );
    setProviders(axiosGet);
  };
  const getPatients = async () => {
    const axiosGet = await getAsync<PatientModelSelect[]>(
      `Patients/GetPatientsByDepartmentStructure?departmentId=${reportFilterModel.departmentId || emptyGUID
      }&officeId=${reportFilterModel.officeId || emptyGUID}&providerId=${reportFilterModel.providerId || emptyGUID
      }`
    );

    if (!axiosGet) {
      manageErrorAlert(axiosError?.Messages[0]);
      return;
    }

    axiosGet.sort((patientA, patientB) =>
      sortStringsWithNumbers(
        patientA.firstName + " " + patientA.lastName,
        patientB.firstName + " " + patientB.lastName
      )
    );
    setPatients(axiosGet);
  };

  const getBillingCodes = async () => {
    const axiosGet = await getAsyncCatalogs<BillingCodeModel[]>(
      "BillingCodes/GetBillingCodesList",
      {
        PageNumber: 0,
        PageSize: 50,
      }
    );

    setRefresh(false);
    if (!axiosGet) {
      manageErrorAlert(axiosErrorCatalogs?.Messages[0]);
      return;
    }

    let temporalFullColumns = [...definedColumns];
    axiosGet.reverse();
    axiosGet.forEach((bc) => {
      if (bc.code === BillingCode4Setup) return;
      temporalFullColumns = [
        ...temporalFullColumns,
        {
          field: bc.code || "",
          headerName: (bc.code || "") + " Time",
          flex: 0.85,
          headerAlign: "center",
          align: "right",
          hideable: false,
          filterable: false,
          valueGetter: (params) => {
            return params.row.billingCodes.find(
              (i: BillingCodesShort) => i.code === bc.code
            ).timeTotal;
          },
        },
      ];
    });

    setFullColumns(temporalFullColumns);
  };

  const getTimeReport = async (pageSize: number, page: number, field: string, sort: string) => {
    if (!isEmpty(validationErrors)) {
      if (!("startDate" in reportFilterModel)) {
        setReportFilterModel({...reportFilterModel,endDate:getDateOfToday(),startDate:getFirstDayOfCurrentMonth()});
      }
      return;
    }
    const axiosGet = await getAsync<TimeReportModel[]>(
      `Reports/GetTimeReports?` +
      `&dateIni=${reportFilterModel.startDate?.toLocaleDateString("en-US")}` +
      `&dateEnd=${reportFilterModel.endDate?.toLocaleDateString("en-US")}` +
      `${!!reportFilterModel.departmentId ? "&departmentId=" + reportFilterModel.departmentId : ""}` +
      `${!!reportFilterModel.officeId ? "&officeId=" + reportFilterModel.officeId : ""}` +
      `${!!reportFilterModel.providerId ? "&providerId=" + reportFilterModel.providerId : ""}` +
      `${!!reportFilterModel.patientId ? "&patientId=" + reportFilterModel.patientId : ""}` +
      `&orderBy=${field}&sort=${sort}`,
      {
        PageNumber: page + 1,
        PageSize: pageSize
      }
    );
    setRefresh(false);
    if (!axiosGet) {
      manageErrorAlert(axiosError?.Messages[0]);
      return;
    }
    setTimeReport(axiosGet);
  };

  //#endregion


  //#region Validations
  
  const validationErrors = {
    ...(isUndefined(reportFilterModel.startDate) && {
      StartDate: "Start date is required",
    }),
    ...(reportFilterModel.startDate?.toString() === "Invalid Date" && {
      StartDate: "Start date must be a valid date",
    }),
    ...(isUndefined(reportFilterModel.endDate) && {
      EndDate: "End date is required",
    }),
    ...(reportFilterModel.endDate?.toString() === "Invalid Date" && {
      EndDate: "End date must be a valid date",
    }),
    ...(datesErrors && {
      StartDate: "Date cannot be greater than end date",
      EndDate: "Date cannot be less than start date",
    }),
  };

  //#endregion


  //#region UI - JSX Components
  
  const ui_Header_search = (
    <>
      <Grid item xs={3}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            disableFuture
            onChange={(newValue: Dayjs | null) =>
              setReportFilterModel({
                ...reportFilterModel,
                startDate: newValue ? newValue.toDate() : undefined,
              })
            }
            value={reportFilterModel.startDate || null}
            label="Start Date"
            inputFormat={DateDisplayFormat}
            renderInput={(params) => (
              <TextField
                required
                inputProps={{
                  tabIndex: 1,
                }}
                {...params}
                error={!!validationErrors.StartDate}
                helperText={validationErrors.StartDate}
                fullWidth
              />
            )}
            PopperProps={{
              placement: "bottom-start",
              sx: {zIndex:1200, paddingBottom: 2 }
            }}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={3}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            onChange={(newValue: Dayjs | null) =>
              setReportFilterModel({
                ...reportFilterModel,
                endDate: newValue ? newValue.toDate() : undefined,
              })
            }
            value={reportFilterModel.endDate || null}
            label="End Date"
            inputFormat={DateDisplayFormat}
            renderInput={(params) => (
              <TextField
                required
                inputProps={{
                  tabIndex: 2,
                }}
                {...params}
                error={!!validationErrors.EndDate}
                helperText={validationErrors.EndDate}
                fullWidth
              />
            )}
            PopperProps={{
              placement: "bottom-start",
              sx: { zIndex:1200, paddingBottom: 2 }
            }}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <InputLabel id="PatientDepartmentLabel" color="primary">
            Patient Department
          </InputLabel>
          <Select
            fullWidth
            id="PatientDepartmentCombo"
            defaultValue={""}
            unselectable="on"
            inputProps={{
              tabIndex: 3,
            }}
            label="Patient Department"
            labelId="PatientDepartmentLabel"
            value={reportFilterModel.departmentId || ""}
            onChange={departmentFilterHandler}
            endAdornment={
              reportFilterModel?.departmentId !== "" && (
                CleanButton(departmentFilterHandler)
              )
            }
          >
            {departments.length === 0 && (
              <MenuItem value="">No departments found...</MenuItem>
            )}
            {departments &&
              departments.map((department: DepartmentModel) => (
                <MenuItem
                  value={department.departmentId}
                  key={department.departmentId}
                >
                  {department.departmentName}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <InputLabel id="PatientOfficeLabel" color="primary">
            Patient Office
          </InputLabel>
          <Select
            fullWidth
            id="PatientOfficeCombo"
            defaultValue={""}
            unselectable="on"
            inputProps={{
              tabIndex: 3,
            }}
            multiple={false}
            native={false}
            label="Patient Office"
            labelId="PatientOfficeLabel"
            value={reportFilterModel.officeId || ""}
            onChange={officeFilterHandler}
            endAdornment={
              reportFilterModel?.officeId !== "" && (
                CleanButton(officeFilterHandler)
              )
            }
          >
            {offices.length === 0 && (
              <MenuItem value="">No offices found...</MenuItem>
            )}
            {offices &&
              offices.map((office: OfficeModel) => (
                <MenuItem value={office.officeId} key={office.officeId}>
                  {office.officeName}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <InputLabel id="PatientProviderLabel" color="primary">
            Patient Provider
          </InputLabel>
          <Select
            fullWidth
            id="PatientProviderCombo"
            defaultValue={""}
            unselectable="on"
            inputProps={{
              tabIndex: 3,
            }}
            multiple={false}
            native={false}
            label="Patient Provider"
            labelId="PatientProviderLabel"
            value={reportFilterModel.providerId || ""}
            onChange={providerFilterHandler}
            endAdornment={
              reportFilterModel?.providerId !== "" && (
                CleanButton(providerFilterHandler)
              )
            }
          >
            {providers.length === 0 && (
              <MenuItem value="">No providers found...</MenuItem>
            )}
            {providers &&
              providers.map((provider: ProviderModel) => (
                <MenuItem
                  value={provider.providerId}
                  key={provider.providerId}
                >
                  {provider.providerName}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl
          fullWidth
        >
          <InputLabel
            id="PatientLabel"
            color={"primary"}
          >
            Patient
          </InputLabel>
          <Select
            fullWidth
            id="PatientCombo"
            defaultValue={""}
            labelId="PatientLabel"
            label={"Patient"}
            inputProps={{
              tabIndex: 3,
            }}
            multiple={false}
            native={false}
            unselectable="on"
            value={reportFilterModel.patientId || ""}
            onChange={patientSelectHandler}
            endAdornment={
              reportFilterModel?.patientId !== "" && (
                CleanButton(patientSelectHandler)
              )
            }
          >
            {patients.length === 0 && (
              <MenuItem value="">No patients found...</MenuItem>
            )}
            {patients &&
              patients.map((patient: PatientModelSelect) => (
                <MenuItem
                  value={patient.patientId}
                  key={patient.patientId}
                >
                  {`${patient.firstName} ${patient.lastName}`}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={1}>
        <Button
          disabled={!isEmpty(validationErrors)}
          fullWidth
          sx={{ mb: 2, height: 55 }}
          variant="contained"
          color="primary"
          onClick={onSubmitReportClick}
        >
          SUBMIT
        </Button>
      </Grid>
      <Grid item xs={1}>
        <Button
          disabled={
            !exportFilterModel.startDate ||
            !exportFilterModel.endDate ||
            timeReports.length < 1
          }
          fullWidth
          sx={{ mb: 2, height: 55 }}
          variant="contained"
          color="primary"
          onClick={onExportReportClick}
        >
          EXPORT
        </Button>
      </Grid>
    </>
  );

  //#endregion
  

  return <PageWrapper title={"Time Report"}>
    <Grid container spacing={2} rowSpacing={1} width={"100%"}>
      {ui_Header_search}
      <Grid item xs={12}>
        <StripedDataGrid
          sx={{ height: 640 }}
          loading={axiosLoading}
          disableColumnSelector
          disableColumnMenu
          disableSelectionOnClick
          paginationMode="server"
          sortingMode="server"
          rows={timeReports}
          columns={fullColumns}
          rowsPerPageOptions={[5, 10, 20, 50]}
          pageSize={paginationModel.pageSize}
          page={paginationModel.page}
          components={{
            NoResultsOverlay: () =>
              CustomNoRowsReportOverlay(
                !exportFilterModel.startDate ||
                  !exportFilterModel.startDate
                  ? "Please select a date range to display the report"
                  : "No results for the selected date range "
              ),
            NoRowsOverlay: () =>
              CustomNoRowsReportOverlay(
                !exportFilterModel.startDate ||
                  !exportFilterModel.startDate
                  ? "Please select a date range to display the report"
                  : "No results for the selected date range "
              ),
            Footer: () =>
              CustomPagination(
                Math.ceil(
                  rowCountState /
                  (paginationModel ? paginationModel.pageSize! : 1)
                ),
                paginationModel.page + 1,
                onPageChangeHandler
              ),
          }}
          rowCount={rowCountState}
          onPageChange={onPageChangeHandler}
          onPageSizeChange={onPageSizeChangeHandler}
          onSortModelChange={setSortModel}
          sortModel={sortModel}
          sortingOrder={["asc", "desc"]}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
          }
        />
      </Grid>
    </Grid>
  </PageWrapper>;
};

export default TimeReport;