import { addNodeUnderParent, changeNodeAtPath, removeNodeAtPath, toggleExpandedForAll, TreeItem } from "@nosferatu500/react-sortable-tree"
import { isArray } from "lodash"
import DepartmentStructureModel from "../Api/Model/DepartmentStructure/DepartmentStructureModel"
import OfficeModel from "../Api/Model/DepartmentStructure/OfficeModel"
import ProviderModel from "../Api/Model/DepartmentStructure/ProviderModel"

type DepartmentStructureType = {
    /**
     * This is the tree data that will be used to render the tree
     */
    treeData: TreeItem[] | undefined
    /**
     * This is the local department structure that will be used to manage the state of the tree, can be used to create or update a tree.
     * @remarks The system uses this state to create local trees, that don't need to be saved in the database immediately
     */
    localDepartmentStructure: DepartmentStructureModel[] | undefined
}

export const departmentStructureInit: DepartmentStructureType = {
    treeData: Array<TreeItem>(),
    localDepartmentStructure: Array<DepartmentStructureModel>()
}

/**
 * This enum will be used to define the types of actions that will be used in the reducer
 */
enum DepartmentStructureActionType {
    /**
     * @remarks This is a tree data type
     */
    TOGGLE_EXPANDED,
    /**
     * @remarks This is a tree data type
     */
    INITIALIZE,
    /**
     * @remarks This is a tree data type
     */
    UPDATE,
    /**
     * @remarks This is a tree data type
     */
    ADD_NODE,
    /**
     * @remarks This is a tree data type
     */
    ADD_NODE_CHILD,
    /**
     * @remarks This is a tree data type
     */
    CHANGE_NODE,
    /**
     * @remarks This is a tree data type
     */
    DELETE_NODE,
    /**
     * This action will be used to update the local structure, usefull when the entity is being updated
     * @remarks This is a local department structure type
     */
    UPDATE_LOCAL_STRUCTURE,
    /**
     * This action will be used to delete the local structure, usefull when the entity is being deleted
     * @remarks This is a local department structure type
     */
    DELETE_LOCAL_STRUCTURE,
    /**
     * This action will be used to clean the local structure, usefull when the entity is being created
     * @remarks This is a local department structure type
     */ 
    CLEAN_LOCAL_STRUCTURE
}

export function isDepartmentStructureActionType({ type }: { type: DepartmentStructureActionType }): boolean {
    return Object.values(DepartmentStructureActionType).includes(type)
}
/**
 * This function will be used to toggle the expanded state of the whole node
 * @returns 
 */
export function toggleExpanded(expanded: boolean) {
    return {
        type: DepartmentStructureActionType.TOGGLE_EXPANDED,
        payload: {
            expanded
        }
    } as const
}
/**
 * This function will be used to initialize the tree data model, usefull when the component is first mounted
 * @param treeDataModel This is the tree data model that will be used to initialize the tree, will be mapped to the tree data
 */
export function initializeTree(treeDataModel: DepartmentStructureModel[]) {
    return {
        type: DepartmentStructureActionType.INITIALIZE,
        payload: {
            treeDataModel
        }
    } as const
}
/**
 * This function will be used to update the tree data model, usefull when the component changes
 * @param treeData This is the new tree data model
 */
export function updateTree(treeData: TreeItem[]) {
    return {
        type: DepartmentStructureActionType.UPDATE,
        payload: {
            treeData
        }
    } as const
}
/**
 * This function will be used to add a new node to the tree
 * @param title This is the title of the node
 * @param subtitle This is the subtitle of the node
 */
export function addNode(title: string, subtitle: string, id: string) {
    return {
        type: DepartmentStructureActionType.ADD_NODE,
        payload: {
            title,
            subtitle,
            id
        }
    } as const
}
/**
 * This function will be used to add a new node as a child of another node
 * @param title This is the title of the node
 * @param subtitle This is the subtitle of the node
 * @param parentKey This is the key of the parent node
 * @param expanded This is the expanded state of the node
 * @param isDirectory This is the isDirectory state of the node
 */
export function addNodeAsChild(title: string, subtitle: string, id: string, parentKey: string, expanded: boolean, isDirectory: boolean, type: 'office' | 'provider') {
    return {
        type: DepartmentStructureActionType.ADD_NODE_CHILD,
        payload: {
            parentKey,
            title,
            subtitle,
            id,
            type,
            expanded,
            isDirectory
        }
    } as const
}
/**
 * This function will be used to change the title and subtitle of a node
 * @param title This is the new title of the node
 * @param subtitle This is the new subtitle of the node
 * @param node This is the node that will be changed
 * @param path This is the path of the node that will be changed
 */
export function changeNode(title: string, subtitle: string, node: any, path: number[]) {
    return {
        type: DepartmentStructureActionType.CHANGE_NODE,
        payload: {
            title,
            subtitle,
            node,
            path
        }
    } as const
}
/**
 * This function will be used to delete a node
 * @param path This is the path of the node that will be deleted
 */
export function deleteNode(path: number[]) {
    return {
        type: DepartmentStructureActionType.DELETE_NODE,
        payload: {
            path
        }
    } as const
}
/**
 * This function will be used to update a local structure, usefull when the entity is being updated
 * @param rowInfo This is the row info that will be used to update the local structure
 */
export function updateOnLocalStructure(rowInfo: any) {
    return {
        type: DepartmentStructureActionType.UPDATE_LOCAL_STRUCTURE,
        payload: {
            rowInfo
        }
    } as const
}
/**
 * This function will be used to delete a local structure, usefull when the entity is being deleted
 * @param rowInfo This is the row info that will be used to delete the local structure
*/
export function deleteOnLocalStructure(rowInfo: any) {
    return {
        type: DepartmentStructureActionType.DELETE_LOCAL_STRUCTURE,
        payload: {
            rowInfo
        }
    } as const
}
/**
 * This function will be used to clean a local structure, usefull when the entity is being deleted
 * @param rowInfo This is the row info that will be used to clean up the local structure
*/
export function cleanLocalStructure() {
    return {
        type: DepartmentStructureActionType.CLEAN_LOCAL_STRUCTURE,
        payload: {}
    } as const
}

type DepartmentStructureAction = ReturnType<
    typeof toggleExpanded |
    typeof initializeTree |
    typeof updateTree |
    typeof addNode |
    typeof addNodeAsChild |
    typeof changeNode |
    typeof deleteNode |
    typeof updateOnLocalStructure |
    typeof deleteOnLocalStructure |
    typeof cleanLocalStructure
>

export const getNodeKey = ({ node }: any) => node.id;

// This is the reducer that will be used to update the state
/**
 * This is the reducer that will be used to update the state of a Department Structure component
 * @param state This is the current state of the DepartmentStructure component
 * @param action This is the action that will be used to update the state
 * @remarks This reducer is able to handle the state of the tree (treeData) and the local structure (localDepartmentStructure) this last one is usefull when the component is used when creating an entity
 */
export function departmentStructureReducer(state: DepartmentStructureType, action: DepartmentStructureAction): DepartmentStructureType {
    switch (action.type) {
        case DepartmentStructureActionType.ADD_NODE:
            return {
                ...state,
                treeData: addNodeUnderParent({
                    treeData: state.treeData,
                    parentKey: null,
                    expandParent: true,
                    getNodeKey,
                    newNode: {
                        title: action.payload.title,
                        subtitle: action.payload.subtitle,
                        expanded: true,
                        isDirectory: true,
                        id: action.payload.id,
                        type: "department"
                    }
                }).treeData
            }
        case DepartmentStructureActionType.ADD_NODE_CHILD:
            return {
                ...state,
                treeData: addNodeUnderParent({
                    treeData: state.treeData,
                    parentKey: action.payload.parentKey,
                    expandParent: true,
                    getNodeKey,
                    newNode: {
                        title: action.payload.title,
                        subtitle: action.payload.subtitle,
                        expanded: action.payload.expanded,
                        isDirectory: action.payload.isDirectory,
                        id: action.payload.id,
                        type: action.payload.type
                    }
                }).treeData
            }
        case DepartmentStructureActionType.CHANGE_NODE:
            return {
                ...state,
                treeData: changeNodeAtPath({
                    treeData: state.treeData,
                    path: action.payload.path,
                    getNodeKey,
                    newNode: {
                        ...action.payload.node,
                        title: action.payload.title,
                        subtitle: action.payload.subtitle
                    } as TreeItem
                })
            }
        case DepartmentStructureActionType.DELETE_NODE:
            return {
                ...state,
                treeData: removeNodeAtPath({
                    treeData: state.treeData,
                    path: action.payload.path,
                    getNodeKey,
                })
            }
        case DepartmentStructureActionType.INITIALIZE:
            if(!action.payload.treeDataModel || action.payload.treeDataModel.length === 0) {
                return {
                    ...state,
                    treeData: []
                }
            }

            const deptStructureTree: TreeItem[] = action.payload.treeDataModel.map((ds: DepartmentStructureModel) => {
                const item = {
                    title: ds.departmentName,
                    subtitle: ds.code,
                    isDirectory: true,
                    expanded: true,
                    id: ds.departmentId,
                    type: 'department'
                } as TreeItem

                const hasOffices = isArray(ds.offices) && ds.offices.length > 0;
                if (hasOffices) {
                    const offices: TreeItem[] = ds.offices.map((o: OfficeModel) => {
                        const office = {
                            title: o.officeName,
                            subtitle: o.code,
                            isDirectory: true,
                            expanded: true,
                            id: o.officeId,
                            type: 'office'
                        } as TreeItem

                        const hasProviders = isArray(o.providers) && o.providers.length > 0;
                        if (hasProviders) {
                            const providers: TreeItem[] = o.providers.map((p: ProviderModel) => {
                                const provider = {
                                    title: p.providerName,
                                    subtitle: p.code,
                                    expanded: false,
                                    isDirectory: false,
                                    id: p.providerId,
                                    type: 'provider'
                                } as TreeItem
                                return provider;
                            })
                            office.children = providers;
                        }

                        return office;
                    })
                    item.children = offices;
                }

                const hasProviders = isArray(ds.providers) && ds.providers.length > 0;
                if (hasProviders) {
                    const providers: TreeItem[] = ds.providers.map((p: ProviderModel) => {
                        const provider = {
                            title: p.providerName,
                            subtitle: p.code,
                            expanded: false,
                            isDirectory: false,
                            id: p.providerId,
                            type: 'provider'
                        } as TreeItem
                        return provider;
                    })

                    const prevArray = item?.children as TreeItem[];
                    if (isArray(prevArray)) {
                        item.children = [...prevArray, ...providers];
                    } else {
                        item.children = providers;
                    }
                }

                return item;
            })
            return {
                ...state,
                treeData: deptStructureTree
            }
        case DepartmentStructureActionType.TOGGLE_EXPANDED:
            return {
                ...state,
                treeData: toggleExpandedForAll({
                    expanded: action.payload.expanded,
                    treeData: state.treeData
                })
            }
        case DepartmentStructureActionType.UPDATE:
            return {
                ...state,
                treeData: action.payload.treeData
            }
        case DepartmentStructureActionType.UPDATE_LOCAL_STRUCTURE:
            if (!state.localDepartmentStructure) {
                return state;
            }

            const newDepartmentStructure = action.payload.rowInfo as DepartmentStructureModel;

            const departmentExists = state.localDepartmentStructure.some(x => x.departmentId === newDepartmentStructure.departmentId);
            if (!departmentExists) {
                return {
                    ...state,
                    localDepartmentStructure: [...state.localDepartmentStructure, newDepartmentStructure]
                }
            }
            else {
                return {
                    ...state,
                    localDepartmentStructure: state.localDepartmentStructure.map(x => {
                        if (x.departmentId === newDepartmentStructure.departmentId) {
                            const isUnderOffice = newDepartmentStructure.offices.length > 0;
                            if (isUnderOffice) {
                                const officeExists = x.offices.some(y => y.officeId === newDepartmentStructure.offices[0].officeId);
                                if (!officeExists) {
                                    x.offices.push(newDepartmentStructure.offices[0]);
                                } else {
                                    const providerExists = x.offices[0].providers.some(y => y.providerId === newDepartmentStructure.offices[0].providers[0].providerId);
                                    if (!providerExists) {
                                        x.offices[0].providers.push(newDepartmentStructure.offices[0].providers[0]);
                                    }
                                }
                            } else {
                                const providersFiltered = newDepartmentStructure.providers.filter(y => x.providers.every(z => z.providerId !== y.providerId));
                                if (providersFiltered && providersFiltered.length > 0) {
                                    x.providers = [...x.providers, ...providersFiltered];
                                }
                            }
                        }
                        return x;
                    })
                }
            }
        case DepartmentStructureActionType.DELETE_LOCAL_STRUCTURE:
            if (!state.localDepartmentStructure) {
                return state;
            }

            const type = action.payload.rowInfo?.node?.type;
            switch (type) {
                case 'department':
                    return {
                        ...state,
                        localDepartmentStructure: state.localDepartmentStructure.filter(x => x.departmentId !== action.payload.rowInfo?.node?.id)
                    }
                case 'office':
                    let clonedStructure = JSON.parse(JSON.stringify(state.localDepartmentStructure)) as DepartmentStructureModel[];
                    clonedStructure = state.localDepartmentStructure.map(x => {
                        if (x.departmentId === action.payload.rowInfo?.path[0]) {
                            x.offices = x.offices.filter(y => y.officeId !== action.payload.rowInfo?.node?.id);
                        }
                        return x;
                    });
                    return {
                        ...state,
                        localDepartmentStructure: clonedStructure
                    }
                case 'provider':
                    let clonedStructureByProvider = JSON.parse(JSON.stringify(state.localDepartmentStructure)) as DepartmentStructureModel[];
                    clonedStructureByProvider = state.localDepartmentStructure.map(x => {
                        if (x.departmentId === action.payload.rowInfo?.path[0]) {
                            const isUnderOffice = action.payload.rowInfo?.path.length === 3;
                            if (isUnderOffice) {
                                x.offices = x.offices.map(y => {
                                    if (y.officeId === action.payload.rowInfo?.path[1]) {
                                        y.providers = y.providers.filter(z => z.providerId !== action.payload.rowInfo?.node?.id);
                                    }
                                    return y;
                                });
                            }
                            else {
                                x.providers = x.providers.filter(y => y.providerId !== action.payload.rowInfo?.node?.id);
                            }
                        }
                        return x;
                    });
                    return {
                        ...state,
                        localDepartmentStructure: clonedStructureByProvider
                    }
                default:
                    return state;
            }
        case DepartmentStructureActionType.CLEAN_LOCAL_STRUCTURE:
            return {
                ...state,
                localDepartmentStructure: []
            }
        default:
            return state
    }
}