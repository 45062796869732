import { ErrorTwoTone } from "@mui/icons-material";
import { Backdrop, Box, Button, Fade, Grid, Modal, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { confirmationModalStyle } from "../../../constants/styles.constants";

type ConfirmationModalProps = {
  open: boolean,
  handleClose: Function,
  type: "confirmation" | "deletion",
  title?: string,
  message?: string,
  onAbort?: Function,
  onConfirm?: Function,
  warningAlert?: string,
};

const getDefaultTitle = (type: "confirmation" | "deletion"): string => type === 'confirmation' ? "Confirmation" : "Delete Confirmation";

const getDefaultMessage = (type: "confirmation" | "deletion"): string => type === 'confirmation' ? "Are you sure you want to proceed?" : "Are you sure you want to delete this item?";

const ConfirmationModalComponent = (props: ConfirmationModalProps) => {
  const [title, setTitle] = React.useState<string>("");
  const [message, setMessage] = React.useState<string>("");

  const closeModal = () => props.handleClose();  
  const onConfirm = () => {
    closeModal();
    !!props.onConfirm && props.onConfirm();
  }

  useEffect(() => {
    const titleVal = !!!props.title || props.title === "" ? getDefaultTitle(props.type) : props.title;
    const messageVal = !!!props.message || props.message === "" ? getDefaultMessage(props.type) : props.message;
    setTitle(titleVal);
    setMessage(messageVal);
  });

  return (
    <Modal
      open={props.open}
      onClose={closeModal}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
    >
      <Fade in={props.open}>
        <Box sx={confirmationModalStyle}>
          <Typography id="modal-title" variant="h6" component="h2">
            {title}
          </Typography>
          <Typography id="modal-description" sx={{ mt: 2 }}>
            {message}
          </Typography>
          {props.warningAlert && <Grid container spacing={3} sx={{px:1, pt:2}}>
            <Grid item xs={1}>
              <Box sx={{ display: 'flex', m: 1, alignItems: 'center', justifyContent: 'center', height:'100%' }}>
                <ErrorTwoTone color="error" fontSize="large"/>
              </Box>
            </Grid>
            <Grid item xs={11}>
              <Typography variant="body2" color="error" sx={{pl:1}}><Typography fontWeight='bold'>Caution!</Typography>{props.warningAlert}</Typography>
            </Grid>
          </Grid>}
          <Box className="mt-4" sx={{ display: 'flex', justifyContent: 'flex-end', gap:'5px' }}>
            <Button onClick={closeModal} variant="contained" color="inherit" size="small">Cancel</Button>
            <Button onClick={onConfirm} variant="contained" color={props.type === 'deletion' ? 'error' : 'primary'} size="small">Ok</Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  )
}

export default ConfirmationModalComponent