import { Grid, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { PatientNoteModel } from "../../Api/Model/PatientNoteModel";
import { CustomDialog } from "../Shared/Modal/Modal.component";

type PatientNoteModalProps = {
  open: boolean;
  closeHandler: Function;
  onConfirm: Function;
  type: "create" | "update";
  description?: string;
  name?: string;
  patientNoteParam?: PatientNoteModel;
};

export const PatientNoteModal = (props: PatientNoteModalProps) => {
  const [patientNote, setPatientNote] = useState<PatientNoteModel>(
    {} as PatientNoteModel
  );

  useEffect(()=>{
    if (!!props.patientNoteParam) {
      setPatientNote(props.patientNoteParam);
      return;
    }
    setPatientNote({});
  },[props.open]);

  const closeModal = () => props.closeHandler();

  const onConfirm = () => {
    !!props.onConfirm && props.onConfirm(patientNote);
    closeModal();
    setPatientNote({});
  };

  let validationErrors = {
    ...(isEmpty(patientNote.note?.trim()) && {
      Note: "Note is required",
    }),
    ...(!isEmpty(patientNote.note?.trim()) &&
      (patientNote.note?.trim().length || 0) <= 5 && {
        Note: "Note must have more than 5 characters",
      }),
  };

  const handleSetTextFieldNote = (event: any) => {
    let fieldS = event.target.id.toString();
    setPatientNote((dc: PatientNoteModel) => {
      return { ...dc, [fieldS]: event.target.value };
    });
  };

  return (
    <CustomDialog
      isOkButtonDisabled={Object.keys(validationErrors).length > 0}
      open={props.open}
      onConfirm={onConfirm}
      handleClose={props.closeHandler}
      title={props.type === "create" ? "Create Note" : "Update Note"}
      size="sm"
    >
      <Grid item xs={12}>
        <TextField
          id="note"
          type={"text"}
          onChange={handleSetTextFieldNote}
          label="Note"
          className="mb-2"
          value={patientNote.note || ""}
          error={!!validationErrors.Note && props.open}
          helperText={!props.open || validationErrors.Note}
          variant="outlined"
          inputProps={{
            tabIndex: 0,
            minLength: 5,
          }}
          sx={{ marginTop: "5px" }}
          rows={4}
          multiline
          fullWidth
        />
      </Grid>
    </CustomDialog>
  );
};
