import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl, FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Grid,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { useUser } from "../../hooks/useUserInfo";
import { isEmpty } from "lodash";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { emailvalidator, phoneNumberValidator } from "../../Api/Model/Shared/Validations/Validations";
import UserDepartmentAssignment from "./UserDepartmentAssignment/UserDepartmentAssignment.component";
import DepartmentStructureModel from "../../Api/Model/DepartmentStructure/DepartmentStructureModel";
import { CleanButton } from "../../Shared/CleanHandler.provider";
import PageWrapper from "../Shared/Wrapper/PageWrapper.component";
import { colorsPalette } from "../../constants/styles.constants";

const AddUser = (props: any) => {
  const navigate = useNavigate();
  const parameter = useParams();
  const { manageErrorAlert, showAlertSnack } = useOutletContext<{ manageErrorAlert: Function, showAlertSnack: Function }>();

  const {
    user,
    userExists,
    setUser,
    rolesDB,
    handleSubmit,
    userNameInUse,
    searchingUserName,
    axiosError
  } = useUser(props, parameter, manageErrorAlert, showAlertSnack);

  useEffect(() => {
    if (!parameter.id) {
      setUser({});
    }
  }, [parameter]);

  useEffect(() => {
    if (axiosError && axiosError?.Code === '401') {
      manageErrorAlert("Unauthorized");
      navigate("/patientlist", { replace: true });
      return;
    }
  }, [axiosError]);

  useEffect(() => {
    if (!userExists && parameter.id) {
      navigate("/userlist", { replace: true });
      setTimeout(() => {
        manageErrorAlert("User not found");
      }, 100);
      return;
    }
  }, [userExists]);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  const handleMouseDownConfirmPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleRoleChange = (event: any) => {
    setUser({ ...user, roleId: event.target.value as string });
  };

  const handleCancel = () => {
    (parameter.id === undefined) ? setUser({}) : navigate("/userlist");
  };

  let validationErrors = {
    ...(isEmpty(user.userName?.trim()) && {
      UserName: "Username is required",
    }),
    ...(!isEmpty(user.userName?.trim()) && user.userName!.trim().length < 5) && {
      UserName: "Username must be at least 5 characters long",
    },
    ...(!isEmpty(user.userName?.trim()) && !(user.userName!.trim().length < 5) && userNameInUse && user.userId === undefined) && {
      UserName: "Username is already used.",
    },
    ...(isEmpty(user.firstName?.trim()) && {
      FirstName: "First name is required",
    }),
    ...(!isEmpty(user.firstName?.trim()) && user.firstName!.trim().length < 3) && {
      FirstName: "First name must be at least 3 characters long",
    },
    ...(isEmpty(user.lastName?.trim()) && {
      LastName: "Last name is required",
    }),
    ...(!isEmpty(user.lastName?.trim()) && user.lastName!.trim().length < 3) && {
      LastName: "Last name must be at least 3 characters long",
    },
    ...(isEmpty(user.password?.trim()) && user.userId === undefined && {
      Password: "Password is required",
    }),
    ...(!isEmpty(user.password?.trim()) && user.password!.trim().length < 8 && user.userId === undefined) && {
      Password: "Password must be at least 8 characters long",
    },
    ...(!isEmpty(user.password?.trim()) && user.password!.trim().length < 8) && {
      Password: "Password must be at least 8 characters long",
    },
    ...(isEmpty(user.confirmPassword?.trim()) && user.userId === undefined && {
      ConfirmPassword: "Confirm password is required",
    }),
    ...(!isEmpty(user.password?.trim()) && isEmpty(user.confirmPassword?.trim())  && {
      ConfirmPassword: "Confirm password is required",
    }),
    ...(!isEmpty(user.confirmPassword?.trim()) && user.confirmPassword!.trim().length < 8 && user.userId === undefined) && {
      ConfirmPassword: "Confirm password must be at least 8 characters long",
    },
    ...(!isEmpty(user.confirmPassword?.trim()) && user.confirmPassword!.trim().length < 8) && {
      ConfirmPassword: "Confirm password must be at least 8 characters long",
    },
    ...(!isEmpty(user.confirmPassword?.trim()) && !(user.confirmPassword!.trim().length < 8) && (user.confirmPassword?.trim() !== user.password?.trim())) && {
      ConfirmPassword: "Confirm password does not match",
    },
    ...(isEmpty(user.roleId?.trim()) && {
      Role: "Role is required",
    }),
    ...(isEmpty(user.email?.trim()) && {
      Email: "Email is required",
    }),
    ...(!isEmpty(user.phoneNumber?.trim()) && !phoneNumberValidator(user.phoneNumber?.trim()) && {
      PhoneNumber: "Phone number must be number",
    }),
    ...(!isEmpty(user.email?.trim()) && !emailvalidator(user.email?.trim()) && {
      Email: "Email is invalid",
    })
  };

  const isFormValid = isEmpty(validationErrors);

  const handleGetPatientStructure = (structure: DepartmentStructureModel[]) => parameter.id === undefined && setUser({ ...user, userStructure: structure })

  const userDepartmentStructure_UI = <Card className="noBorder">
    <CardHeader title="Department Assignment" sx={{ pl: 0, mt: 2 }} />
    <CardContent sx={{ pl: 0 }}>
      <UserDepartmentAssignment isAdding={parameter.id === undefined} onStructureChanged={handleGetPatientStructure} userID={parameter.id} userStructure={user.userStructure} />
    </CardContent>
  </Card>;

  return (
    <PageWrapper title={parameter.id === undefined ? "Create User" : "Edit User"} >
      {((user.userId && parameter.id) || (!parameter.id)) &&
        <Box component="form" autoComplete="off" >
          <Grid container spacing={2} width={"100%"}>
            <Grid item xs={6}>
              <TextField
                id="InternalCode"
                type={"text"}
                onChange={(event) => setUser({ ...user, internalCode: event.target.value as string })}
                label="User ID"
                value={user.internalCode || ""}
                variant="outlined"
                disabled
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="UserName"
                type={"text"}
                onChange={(event) => setUser({ ...user, userName: event.target.value.trim().replaceAll(" ", "") as string })}
                value={user.userName || ""}
                label="Username"
                error={!!validationErrors.UserName}
                helperText={validationErrors.UserName}
                variant="outlined"
                inputProps={{
                  tabIndex: 0,
                  maxLength: 15,
                  minLength: 5,
                  autoComplete: "no",
                }}
                required
                fullWidth
                disabled={user.userId !== undefined}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="FirstName"
                type={"text"}
                onChange={(event) => setUser({ ...user, firstName: event.target.value as string })}
                multiline
                value={user.firstName || ""}
                label="First name"
                variant="outlined"
                error={!!validationErrors.FirstName}
                helperText={validationErrors.FirstName}
                inputProps={{
                  tabIndex: 1,
                  maxLength: 50,
                  minLength: 3,
                }}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth variant="outlined" error={!!validationErrors.Password}>
                <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                <OutlinedInput
                  id="Password"
                  type={showPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                  onChange={(event) => setUser({ ...user, password: event.target.value.trim().replaceAll(" ", "") as string })}
                  value={user.password || ""}
                  inputProps={{
                    tabIndex: 5,
                    maxLength: 15,
                    minLength: 8,
                    autoComplete: 'new-password'
                  }}
                  required
                  fullWidth
                />
                <FormHelperText>{validationErrors.Password}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="LastName"
                type={"text"}
                onChange={(event) => setUser({ ...user, lastName: event.target.value as string })}
                multiline
                label="Last name"
                value={user.lastName || ""}
                variant="outlined"
                error={!!validationErrors.LastName}
                helperText={validationErrors.LastName}
                inputProps={{
                  tabIndex: 2,
                  maxLength: 50,
                  minLength: 3,
                }}
                required
                fullWidth
              />
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth variant="outlined" error={!!validationErrors.ConfirmPassword}>
                <InputLabel htmlFor="outlined-adornment-password">Confirm password</InputLabel>
                <OutlinedInput
                  id="ConfirmPassword"
                  type={showConfirmPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowConfirmPassword}
                        onMouseDown={handleMouseDownConfirmPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Confirm password"
                  value={user.confirmPassword || ""}
                  onChange={(event) => setUser({ ...user, confirmPassword: event.target.value.trim().replaceAll(" ", "") as string })}
                  inputProps={{
                    tabIndex: 6,
                    maxLength: 15,
                    minLength: 8,
                  }}
                  required
                  fullWidth
                />
                <FormHelperText>{validationErrors.ConfirmPassword}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="Email"
                value={user.email || ""}
                onChange={(event) => setUser({ ...user, email: event.target.value.trim().replaceAll(" ", "") as string })}
                type={"email"}
                multiline
                required
                label="Email"
                variant="outlined"
                error={!!validationErrors.Email}
                helperText={validationErrors.Email}
                inputProps={{
                  tabIndex: 3,
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="PhoneNumber"
                type="tel"
                value={user.phoneNumber || ""}
                onChange={(event) => setUser({ ...user, phoneNumber: event.target.value as string })}
                label="Phone number"
                variant="outlined"
                error={!!validationErrors.PhoneNumber}
                helperText={validationErrors.PhoneNumber}
                inputProps={{
                  tabIndex: 7,
                  maxLength: 25,
                  minLength: 8,
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="Contact"
                value={user.contact || ""}
                onChange={(event) => setUser({ ...user, contact: event.target.value as string })}
                type={"Contact"}
                multiline
                label="Contact"
                variant="outlined"
                inputProps={{
                  tabIndex: 4,
                  maxLength: 70
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth error={!!validationErrors.Role} >
                <InputLabel id="RoleCombo" color="primary" sx={{bgcolor: colorsPalette.white}} >
                  Role *
                </InputLabel>
                <Select
                  id="SelectRole"
                  fullWidth
                  defaultValue={""}
                  displayEmpty
                  label="Role"
                  labelId="RoleCombo"
                  value={user.roleId || ""}
                  onChange={handleRoleChange}
                  inputProps={{
                    tabIndex: 9,
                  }}
                  required
                    endAdornment={
                        user.roleId &&
                        (CleanButton(handleRoleChange))
                    }
                >
                  {rolesDB && rolesDB.map((item: any) => (
                    <MenuItem value={item.roleId} key={item.roleId}>
                      {item.roleName}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{validationErrors.Role}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              {userDepartmentStructure_UI}
            </Grid>
            <Grid item xs={12}>
              <Box
                justifyContent="flex-end"
                alignItems="flex-end"
                sx={{ display: "flex", flexDirection: "row", right: 5 }}
              >
                <Button
                  sx={{
                    width: 1 / 7,
                    height: 40,
                    mr: 2,
                    marginBlock: 2,
                    display: "inline-block",
                  }}
                  onClick={handleSubmit}
                  color="primary"
                  variant="contained"
                  disabled={!isFormValid || searchingUserName}
                  tabIndex={10}
                >
                  Save
                </Button>
                <Button
                  onClick={handleCancel}
                  sx={{
                    width: 1 / 7,
                    height: 40,
                    marginBlock: 2,
                    display: "inline-block",
                  }}
                  color="secondary"
                  variant="contained"
                  tabIndex={11}
                >
                  Cancel
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>}
    </PageWrapper>
  );
};

export default AddUser;
