import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  Fade,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { colorsPalette, confirmationModalStyle } from "../../constants/styles.constants";
import { isEmpty } from "lodash";
import { BillingCodeModel } from "../../Api/Model/BillingCode/BillingCodeModel";
import { CatalogModel } from "../../Api/Model/GenericCatalog/CatalogModel";
import { useOutletContext } from "react-router-dom";
import { useAxios } from "../../hooks/useAxios";
import { CleanButton } from "../../Shared/CleanHandler.provider";

type BillingCodeModalProps = {
  open: boolean;
  handleClose: Function;
  onConfirm: Function;
  type: "create" | "update";
  description?: string;
  name?: string;
  billingCodeParam?: BillingCodeModel;
};

export const BillingCodeModal = (props: BillingCodeModalProps) => {
  const { showAlertSnack } = useOutletContext<{
    manageErrorAlert: Function;
    showAlertSnack: Function;
  }>();
  const [billingCode, setBillingCode] = useState<BillingCodeModel>(
    {} as BillingCodeModel
  );
  const [billingTypes, setBillingTypes] = useState<CatalogModel[]>([]);
  const [billingCodeExists, setBillingCodeExists] = useState(false);
  const { getAsync, axiosError } = useAxios();

  const getRelationshipList = async () => {
    const axiosGet = await getAsync<CatalogModel[]>(
      "Catalogs/GetSubCatalogsByCatalogName/Billing Types"
    );

    if (!axiosGet) {
      showAlertSnack(axiosError?.Messages[0], "error");
      return;
    }

    setBillingTypes(axiosGet);
  };

  const ValidateBillingCodeExists = async () => {
    const billingExist = await getAsync<boolean>(
      "BillingCodes/BillingCodeExists/" + billingCode.code
    );

    if (billingExist) {
      setBillingCodeExists(true);
    } else {
      setBillingCodeExists(false);
    }
  };

  const closeModal = () => props.handleClose();
  const onConfirm = async () => {
    if (props.type === "update") {
      closeModal();
      !!props.onConfirm && props.onConfirm(billingCode);
      return;
    }

    const billingExist = await getAsync<boolean>(
      "BillingCodes/BillingCodeExists/" + billingCode.code
    );
    if (!billingExist) {
      closeModal();
      !!props.onConfirm && props.onConfirm(billingCode);
    } else {
      setBillingCodeExists(true);
    }
  };

  useEffect(() => {
    if (!!props.billingCodeParam) {
      setBillingCode(props.billingCodeParam);
    } else {
      setBillingCode({} as BillingCodeModel);
    }
  }, [props.open]);

  useEffect(() => {
    getRelationshipList();
  }, []);

  useEffect(() => {
    if (
      !isEmpty(billingCode.code?.trim()) &&
      billingCode.code!.trim().length >= 3 &&
      props.type !== "update"
    ) {
      ValidateBillingCodeExists();
    }
  }, [billingCode.code]);

  const validationErrors = {
    ...(isEmpty(billingCode.code?.trim()) && {
      Code: "Name is required",
    }),
    ...(!isEmpty(billingCode.code?.trim()) &&
      billingCode.code!.trim().length <= 3 && {
        Code: "Name must must be at least 4 characters long",
      }),
    ...(!isEmpty(billingCode.code?.trim()) &&
      !(billingCode.code!.trim().length <= 3) &&
      billingCodeExists &&
      props.type !== "update" && {
        Code: "Name is already exists",
      }),
    ...(isEmpty(billingCode.description?.trim()) && {
      Description: "Description is required",
    }),
    ...(!isEmpty(billingCode.description?.trim()) &&
      billingCode.description!.trim().length <= 3 && {
        Description: "Description must be at least 4 characters long",
      }),
    ...(isEmpty(billingCode.typeId?.trim()) && {
      Type: "Type is required",
    }),
    ...(isEmpty(billingCode.iterations?.toString()) && {
      Iterations: "Iteration number is required",
    }),
    ...(!isEmpty(billingCode.iterations?.toString()) &&
      (billingCode.iterations! > 99 || billingCode.iterations! < 0) && {
        Iterations: "Iteration number must be between 0 and 99",
      }),
    ...(isEmpty(billingCode.expiration?.toString()) && {
      Expiration: "Minutes of Interval is required",
    }),
    ...(!isEmpty(billingCode.expiration?.toString()) &&
      (billingCode.expiration! > 60 || billingCode.expiration! < 1) && {
        Expiration: "Minutes of Interval must be between 1 and 60",
      }),
    ...(isEmpty(billingCode.index?.toString()) && {
      Index: "Index number is required",
    }),
    ...(!isEmpty(billingCode.index?.toString()) &&
      (billingCode.index! > 99 || billingCode.index! < 0) && {
        Index: "Index number must be between 0 and 99",
      }),
  };

  const handleSetTextField = (event: any) => {
    let fieldS = event.target.id.toString();
    setBillingCode((dc: BillingCodeModel) => {
      return { ...dc, [fieldS]: event.target.value };
    });
  };

  const handleTypeChange = (event: any) => {
    setBillingCode({ ...billingCode, typeId: event.target.value as string });
  };

  const isFormValid = isEmpty(validationErrors);

  return (
    <Modal
      open={props.open}
      onClose={closeModal}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
    >
      <Fade in={props.open}>
        <Box sx={confirmationModalStyle}>
          <Typography
            className="mb-4"
            id="modal-title"
            variant="h6"
            component="h2"
          >
            {props.type === "create" ? "Create" : "Update"} Billing Code
          </Typography>

          <Grid container spacing={2} width={"100%"}>
            <Grid item xs={12}>
              <TextField
                id="code"
                type={"text"}
                onChange={handleSetTextField}
                label="Name"
                className="mb-2"
                value={billingCode.code || ""}
                error={!!validationErrors.Code}
                helperText={validationErrors.Code}
                variant="outlined"
                required
                fullWidth
                inputProps={{
                  tabIndex: 1,
                  maxLength: 15,
                }}
                disabled={props.type === "update"}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="description"
                type={"text"}
                onChange={handleSetTextField}
                value={billingCode.description || ""}
                label="Description"
                error={!!validationErrors.Description}
                helperText={validationErrors.Description}
                variant="outlined"
                inputProps={{
                  tabIndex: 2,
                  maxLength: 100,
                }}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="expiration"
                type={"number"}
                onChange={handleSetTextField}
                value={billingCode.expiration || ""}
                label="Minutes of Interval"
                error={!!validationErrors.Expiration}
                helperText={validationErrors.Expiration}
                variant="outlined"
                inputProps={{
                  tabIndex: 3,
                  min: 1,
                  max: 60,
                  maxLength: 2,
                }}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="iterations"
                type={"number"}
                onChange={handleSetTextField}
                value={billingCode.iterations || ""}
                label="Iterations"
                error={!!validationErrors.Iterations}
                helperText={validationErrors.Iterations}
                variant="outlined"
                inputProps={{
                  tabIndex: 4,
                  min: 0,
                  max: 99,
                  maxLength: 2,
                }}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="index"
                type={"number"}
                onChange={handleSetTextField}
                value={billingCode.index || ""}
                label="Index"
                error={!!validationErrors.Index}
                helperText={validationErrors.Index}
                variant="outlined"
                inputProps={{
                  tabIndex: 5,
                  min: 0,
                  max: 99,
                  maxLength: 2,
                }}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth error={!!validationErrors.Type}>
                <InputLabel
                  id="RolCombo"
                  color="primary"
                  sx={{bgcolor: colorsPalette.white}}
                >
                  Type *
                </InputLabel>
                <Select
                  id="typeId"
                  fullWidth
                  defaultValue={""}
                  displayEmpty
                  label="Type"
                  labelId="TypeCombo"
                  value={billingCode.typeId || ""}
                  onChange={handleTypeChange}
                  inputProps={{
                    tabIndex: 6,
                  }}
                  required
                  endAdornment={
                    billingCode.typeId && (
                      CleanButton(handleTypeChange)
                      )
                  }
                >
                  {billingTypes.length === 0 && (
                    <MenuItem value="">No billing types found...</MenuItem>
                  )}
                  {billingTypes &&
                    billingTypes.map((item: any) => (
                      <MenuItem value={item.catalogId} key={item.catalogId}>
                        {item.catalogName}
                      </MenuItem>
                    ))}
                </Select>
                <FormHelperText>{validationErrors.Type}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked
                      color="success"
                      checked={billingCode.onBoarding || false}
                      onChange={(event, checked) =>
                        setBillingCode({ ...billingCode, onBoarding: checked })
                      }
                      inputProps={{
                        tabIndex: 7,
                      }}
                    />
                  }
                  label="On boarding"
                />
              </FormGroup>
            </Grid>
          </Grid>

          <Box
            className="mt-4"
            sx={{ display: "flex", justifyContent: "flex-end", gap: "5px" }}
          >
            <Button
              onClick={closeModal}
              variant="contained"
              color="inherit"
              size="small"
            >
              Cancel
            </Button>
            <Button
              onClick={onConfirm}
              variant="contained"
              color="success"
              size="small"
              disabled={!isFormValid}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};
