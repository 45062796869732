import {
  Backdrop,
  Box,
  Button,
  Chip,
  Fade,
  Grid,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { confirmationModalStyle } from "../../../constants/styles.constants";
import { isEmpty } from "lodash";
import { PatientOrderModel } from "../../../Api/Model/PatientOrderModel";
import { Cancel } from "@mui/icons-material";

type PatientOrderModalProps = {
  open: boolean;
  handleClose: Function;
  onConfirm: Function;
  description?: string;
  name?: string;
  patientOrderParam?: PatientOrderModel;
  currentPatientId?: string;
};

export const PatientOrderModal = (props: PatientOrderModalProps) => {
  const [patientOrder, setPatientOrder] = useState<PatientOrderModel>(
    {} as PatientOrderModel
  );

  const closeModal = () => props.handleClose();
  const onConfirm = () => {
    closeModal();
    !!props.onConfirm && props.onConfirm(patientOrder);
  };

  useEffect(() => {
    if (!!props.patientOrderParam) {
      setPatientOrder(props.patientOrderParam);
    }
  }, [props.open]);

  let validationErrors = {
    ...(patientOrder.file === undefined && {
      Name: "File is required",
    }),
  };

  const handleSetPatientOrderFile = (event: any) => {
    let file = event.target.files[0] as File;
    setPatientOrder((dc: PatientOrderModel) => {
      return { ...dc, file: file, name: file.name };
    });
  };

  return (
    <Modal
      open={props.open}
      onClose={closeModal}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
    >
      <Fade in={props.open}>
        <Box sx={{ ...confirmationModalStyle, width: 550 }}>
          <Typography
            className="mb-4"
            id="modal-title"
            variant="h6"
            component="h2"
          >
            Upload Patient Order
          </Typography>
          <Grid
            container
            spacing={2}
            width={"100%"}
          >
            <Grid item xs={12}
            sx={{ display: "flex", justifyContent: "center", gap: "5px" }}>
              <Button component="label" variant="contained" size="small" color="primary">
                <FileUploadIcon />
                Choose File
                <input
                  type="file"
                  accept="application/pdf"
                  hidden
                  onChange={handleSetPatientOrderFile}
                />
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center", gap: "5px" }}
            >
              {patientOrder.file && (
                <Chip
                  sx={{
                    "& .MuiChip-icon": {
                      order: 1,
                      marginRight: "2px",
                      cursor: "pointer",
                    },
                    "& .MuiChip-deleteIcon": {
                      order: 2,
                    },
                  }}
                  deleteIcon={
                    <IconButton>
                      <Cancel />
                    </IconButton>
                  }
                  onDelete={() =>
                    setPatientOrder((dc: any) => {
                      return { ...dc, file: "", name: "" };
                    })
                  }
                  label={`${patientOrder.name}`}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <Box
                className="mt-4"
                sx={{ display: "flex", justifyContent: "flex-end", gap: "5px" }}
              >
                <Button
                  onClick={closeModal}
                  variant="contained"
                  color="inherit"
                  size="small"
                >
                  Cancel
                </Button>
                <Button
                  onClick={onConfirm}
                  variant="contained"
                  color="success"
                  size="small"
                  disabled={!isEmpty(validationErrors)}
                  tabIndex={1}
                >
                  Upload
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Fade>
    </Modal>
  );
};
