import { ReportFilterModel } from "../../../Api/Model/ReportFilterModel";
import StripedDataGrid, {
  CustomPagination,
} from "../../Shared/StripedDataGrid/StripedDatagrid.component";
import { useAxios } from "../../../hooks/useAxios";
import { useEffect, useState } from "react";
import { BillingRPMReportModel } from "../../../Api/Model/Reports/BillingRPMReportModel";
import { LocaleStringFormat } from "../../../constants/global.constants";
import { useOutletContext } from "react-router-dom";
import { GridColDef, GridSortModel, GridValueGetterParams } from "@mui/x-data-grid";
import { getUTCDate } from "../../../Shared/DateTime.provider";


//#region Definitions

//#region Props Definition

interface RPMTableProps {
  report: ReportFilterModel;
  exportButtonHandler: Function;
  resubmit: boolean;
  resubmitHandler: Function;
}

//#endregion

//#region Columns Definition

const reportColumns: GridColDef[] = [
  {
    field: "patientId",
    headerName: "Patient ID",
    headerAlign: "center",
    align: "left",
    hideable: false,
    filterable: false,
    disableColumnMenu: true,
    flex: 0.5,
    valueGetter: (params: GridValueGetterParams<any, any>): any => {
      const patientId = params.row.patientId;
      return patientId;
    },
  },
  {
    field: "lastName",
    headerName: "Last Name",
    headerAlign: "center",
    align: "left",
    hideable: false,
    filterable: false,
    disableColumnMenu: true,
    flex: 1,
    valueGetter: (params: GridValueGetterParams<any, any>): any => {
      const lastName = params.row.lastName;
      return lastName;
    },
  },
  {
    field: "firstName",
    headerName: "First Name",
    headerAlign: "center",
    align: "left",
    hideable: false,
    filterable: false,
    disableColumnMenu: true,
    flex: 1,
    valueGetter: (params: GridValueGetterParams<any, any>): any => {
      const firstName = params.row.firstName;
      return firstName;
    },
  },
  {
    field: "dateOfBirth",
    headerName: "Date Of Birth",
    headerAlign: "center",
    align: "center",
    hideable: false,
    filterable: false,
    disableColumnMenu: true,
    flex: 0.5,
    valueGetter: (params: GridValueGetterParams<any, any>): any => {
      return getUTCDate(new Date(params.row.dateOfBirth)).toLocaleDateString(LocaleStringFormat);
    },
  },
  {
    field: "billingCode",
    headerName: "Billing Code",
    headerAlign: "center",
    align: "center",
    hideable: false,
    filterable: false,
    disableColumnMenu: true,
    flex: 0.5,
    valueGetter: (params: GridValueGetterParams<any, any>): any => {
      const billingCode = params.row.billingCode;
      return billingCode;
    },
  },
  {
    field: "daysWithReadingsInTheEligibilityPeriod",
    headerName: "Days With Readings",
    headerAlign: "center",
    align: "center",
    hideable: false,
    filterable: false,
    disableColumnMenu: true,
    flex: 0.6,
    valueGetter: (params: GridValueGetterParams<any, any>): any => {
      const daysWithReadingsInTheEligibilityPeriod =
        params.row.daysWithReadingsInTheEligibilityPeriod;
      return daysWithReadingsInTheEligibilityPeriod;
    },
  },
];

//#endregion

//#endregion


const RPMTable = ({ report, exportButtonHandler, resubmit, resubmitHandler }: RPMTableProps) => {
  
  //#region Hooks

  //#region States

  const [patientList, setPatientList] = useState<BillingRPMReportModel[]>([]);
  const [rowCountState, setRowCountState] = useState(0);
  const [sortModel, setSortModel] = useState<GridSortModel>([{field: reportColumns[0].field, sort: "asc"}]);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0
  });

  //#endregion

  //#region Others

  const { manageErrorAlert } = useOutletContext<{
    manageErrorAlert: Function;
    showAlertSnack: Function;
  }>();
  const { getAsync, axiosError, axiosLoading, axiosPagination } = useAxios();

  //#endregion

  //#region Effects

  useEffect(() => {
    setRowCountState((prevState) =>
      axiosPagination?.TotalRecords !== undefined
        ? axiosPagination?.TotalRecords
        : prevState
    );
  }, [axiosPagination?.TotalRecords]);

  useEffect(() => {

    const {pageSize, page} = paginationModel;
    const {field, sort} = sortModel[0] !== undefined ? sortModel[0] : {field: reportColumns[0].field, sort: "asc"};
    if (sortModel[0] === undefined) {
      setSortModel([{field: reportColumns[0].field, sort: "asc"}])
    }
    getRPMReport(report, pageSize, page, field, sort || "");
  }, [paginationModel, sortModel, resubmit]);

  //#endregion

  //#endregion


  //#region Pagination Handlers

  const onPageSizeChangeHandler = (newPageSize: number) => setPaginationModel({...paginationModel, pageSize: newPageSize});
  const onPageChangeHandler = (newPage: number) => setPaginationModel({...paginationModel, page: newPage});

  //#endregion


  //#region Component Functions

  const getRPMReport = async (
    reportFilterModel: ReportFilterModel,
    pageSize: number, page: number, field: string, sort: string
  ) => {
    const {
      startDate,
      endDate,
      departmentId,
      officeId,
      providerId,
      patientId,
    } = reportFilterModel;

    const patientListResult = await getAsync<BillingRPMReportModel[]>(
      `PatientMeasurementRecords/GetRPMReadingsHistory?` + 
      `initialDate=${startDate?.toLocaleDateString(LocaleStringFormat)}`+ 
      `&endDate=${endDate?.toLocaleDateString(LocaleStringFormat)}`+ 
      `&departmentId=${departmentId}`+
      `&officeId=${officeId}`+
      `&providerId=${providerId}`+
      `&patientId=${patientId}`+
      `&orderBy=${field}&sort=${sort}`,
      {
        PageNumber: page + 1,
        PageSize: pageSize,
      }
    );

    if (!patientListResult) {
      exportButtonHandler(false);
      manageErrorAlert(axiosError?.Messages[0]);
      return;
    }

    if (patientListResult.length < 1) {
      exportButtonHandler(false);
      return;
    }

    setPatientList(patientListResult);
    exportButtonHandler(true);
    resubmitHandler(false);
  };

  //#endregion

  return (
      <StripedDataGrid
        sx={{height: 700}}
        loading={axiosLoading}
        disableColumnSelector
        disableColumnMenu
        disableSelectionOnClick
        getRowId={(row) =>
          row.patientId +
          row.lastName +
          row.firstName +
          row.dayOfBirth +
          row.eligibilityPeriod +
          row.daysWithReadings +
          row.billingCode
        }
        paginationMode="server"
        sortingMode="server"
        localeText={{ noRowsLabel: "No results found." }}
        rows={patientList}
        columns={reportColumns}
        rowsPerPageOptions={[5, 10, 20, 50]}
        pageSize={paginationModel.pageSize}
        page={paginationModel.page}
        components={{
          Footer: () =>
            CustomPagination(
              Math.ceil(
                rowCountState /
                  (paginationModel ? paginationModel.pageSize! : 1)
              ),
              paginationModel.page + 1,
              onPageChangeHandler
            ),
        }}
        rowCount={rowCountState}
        onPageChange={onPageChangeHandler}
        onPageSizeChange={onPageSizeChangeHandler}
        onSortModelChange={setSortModel}
        sortModel={sortModel}
        sortingOrder={["asc", "desc"]}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
        }
      />
  );
};

export default RPMTable;
