import { Button, Grid, IconButton, Tooltip } from "@mui/material";
import { GridColDef, GridSortModel } from "@mui/x-data-grid/models";
import { useCallback, useEffect, useState } from "react";
import ConfirmationModalComponent from "../Shared/ConfirmationModal/ConfirmationModal.component";
import { DeleteOutlineRounded, Edit } from "@mui/icons-material";
import "./UserList.component.css";
import StripedDataGrid, { CustomPagination } from "../Shared/StripedDataGrid/StripedDatagrid.component";
import { useNavigate, useOutletContext } from "react-router-dom";
import { UserModel } from "../../Api/Model/user/userModel";
import PageWrapper from "../Shared/Wrapper/PageWrapper.component";
import { useAxios } from "../../hooks/useAxios";

const UserList = (props: any) => {

  //#region Column Definitions

  const definedColumns: GridColDef[] = [
    {
      field: "internalCode",
      headerName: "ID",
      flex: 0.15,
      headerAlign: "center",
      align: "center",
      filterable: false,
      hideable: false
    },
    {
      field: "firstName",
      headerName: "First Name",
      flex: 0.15,
      headerAlign: "center",
      align: "center",
      filterable: false,
      hideable: false
    },
    {
      field: "lastName",
      headerName: "Last Name",
      flex: 0.15,
      headerAlign: "center",
      align: "center",
      filterable: false,
      hideable: false
    },
    {
      field: "userName",
      headerName: "UserName",
      flex: 0.15,
      headerAlign: "center",
      align: "center",
      filterable: false,
      hideable: false
    },
    {
      field: "",
      headerName: "Department(s)",
      flex: 0.15,
      headerAlign: "center",
      align: "center",
      filterable: false,
      hideable: false
    },
    {
      field: "roleName",
      headerName: "Role",
      flex: 0.15,
      headerAlign: "center",
      align: "center",
      filterable: false,
      hideable: false
    },
    {
      field: "Action",
      headerName: "Actions",
      sortable: false,
      flex: 0,
      headerAlign: "center",
      align: "center",
      hideSortIcons: true,
      disableExport: true,
      disableColumnMenu: true,
      hideable: false,
      filterable: false,
      renderCell: (params) => {
        const onClick = () => {
          return onUserClickHandler(params);
        };

        const openConfirmModal = () => {
          setDeleteUserId(params.row.userId);
          setOpenDeleteConfirmation(true);
          return;
        };

        return <Grid container>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <Tooltip title="Edit user">
              <IconButton onClick={onClick} aria-label="Edit user" className='unbordered' color="primary" >
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete user">
              <IconButton onClick={openConfirmModal} aria-label="delete" className='unbordered' color="error">
                <DeleteOutlineRounded />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>;
      }
    }
  ];

  //#endregion

  
  //#region Hooks
  
  //#region States

  const [deleteUserId, setDeleteUserId] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(true);
  const [userList, setUserList] = useState<UserModel[]>([]);
  // Inactivate User
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [rowCountState, setRowCountState] = useState(1);
  const [sortModel, setSortModel] = useState<GridSortModel>([{field: definedColumns[0].field, sort: "asc"}]);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0
  });
  
  //#endregion
  
  //#region Others
  
  const { getAsync, deleteAsync, axiosError, axiosSuccess,axiosPagination } = useAxios();
  const navigate = useNavigate();
  const { manageErrorAlert, showAlertSnack } = useOutletContext<{ manageErrorAlert: Function, showAlertSnack: Function }>();
  const closeConfirmModal = useCallback(() => {
    setOpenDeleteConfirmation(false)
  }, [setOpenDeleteConfirmation],);

  //#endregion

  //#region Effects

  useEffect(() => {
    if (axiosError && axiosError?.Code === '401') {
      manageErrorAlert("Unauthorized");
      navigate("/patientlist", { replace: true });
      return;
    }
  }, [axiosError]);

  useEffect(() => {
    if (!refresh) {
      return;
    }

    const {pageSize, page} = paginationModel;
    const {field, sort} = sortModel[0] !== undefined ? sortModel[0] : {field: definedColumns[0].field, sort: "asc"};

    getUserList(pageSize, page, field, sort || "");
  }, [refresh]);

  useEffect(() => {
    setRowCountState((prevState) =>
      axiosPagination?.TotalRecords !== undefined ? axiosPagination?.TotalRecords : prevState
    );
  }, [axiosPagination?.TotalRecords]);

  useEffect(() => {
    if (sortModel[0] === undefined) {
      setSortModel([{field: definedColumns[0].field, sort: "asc"}])
    }
    setRefresh(true);
  }, [paginationModel, sortModel]);

  //#endregion

  //#endregion


  //#region Handlers

  //#region Pagination Handlers

  const onPageSizeChangeHandler = (newPageSize: number) => setPaginationModel({...paginationModel, pageSize: newPageSize});
  const onPageChangeHandler = (newPage: number) => setPaginationModel({...paginationModel, page: newPage});

  //#endregion

  //#region Button Handlers

  const onUserClickHandler = (params: any) => {
    navigate(`/userlist/edituser/${params.row.userId}`);
  }

  const onDeleteConfirmationHandler = async () => {
    await deleteAsync('User/DeleteUser', deleteUserId || '');

    if (axiosSuccess) {
      showAlertSnack("The user was deleted successfully", "success");
      setRefresh(true);
    }
  }

  const onAddNewUserHandler = (): void => {
    navigate("/createuser", { replace: true });
  }

  //#endregion

  //#endregion


  //#region Axios Methods

  //get User List
  const getUserList = async (pageSize: number, page: number, field: string, sort: string) => {
    setIsLoading(true);
    const axiosGet = await getAsync<UserModel[]>(`User/GetAdministrativeUsersList` +
    `?orderBy=${field}&sort=${sort}`,
    {
      PageNumber: page + 1,
      PageSize: pageSize
    });
    setIsLoading(false);
    setRefresh(false);
    
    if (!axiosGet) {
      manageErrorAlert(axiosError?.Messages[0]);
      return;
    }
    
    setUserList(axiosGet);
  };

  //#endregion


  //#region UI - JSX Components

  const ui_confirm = (
    <ConfirmationModalComponent
      open={openDeleteConfirmation}
      handleClose={closeConfirmModal}
      type="deletion"
      onConfirm={onDeleteConfirmationHandler}
    />
  );

  const ui_addBtn = <Button sx={{ mb: 2 }} variant="contained" color="success" onClick={onAddNewUserHandler}>Add new user</Button>;

  //#endregion

  return (
    <PageWrapper title="User List">
      {ui_addBtn}
      <StripedDataGrid
        sx={{height: 700}}
        loading={isLoading}
        disableColumnSelector
        disableColumnMenu
        disableSelectionOnClick
        paginationMode="server"
        sortingMode="server"
        rows={userList}
        getRowId={(row) => row.userId}
        columns={definedColumns}
        rowsPerPageOptions={[5, 10, 20, 50]}
        pageSize={paginationModel.pageSize}
        page={paginationModel.page}
        components={{ Footer: () => CustomPagination(Math.ceil(rowCountState / (paginationModel ? paginationModel.pageSize! : 1)), paginationModel.page + 1, onPageChangeHandler) }}
        rowCount={rowCountState}
        onPageChange={onPageChangeHandler}     
        onPageSizeChange={onPageSizeChangeHandler}
        sortModel={sortModel}
        onSortModelChange={setSortModel}
        sortingOrder={["asc", "desc"]}
        onRowDoubleClick={onUserClickHandler}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
        }
      />
      {ui_confirm}
    </PageWrapper>
  );
};

export default UserList;
