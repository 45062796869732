const dtLocale = "en-US";

const dateOptions: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "long",
  day: "numeric",
};

const timeOptions: Intl.DateTimeFormatOptions = {
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
  hour12: true,
};

export const DateToLongString = (date: Date): string => `${date.toLocaleDateString(dtLocale, dateOptions)} ${date.toLocaleTimeString(dtLocale, timeOptions)}`;

export const DateToString = (date: Date): string => `${dateToShortString(date)} ${date.toLocaleTimeString(dtLocale, timeOptions)}`;

export const DateToStringShort = (date: Date): string => dateToShortString(date);

export const DateToStringStandarized = (date: Date): string =>
  `${(date.getMonth() + 1).toString().padStart(2, "0")}-` +
  `${date.getDate().toString().padStart(2, "0")}-` +
  `${date.getFullYear().toString()}`;

export const removeHoursFromDate = (date: Date): Date => {
  date.setHours(0, 0, 0, 0);
  return date;
};

export const getActualHourPlusXMins = (minutes: number): Date => {
  const timePlusX = new Date();
  timePlusX.setMinutes(timePlusX.getMinutes() + minutes);
  return timePlusX;
};

const dateToShortString = (date: Date): string => {
  const formattedDayOfMonth = date.getDate().toString().padStart(2, '0');
  const formattedMonth = (date.getMonth() + 1).toString().padStart(2, '0');

  return `${date.getFullYear()}-${formattedMonth}-${formattedDayOfMonth}`;
}

export const getDateOfToday = () => {
  let date = new Date();
  return new Date(date.getFullYear(), date.getMonth() + 1, 0);
};

export const getFirstDayOfCurrentMonth = () => {
  let date = new Date();
  return new Date(date.getFullYear(), date.getMonth(), 1);
};

export const getFormatedShortDateTimeString = (date: Date): string => {
  const formattedDayOfMonth = date.getDate().toString().padStart(2, '0');
  const formattedMonth = (date.getMonth() + 1).toString().padStart(2, '0');
  return `${formattedMonth}-${formattedDayOfMonth}-${date.getFullYear().toString().slice(-2)} ${date.toLocaleTimeString('en-US',{hour:'2-digit',minute:'2-digit'})}`;
};

export const getUTCDate =(date:Date):Date=> new Date(date.getTime()+ date.getTimezoneOffset()*60000);

export const getFirstDateIsBiggerThatSecondDate = (
  firstDate: Date,
  secondDate: Date
) => getUTCDate(firstDate).setHours(0, 0, 0, 0) > getUTCDate(secondDate).setHours(0, 0, 0, 0);

export const getDateIsBetweenLast30Days = (date: Date) => {
  let date30DaysAgo = new Date();
  date30DaysAgo.setDate(date30DaysAgo.getDate() + 30);
  let firstDate = getUTCDate(date30DaysAgo).setHours(0, 0, 0, 0);
  let secondDate = getUTCDate(new Date()).setHours(0, 0, 0, 0);
  let formatedDate = getUTCDate(date).setHours(0, 0, 0, 0);
  return formatedDate <= firstDate && formatedDate >= secondDate;
};