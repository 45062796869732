import { createTheme } from "@mui/material";
import type { } from "@mui/x-data-grid/themeAugmentation";
import { GlobalFontSize } from "../constants/global.constants";
import { colorsPalette, muiPalette } from "../constants/styles.constants";

export const fontTheme = createTheme({
  typography: {
    fontSize: GlobalFontSize,
  },
  palette: muiPalette,
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: GlobalFontSize
        }
      }
    },
  }
});

export const themeLogin = createTheme({
  palette: muiPalette,
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          backgroundColor: colorsPalette.dark,
          fontFamily: "GothamBook"
        },
        columnHeaderTitle: {
          fontWeight: "bold",
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: colorsPalette.white,
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          backgroundColor: colorsPalette.dark,
        },
      },
    },
  },
});

const PRISMCareTheme = createTheme({
  typography: {
    fontSize: GlobalFontSize,
    fontFamily: "GothamBook",
  },
  palette: muiPalette,
  components: {
    MuiSelect: {
      defaultProps: {
        SelectDisplayProps: {
          style: {
            backgroundColor: colorsPalette.white,
            paddingRight: 0,
            fontSize: "inherit",
          }
        },
        MenuProps: {
          style: {
            maxWidth: 100,
            maxHeight: 255,
            width: 'auto',
          }
        }
      },
      styleOverrides: {
        select: {
          backgroundColor: colorsPalette.white,
          fontSize: GlobalFontSize,
          fontFamily: "GothamBook"
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          display: 'block'
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        select: {
          paddingRight: '24px !important',
        }
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          backgroundColor: colorsPalette.white,
          fontFamily: "GothamBook"
        },
        columnHeaderTitle: {
          fontWeight: "bold",
          fontSize: GlobalFontSize,
        },
        row: {
          fontSize: GlobalFontSize
        }
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: GlobalFontSize,
          fontFamily: "GothamBook",
          fontWeight: "bold",
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: colorsPalette.white,
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          backgroundColor: colorsPalette.white,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: GlobalFontSize
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: colorsPalette.lightGray,
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: colorsPalette.red
        }
      }
    }
  },
});

export default PRISMCareTheme;
