import { Bloodtype, MonitorHeart, Opacity, Scale } from "@mui/icons-material";
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  Grid,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { GlobalFontSize } from "../../../constants/global.constants";
import { useAxios } from "../../../hooks/useAxios";
import { colorsPalette } from "../../../constants/styles.constants";

type PatientReadingsGraphType = {
  patientId: string | undefined;
  hasBloodPressureRecords: boolean;
  hasSpO2Records: boolean;
  hasWeightRecords: boolean;
  hasGlucoseRecords: boolean;
  initialDate?: Date;
  endDate?: Date;
};

interface AverageReadingResult {
  groupId?: string;
  date: Date;
  results: Result[];
}

interface Result {
  patientThresholId: string;
  patientMeasurementRecordId: string;
  value: number;
  date: Date;
  name: string;
  hasMedication: boolean;
  isManualReading: boolean;
  isOverLimit: boolean;
}

export const PatientReadingsGraphSection = (
  props: PatientReadingsGraphType
): JSX.Element => {
  const {
    patientId,
    hasBloodPressureRecords,
    hasSpO2Records,
    hasWeightRecords,
    hasGlucoseRecords,
    initialDate,
    endDate
  } = props;
  const noDaTa =
    !hasBloodPressureRecords &&
    !hasSpO2Records &&
    !hasGlucoseRecords &&
    !hasWeightRecords;

  const [bloodPressureAverage, setBloodPressureAverage] =
    useState<AverageReadingResult>();
  const [bloodGlucoseAverage, setBloodGlucoseAverage] =
    useState<AverageReadingResult>();
  const [weightAverage, setWeightAverage] = useState<AverageReadingResult>();
  const [spo2Average, setSpo2Average] = useState<AverageReadingResult>();
  const { getAsync, axiosSuccess } = useAxios();

  useEffect(() => {
    hasBloodPressureRecords &&
      fetchAverageReading("BloodPressure", setBloodPressureAverage);
    hasGlucoseRecords &&
      fetchAverageReading("BloodGlucose", setBloodGlucoseAverage);
    hasWeightRecords && fetchAverageReading("Weight", setWeightAverage);
    hasSpO2Records && fetchAverageReading("SpO2", setSpo2Average);
  }, []);

  const fetchAverageReading = async (
    measurementType: string,
    stateToSave: Dispatch<SetStateAction<AverageReadingResult | undefined>>
  ) => {
    const fetched = await getAsync<AverageReadingResult>(
      `PatientMeasurementRecords/GetPatientAverageReadings` +
        `?patientId=${patientId}` +
        `&measurementType=${measurementType}`+
        `&initialDate=${initialDate?.toLocaleDateString('en-US')}`+
        `&endDate=${endDate?.toLocaleDateString('en-US')}`
    );

    if (axiosSuccess && fetched) {
      stateToSave(fetched);
    }
  };

  const noAverageData = <ListItem
  alignItems="center"
  sx={{
    paddingTop: 4,
    paddingBottom: 2,
    marginBottom: 1,
    justifyContent: "space-between",
    alignContent: "center",
    textAlign: "center",
    height: "100%",
    display:'inline-block'
  }}
>
No average readings to display </ListItem>

  function ui_listMeasurements(header: string): JSX.Element {
    let listItemContent: JSX.Element = <></>;
    switch (header) {
      case "Blood pressure":
        listItemContent = (
          <Card elevation={5} sx={{ minHeight: 200 }}>
            <CardHeader
              title={
                <Typography
                  noWrap
                  variant="h6"
                  fontFamily={"inherit"}
                  fontWeight={400}
                >
                  {header.toUpperCase()}
                </Typography>
              }
              titleTypographyProps={{ style: { fontSize: 24 } }}
              avatar={
                <Avatar sx={{ bgcolor: colorsPalette.darkRed }}>
                  <MonitorHeart />
                </Avatar>
              }
            />

            <CardContent
              sx={{
                paddingTop: 0,
                paddingBottom: "0!important",
                height: 136,
              }}
            >
              <Divider
                orientation="horizontal"
                sx={{ marginTop: -1, marginBottom: 1, paddingTop: 0 }}
              />
              <List sx={{ width: "100%", height: "100%" }}>
                {bloodPressureAverage?.results === undefined && noAverageData }
                {bloodPressureAverage?.results.map((r) =>
                  !!r.name && r.value !== 0  ?(
                    <ListItem
                      key={r.name}
                      alignItems="center"
                      sx={{
                        padding: 0,
                        paddingBottom: 2,
                        marginBottom: 1,
                        justifyContent: "space-between",
                        alignContent: "center",
                        height:
                          100 /
                          bloodPressureAverage.results.filter(
                            (bp) => bp.value !== 0
                          ).length,
                      }}
                    >
                      <Box sx={{ fontWeight: "bold" }}>
                        {r.name.replace(" BP", "")}:
                      </Box>
                      <Chip
                        sx={{ fontWeight: "bold" }}
                        label={
                          r.value.toFixed(2) +
                          (r.name === "Pulse BP" ? " BPM" : " mmHg")
                        }
                        style={{ backgroundColor: colorsPalette.darkRed, color: colorsPalette.white }}
                        size="small"
                      />
                    </ListItem>
                  ) : null
                )}
              </List>
            </CardContent>
          </Card>
        );
        break;
      case "Oximeter":
        listItemContent = (
          <Card elevation={5} sx={{ minHeight: 200 }}>
            <CardHeader
              title={header.toUpperCase()}
              titleTypographyProps={{ style: { fontSize: 24 } }}
              avatar={
                <Avatar sx={{ bgcolor: colorsPalette.deepSkyBlue }}>
                  <Opacity />
                </Avatar>
              }
            />
            <CardContent
              sx={{
                paddingTop: 0,
                paddingBottom: "0!important",
                height: 136,
              }}
            >
              <Divider
                orientation="horizontal"
                sx={{ marginTop: -1, marginBottom: 1, paddingTop: 0 }}
              />
              <List sx={{ width: "100%", height: "100%" }}>
                {spo2Average?.results === undefined && noAverageData }
                {spo2Average?.results.map((r) =>
                  !!r.name && r.value !== 0 ? (
                    <ListItem
                      key={r.name}
                      alignItems="center"
                      sx={{
                        padding: 0,
                        paddingBottom: 2,
                        marginBottom: 1,
                        justifyContent: "space-between",
                        alignContent: "center",
                        height:
                          100 /
                          spo2Average.results.filter((spo2) => spo2.value !== 0)
                            .length,
                      }}
                    >
                      <Box sx={{ fontWeight: "bold" }}>
                        {r.name === "SpO2 Pulse" ? "Pulse" : r.name}:
                      </Box>
                      <Chip
                        sx={{ fontWeight: "bold" }}
                        label={
                          r.value.toFixed(2) +
                          (r.name === "SpO2 Pulse" ? " BPM" : "%")
                        }
                        style={{
                          backgroundColor: colorsPalette.deepSkyBlue,
                          color: colorsPalette.white,
                        }}
                        size="small"
                      />
                    </ListItem>
                  ) : null
                )}
              </List>
            </CardContent>
          </Card>
        );
        break;
      case "Glucometer":
        listItemContent = (
          <Card elevation={5} sx={{ minHeight: 200 }}>
            <CardHeader
              title={header.toUpperCase()}
              titleTypographyProps={{ style: { fontSize: 24 } }}
              avatar={
                <Avatar sx={{ bgcolor: colorsPalette.orange }}>
                  <Bloodtype />
                </Avatar>
              }
            />

            <CardContent
              sx={{
                paddingTop: 0,
                paddingBottom: "0!important",
                height: 136,
              }}
            >
              <Divider
                orientation="horizontal"
                sx={{ marginTop: -1, marginBottom: 1, paddingTop: 0 }}
              />
              <List sx={{ width: "100%", height: "100%" }}>
                {bloodGlucoseAverage?.results === undefined && noAverageData }
                {bloodGlucoseAverage?.results.map((r) => (
                  <ListItem
                    key={r.name}
                    alignItems="center"
                    sx={{
                      padding: 0,
                      paddingBottom: 2,
                      marginBottom: 1,
                      justifyContent: "space-between",
                      alignContent: "center",
                      height: "100%",
                    }}
                  >
                    <Box sx={{ fontWeight: "bold" }}>{r.name}:</Box>
                    <Chip
                      sx={{ fontWeight: "bold" }}
                      label={r.value.toFixed(2) + " mg/dL"}
                      style={{ backgroundColor: colorsPalette.orange, color: colorsPalette.white }}
                      size="small"
                    />
                  </ListItem>
                ))}
              </List>
            </CardContent>
          </Card>
        );
        break;
      case "Weight":
        listItemContent = (
          <Card elevation={5} sx={{ minHeight: 200 }}>
            <CardHeader
              title={header.toUpperCase()}
              titleTypographyProps={{ style: { fontSize: 24 } }}
              avatar={
                <Avatar sx={{ bgcolor: colorsPalette.purple }}>
                  <Scale />
                </Avatar>
              }
            />
            <CardContent
              sx={{
                paddingTop: 0,
                paddingBottom: "0!important",
                height: 136,
              }}
            >
              <Divider
                orientation="horizontal"
                sx={{ marginTop: -1, marginBottom: 1, paddingTop: 0 }}
              />
              <List sx={{ width: "100%", height: "100%" }}>
                {weightAverage?.results === undefined && noAverageData }
                {weightAverage?.results.map((r) => (
                  <ListItem
                    key={r.name}
                    alignItems="center"
                    sx={{
                      padding: 0,
                      paddingBottom: 2,
                      marginBottom: 1,
                      justifyContent: "space-between",
                      alignContent: "center",
                      height: "100%",
                    }}
                  >
                    <Box sx={{ fontWeight: "bold" }}>{r.name}:</Box>
                    <Chip
                      sx={{ fontWeight: "bold" }}
                      label={r.value.toFixed(2) + " lb"}
                      style={{ backgroundColor: colorsPalette.purple, color: colorsPalette.white }}
                      size="small"
                    />
                  </ListItem>
                ))}
              </List>
            </CardContent>
          </Card>
        );
        break;
      default:
        break;
    }
    return listItemContent;
  }

  return !noDaTa ? (
    <Grid container spacing={1.5}>
      <Grid
        item
        xs={12}
        sx={{
          fontSize: GlobalFontSize,
          fontWeight: 500,
          marginTop: 1.5,
          marginLeft: 2,
        }}
      >
        AVERAGE MEASUREMENTS
      </Grid>
      {hasBloodPressureRecords && (
        <Grid item xs={6}>
          {ui_listMeasurements("Blood pressure")}
        </Grid>
      )}
      {hasSpO2Records && (
        <Grid item xs={6}>
          {ui_listMeasurements("Oximeter")}
        </Grid>
      )}
      {hasWeightRecords && (
        <Grid item xs={6}>
          {ui_listMeasurements("Weight")}
        </Grid>
      )}
      {hasGlucoseRecords && (
        <Grid item xs={6}>
          {ui_listMeasurements("Glucometer")}
        </Grid>
      )}
    </Grid>
  ) : (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        opacity: 0.5,
        height: "100%",
      }}
    >
      No average readings to display
    </Box>
  );
};
