import { useNavigate } from "react-router-dom";
import { CustomDialog } from "../Shared/Modal/Modal.component";
import { Box, Button, Grid } from '@mui/material';

const TimeoutWarningModal = (props: any) => {     
    const navigate = useNavigate();

    const {
        isOpen,
        onRequestClose,
        setLeavingSessionByTimeout
    } = props;

    const logOff = () => {
        setLeavingSessionByTimeout(true);
        sessionStorage.clear();
        navigate("/logout",{replace: true});
    }

    return (
        <CustomDialog
            isOkButtonDisabled={true}
            open={isOpen}
            onConfirm={onRequestClose}
            handleClose={logOff}
            subTitle=""
            size="sm"
            title="Session Timeout"
            showCloseButton
            hideButtons
            confirmText='Stay in page'
            >
                <Box
                    component="form"
                    noValidate
                    autoComplete="off"
                >
                    <div>You're being timed out due to inactivity. Please click on stay signed in to remain logged in. Otherwise, you will be logged off automatically</div>
                    <br/>
                    <Grid container spacing={1} rowSpacing={1} >
                        <Grid item xs={12} sx={{textAlign: 'right'}}>
                            <Button
                                onClick={onRequestClose}
                                sx={{
                                height: 40,
                                }}
                                variant="contained"
                            >
                                Stay signed in
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </CustomDialog>
    );
};

export default TimeoutWarningModal;