import { AddCircle } from "@mui/icons-material";
import { ListItem, Box, Button } from "@mui/material";
import { useCallback, useContext, useEffect, useState } from "react";
import { PatientThresholdModel } from "../../../Api/Model/PatientThreshold";
import { useAxios } from "../../../hooks/useAxios";
import { PatientInfoComponentParams } from "../../../Shared/PatientInfoComponentParams";
import ConfirmationModalComponent from "../../Shared/ConfirmationModal/ConfirmationModal.component";
import { PatientThresholdModal } from "./PatientInfoPThresholdModal";
import { PatientContext } from "../../../Context/PatientContext";
import { emptyGUID } from "../../../constants/guid.contast";
import { PatientThresholdChip } from "./PatientThresholdChip.component";

export const PatientThresholds = (params: PatientInfoComponentParams) => {
  const {deleteAsync,postAsync,getAsync,updateAsync,axiosError,axiosSuccess,} = useAxios();
  const [patientThreshold, setPatientThreshold] = useState<PatientThresholdModel[]>([]);
  const [currentPatientThreshold, setCurrentPatientThreshold] = useState<any>(undefined);
  const [patientThresholdType, setPatientThresholdType] = useState<"create" | "update">("create");
  const [openDeletePThresholdConfirmation,setOpenDeletePThresholdConfirmation] = useState(false);
  const { showAlertSnack, isPatientInUse, manageErrorAlert } = params;
  const { patientDepartmentHasChanged, currentPatient, patientIsReady } = useContext(PatientContext);
 

  const getPatientAlertThreshold = async (patientId: string) => {
    if (!patientId) {
      return;
    }
    const axiosGet = await getAsync<PatientThresholdModel[]>(
      `PatientThreshold/GetPatientThreshold?patientId=${patientId}`
    );
    if (!axiosGet) {
      setPatientThreshold([]);
      return;
    }
    setPatientThreshold(axiosGet);
  };
  
  const onDeletePThresholdConfirmation = async () => {
    await deleteAsync(
      "PatientThreshold/DeletePatientThreshold",
      currentPatientThreshold.patientThresholdId || ""
    );
    if (axiosSuccess) {
      await getPatientAlertThreshold(currentPatient?.patientId || "");
      showAlertSnack(
        "The patient threshold was successfully removed.",
        "success"
      );
    }
  };

  const closeConfirmModalPThreshold = useCallback(() => {
    setOpenDeletePThresholdConfirmation(false);
  }, [setOpenDeletePThresholdConfirmation]);

  const ui_confirmPThreshold = (
    <ConfirmationModalComponent
      open={openDeletePThresholdConfirmation}
      handleClose={closeConfirmModalPThreshold}
      type="deletion"
      onConfirm={onDeletePThresholdConfirmation}
    />
  );

  const openConfirmModalPThreshold = (actual: PatientThresholdModel) => {
    setCurrentPatientThreshold(actual);
    setOpenDeletePThresholdConfirmation(true);
  };

  const handleRemovePatientThreshold = (index: any) => {
    let selectedPThreshold = patientThreshold[index];
    openConfirmModalPThreshold(selectedPThreshold);
  };

  const [openPatientThresholdModal, setOpenPatientThresholdModal] =
    useState(false);

  const handleEditPatientThreshold = (event: number) => {
    let selectedPThreshold = patientThreshold[event];
    setCurrentPatientThreshold(selectedPThreshold);
    setPatientThresholdType("update");
    setOpenPatientThresholdModal(true);
  };

  const closePatientThresholdModal = useCallback(() => {
    setOpenPatientThresholdModal(false);
  }, [setOpenPatientThresholdModal]);
  
  const onPatientThresholdModalConfirm = async (
    patientThresholdModel: PatientThresholdModel
  ) => {
    if (patientThresholdType === "create") {
      if (
        patientThreshold.some((pt)=> pt.measurementName === patientThresholdModel.measurementName)
      ) {
        showAlertSnack(
          "The patient threshold already exists, nothing added.",
          "info"
        );
        return;
      }
      await postAsync(
        `PatientThreshold/AddPatientThreshold?patientId=${currentPatient?.patientId || ""}`,
        patientThresholdModel
      );
      if (!axiosSuccess) {
        manageErrorAlert(axiosError?.Messages[0]);
        return;
      }
      showAlertSnack(
        "The patient threshold was added successfully.",
        "success"
      );
      await getPatientAlertThreshold(currentPatient?.patientId || "");
      return;
    }
    await updateAsync<PatientThresholdModel>(
      "PatientThreshold/UpdatePatientThreshold",
      patientThresholdModel.patientThresholdId || "",
      patientThresholdModel
    );

    if (!axiosSuccess) {
      manageErrorAlert(axiosError?.Messages[0]);
      return;
    }
    await getPatientAlertThreshold(currentPatient?.patientId || "");
    showAlertSnack("Patient threshold was updated successfully.", "success");
  };

  const addNewPatientThreshold = (): void => {
    setCurrentPatientThreshold(null);
    setPatientThresholdType("create");
    setOpenPatientThresholdModal(true);
  };



  useEffect(() => {
    if(currentPatient === undefined || !patientIsReady) {
      return;
    }
    getPatientAlertThreshold(currentPatient.patientId || emptyGUID);
  }, [patientIsReady]);

  useEffect(() => {
    if (patientDepartmentHasChanged) {
      getPatientAlertThreshold(currentPatient?.patientId || "");
    }
  }, [patientDepartmentHasChanged]);

  const ui_patientThreshold_modal = (
    <PatientThresholdModal
      open={openPatientThresholdModal}
      handleClose={closePatientThresholdModal}
      type={patientThresholdType}
      onConfirm={onPatientThresholdModalConfirm}
      patientThresholdParam={currentPatientThreshold}
      currentPatientId={currentPatient?.patientId || ""}
    />
  );

  const thresholds_ui = (
    <>
      {patientThreshold && patientThreshold.map((input: PatientThresholdModel, index: any) => (
        <ListItem key={"PThreshold" + index}>
          <PatientThresholdChip isPatientInUse={isPatientInUse}
            handleEditPatientThreshold={handleEditPatientThreshold}
            handleRemovePatientThreshold={handleRemovePatientThreshold}
            patientThreshold={input}
            index={index} />
        </ListItem>
      ))}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          disabled={isPatientInUse}
          onClick={addNewPatientThreshold}
          sx={{
            width: 3 / 10,
            height: 40,
            marginBlock: 2,
            fontWeight: "bold",
            textTransform: "capitalize",
          }}
          color="success"
          variant="contained"
        >
          <AddCircle /> Add Threshold
        </Button>
      </Box>
    </>
  );
  return <>
  {ui_confirmPThreshold}
  {ui_patientThreshold_modal}
  {thresholds_ui}
  </>;
};
