export const patienRolName= "PatientRol";
export const FrequencyCatalogName= "Frequency Type";
export const DosageCatalogName= "Dosage Unit";
export const MedicationCatalogName= "Medication";
export const DeviceTypeCatalogName = "Device";
export const MeasurementTypeCatalogName = "Measurement";
export const TimeTrackingMinutes = 20;
export const BPMeasurementName= "BP";
export const BillingCode4Setup= "99453";
export const DateDisplayFormat = "MM/DD/YYYY";
export const LocaleStringFormat= 'en-US';
export const GlobalFontSize= 16;
export const SystemRoles = {
    Admin: "Admin",
    PES: "PES",
    Nurse: "Nurse",
    Developer: "Developer",
    Patient: "Patient",
    Provider: "Provider",
    OfficeStaff: "Office Staff"
};