import { NotificationImportantOutlined, Close } from "@mui/icons-material";
import { Box, Paper, Grid, IconButton } from "@mui/material";
import { useCallback, useContext, useEffect, useState } from "react";
import { UserInUsePatientModel } from "../../Api/Model/UserInUsePatient";
import { emptyGUID } from "../../constants/guid.contast";
import { useAxios } from "../../hooks/useAxios";
import { getSessionUser } from "../../Shared/sessionUser.provider";
import { colorsPalette } from "../../constants/styles.constants";
import { PatientContext } from "../../Context/PatientContext";

interface InUseAlertParams {
  isPatientInUse: boolean;
  isEditing: boolean;
  setIsPatientInUse: (param: boolean) => void;
}

export const InUsePatientAlert = (params: InUseAlertParams) => {
  const [userOnPatient, setUserOnPatient] = useState<UserInUsePatientModel>({});
  const [alertVisible, setAlertVisible] = useState(true);
  const { getAsync, updateAsync } = useAxios();
  const sessionUser = getSessionUser();
  let isInUseBeforeLeft = false;
  const { isPatientInUse, setIsPatientInUse, isEditing } = params;
  const { currentPatient, patientIsReady } = useContext(PatientContext);

  window.onbeforeunload = (event) => {
    onLeftPage();
  };

  const updateUserUsingPatient = async (patientId: string, userId: string) => {
    await updateAsync<UserInUsePatientModel>(
      `Patients/SetChangeUsePatient/${patientId}`,
      userId || "",
      {}
    );
  };

  const getPatientInUse = async (patientId: string) => {
    const axiosGet = await getAsync<UserInUsePatientModel>(
      "Patients/GetInUsePatient?UuId=" + patientId
    );
    if (axiosGet) {
      if (axiosGet.id === sessionUser.id) {
        setIsPatientInUse(false);
        return;
      }
      setUserOnPatient(axiosGet);
      setIsPatientInUse(true);
      isInUseBeforeLeft = true;
      return;
    }
    setIsPatientInUse(false);
    updateUserUsingPatient(patientId, sessionUser.id);
  };

  const onLeftPage = useCallback(() => {
    if (isInUseBeforeLeft) {
      return;
    }
    updateUserUsingPatient(currentPatient?.patientId || emptyGUID, emptyGUID);
  }, [patientIsReady]);

  useEffect(() => {
    if(currentPatient === undefined || !patientIsReady) {
      return;
    }
    getPatientInUse(currentPatient.patientId || emptyGUID);
    return () => {
      onLeftPage();
    };
  }, [patientIsReady]);

  const handleHideInUseAlert = () => {
    setAlertVisible(false);
  };

  const alert_ui = (
    <Box
      visibility={!isEditing && isPatientInUse && alertVisible ? "visible" : "hidden"}
      sx={{
        display: "inline-flex",
        "& >:not(style)": {
          marginRight: 2,
          marginBottom: 1,
          minWidth: 454,
          maxWidth: 500,
          height: 53,
          marginTop: 1,
        },
      }}
    >
      <Paper
        sx={{
          bgcolor: colorsPalette.red,
          alignItems: "center",
          textJustify: "center",
          display: "flex",
        }}
      >
        <Grid container>
          <Grid item xs={1.5}>
            <NotificationImportantOutlined
              sx={{
                display: "inline-flex",
                width: 45,
                height: 45,
                marginLeft: 2,
              }}
              htmlColor={colorsPalette.white}
            />
          </Grid>
          <Grid item xs={9.5}>
            <Box
              sx={{
                paddingLeft: 2,
                display: "inline-flex",
                color: colorsPalette.white,
                fontSize: 15,
              }}
            >
              {`${userOnPatient.userName} is currently editing this chart.`}
              <br />
              {"Write access temporarily disabled."}
            </Box>
          </Grid>
          <Grid item xs={1}>
            <IconButton onClick={handleHideInUseAlert}>
              <Close htmlColor={colorsPalette.white} sx={{ width: 15, height: 15 }} />
            </IconButton>
          </Grid>
        </Grid>
      </Paper>
    </Box>);

  return <>
    {!isEditing && isPatientInUse && alertVisible && alert_ui}
  </>;
};
