import {
  Button,
  CircularProgress,
  Grid,
  TextField,
  TextFieldProps,
  Typography,
} from "@mui/material";
import { GridColDef, GridSortModel } from "@mui/x-data-grid";
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs";
import { isEmpty, isUndefined } from "lodash";
import { useEffect, useState } from "react";
import { useOutletContext } from "react-router";
import { ReportFilterModel } from "../../Api/Model/ReportFilterModel";
import { DateDisplayFormat, LocaleStringFormat } from "../../constants/global.constants";
import { CustomNoRowsReportOverlay } from "../../constants/styles.constants";
import { useAxios } from "../../hooks/useAxios";
import StripedDataGrid, { CustomPagination } from "../Shared/StripedDataGrid/StripedDatagrid.component";
import { TimeTrackingByPatientReportModel } from "../../Api/Model/Reports/TimeTrackingByPatientModel";
import { DateToLongString, getDateOfToday, getFirstDayOfCurrentMonth, getUTCDate } from "../../Shared/DateTime.provider";
import { downloadFileResponse } from "../../Shared/Files.provider";
import PageWrapper from "../Shared/Wrapper/PageWrapper.component";

//#region Definitions

//#region Types and Interfaces

type PatientReadingsType = {
  patientId: string | undefined;
};

interface BillingReport {
  code: string;
  duration: string;
}

//#endregion

//#region Defined Columns

const definedColumnsBillingTime: GridColDef[] = [
  {
    field: "code",
    headerName: "General Billing Code",
    headerAlign: "left",
    align: "left",
    flex: 1,
    hideable: false,
    filterable: false,
  },
  {
    field: "duration",
    headerName: "Total time",
    flex: 1,
    headerAlign: "left",
    align: "left",
    hideable: false,
    filterable: false,
  },
];

const definedColumns: GridColDef[] = [
  {
    field: "createdDate",
    headerName: "Start Time",
    headerAlign: "left",
    align: "left",
    flex: 1,
    hideable: false,
    filterable: false,
    valueGetter: (params) => {
        return DateToLongString(new Date(params.row.createdDate));
    },
  },
  {
    field: "createdBy",
    headerName: "User",
    flex: 1,
    headerAlign: "left",
    align: "left",
    hideable: false,
    filterable: false,
  },
  {
    field: "code",
    headerName: "Billing Code",
    flex: 1,
    headerAlign: "left",
    align: "left",
    hideable: false,
    filterable: false,
  },
  {
    field: "type",
    headerName: "Type",
    flex: 1,
    headerAlign: "left",
    align: "left",
    hideable: false,
    filterable: false,
  },
  {
    field: "realTime",
    headerName: "Duration",
    flex: 1,
    headerAlign: "left",
    align: "left",
    hideable: false,
    filterable: false,
  },
];

//#endregion

//#endregion

export const TimeTrackingSection = ({patientId}: PatientReadingsType) => {

  //#region Hooks

  //#region States

  const [reportFilterModel, setReportFilterModel] = useState<ReportFilterModel>(
    {}
  );
  const [exportFilterModel, setExportFilterModel] = useState<ReportFilterModel>(
    {}
  );
  const [datesErrors, setDatesErrors] = useState(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [timeTrackingReport, setTimeTrackingReport] = useState<
    TimeTrackingByPatientReportModel[]
  >([]);
  const [billingReport, setBillingReport] = useState<BillingReport[]>([]);
  // For Total Time
  const [sortModelTotals, setSortModelTotals] = useState<GridSortModel>([{field: definedColumnsBillingTime[0].field, sort: "asc"}]);
  const [paginationModelTotals, setPaginationModelTotals] = useState({
    pageSize: 10,
    page: 0
  });
  // For Time Details
  const [rowCountState, setRowCountState] = useState(0);
  const [sortModel, setSortModel] = useState<GridSortModel>([{field: definedColumns[0].field, sort: "asc"}]);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0
  });

  //#endregion

  //#region Others

  const { getAsync, axiosError, axiosLoading, axiosPagination } = useAxios();
  const { downloadFileAsync, axiosLoading: axiosDownloadLoading, axiosSuccess } = useAxios();

  //#endregion

  //#region Effects

  useEffect(() => {
    if (!refresh) {
      return;
    }
    if (reportFilterModel.startDate && reportFilterModel.endDate) {
      onSubmitReportClick();
    }
    if (!("startDate" in reportFilterModel)) {
      setReportFilterModel({endDate:getDateOfToday(),startDate:getFirstDayOfCurrentMonth()});
    }
    return;
  }, [refresh]);

  useEffect(() => {
    setRowCountState((prevState) =>
      axiosPagination?.TotalRecords !== undefined
        ? axiosPagination?.TotalRecords
        : prevState
    );
  }, [axiosPagination?.TotalRecords]);

  useEffect(() => {
    if (sortModel[0] === undefined) {
      setSortModel([{field: definedColumns[0].field, sort: "asc"}])
    }
    if (sortModelTotals[0] === undefined) {
      setSortModel([{field: definedColumnsBillingTime[0].field, sort: "asc"}])
    }
    setRefresh(true);
  }, [paginationModel, paginationModelTotals, sortModel, sortModelTotals]);

  useEffect(() => {
    setDatesErrors(
      (reportFilterModel.endDate &&
        reportFilterModel.startDate &&
        reportFilterModel.endDate < reportFilterModel.startDate) ||
      false
    );
  }, [reportFilterModel.startDate, reportFilterModel.endDate]);

  //#endregion
  
  //#endregion


  //#region Validations

  const validationErrors = {
    ...(isUndefined(reportFilterModel.startDate) && {
      StartDate: "Start Date is required",
    }),
    ...(reportFilterModel.startDate?.toString() === "Invalid Date" && {
      StartDate: "Start Date must be a valid date",
    }),
    ...(!!reportFilterModel.startDate
      ? reportFilterModel.startDate > new Date() && {
        StartDate: "Start Date cannot be past today date",
      }
      : false),
    ...(isUndefined(reportFilterModel.endDate) && {
      EndDate: "End Date is required",
    }),
    ...(reportFilterModel.endDate?.toString() === "Invalid Date" && {
      EndDate: "End Date must be a valid date",
    }),
    ...(datesErrors && {
      StartDate: "Date cannot be greater than End Date",
      EndDate: "Date cannot be less than Start Date",
    }),
  };

  //#endregion


  //#region Axios Methods

  const { manageErrorAlert, showAlertSnack } = useOutletContext<{
    manageErrorAlert: Function;
    showAlertSnack: Function;
  }>();

  const getTimeTrackingReport = async (
    {startDate, endDate}: ReportFilterModel,
    pageSize: number, page: number, field: string, sort: string
  ) => {
    if (!isEmpty(validationErrors)) {
      return;
    }
    const axiosGetReportByPatient = await getAsync<
      TimeTrackingByPatientReportModel[]
    >(
      `Reports/GetTimeTrackingRealReportsByPatient?` +
      `&dateIni=${startDate?.toLocaleDateString(LocaleStringFormat)}` +
      `&dateEnd=${endDate?.toLocaleDateString(LocaleStringFormat)}` +
      `&patientId=${patientId}`+
      `&orderBy=${field}&sort=${sort}`,
      {
        PageNumber: page + 1,
        PageSize: pageSize
      }
    );
    setRefresh(false);
    if (!axiosGetReportByPatient) {
      manageErrorAlert(axiosError?.Messages[0]);
      return;
    }
    setTimeTrackingReport(axiosGetReportByPatient);
  };

  const getBillingCodeReport = async ({startDate, endDate}: ReportFilterModel, pageSize: number, page: number, field: string, sort: string) => {
    const axiosGetReportByCodeTotals = await getAsync<BillingReport[]>(
      `Reports/GetTimeTrackingRealReportsByPatientTotalTime?` +
      `dateIni=${startDate?.toLocaleDateString(LocaleStringFormat)}` +
      `&dateEnd=${endDate?.toLocaleDateString(LocaleStringFormat)}` +
      `&patientId=${patientId}` +
      `&orderBy=${field}&sort=${sort}`,
      {
        PageNumber: page + 1,
        PageSize: pageSize
      }
    );
    setRefresh(false);
    if (!axiosGetReportByCodeTotals) {
      return;
    }
    setBillingReport(axiosGetReportByCodeTotals);
  };
  
  //#endregion


  //#region Handlers

  //#region Pagination Handlers

  // For Time Details
  const onPageSizeChangeHandler = (newPageSize: number) => setPaginationModel({...paginationModel, pageSize: newPageSize});
  const onPageChangeHandler = (newPage: number) => setPaginationModel({...paginationModel, page: newPage});
  
  // For Time Totals
  const onPageSizeTotalsChangeHandler = (newPageSize: number) => setPaginationModelTotals({...paginationModelTotals, pageSize: newPageSize});
  const onPageTotalsChangeHandler = (newPage: number) => setPaginationModelTotals({...paginationModelTotals, page: newPage});

  //#endregion

  //#region Button Handlers

  const onSubmitReportClick = async () => {

    // For Time Details
    const {pageSize, page} = paginationModel;
    const {field, sort} = sortModel[0] !== undefined ? sortModel[0] : {field: definedColumns[0].field, sort: "asc"};
    getTimeTrackingReport(reportFilterModel, pageSize, page, field, sort || "");
    
    // For Time Totals
    const {pageSize: pageSizeTotals, page: pageTotals} = paginationModelTotals;
    const {field: fieldTotals, sort: sortTotals} = sortModelTotals[0] !== undefined ? sortModelTotals[0] : {field: definedColumnsBillingTime[0].field, sort: "asc"};
    getBillingCodeReport(reportFilterModel, pageSizeTotals, pageTotals, fieldTotals, sortTotals || "");

    setExportFilterModel(reportFilterModel);
  };

  const onExportReportClick = async () => {
    let response = await downloadFileAsync(
      "Reports/ExportTimeTrackingReportByPatient",
      `dateIni=${exportFilterModel.startDate?.toLocaleDateString(LocaleStringFormat)}` +
      `&dateEnd=${exportFilterModel.endDate?.toLocaleDateString(LocaleStringFormat)}` +
      `&patientId=${patientId}`
    );

    if (axiosSuccess) {
      downloadFileResponse(
        response,
        `PatientTimeTracking_${patientId}.xlsx`
      );
    } else {
      manageErrorAlert(axiosError?.Messages[0]);
    }
  };

  //#endregion

  //#endregion


  //#region UI - JSX Elements
  
  function ui_datePicker(startOrEnd: boolean = true): JSX.Element {
    return (
      <>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            disableFuture={startOrEnd}
            onChange={(newValue: Dayjs | null) => {
              startOrEnd
                ? setReportFilterModel({
                  ...reportFilterModel,
                  startDate: newValue ? newValue.toDate() : undefined,
                })
                : setReportFilterModel({
                  ...reportFilterModel,
                  endDate: newValue ? newValue.toDate() : undefined,
                });
            }}
            value={
              startOrEnd
                ? reportFilterModel.startDate || null
                : reportFilterModel.endDate || null
            }
            label={startOrEnd ? "Start Date" : "End date"}
            inputFormat={DateDisplayFormat}
            renderInput={(params) =>
              ui_textFieldForDatePicker(params, startOrEnd)
            }
            PopperProps={{
              placement: "bottom-start",
              sx: {zIndex: 1200, paddingBottom: 2}
            }}
          />
        </LocalizationProvider>
      </>
    );
  }

  function ui_textFieldForDatePicker(
    params: TextFieldProps,
    startOrEnd: boolean = true
  ): JSX.Element {
    return (
      <>
        <TextField
          required
          inputProps={startOrEnd ? { tabIndex: 1 } : { tabIndex: 2 }}
          {...params}
          error={
            startOrEnd
              ? !!validationErrors.StartDate
              : !!validationErrors.EndDate
          }
          helperText={
            startOrEnd ? validationErrors.StartDate : validationErrors.EndDate
          }
          fullWidth
        />
      </>
    );
  }

  //#endregion


  return <PageWrapper>
    <Grid container spacing={2} rowSpacing={1} width={"100%"}>
      <Grid item xs={2}>
        {ui_datePicker(true)}
      </Grid>
      <Grid item xs={2}>
        {ui_datePicker(false)}
      </Grid>
      <Grid item xs={2}>
        <Button
          disabled={!isEmpty(validationErrors)}
          sx={{ mb: 2, height: 55 }}
          variant="contained"
          onClick={onSubmitReportClick}
        >
          GET HISTORY
        </Button>
      </Grid>
      <Grid item xs={6} sx={{ display: "flex", justifyContent: "right" }}>
        <Button
          disabled={
            isEmpty(timeTrackingReport) ||
            isEmpty(billingReport) || axiosDownloadLoading
          }
          sx={{ mb: 2, height: 55 }}
          variant="contained"
          onClick={onExportReportClick}
        >
          {!axiosDownloadLoading ? "EXPORT TO EXCEL" : 'DOWNLOADING...'}
        </Button>
        {axiosDownloadLoading &&
          <CircularProgress
            size={24}
            sx={{
              color: 'primary',
              position: 'absolute',
              marginTop: 2,
              marginRight: 10,
            }}
          />}
      </Grid>
      <Grid item xs={12} sx={{ width: "100%" }}>
        <Grid item xs={12} sx={{ marginTop: 2, marginBottom: 2 }}>
          <Typography fontWeight={"bold"}>Summary</Typography>
        </Grid>
        <Grid item xs={4}>
            <StripedDataGrid
              sx={{ height: 250 }}
              loading={axiosLoading}
              disableColumnSelector
              disableColumnMenu
              disableSelectionOnClick
              getRowId={(row) => row.code}
              paginationMode="server"
              sortingMode="server"
              rows={billingReport}
              columns={definedColumnsBillingTime}
              hideFooterPagination
              hideFooter
              rowsPerPageOptions={[50]}
              pageSize={paginationModelTotals.pageSize}
              page={paginationModelTotals.page}
              components={{
                NoResultsOverlay: () =>
                  CustomNoRowsReportOverlay("No data available"),
                NoRowsOverlay: () =>
                  CustomNoRowsReportOverlay("No data available"),
              }}
              rowCount={rowCountState}
              onPageChange={onPageTotalsChangeHandler}
              onPageSizeChange={onPageSizeTotalsChangeHandler}
              onSortModelChange={setSortModelTotals}
              sortModel={sortModelTotals}
              sortingOrder={["asc", "desc"]}
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0
                  ? "even"
                  : "odd"
              }
            />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid item xs={12} sx={{ marginTop: 2, marginBottom: 2 }}>
          <Typography fontWeight={"bold"}>
            Detailed Time Tracking Records
          </Typography>
        </Grid>
          <StripedDataGrid
            sx={{ height: 622 }}
            loading={axiosLoading}
            disableColumnSelector
            disableColumnMenu
            disableSelectionOnClick
            getRowId={(row) => row.billingId}
            paginationMode="server"
            sortingMode="server"
            rows={timeTrackingReport}
            columns={definedColumns}
            rowsPerPageOptions={[5, 10, 20, 50]}
            pageSize={paginationModel.pageSize}
            page={paginationModel.page}
            components={{
              NoResultsOverlay: () =>
                CustomNoRowsReportOverlay(
                  !exportFilterModel.startDate ||
                    !exportFilterModel.startDate
                    ? "Please select a date range to display the report"
                    : "No results for the selected date range "
                ),
              NoRowsOverlay: () =>
                CustomNoRowsReportOverlay(
                  !exportFilterModel.startDate ||
                    !exportFilterModel.startDate
                    ? "Please select a date range to display the report"
                    : "No results for the selected date range "
                ),
              Footer: () => 
                CustomPagination(
                  Math.ceil(
                    rowCountState /
                      (paginationModel ? paginationModel.pageSize! : 1)
                    ), 
                    paginationModel.page + 1,
                    onPageChangeHandler
                ),
            }}
            rowCount={rowCountState}
            onPageChange={onPageChangeHandler}
            onPageSizeChange={onPageSizeChangeHandler}
            onSortModelChange={setSortModel}
            sortingOrder={["asc", "desc"]}
            sortModel={sortModel}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
            }
          />
      </Grid>
    </Grid>
  </PageWrapper>;
};
