import axios from "axios";
import { useState } from "react";
import Cookies from "universal-cookie";
import { PaginationRequestModel } from "../Api/Model/Shared/PaginationModel";
import { PaginationResult } from "../Api/Model/Shared/PaginationResult";
import { ErrorResult } from "../Api/Model/Shared/RequestModel";
import { AuthUserModel } from "../Api/Model/user/authModel";
import { urlActive } from "../constants/urlBase.constants";

export const useAxios = () => {
  const [axiosLoading, setAxiosLoading] = useState(false);
  const [axiosSuccess, setAxiosSuccess] = useState(true);
  const [axiosError, setAxiosError] = useState<ErrorResult | undefined>();
  const [axiosPagination, setAxiosPagination] = useState<
    PaginationResult | undefined
  >({ PageSize: 10 });

  const cookies = new Cookies();
  const sessionUser = cookies.get("sessionUser") as AuthUserModel;

  /**
   * Axios method to get from service, handles the paginated result
   * @param url Set the url as `contoller/action`
   * @param paginationInfo This is an optional parameter, this is a must have if the expected result is a paginated result
   * @returns The fetched data as the type that has been provided
   * @requires The endpoint to be an HttpGet method
   */
  const getAsync = async <T>(
    url: string,
    paginationInfo?: PaginationRequestModel
  ) => {
    setAxiosLoading(true);
    const targetUrl = urlActive + url;
    try{
      const fetched = await axios.get(targetUrl, {
        params: paginationInfo && {
          pageNumber: paginationInfo.PageNumber,
          pageSize: paginationInfo.PageSize,
        },
        headers: sessionUser && {
          Authorization: `Bearer ${sessionUser.authToken}`,
          'OffsetMinutes': new Date().getTimezoneOffset()
        },
      });

      setAxiosLoading(false);

      if(fetched === undefined) {
        return;
      }

      if (!fetched || fetched.status.toString()[0] !== "2") {
        setAxiosSuccess(false);
        setAxiosError({
          Messages: [fetched?.statusText],
          Code: fetched?.status.toString(),
          Url: targetUrl,
        });
        return;
      }
  
      if (fetched.data.data) {
        setAxiosPagination({
          FirstPage: fetched.data.firstPage,
          LastPage: fetched.data.lastPage,
          NextPage: fetched.data.nextPage,
          PageNumber: fetched.data.pageNumber,
          PageSize: fetched.data.pageSize,
          Succeeded: fetched.data.succeeded,
          TotalPages: fetched.data.totalPages,
          TotalRecords: fetched.data.totalRecords,
        } as PaginationResult);
        return fetched.data.data as T;
      }
  
      return fetched.data as T;
    } catch(e: any){
      if(axios.isAxiosError(e)){
        if(e.response?.status === 401) {
          setAxiosSuccess(false);
          setAxiosError({
            Messages: e.response?.data?.message,
            Code: e.response?.status?.toString(),
            Url: targetUrl,
          });
          return;
        }        

        setAxiosError({
          Messages: [e.message],
          Code: e.code?.toString(),
          Url: targetUrl,
        });
      }

      setAxiosSuccess(false);
      setAxiosLoading(false);
      return;
    }
  };

  /**
   * Handles posting onto the web service
   * @param url Set the url as `contoller/action`
   * @param payload Required params for the web service within the request body
   * @param authToken Optional parameter, if is set, this will be the bearer token sent on the request
   * @returns The data returned as the type that has been provided
   * @requires The endpoint to be an HttpPost method
   */
  const postAsync = async <T>(url: string, payload: any, authToken: string = '') => {
    setAxiosLoading(true);
    const targetUrl = urlActive + url;
    const authTokenHeader = sessionUser ? sessionUser.authToken : authToken;
    const headers:any = {
      "content-type": "text/json",
      'OffsetMinutes': new Date().getTimezoneOffset()
    }
    if(authTokenHeader){
      headers['Authorization'] = `Bearer ${authTokenHeader}`
    }
    try{
      const fetched = await axios.post(targetUrl, JSON.stringify(payload), {
        headers: headers,
      });
      setAxiosLoading(false);
  
      if (!fetched || fetched.status.toString()[0] !== "2") {
        setAxiosSuccess(false);
        setAxiosError({
          Messages: [fetched?.statusText],
          Code: fetched?.status?.toString(),
          Url: targetUrl,
        });
        return;
      }    
    
      if (fetched.data.data) {
        setAxiosPagination({
          FirstPage: fetched.data.firstPage,
          LastPage: fetched.data.lastPage,
          NextPage: fetched.data.nextPage,
          PageNumber: fetched.data.pageNumber,
          PageSize: fetched.data.pageSize,
          Succeeded: fetched.data.succeeded,
          TotalPages: fetched.data.totalPages,
          TotalRecords: fetched.data.totalRecords,
        } as PaginationResult);
        return fetched.data.data as T;
      }
  
      return fetched.data as T;
    }
    catch(e: any){
      if(axios.isAxiosError(e)){
        if(e.response?.status === 401) {
          setAxiosSuccess(false);
          setAxiosError({
            Messages: e.response?.data?.message,
            Code: e.response?.status?.toString(),
            Url: targetUrl,
          });
          return;
        }        

        setAxiosError({
          Messages: [e.message],
          Code: e.code?.toString(),
          Url: targetUrl,
        });
      }

      setAxiosSuccess(false);
      setAxiosLoading(false);
      return;
    }
  };

  /**
   * Handles the axios logic to update an entity
   * @param url Set the url as `contoller/action`
   * @param id The entity identifier
   * @param payload Required params for the web service within the request body
   * @returns The data returned as the type that has been provided
   * @requires The endpoint to be an HttpPut method
   */
  const updateAsync = async <T>(url: string, id: string, payload: any) => {
    setAxiosLoading(true);
    const targetUrl = `${urlActive}${url}/${id}`;
    try{
      const fetched = await axios.put(targetUrl, JSON.stringify(payload), {
        headers: {
          "content-type": "text/json",
          Authorization: sessionUser && `Bearer ${sessionUser.authToken}`,
        },
      });
      setAxiosLoading(false);

      if (!fetched || fetched.status.toString()[0] !== "2") {
        setAxiosSuccess(false);
        setAxiosError({
          Messages: [fetched?.statusText],
          Code: fetched?.status?.toString(),
          Url: targetUrl,
        });
        return;
      }

      return fetched.data as T;
    }
    catch(e: any){
      if(axios.isAxiosError(e)){
        if(e.response?.status === 401) {
          setAxiosSuccess(false);
          setAxiosError({
            Messages: e.response?.data?.message,
            Code: e.response?.status?.toString(),
            Url: targetUrl,
          });
          return;
        }        

        setAxiosError({
          Messages: [e.message],
          Code: e.code?.toString(),
          Url: targetUrl,
        });
      }

      setAxiosSuccess(false);
      setAxiosLoading(false);
      return;
    }
  };

  /**
   * Handles the axios logic to delete an entity
   * @param url Set the url as `contoller/action`
   * @param id The entity identifier
   * @returns The data returned as the type that has been provided
   * @requires The endpoint to be an HttpDelete method
   */
  const deleteAsync = async <T>(url: string, id: string) => {
    setAxiosLoading(true);
    const targetUrl = `${urlActive}${url}/${id}`;
    try {
      const fetched = await axios.delete(targetUrl, {
        headers: {
          "content-type": "text/json",
          Authorization: sessionUser && `Bearer ${sessionUser.authToken}`,
        },
      });
      setAxiosLoading(false);
  
      if (!fetched || fetched.status.toString()[0] !== "2") {
        setAxiosSuccess(false);
        setAxiosError({
          Messages: [fetched?.statusText],
          Code: fetched?.status?.toString(),
          Url: targetUrl,
        });
        return;
      }
  
      return fetched.data as T;
    } catch(e: any){
      if(axios.isAxiosError(e)){
        if(e.response?.status === 401) {
          setAxiosSuccess(false);
          setAxiosError({
            Messages: e.response?.data?.message,
            Code: e.response?.status?.toString(),
            Url: targetUrl,
          });
          return;
        }        

        setAxiosError({
          Messages: [e.message],
          Code: e.code?.toString(),
          Url: targetUrl,
        });
      }

      setAxiosSuccess(false);
      setAxiosLoading(false);
      return;
    }
  };

  /**
   * Handles posting onto the web service
   * @param url Set the url as `contoller/action`
   * @param data Required params for the web service within the request body from file
   * @returns The data returned as the type that has been provided
   * @requires The endpoint to be an HttpPost method
   */
  const uploadAsync = async <T>(url: string, payload: any) => {
    setAxiosLoading(true);
    setAxiosSuccess(false);
    const targetUrl = `${urlActive}${url}`;
    try {
      const formData = new FormData();
      formData.append("file", payload);
      const fetched = await axios.post(targetUrl, formData, {
        headers: {
          "content-type": "text/json",
          Authorization: sessionUser && `Bearer ${sessionUser.authToken}`,
        },
      });

      setAxiosLoading(false);

      if(fetched === undefined) {
        return;
      }
      if (!fetched || fetched.status.toString()[0] !== "2") {
        setAxiosSuccess(false);
        setAxiosError({
          Messages: [fetched?.statusText],
          Code: fetched?.status?.toString(),
          Url: targetUrl,
        });
        return;
      }
      setAxiosSuccess(true);
      return fetched.data as T;
    } catch(e: any){
      if(axios.isAxiosError(e)){
        if(e.response?.status === 400) {
          setAxiosSuccess(false);
          setAxiosError({
            Messages: [e.response?.data],
            Code: e.response?.status?.toString(),
            Url: targetUrl,
          });

          return;
        }        

        setAxiosError({
          Messages: [e.message],
          Code: e.code?.toString(),
          Url: targetUrl,
        });
      }

      setAxiosSuccess(false);
      setAxiosLoading(false);
      return;
    }
  };

    /**
     * Handles posting onto the web service
     * @param url Set the url as `contoller/action`
     * @param payload Required params for the web service within the request URL
     * @returns The data returned as the type that has been provided
     * @requires The endpoint to be an HttpPost method
     */
    const downloadFileAsync = async (url: string, payload: any) => {
            setAxiosLoading(true);
            const targetUrl = urlActive + url+"?"+payload;
            const fetched = await axios.post(targetUrl, null,
                {
                  headers: {
                      Authorization: sessionUser && `Bearer ${sessionUser.authToken}`,
                      'OffsetMinutes': new Date().getTimezoneOffset() 
                  },
                  responseType:'blob'
                }
            );
            setAxiosLoading(false);
    
            if (!fetched || fetched.status.toString()[0] !== "2") {
                setAxiosSuccess(false);
                setAxiosError({
                    Messages: [fetched?.statusText],
                    Code: fetched?.status?.toString(),
                    Url: targetUrl
                });
                return;
            }
        return fetched.data;
    };

    return {
        axiosSuccess,
        axiosPagination,
        axiosLoading,
        axiosError,
        getAsync,
        postAsync,
        updateAsync,
        deleteAsync,
        uploadAsync,
        downloadFileAsync
    };
};
