import { useState, useEffect } from "react";
import { SideBarItemsModel } from "../Api/Model/SideBarItemsModel";
import Cookies from "universal-cookie";
import { AuthUserModel } from "../Api/Model/user/authModel";
import { useNavigate } from "react-router-dom";
import { useAxios } from "./useAxios";
import { ModulePageModel } from "../Api/Model/Security/ModulePageModel";

export const useMenu = (props: any) => {
  const cookies = new Cookies();
  const sessionUser = cookies.get("sessionUser") as AuthUserModel;
  
  const {getAsync,  axiosError} = useAxios();
  const navigate = useNavigate();
  
  const [isLoading, setIsLoading] = useState(true);
  const [roleMenu, setRoleMenus] = useState<SideBarItemsModel[]>([]);
  const {manageErrorAlert} = props;

  function getModules(module: any) {
    return {
      index: module.moduleIndex,
      name: module.moduleName,
      icon: module.icon,
      uri: module.uriModule,
      items:
        module.children.lenght <= 0
          ? []
          : module.children
              .map((item: any) => ({
                index: item.moduleIndex,
                name: item.moduleName,
                icon: item.icon,
                uri: item.uriModule,
              }))
              .sort((a: any, b: any) => (a.index > b.index ? 1 : -1)),
    };
  }

  async function setMenuBD(sRoleId: string) {
    const axiosGet = await getAsync<ModulePageModel[]>(`Role/GetModulePagesByRoleId?id=${sRoleId}`);

    if (!axiosGet) {
      manageErrorAlert(axiosError?.Messages[0]);
      navigate("/logout",{replace: true});
      return;
    }

    const modules = axiosGet.map((element: any) => getModules(element));
    
    setRoleMenus( modules.sort((a: any, b: any) => (a.index > b.index ? 1 : -1)) );
  }

  useEffect(() => {
    setIsLoading(true);      
    setMenuBD(sessionUser.roleId || "");
    setIsLoading(false);
  }, []);

  return {
    isLoading,
    roleMenu
  };
};
