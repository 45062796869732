import {
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useOutletContext } from "react-router-dom";
import { useCallback, useContext, useEffect, useState } from "react";
import { EmergencyContactModel } from "../../Api/Model/EmergencyContact/EmergencyContactModel";
import { Box } from "@mui/system";
import { isEmpty } from "lodash";
import {
  emailvalidator,
  phoneNumberValidator,
  zipCodeValidator,
} from "../../Api/Model/Shared/Validations/Validations";
import { PatientAdress } from "../../Api/Model/AddressModel";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import StarIcon from "@mui/icons-material/Star";
import ConfirmationModalComponent from "../Shared/ConfirmationModal/ConfirmationModal.component";
import { useAxios } from "../../hooks/useAxios";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { CleanButton } from "../../Shared/CleanHandler.provider";
import { colorsPalette } from "../../constants/styles.constants";
import { CatalogModel } from "../../Api/Model/GenericCatalog/CatalogModelAxios";
import { PatientContext } from "../../Context/PatientContext";
import { emptyGUID } from "../../constants/guid.contast";

const EmergencyContact = (props: any) => {
  const { isPatientInUse } = props;
  const { showAlertSnack } = useOutletContext<{
    manageErrorAlert: Function;
    showAlertSnack: Function;
  }>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(true);
  const [emergencyContactList, setEmergencyContactList] = useState<
    EmergencyContactModel[]
  >([]);
  const [relationshipList, setRelationshipList] = useState<CatalogModel[]>([]);
  const [emergencyContact, setEmergencyContact] =
    useState<EmergencyContactModel>({relationshipId:""} as EmergencyContactModel);
  const [addresContact, setAddresContact] = useState<PatientAdress>(
    {} as PatientAdress
  );
  const [deleteEmergencyContactId, setDeleteEmergencyContactId] = useState("");
  const { getAsync, postAsync,deleteAsync, axiosError, axiosSuccess } = useAxios();
  const [hasUserName, setHasUserName] = useState<boolean>(false);
  const [userNameInUse, setUserNameInUse] = useState(false);
  const [selectedRelationship, setSelectedRelationship] = useState("");
  const [isOtherSelected, setIsOtherSelected] = useState(false);
  const [otherRelationshipDescription, setOtherRelationshipDescription] = useState("");

  const { currentPatient } = useContext(PatientContext);

  const getEmergencyContactList = async (patientId:string) => {
    setIsLoading(true);
    const axiosGet = await getAsync<CatalogModel[]>(`EmergencyContact/GetEmergencyContactListByPatientId/${patientId}`);
    setIsLoading(false);
    if (!axiosGet) {
      showAlertSnack(axiosError?.Messages[0], "error");
      return;
    }

    setEmergencyContactList(axiosGet);
  };

  const getRelationshipList = async () => {
    setIsLoading(true);
    const axiosGet = await getAsync<CatalogModel[]>(`Catalogs/GetSubCatalogsByCatalogName/${"Relationship"}`);
    setIsLoading(false);
    if (!axiosGet) {
      showAlertSnack(axiosError?.Messages[0], "error");
      return;
    }
    setRelationshipList(axiosGet);
  };

  const addNewEmergencyContact = async () => {
    setIsLoading(true);
    if (!emergencyContact.emergencyContactId) {
      emergencyContact.comments = otherRelationshipDescription;

      if (emergencyContact.address !== undefined) {
        if (emergencyContact.address.streetLine1 === undefined) {
          emergencyContact.address.streetLine1 = "";
        }
        if (emergencyContact.address.streetLine2 === undefined) {
          emergencyContact.address.streetLine2 = "";
        }
        if (emergencyContact.address.city === undefined) {
          emergencyContact.address.city = "";
        }
        if (!!emergencyContact.address.zipCode === undefined) {
          emergencyContact.address.zipCode = "";
        }
        if (emergencyContact.address.state === undefined) {
          emergencyContact.address.state = "";
        }
      }

      if (emergencyContact.address === undefined) {
        emergencyContact.address = {
          streetLine1: "",
          streetLine2: "",
          city: "",
          zipCode: "",
          state: "",
        };
      }

      const response = await postAsync(
        "EmergencyContact/AddEmergencyContact",
        emergencyContact
      );
      setIsLoading(false);

      if (!response) {
        showAlertSnack(axiosError?.Messages[0], "error");
        return;
      }

      showAlertSnack("Emergency Contact was created succesfully", "success");
    } else {
      emergencyContact.comments = isOtherSelected ? otherRelationshipDescription : "";
      const response = await postAsync(
        "EmergencyContact/UpdateEmergencyContact?emergencyContactId=" +
          emergencyContact.emergencyContactId,
        emergencyContact
      );
      setIsLoading(false);

      if (!response) {
        showAlertSnack(axiosError?.Messages[0], "error");
        return;
      }

      showAlertSnack("Emergency Contact was updated succesfully", "success");
    }
    handleCancelButton();
    setRefresh(true);
  };

  const handleCancelButton = () => {
    setEmergencyContact({ ...{}, patientId: currentPatient?.patientId || emptyGUID });
    setIsOtherSelected(false);
    setOtherRelationshipDescription("");
    setSelectedRelationship("");
    setAddresContact({});
    setHasUserName(false);
  };

  useEffect(() => {
    getRelationshipList();
  }, []);

  useEffect(() => {
    setEmergencyContact({ ...emergencyContact, patientId: currentPatient?.patientId || emptyGUID });
  }, [currentPatient]);

  useEffect(() => {
    if (!refresh || currentPatient === undefined) {
      return;
    }

    getEmergencyContactList(currentPatient.patientId || emptyGUID);
    setRefresh(false);
  }, [refresh, currentPatient]);

  useEffect(() => {
    if (emergencyContact) {
      if (
        !isEmpty(emergencyContact.userName?.trim()) &&
        !(emergencyContact.userName!.trim().length < 8)
      ) {
        const fetchData = async () => {
          const response = await getAsync<boolean>(
            "EmergencyContact/ValidateEmergencyContactUserName?emergencyContactUserName=" +
              emergencyContact.userName
          );

          setUserNameInUse(response || false);
        };

        fetchData();
      }
    }
  }, [emergencyContact.userName]);

  useEffect(() => {
    setIsOtherSelected(selectedRelationship === "Other");
  }, [selectedRelationship, isOtherSelected])
  

  let validationErrors = {
    ...(isEmpty(emergencyContact.firstName?.trim()) && {
      FirstName: "First name is required",
    }),
    ...(!isEmpty(emergencyContact.firstName?.trim()) &&
      emergencyContact.firstName!.trim().length < 3 && {
        FirstName: "First name must be 3 or more characters",
      }),
    ...(isEmpty(emergencyContact.lastName?.trim()) && {
      LastName: "Last name is required",
    }),
    ...(!isEmpty(emergencyContact.lastName?.trim()) &&
      emergencyContact.lastName!.trim().length < 3 && {
        LastName: "Last name must be 3 or more characters",
      }),
    ...(emergencyContact.isMainContact &&
      isEmpty(emergencyContact.userName?.trim()) && {
        UserName: "Username is required",
      }),
    ...(emergencyContact.isMainContact &&
      !isEmpty(emergencyContact.userName?.trim()) &&
      emergencyContact.userName!.trim().length < 8 && {
        UserName: "Username must be at least 8 characters long",
      }),
    ...(emergencyContact.isMainContact &&
      !isEmpty(emergencyContact.userName?.trim()) &&
      !(emergencyContact.userName!.trim().length < 8) &&
      !hasUserName &&
      userNameInUse && {
        UserName: "Username in use",
      }),
    ...(emergencyContact.isMainContact &&
      !hasUserName &&
      isEmpty(emergencyContact.password?.trim()) && {
        Password: "Password is required",
      }),
    ...(emergencyContact.isMainContact &&
      !hasUserName &&
      !isEmpty(emergencyContact.password?.trim()) &&
      emergencyContact.password!.trim().length < 8 && {
        Password: "Password must be at least 8 characters long",
      }),
    ...(isEmpty(emergencyContact.email?.trim()) &&
      isEmpty(emergencyContact.phoneNumber?.trim()) && {
        Email: "Email or Phone number is required",
        PhoneNumber: "Phone number or Email is required",
      }),
    ...(!isEmpty(emergencyContact.phoneNumber?.trim()) &&
      !phoneNumberValidator(emergencyContact.phoneNumber!.trim()) && {
        PhoneNumber: "Phone number must be number",
      }),
    ...(!isEmpty(emergencyContact.email?.trim()) &&
      !emailvalidator(emergencyContact.email!.trim()) && {
        Email: "Email is invalid",
      }),
    ...(!isEmpty(addresContact.zipCode?.trim()) &&
      !zipCodeValidator(addresContact.zipCode!.trim()) && {
        ZipCode: "Zip Code is invalid",
      }),
    ...(isEmpty(emergencyContact.relationshipId?.trim()) && {
      Relationship: "Relationship is required",
    }),
    ...(isEmpty(otherRelationshipDescription.trim()) &&
      selectedRelationship === "Other" && {
        OtherContact: "Relationship description required",
      }),
  };

  const isFormValid = isEmpty(validationErrors);

  const handleSetAddressTextField = (event: any) => {
    let fieldS = event.target.name.toString();
    let fieldAddress = { ...addresContact, [fieldS]: event.target.value };
    setAddresContact(fieldAddress);
    setEmergencyContact({ ...emergencyContact, address: fieldAddress });
  };

  const handleRelationshipChange = (event: any) => {
    const {value} = event.target;
    const catalog = relationshipList.filter((r) => r.catalogId === value);
    if (catalog && catalog.length > 0) {
      const { catalogName } = catalog[0];
      setSelectedRelationship(catalogName);
      setOtherRelationshipDescription("");
    } else {
      setSelectedRelationship("");
      setOtherRelationshipDescription("");
    }
    
    setEmergencyContact({
      ...emergencyContact,
      relationshipId: event.target.value,
    });
  };

  // Delete Emeregency Contact
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const onDeleteConfirmation = async () => {
    await deleteAsync('EmergencyContact/DeleteEmergencyContact', deleteEmergencyContactId || '');

    if (!axiosSuccess) {
      return;
    }

    showAlertSnack(
      "The emergency contact has been successfully removed.",
      "success"
    );

    // If the Emergency contact that I've deleten is the same that i'm modifying, clean the object
    if (
      emergencyContact &&
      deleteEmergencyContactId === emergencyContact.emergencyContactId
    ) {
      setEmergencyContact({ ...{}, patientId: currentPatient?.patientId || emptyGUID });
      setAddresContact({});
      setHasUserName(false);
    }

    setRefresh(true);
  };

  const closeConfirmModal = useCallback(() => {
    setOpenDeleteConfirmation(false);
  }, [setOpenDeleteConfirmation]);

  const ui_confirm = (
    <ConfirmationModalComponent
      open={openDeleteConfirmation}
      handleClose={closeConfirmModal}
      type="deletion"
      onConfirm={onDeleteConfirmation}
    />
  );

  const openConfirmModal = (emergencyContactId: string | undefined) => {
    setDeleteEmergencyContactId(emergencyContactId || "");
    setOpenDeleteConfirmation(true);
    return;
  };

  const openModificationData = async (
    emergencyContactId: string | undefined
  ) => {
    setIsLoading(true);
    const axiosGet = await getAsync<EmergencyContactModel>(
      "EmergencyContact/GetEmergencyContactById?emergencyContactId=" +
        emergencyContactId
    );
    setIsLoading(false);

    setRefresh(false);

    if (!axiosGet) {
      showAlertSnack(axiosError?.Messages[0], "error");
      return;
    }

    setEmergencyContact(axiosGet);
    setOtherRelationshipDescription(axiosGet.comments || "");
    setSelectedRelationship(axiosGet.relationship?.catalogName || "")
    setAddresContact(axiosGet?.address || {});

    if (axiosGet.userName && !isEmpty(axiosGet.userName?.trim())) {
      setHasUserName(true);
      return;
    }
    setHasUserName(false);
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const addressFormatter = (address: PatientAdress): string =>{
    const {streetLine1, streetLine2, city, state, zipCode} = address;
    if (streetLine1 !== "" || streetLine2 !== "" || city !== "" || state !== "" || zipCode) {

      const street1 = streetLine1 === undefined || streetLine1 === "" ? "-" : `${streetLine1},`;
      const street2 = streetLine2 === undefined  || streetLine2 === "" ? "" : `${streetLine2},`;
      const cityF = city === undefined  || city === "" ? "-," : `${city},`;
      const stateF = state === undefined  || state === "" ? "-," : `${state},`;
      const zip = zipCode === undefined  || zipCode === "" ? "-," : `${zipCode},`;

      const addr = `${street1}${street2}${cityF}${stateF}${zip}`

      let formattedAddress = addr.startsWith(",") ? addr.substring(1, addr.length): addr;
      formattedAddress = formattedAddress.endsWith(",") ? formattedAddress.substring(0, formattedAddress.length-1) : formattedAddress;
      
      return formattedAddress;
    }
    return "-"
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Box sx={{ width: "100%", height: "647", overflow: "auto" }}>
          {emergencyContactList &&
            emergencyContactList.map(
              (item: EmergencyContactModel, index: any) => {
                return (
                  <Card
                    sx={{ width: "100%", marginBottom: "10px" }}
                    key={index}
                  >
                    <CardContent>
                      <Typography
                        sx={{ fontSize: 14 }}
                        color="inherit"
                        gutterBottom
                      >
                        Name: {item.firstName} {item.lastName}
                      </Typography>
                      {item.isMainContact && (
                        <Typography
                          sx={{ fontSize: 14 }}
                          color="inherit"
                          gutterBottom
                        >
                          Username: {item.userName}
                        </Typography>
                      )}
                      {item.email && (
                        <Typography
                          sx={{ fontSize: 14 }}
                          color="inherit"
                          gutterBottom
                        >
                          Email: {item.email}
                        </Typography>
                      )}
                      {item.phoneNumber && (
                        <Typography
                          sx={{ fontSize: 14 }}
                          color="inherit"
                          gutterBottom
                        >
                          Phone Number: {item.phoneNumber}
                        </Typography>
                      )}
                      {item.relationship?.catalogName !== "Other" && (
                        <Typography
                          sx={{ fontSize: 14 }}
                          color="inherit"
                          gutterBottom
                        >
                          Relationship: {item.relationship?.catalogName}
                        </Typography>
                      )}
                      {item.relationship?.catalogName === "Other" && (
                        <Typography
                          sx={{ fontSize: 14 }}
                          color="inherit"
                          gutterBottom
                        >
                          Relationship (Other): {item.comments}
                        </Typography>
                      )}
                      <Typography
                        sx={{ fontSize: 14 }}
                        color="inherit"
                        gutterBottom
                      >
                        Address: {!!item.address ? addressFormatter(item.address): "-"}
                      </Typography>
                    </CardContent>
                    <CardActions style={{ textAlign: "right" }}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          {item.isMainContact && (
                            <Tooltip title="Main contact">
                              <IconButton
                                aria-label="Main"
                                style={{ color: colorsPalette.orange }}
                                disabled={isPatientInUse}
                              >
                                <StarIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                          <Tooltip title="Delete contact">
                            <IconButton
                              aria-label="Delete"
                              color="error"
                              onClick={() => {
                                openConfirmModal(item.emergencyContactId);
                              }}
                              disabled={isPatientInUse}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Edit contact">
                            <IconButton
                              aria-label="Edit"
                              color="info"
                              onClick={() => {
                                openModificationData(item.emergencyContactId);
                              }}
                              disabled={isPatientInUse}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </CardActions>
                  </Card>
                );
              }
            )}
        </Box>
        {ui_confirm}
      </Grid>
      <Grid item xs={6}>
        <Card sx={{ width: "100%" }}>
          <CardContent style={{ textAlign: "center" }}>
            <Typography sx={{ fontSize: 16 }} gutterBottom>
              {emergencyContact &&
                !emergencyContact.emergencyContactId &&
                "Add New Emergency Contact"}
              {emergencyContact &&
                emergencyContact.emergencyContactId &&
                "Edit Emergency Contact"}
            </Typography>
          </CardContent>
          <CardContent>
            <Grid container spacing={2} sx={{ width: "100%" }}>
              <Grid item xs={12}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={isPatientInUse}
                        checked={emergencyContact.isMainContact || false}
                        onChange={(event) =>
                          setEmergencyContact({
                            ...emergencyContact,
                            isMainContact: event.target.checked as boolean,
                            password: "",
                          })
                        }
                      />
                    }
                    label="Main Contact"
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="ECFirstName"
                  label="First name"
                  variant="outlined"
                  fullWidth
                  size="small"
                  inputProps={{
                    tabIndex: 100,
                    maxLength: 50,
                    minLength: 3,
                  }}
                  disabled={isPatientInUse}
                  value={emergencyContact.firstName || ""}
                  onChange={(event) =>
                    setEmergencyContact({
                      ...emergencyContact,
                      firstName: event.target.value as string,
                    })
                  }
                  error={!isPatientInUse && !!validationErrors.FirstName}
                  helperText={!isPatientInUse && validationErrors.FirstName}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="ECLastName"
                  label="Last name"
                  variant="outlined"
                  fullWidth
                  size="small"
                  inputProps={{
                    tabIndex: 101,
                    maxLength: 50,
                    minLength: 3,
                  }}
                  disabled={isPatientInUse}
                  value={emergencyContact.lastName || ""}
                  onChange={(event) =>
                    setEmergencyContact({
                      ...emergencyContact,
                      lastName: event.target.value as string,
                    })
                  }
                  error={!isPatientInUse && !!validationErrors.LastName}
                  helperText={!isPatientInUse && validationErrors.LastName}
                />
              </Grid>
              {emergencyContact && emergencyContact.isMainContact && (
                <Grid item xs={12}>
                  <TextField
                    id="ECUserName"
                    label="Username"
                    variant="outlined"
                    fullWidth
                    size="small"
                    inputProps={{
                      tabIndex: 102,
                      maxLength: 50,
                      autoComplete: "no",
                    }}
                    value={emergencyContact.userName || ""}
                    onChange={(event) =>
                      setEmergencyContact({
                        ...emergencyContact,
                        userName: event.target.value
                          .trim()
                          .replaceAll(" ", "") as string,
                      })
                    }
                    error={!!validationErrors.UserName}
                    helperText={validationErrors.UserName}
                    disabled={isPatientInUse || hasUserName}
                  />
                </Grid>
              )}
              {emergencyContact &&
                emergencyContact.isMainContact &&
                !hasUserName && (
                  <Grid item xs={12}>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      error={!!validationErrors.Password}
                      size="small"
                    >
                      <InputLabel htmlFor="outlined-adornment-password">
                        Password
                      </InputLabel>
                      <OutlinedInput
                        id="Password"
                        type={showPassword ? "text" : "password"}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Password"
                        onChange={(event) =>
                          setEmergencyContact({
                            ...emergencyContact,
                            password: event.target.value
                              .trim()
                              .replaceAll(" ", "") as string,
                          })
                        }
                        value={emergencyContact.password || ""}
                        inputProps={{
                          tabIndex: 103,
                          maxLength: 15,
                          minLength: 8,
                          autoComplete: "new-password",
                        }}
                        required
                        fullWidth
                        disabled={isPatientInUse}
                        autoComplete="off"
                      />
                      <FormHelperText>
                        {validationErrors.Password}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                )}
              <Grid item xs={12}>
                <TextField
                  id="ECEmail"
                  label="Email"
                  variant="outlined"
                  fullWidth
                  size="small"
                  inputProps={{
                    tabIndex: 104,
                    maxLength: 100,
                  }}
                  value={emergencyContact.email || ""}
                  onChange={(event) =>
                    setEmergencyContact({
                      ...emergencyContact,
                      email: event.target.value
                        .trim()
                        .replaceAll(" ", "") as string,
                    })
                  }
                  error={!isPatientInUse && !!validationErrors.Email}
                  helperText={!isPatientInUse && validationErrors.Email}
                  disabled={isPatientInUse}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="ECPhoneNumber"
                  label="Phone number"
                  variant="outlined"
                  fullWidth
                  size="small"
                  inputProps={{
                    tabIndex: 105,
                    maxLength: 30,
                  }}
                  value={emergencyContact.phoneNumber || ""}
                  onChange={(event) =>
                    setEmergencyContact({
                      ...emergencyContact,
                      phoneNumber: event.target.value as string,
                    })
                  }
                  error={!isPatientInUse && !!validationErrors.PhoneNumber}
                  helperText={!isPatientInUse && validationErrors.PhoneNumber}
                  disabled={isPatientInUse}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  error={!isPatientInUse && !!validationErrors.Relationship}
                  fullWidth
                  size="small"
                >
                  <InputLabel id="ECRelationship">Relationship</InputLabel>
                  <Select
                    id="CatalogId"
                    label="Relationship"
                    defaultValue={""}
                    displayEmpty
                    fullWidth
                    disabled={isPatientInUse}
                    labelId="ECRelationshipSelectLabel"
                    value={emergencyContact.relationshipId || ""}
                    onChange={handleRelationshipChange}
                    inputProps={{
                      tabIndex: 106,
                    }}
                    required
                    endAdornment={
                      emergencyContact.relationshipId &&
                      CleanButton(handleRelationshipChange)
                    }
                  >
                    {relationshipList.length === 0 && (
                      <MenuItem value="">No relationships found...</MenuItem>
                    )}
                    {relationshipList &&
                      relationshipList.map((item: CatalogModel) => (
                        <MenuItem value={item.catalogId} key={item.catalogId}>
                          {item.catalogName}
                        </MenuItem>
                      ))}
                  </Select>
                  <FormHelperText>
                    {!isPatientInUse && validationErrors.Relationship}
                  </FormHelperText>
                </FormControl>
              </Grid>
              {isOtherSelected && selectedRelationship === "Other" && (
                <Grid item xs={12}>
                  <FormControl
                    error={!isPatientInUse && !!validationErrors.OtherContact}
                    fullWidth
                    size="small"
                  >
                    <TextField
                      id="ECOtherRelationship"
                      label="Specify the relationship"
                      variant="outlined"
                      fullWidth
                      size="small"
                      inputProps={{
                        tabIndex: 100,
                        maxLength: 50,
                        minLength: 3,
                      }}
                      disabled={
                        isPatientInUse || selectedRelationship !== "Other"
                      }
                      value={otherRelationshipDescription || ""}
                      onChange={(event) => setOtherRelationshipDescription(event.target.value)}
                      error={!isPatientInUse && !!validationErrors.OtherContact}
                      helperText={
                        !isPatientInUse && validationErrors.OtherContact
                      }
                    />
                  </FormControl>
                </Grid>
              )}
              <Grid item xs={12}>
                <TextField
                  name="streetLine1"
                  label="Address"
                  variant="outlined"
                  fullWidth
                  size="small"
                  inputProps={{
                    tabIndex: 107,
                    maxLength: 100,
                  }}
                  value={addresContact.streetLine1 || ""}
                  onChange={handleSetAddressTextField}
                  disabled={isPatientInUse}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="streetLine2"
                  label="Address 2"
                  variant="outlined"
                  fullWidth
                  size="small"
                  inputProps={{
                    tabIndex: 108,
                    maxLength: 100,
                  }}
                  value={addresContact.streetLine2 || ""}
                  onChange={handleSetAddressTextField}
                  disabled={isPatientInUse}
                />
              </Grid>
              <Grid item xs={7}>
                <TextField
                  name="city"
                  label="City"
                  variant="outlined"
                  fullWidth
                  size="small"
                  inputProps={{
                    tabIndex: 109,
                    maxLength: 50,
                  }}
                  value={addresContact.city || ""}
                  onChange={handleSetAddressTextField}
                  disabled={isPatientInUse}
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  name="zipCode"
                  label="Zip Code"
                  variant="outlined"
                  fullWidth
                  size="small"
                  inputProps={{
                    tabIndex: 110,
                    maxLength: 50,
                  }}
                  value={addresContact.zipCode || ""}
                  onChange={handleSetAddressTextField}
                  error={!isPatientInUse && !!validationErrors.ZipCode}
                  helperText={!isPatientInUse && validationErrors.ZipCode}
                  disabled={isPatientInUse}
                />
              </Grid>
              <Grid item xs={7}>
                <TextField
                  name="state"
                  label="State"
                  variant="outlined"
                  fullWidth
                  size="small"
                  inputProps={{
                    tabIndex: 111,
                    maxLength: 50,
                  }}
                  value={addresContact.state || ""}
                  onChange={handleSetAddressTextField}
                  disabled={isPatientInUse}
                />
              </Grid>
              <Grid item xs={12} style={{ textAlign: "right" }}>
                <Button
                  variant="contained"
                  color="success"
                  style={{ marginRight: "10px" }}
                  disabled={isPatientInUse || (!isFormValid && !isLoading)}
                  onClick={addNewEmergencyContact}
                >
                  {emergencyContact &&
                    emergencyContact.emergencyContactId &&
                    "SAVE CONTACT"}
                  {emergencyContact &&
                    !emergencyContact.emergencyContactId &&
                    "ADD CONTACT"}
                </Button>
                <Button
                  variant="contained"
                  color="inherit"
                  onClick={handleCancelButton}
                  disabled={isPatientInUse}
                >
                  CANCEL
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default EmergencyContact;
