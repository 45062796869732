import { AddCircle } from "@mui/icons-material";
import { Button, Grid, Box, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { PatientMedicationsModel } from "../../../Api/Model/PatientMedications";
import { useAxios } from "../../../hooks/useAxios";
import { PatientInfoComponentParams } from "../../../Shared/PatientInfoComponentParams";
import { PatientMedicationRow } from "./PatientMedicationRow.component";
import { PatientContext } from "../../../Context/PatientContext";
import { emptyGUID } from "../../../constants/guid.contast";

const compareMedications = (o1: Array<PatientMedicationsModel>, o2: Array<PatientMedicationsModel>) => {
    const sameLength = Object.keys(o1).length === Object.keys(o2).length;
    const sameKeyValuePairs = Object.keys(o1).every((p:any) =>{
        const medication1 = o1[p];
        const medication2 = o2[p];

        return medication1?.medication === medication2?.medication &&
            medication1?.frequency === medication2?.frequency &&
            medication1?.dosage === medication2?.dosage &&
            medication1?.route === medication2?.route &&
            medication1?.notes === medication2?.notes &&
            medication1?.medicationStatus === medication2?.medicationStatus;
    });
    return sameLength && sameKeyValuePairs;
}

export const PatientMedications = (params: PatientInfoComponentParams) => {
    const { showAlertSnack, isPatientInUse, manageErrorAlert } = params;
    const { postAsync, getAsync, axiosError, axiosSuccess } = useAxios();
    const [aMedicationHasAnError, setAMedicationHasAnError] = useState<boolean>(false);
    const [thereIsAnEmptyMedication, setThereIsAnEmptyMedication] = useState<boolean>(false);
    const [ambiguousMedication, setAmbiguousMedication] = useState<PatientMedicationsModel | undefined>(undefined);
    const [storedMedications, setStoredMedications] = useState<PatientMedicationsModel[]>([]);
    const [medicationsAreDirty, setMedicationsAreDirty] = useState<boolean>(false);

    const [medication1, setMedication1] = useState<PatientMedicationsModel | undefined>();
    const [medication2, setMedication2] = useState<PatientMedicationsModel | undefined>();
    const [medication3, setMedication3] = useState<PatientMedicationsModel | undefined>();
    const [additionalPatientMedications, setAdditionalPatientMedications] = useState<PatientMedicationsModel[]>([]);
    
    const { currentPatient, patientIsReady } = useContext(PatientContext);

    const getPatientMedications = async (patientId:string) => {
        if (!patientId) {
            return;
        }
        const medicationResults = await getAsync<PatientMedicationsModel[]>(`PatientMedication/GetPatientMedicationsById?patientID=${patientId}`);
        if (!medicationResults) {
            return;
        }

        let [medication1, medication2, medication3] = medicationResults;

        if (medication1) {
            medication1.hasError = false;
        }
        if (medication2) {
            medication2.hasError = false;
        }
        if (medication3) {
            medication3.hasError = false;
        }

        setThereIsAnEmptyMedication(medicationResults.length < 3);

        let otherMedications = medicationResults.slice(3);

        setMedication1(medication1);
        setMedication2(medication2);
        setMedication3(medication3);

        setAdditionalPatientMedications(otherMedications);

        const allMedications = [medication1, medication2, medication3, ...otherMedications].filter(m => m !== undefined);
        setStoredMedications(JSON.parse(JSON.stringify(allMedications)));
        setAMedicationHasAnError(false);
        setAmbiguousMedication(undefined);
        setMedicationsAreDirty(false);
    };

    const addMedicationHandler = (event: any) => {
        setThereIsAnEmptyMedication(true);
        setAMedicationHasAnError(true);
        setAdditionalPatientMedications([
            ...additionalPatientMedications,
            { medication: "", frequency: "", dosage: "", route: "", patientMedicationId: "", patientId: currentPatient?.patientId || emptyGUID, notes:"",medicationStatus:"Active", hasError: true },
        ]);
    };

    const removeMedicationHandler = (index: number) => {
        const dataAdditionals = [...additionalPatientMedications];
        dataAdditionals.splice(index, 1);
        setAdditionalPatientMedications(dataAdditionals);
        triggerEffect([medication1, medication2, medication3, ...dataAdditionals]);
    }

    const saveMedicationsHandler = async (event: any) => {
        const patientId = currentPatient?.patientId || emptyGUID;
        if (!patientId) {
            return;
        }

        let patientMedications: PatientMedicationsModel[] = [];
        if (medication1) {
            patientMedications.push(medication1);
        }
        if (medication2) {
            patientMedications.push(medication2);
        }
        if (medication3) {
            patientMedications.push(medication3);
        }

        patientMedications = [...patientMedications, ...additionalPatientMedications];

        patientMedications.forEach(medication => {
            medication.patientId = patientId;
            medication.patientMedicationId = medication.patientMedicationId === "" ? undefined : medication.patientMedicationId;
        });

        const result = await postAsync<unknown>(`PatientMedication/SetPatientMedications/${patientId}`, patientMedications);
        if (result && axiosSuccess) {
            setStoredMedications(JSON.parse(JSON.stringify(patientMedications)));
            setMedicationsAreDirty(false);
            showAlertSnack("Medications were modified successfully.", "success");
        }
        else {
            manageErrorAlert(axiosError?.Messages[0]);
        }
    };

    useEffect(() => {
        if(currentPatient === undefined || !patientIsReady)
        {
            return;
        }
        getPatientMedications(currentPatient.patientId || emptyGUID);
    }, [patientIsReady]);

    const fixedMedicationsHandler = (medication: PatientMedicationsModel | undefined, index: number) => {
        switch (index) {
            case 0:
                setMedication1(medication);
                const allMedications = [medication, medication2, medication3, ...additionalPatientMedications];
                triggerEffect(allMedications);
                break;
            case 1:
                setMedication2(medication);
                const allMedications2 = [medication1, medication, medication3, ...additionalPatientMedications];
                triggerEffect(allMedications2);
                break;
            case 2:
                setMedication3(medication);
                const allMedications3 = [medication1, medication2, medication, ...additionalPatientMedications];
                triggerEffect(allMedications3);
                break;
        }
    }

    const additionalMedicationsHandler = (index: number, medication: PatientMedicationsModel | undefined) => {
        setAdditionalPatientMedications(prevState => {
            if (prevState.length === 0) {
                return [medication || { medication: "", frequency: "", dosage: "", route: "",notes:"",medicationStatus:"Active", patientMedicationId: "", patientId: currentPatient?.patientId || emptyGUID }];
            }

            const prevLength = prevState.length;
            if (index > prevLength || medication === undefined) {
                return [...prevState];
            }

            prevState[index] = medication;

            const allMedications = [medication1, medication2, medication3, ...prevState];
            triggerEffect(allMedications);

            return [...prevState];
        })
    }

    const triggerEffect = (allMedications: Array<PatientMedicationsModel | undefined>) => {
        const filteredMedications: Array<PatientMedicationsModel> = allMedications.filter(m => m !== undefined) as Array<PatientMedicationsModel>;
        const medicationsAreDirty = !compareMedications(storedMedications, filteredMedications);
        setMedicationsAreDirty(medicationsAreDirty);

        const anyHasAnError = allMedications.some(medication => medication?.hasError);
        setAMedicationHasAnError(anyHasAnError);

        const anyIsEmpty = allMedications.some(medication => !medication?.medication || !medication?.frequency || !medication?.dosage || !medication?.route);
        setThereIsAnEmptyMedication(anyIsEmpty);

        const duplicates = allMedications
            .filter(m => m !== undefined)
            .filter(m => m?.medication.trim() !== "")
            .filter((m, index, self) => self.findIndex(m2 => m2?.medication.toUpperCase().trim() === m?.medication.toUpperCase().trim()) !== index);
        setAmbiguousMedication(duplicates[0]);
    }

    return <>
        <PatientMedicationRow
            isFixedRow
            patientId={currentPatient?.patientId || emptyGUID}
            disabled={isPatientInUse}
            medication={medication1}
            onMedicationChange={(medication: PatientMedicationsModel | undefined) => fixedMedicationsHandler(medication, 0)}
        />
        <PatientMedicationRow
            isFixedRow
            patientId={currentPatient?.patientId || emptyGUID}
            disabled={isPatientInUse}
            medication={medication2}
            onMedicationChange={(medication: PatientMedicationsModel | undefined) => fixedMedicationsHandler(medication, 1)}
        />
        <PatientMedicationRow
            isFixedRow
            patientId={currentPatient?.patientId || emptyGUID}
            disabled={isPatientInUse}
            medication={medication3}
            onMedicationChange={(medication: PatientMedicationsModel | undefined) => fixedMedicationsHandler(medication, 2)}
        />
        {additionalPatientMedications.map((patientMedication, index) =>
            <PatientMedicationRow
                isFixedRow={false}
                key={patientMedication?.patientMedicationId || index}
                patientId={currentPatient?.patientId || emptyGUID}
                disabled={isPatientInUse}
                medication={patientMedication}
                onRemoveMedication={() => removeMedicationHandler(index)}
                onMedicationChange={(medication: PatientMedicationsModel | undefined) => additionalMedicationsHandler(index, medication)}
            />
        )}
        {!!ambiguousMedication && <Box sx={{ width: '100%', pr: 2, pl: 4 }}>
            <Typography variant="caption" color="error" sx={{ p: 0, textAlign: 'center', width: '100%', display: 'block' }}>Medication {ambiguousMedication.medication} is duplicated, please check and try again.</Typography>
        </Box>}
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Button
                disabled={isPatientInUse || aMedicationHasAnError || thereIsAnEmptyMedication || !!ambiguousMedication}
                onClick={addMedicationHandler}
                sx={{
                    width: 3 / 10,
                    height: 40,
                    marginBlock: 2,
                    fontWeight: "bold",
                    textTransform: "capitalize",
                }}
                color="success"
                variant="contained"
            >
                <AddCircle /> Add Medication
            </Button>
        </Box>
        <Grid
            container
            spacing={1}
            rowSpacing={1}
            justifyContent="end"
            alignItems="center"
            marginTop={1}
        >
            <Grid item xs={1}>
                <Button
                    disabled={isPatientInUse || aMedicationHasAnError || !medicationsAreDirty || !!ambiguousMedication}
                    onClick={saveMedicationsHandler}
                    color="success"
                    variant="contained"
                    fullWidth
                >
                    Save
                </Button>
            </Grid>
            <Grid item xs={1}>
                <Button
                    disabled={isPatientInUse}
                    onClick={() => medicationsAreDirty && getPatientMedications(currentPatient?.patientId || emptyGUID)}
                    color="secondary"
                    variant="contained"
                    fullWidth
                >
                    Cancel
                </Button>
            </Grid>
        </Grid>
    </>;
};
