import { Navigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { useFirebaseMessaging } from "../../hooks/useFirebaseMessaging";
import { getSessionUser } from "../../Shared/sessionUser.provider";

const cookies = new Cookies();

const LogOut = () => {
    cookies.remove("sessionUser", { path: "/" });   
    sessionStorage.clear();
}

const LogOutComponent = (props: any) => {
    const currentUser = getSessionUser();
    const { removeToken } = useFirebaseMessaging();
    removeToken(currentUser.id || '')
    LogOut();
    return <Navigate to="/" />
}

export default LogOutComponent;