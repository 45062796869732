import { Box, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import ProviderAlertsModel from '../../Api/Model/DepartmentStructure/ProviderAlertsModel';
import { ThresholdDefaultModel } from '../../Api/Model/ThresholdDefaultModel';
import { CustomDialog } from '../Shared/Modal/Modal.component';
import { CleanButton, handleClearSelect } from '../../Shared/CleanHandler.provider';
import { useAxios } from '../../hooks/useAxios';

interface ProviderAlertsModalProps {
    provider: ProviderAlertsModel;
    open: boolean;
    handleClose: Function;
    onConfirm: (alertDefault: ThresholdDefaultModel) => void;
    alertDeafaultParam?: ThresholdDefaultModel;
}

interface ThresholdKeyType {
    id: string;
    name: string;
    subId?: string;
}

const ProviderAlertsModal = (props: ProviderAlertsModalProps) => {
    const closeModal = () => props.handleClose();
    const onConfirm = async () => {
        closeModal();
        !!props.onConfirm && props.onConfirm(alertDefault);
    };

    const [valuesError, setValuesError] = useState({ both: false, minimum: false, maximum: false });
    const [alertDefault, setAlertDefault] = useState<ThresholdDefaultModel>(
        { limitValues: [{ maximum: 1, minimum: 0 }] } as ThresholdDefaultModel
    );
    const [deviceTypes, setDeviceTypes] = useState<ThresholdKeyType[]>([]);
    const { getAsync } = useAxios();
    const [measurementTypes, setMeasurementTypes] = useState<ThresholdKeyType[]>([]);
    const [thresholdsDefaults, setThresholdsDefaults] = useState<ThresholdDefaultModel[]>([]);
    const getThresholdDefaults = async () => {
        const axiosGet = await getAsync<ThresholdDefaultModel[]>("ManagementAlerts/GetAlertsDefaults");
        if (!axiosGet) {
            return;
        }
        setThresholdsDefaults(axiosGet);
    };

    useEffect(() => {
        getThresholdDefaults();
    }, []);

    useEffect(() => {
        if (thresholdsDefaults && thresholdsDefaults.length > 0) {
            const deviceTypes = thresholdsDefaults.map((x) => {
                return {
                    id: x.deviceTypeId,
                    name: x.deviceName,
                } as ThresholdKeyType;
            }).filter((device, index, self) =>
                index === self.findIndex((p) => p.name === device.name && p.id === device.id)
            );
            setDeviceTypes(deviceTypes);
        }
    }, [thresholdsDefaults]);

    useEffect(() => {
        if (!props.open) {
            return;
        }        

        const measurementTypes = thresholdsDefaults.map((x) => {
            return {
                id: x.alertId,
                name: x.measurementName,
                subId: x.deviceTypeId,
            } as ThresholdKeyType;
        });
        setMeasurementTypes(measurementTypes);

        if (!!props.alertDeafaultParam) {
            setAlertDefault({
                ...props.alertDeafaultParam,
                measurementName: props.alertDeafaultParam.measurementName,
            });
        } else {
            setAlertDefault({
                limitValues: [{ maximum: 0, minimum: 0 }],
            } as ThresholdDefaultModel);
        }
    }, [props.open]);

    useEffect(() => {
        setValuesError({ minimum: (+1 > +alertDefault?.limitValues[0].minimum), maximum: (+1 > +alertDefault?.limitValues[0].maximum), both: (+alertDefault.limitValues[0].maximum <= +alertDefault?.limitValues[0].minimum) });
    }, [alertDefault.limitValues]);

    const validationErrors = {
        ...((!alertDefault || !alertDefault.limitValues || isEmpty(alertDefault.limitValues[0].minimum?.toString())) && {
            Minimum: "Minimum value is required with valid numbers",
        }),
        ...((!alertDefault || !alertDefault.limitValues || isEmpty(alertDefault.limitValues[0].maximum?.toString())) && {
            Maximum: "Maximum value is required with valid numbers",
        }),
        ...((!alertDefault || !alertDefault.limitValues || isNaN(alertDefault.limitValues[0].minimum)) && {
            Minimum: "Minimum must have numerical values",
        }),
        ...((!alertDefault || !alertDefault.limitValues || isNaN(alertDefault.limitValues[0].maximum)) && {
            Maximum: "Maximum must have numerical values",
        }),
        ...(alertDefault.limitValues && valuesError.both && {
            Maximum: "This value must be greater than the minimun value",
            Minimum: "This value must be lower than the maximum value",
        }),
        ...(alertDefault.limitValues && valuesError.minimum && {
            Minimum: "This value must be at least 1",
        }),
        ...(alertDefault.limitValues && valuesError.maximum && {
            Maximum: "This value must be at least 1",
        }),
        ...(isEmpty(alertDefault.measurementName?.trim()) && {
            MeasurementName: "Measurement is required",
        }),
        ...(isEmpty(alertDefault.deviceName?.trim()) && {
            DeviceName: "Device is required",
        }),
    };

    const handleSetMaximumOrMinimum = (event: any) => {
        if (
            (!(typeof +event.target.value === "number") ||
                !(+event.target.value === +event.target.value) ||
                !(+event.target.value > 0) && event.target.value !== "")
        ) {
            return;
        }
        let fieldS = event.target.id.toString();
        if (fieldS === "maximum") {
            setAlertDefault((dc: ThresholdDefaultModel) => {
                return {
                    ...dc,
                    limitValues: [
                        { minimum: dc.limitValues[0].minimum, maximum: event.target.value },
                    ],
                };
            });
            return;
        }
        setAlertDefault((dc: ThresholdDefaultModel) => {
            return {
                ...dc,
                limitValues: [
                    { maximum: dc.limitValues[0].maximum, minimum: event.target.value },
                ],
            };
        });
    };
    const handleDeviceTypeChange = (event: any) => {
        const deviceType = thresholdsDefaults.find((x) => x.deviceName === event.target.value);

        const measurementTypes = thresholdsDefaults.map((x) => {
            return {
                id: x.alertId,
                name: x.measurementName,
                subId: x.deviceTypeId,
            } as ThresholdKeyType;
        }).filter((x) => x.subId === deviceType?.deviceTypeId);

        setMeasurementTypes(measurementTypes);

        setAlertDefault((dc: ThresholdDefaultModel) => {
            return { ...dc, deviceName: event.target.value, deviceTypeId: deviceType?.deviceTypeId };
        });
        handleClearSelect(handleMeasurementTypeChange);
    };
    const handleMeasurementTypeChange = (event: any) => {
        const measurement = thresholdsDefaults.find(t => t.measurementName === event.target.value);
        setAlertDefault((dc: ThresholdDefaultModel) => {
            return {
                ...dc,
                alertId: measurement?.alertId,
                measurementName: event.target.value as string
            };
        });
    };

    return (
        <CustomDialog
            isOkButtonDisabled={!isEmpty(validationErrors)}
            open={props.open}
            onConfirm={onConfirm}
            handleClose={closeModal}
            title={!!props.alertDeafaultParam ? "Update " + alertDefault.measurementName + " Alert Default" : "Add Alert Default"}
            size="xs"
        >
            <Box sx={{ marginTop: 2 }}>
                <FormControl sx={{ mb: 4 }} fullWidth>
                    <InputLabel id="DeviceTypeCombo" color="primary">
                        Device Type
                    </InputLabel>
                    <Select
                        id="DeviceType"
                        fullWidth
                        label=" Device Type"
                        defaultValue={""}
                        displayEmpty
                        multiple={false}
                        disabled={(props.alertDeafaultParam !== undefined)}
                        error={!!validationErrors.DeviceName}
                        native={false}
                        onChange={handleDeviceTypeChange}
                        labelId="DeviceTypeCombo"
                        value={alertDefault.deviceName || ""}
                        required
                        endAdornment={
                            alertDefault.deviceName && 
                            CleanButton(handleDeviceTypeChange)
                          }
                    >
                        {deviceTypes.map((deviceCat: any) => {
                            return (
                                <MenuItem
                                    value={deviceCat.name}
                                    key={deviceCat.id}
                                >
                                    {deviceCat.name}
                                </MenuItem>
                            )
                        })
                            .filter((item) => item !== null)}
                    </Select>
                    <FormHelperText error={!!validationErrors.DeviceName}>{validationErrors.DeviceName}</FormHelperText>
                </FormControl>
                <FormControl sx={{ mb: 4 }} fullWidth>
                    <InputLabel id="MeasurementTypeCombo" color="primary">
                        Measurement Type
                    </InputLabel>
                    <Select
                        id="MeasurementName"
                        fullWidth
                        label="Measurement Type"
                        defaultValue={""}
                        displayEmpty
                        disabled={(props.alertDeafaultParam !== undefined)}
                        multiple={false}
                        error={!!validationErrors.MeasurementName}
                        native={false}
                        labelId="MeasurementTypeCombo"
                        value={alertDefault.measurementName || ""}
                        onChange={handleMeasurementTypeChange}
                        required
                        endAdornment={
                          !!alertDefault.measurementName && (
                            CleanButton(handleMeasurementTypeChange)
                          )
                        }
                    >
                        {measurementTypes.map((measurementCat: any) => {
                            const providerAlerts = props.provider.alerts && props.provider.alerts.length > 0 && props.provider.alerts
                                .find((item) => item.name === measurementCat.name);                            

                            return (
                                <MenuItem
                                    disabled={!!providerAlerts}
                                    value={measurementCat.name}
                                    key={measurementCat.id}
                                >
                                    {measurementCat.name}
                                </MenuItem>
                            )
                        })}
                    </Select>
                    <FormHelperText error={!!validationErrors.MeasurementName}>{validationErrors.MeasurementName}</FormHelperText>
                </FormControl>
                <TextField
                    id="minimum"
                    name="minimum"
                    type={"tel"}
                    onChange={handleSetMaximumOrMinimum}
                    label="Minimum"
                    className="mb-4"
                    InputLabelProps={{ shrink: true }}
                    value={
                        alertDefault.limitValues
                            ? alertDefault.limitValues[0].minimum
                            : ""
                    }
                    error={!!validationErrors.Minimum}
                    helperText={validationErrors.Minimum}
                    variant="outlined"
                    required
                    fullWidth
                    inputProps={{
                        tabIndex: 0,
                        maxLength: 6,
                        minLength: 1,
                        lang: "en-US"
                    }}
                    autoComplete="off"
                />
                <TextField
                    id="maximum"
                    name="maximum"
                    type={"tel"}
                    onChange={handleSetMaximumOrMinimum}
                    value={
                        alertDefault.limitValues
                            ? alertDefault.limitValues[0].maximum
                            : ""
                    }
                    label="Maximum"
                    error={!!validationErrors.Maximum}
                    helperText={validationErrors.Maximum}
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    inputProps={{
                        tabIndex: 1,
                        maxLength: 6,
                        minLength: 1,
                        lang: "en-US"
                    }}
                    required
                    fullWidth
                    autoComplete="off"
                />
            </Box>
        </CustomDialog>
    );
};

export default ProviderAlertsModal;