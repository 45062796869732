import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  OutlinedInput,
  Paper,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import FlagOIcon from "@mui/icons-material/FlagOutlined";
import WarningIcon from "@mui/icons-material/Warning";
import { useCallback, useEffect, useState } from "react";
import { SystemUpdateAlt } from "@mui/icons-material";
import { useAxios } from "../../hooks/useAxios";
import { AlertHistoryModal } from "./AlertHistoryModal.component";
import { PatientAlertModel } from "../../Api/Model/Patient/PatientAlerts/PatientAlertModel";
import PrismLoading from "../Shared/Loading/Loading.component";
import { AlertHistoryElement } from "./AlertHistoryElement.component";
import { downloadFileResponse } from "../../Shared/Files.provider";
import { CleanButton } from "../../Shared/CleanHandler.provider";
import PageWrapper from "../Shared/Wrapper/PageWrapper.component";
import { colorsPalette } from "../../constants/styles.constants";
import { PatientNoteModel } from "../../Api/Model/PatientNoteModel";

const styles = {
  dashboardCard: {
    color: colorsPalette.white,
    fontSize: 14,
    paddingLeft: 2,
  },
  searchButtons: {
    width: 180,
    height: 30,
    fontSize: 12,
    textAlign: "center",
    marginBottom: 1,
    textJustify: "center",
    display: "inline-block",
    verticalAlign: "top",
  },
};

type PatientReadingsType = {
  patientId: string | undefined;
  isInUse: boolean;
  showAlertSnack: Function;
  manageErrorAlert: Function;
};

const AlertHistory = (props: PatientReadingsType) => {
  const [searchText, setSearchText] = useState("");
  const [redFlagCount, setRedFlagCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [cautionAlertCount, setCautionAlertCount] = useState(0);
  const [complianceAlertCount, setComplianceAlertCount] = useState(0);
  const [patientAlerts, setPatientAlerts] = useState<PatientAlertModel[]>([]);
  const [currentPatientAlert, setCurrentPatientAlert] = useState<PatientAlertModel>({});
  const { getAsync, updateAsync, axiosError, axiosSuccess, postAsync } = useAxios();
  const { downloadFileAsync, axiosLoading } = useAxios();

  const handleDownload = async () => {
    const response = await downloadFileAsync(`PatientAlerts/DownloadPatientAlerts`, `patientId=${props.patientId}`);
    downloadFileResponse(
      response,
      `Patient-Alerts-${props.patientId}.xlsx`
    );
  }

  const getPatientAlerts = async () => {
    if (!props.patientId) {
      return;
    }
    setLoading(true);
    const axiosGet = await getAsync<PatientAlertModel[]>(
      `PatientAlerts/GetPatientAlertsByPatientId?patientId=${props.patientId}`
    );
    setLoading(false);
    if (!axiosGet) {
      setPatientAlerts([]);
      return;
    }
    setRedFlagCount(axiosGet.filter((el) => el.flagType === "Red").length);
    setCautionAlertCount(
      axiosGet.filter((el) => el.flagType === "Yellow").length
    );
    setComplianceAlertCount(
      axiosGet.filter((el) => el.flagType === "Danger").length
    );
    setPatientAlerts(
      searchText.trim() != ""
        ? axiosGet.filter((a) =>
          a.alertBody?.toLowerCase().includes(searchText.trim().toLowerCase())
        )
        : axiosGet
    );
  };

  useEffect(() => {
    const timeOutId = setTimeout(() => getPatientAlerts(), 500);
    return () => clearTimeout(timeOutId);
  }, [searchText]);

  const searchTextHandler = (event: any) => setSearchText(event.target.value);

  const [openPatientAlertsModal, setOpenPatientAlertsModal] = useState(false);

  const handleSearchClear = () => {
    setSearchText("");
  };

  const PatientAlertEditHandler = (event: number): void => {
    let selectedPatientAlert = patientAlerts[event];
    setCurrentPatientAlert(selectedPatientAlert);
    setOpenPatientAlertsModal(true);
  };

  const closePatientAlertModal = useCallback(() => {
    setOpenPatientAlertsModal(false);
  }, [setOpenPatientAlertsModal]);

  const onPatientAlertModalConfirm = async (
    patientAlert: PatientAlertModel
  ) => {
    patientAlert.status = true;
    await updateAsync(
      "PatientAlerts/UpdatePatientAlerts",
      patientAlert.patientAlertsId || "",
      patientAlert
    );
    if (!axiosSuccess) {
      props.manageErrorAlert(axiosError?.Messages[0]);
      return;
    }

    let newNote: PatientNoteModel = {
      patientId: patientAlert.patientId,
      note: patientAlert.alertBody,
    };

    await postAsync("PatientInfo/AddPatientNote", newNote);
    if (!axiosSuccess) {
      props.manageErrorAlert(axiosError?.Messages[0]);
      return;
    }
    props.showAlertSnack("The alert was edited successfully.", "success");
    getPatientAlerts();
  };

  const ui_patientalert_modal = (
    <AlertHistoryModal
      open={openPatientAlertsModal}
      handleClose={closePatientAlertModal}
      onConfirm={onPatientAlertModalConfirm}
      patientAlertParam={currentPatientAlert}
    />
  );

  const loadingComponent = <PrismLoading Size={80} Text={"Loading alerts"} />;

  const noDataComponent = (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        opacity: 0.5,
        height: "100%",
      }}
    >
      No alerts to display
    </Box>
  );
  return (
    <PageWrapper>
      <Grid container spacing={1} columnSpacing={1}>
        <Grid item xs={7}>
          <Typography variant="h4" gutterBottom>
            Alert History
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <FormControl fullWidth>
            <InputLabel htmlFor="outlined-adornment-amount">
              Search
            </InputLabel>
            <OutlinedInput
              size="small"
              id="outlined-adornment-amount"
              startAdornment={<SearchIcon color="action"></SearchIcon>}
              label="Amount"
              value={searchText}
              onChange={searchTextHandler}
                endAdornment={
                    searchText !== "" && (
                        CleanButton(searchTextHandler)
                    )
                }
            />
          </FormControl>
        </Grid>
        <Grid
          item
          xs={7}
          sx={{ marginBottom: 2, marginTop: 5, display: "flex" }}
        >
          <Paper
            sx={{
              bgcolor: colorsPalette.red,
              minWidth: 150,
              width: 200,
              height: "68px",
            }}
          >
            <Box sx={styles.dashboardCard}>Red Flags Alerts</Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  paddingLeft: 2,
                  display: "inline-flex",
                  color: colorsPalette.white,
                  fontSize: 34,
                  fontWeight: "medium",
                }}
                style={{ textAlign: "center" }}
              >
                {redFlagCount}
              </Box>
              <FlagOIcon
                sx={{
                  display: "inline-flex",
                  width: 50,
                  height: 50,
                  marginBottom: 2,
                  paddingBottom: 1,
                }}
                htmlColor={colorsPalette.white}
              />
            </Box>
          </Paper>
          <Paper
            sx={{
              bgcolor: colorsPalette.yellow,
              minWidth: 150,
              width: 200,
              height: "68px",
              marginLeft: 1,
            }}
          >
            <Box sx={{ ...styles.dashboardCard, color: colorsPalette.dark }}>
              Caution Alerts
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  paddingLeft: 2,
                  display: "inline-flex",
                  color: colorsPalette.dark,
                  fontSize: 34,
                  fontWeight: "medium",
                }}
                style={{ textAlign: "center" }}
              >
                {cautionAlertCount}
              </Box>
              <FlagOIcon
                sx={{
                  display: "inline-flex",
                  width: 50,
                  height: 50,
                  marginBottom: 2,
                  paddingBottom: 1,
                }}
                htmlColor={colorsPalette.dark}
              />
            </Box>
          </Paper>
          <Paper
            sx={{
              bgcolor: colorsPalette.orange,
              minWidth: 150,
              width: 200,
              height: "68px",
              marginLeft: 1,
            }}
          >
            <Box sx={styles.dashboardCard}>Compliance Alerts </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  paddingLeft: 2,
                  display: "inline-flex",
                  color: colorsPalette.white,
                  fontSize: 34,
                  fontWeight: "medium",
                }}
                style={{ textAlign: "center" }}
              >
                {complianceAlertCount}
              </Box>
              <WarningIcon
                sx={{
                  display: "inline-flex",
                  alignSelf: "end",
                  justifyContent: "end",
                  width: 50,
                  height: 50,
                  marginRight: 1,
                  marginBottom: 2,
                  paddingBottom: 1,
                }}
                htmlColor={colorsPalette.white}
              />
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={5} sx={{ marginBottom: 3, marginTop: 5 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "right",
              width: 1,
              height: "68px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Button
                disabled={axiosLoading || patientAlerts.length < 1}
                aria-label="edit"
                size="small"
                variant="contained"
                color="primary"
                sx={{ fontWeight: 900 }}
                onClick={handleDownload}
              >
                <SystemUpdateAlt sx={{ marginRight: "5px" }} />
                {!axiosLoading ? "Download History" : 'Downloading...'}
              </Button>
            </Box>
          </Box>
        </Grid>
        {loading ? (
          loadingComponent
        ) : patientAlerts && patientAlerts.length > 0 ? (
          patientAlerts.map((input: PatientAlertModel, index: any) => (
            <AlertHistoryElement
              key={"PatientAlert" + index}
              index={index}
              input={input}
              isAllowedToModify={props.isInUse}
              PatientAlertEditHandler={PatientAlertEditHandler}
            />
          ))
        ) : (
          <Grid item xs={12} height="300px">
            {noDataComponent}
          </Grid>
        )}
      </Grid>
      {ui_patientalert_modal}
    </PageWrapper>
  );
};

export default AlertHistory;
