import { Circle, CheckCircleOutlineOutlined, Flag, Edit, } from "@mui/icons-material";
import { Grid, Paper, Box, Divider, IconButton, Typography } from "@mui/material";
import WarningIcon from '@mui/icons-material/Warning';
import dayjs from "dayjs";
import { PatientAlertModel } from "../../Api/Model/Patient/PatientAlerts/PatientAlertModel";
import { GlobalFontSize } from "../../constants/global.constants";
import { colorsPalette } from "../../constants/styles.constants";
let localizedFormat = require('dayjs/plugin/localizedFormat');
type AlertHistoryElementProps = {
    input: PatientAlertModel;
    index: number;
    PatientAlertEditHandler: Function;
    isAllowedToModify: boolean;
};

export const AlertHistoryElement = (props: AlertHistoryElementProps) => {
    const { input, index, PatientAlertEditHandler } = props;
    dayjs.extend(localizedFormat);
    return (<Grid item xs={12} sx={{ maxWidth: 150, width: 150 }} >
        <Paper style={{ margin: 1, border: '1px solid lightgray' }} variant="outlined" >
            <Box sx={{
                display: "flex",
                alignItems: "center",
                position: 'absolute',
                paddingTop: 2
            }}>
                {input.status ? <> 
                <Circle sx={{ position: 'absolute', marginBottom: (input.flagType === "Danger" ? -1 : 2), zIndex: 1, paddingLeft: 4, width: 68, height: 68, color: colorsPalette.white }} /> 
                <CheckCircleOutlineOutlined sx={{ position: 'absolute', zIndex: 1, marginBottom: (input.flagType === "Danger" ? -1 : 2), paddingLeft: 4, width: 68, height: 68, color: colorsPalette.success }} />
                </> : ''}
                {(input.flagType === "Red" ? <Flag sx={{ color: colorsPalette.red, width: 100, height: 100 }} /> : input.flagType === "Yellow" ? <Flag sx={{ color: colorsPalette.yellow, width: 100, height: 100 }} /> : <WarningIcon sx={{ color: colorsPalette.orange, width: 100, height: 100 }} />)}
            </Box>
            <Box sx={{ display: 'flex', marginLeft: 11, paddingTop: 5 }}>
                <Box sx={{
                    display: "flex",
                    fontSize: 21,
                    paddingLeft: 2,
                    alignItems: "center",
                    fontWeight: "bold",
                    width: 215
                }}  >
                    <Typography fontWeight={"bold"}>
                        {(input.flagType == "Red" ? 'Red Flag' : input.flagType == "Yellow" ? 'Caution' : 'Compliance') + ' Alert '}
                    </Typography>
                </Box>
                <Box sx={{
                    display: "flex",
                    fontSize: 19,
                    alignItems: "center", justifyContent: 'left',
                }}  >
                    <Divider orientation="vertical" flexItem sx={{ marginRight: 2, marginTop: 1 }} />
                    <Typography>
                        {'triggered on ' + dayjs(input.triggeredDate?.toString()).format('llll')}
                    </Typography>
                </Box>
                <Box sx={{
                    display: "flex",
                    fontSize: 21,
                    alignItems: "center",
                    color: input.status ? colorsPalette.green : colorsPalette.red,
                    fontWeight: "bold",
                    marginRight: 2,
                    width: 180,
                    marginLeft: 'auto'
                }} >
                    <Typography fontWeight={"bold"}>
                        {!!input.status ? 'Status: Resolved' : 'Status: Pending'}
                    </Typography>
                </Box>
                <IconButton
                    disabled={props.isAllowedToModify}
                    onClick={(e) => {
                        PatientAlertEditHandler(index);
                    }}
                >
                    <Edit />{" "}
                </IconButton>
            </Box>

            <Divider orientation="horizontal" variant="middle" flexItem sx={{ marginBottom: 2, marginTop: 2, marginLeft: 13 }} />
            <Box sx={{ display: 'flex', marginLeft: 1 }}>
                <Box sx={{
                    display: "flex",
                    fontSize: 15,
                    alignItems: "center",
                    marginRight: 4,
                    marginLeft: 12,
                    marginBottom: 2,
                    paddingTop: 2,
                    width: 8.8 / 10,
                }} >
                    <pre style={{
                        fontFamily: "inherit",
                        overflowX: "hidden",
                        overflowY: "auto",
                        width: "100%",
                        marginLeft: "auto",
                        marginRight: "0",
                        wordWrap: "break-word",
                        whiteSpace: 'pre-line',
                        fontSize: GlobalFontSize,
                    }}
                    >
                        {'•  ' + input.alertBody?.split('\n').filter(w => w.trim() !== '').join('\n•  ')}
                    </pre>
                </Box>
            </Box>
        </Paper>
    </Grid>);
}
