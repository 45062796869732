import { initializeApp } from "firebase/app";
import firebaseConfig, { vapidKey } from "../constants/firebase.constants";
import { getMessaging, getToken, onMessage, deleteToken } from "firebase/messaging";
import { useAxios } from "./useAxios";
import { useLocalStorage } from "./useLocalStorage";

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
const fbcMessageTokenKey = 'fbcMessageTokenKey';

export const useFirebaseMessaging = () => {
    const { postAsync } = useAxios();
    const { setItem, getItem, removeItem } = useLocalStorage()

    const activateMessages = async (userId: string, authToken: string) => {
        const token = await getToken(messaging, { vapidKey: vapidKey }).catch(error => console.error("Error while generating a FCM token", error));
        if (token) {
            setItem(fbcMessageTokenKey, token)
            await postAsync("UserFirebaseDevice/AddUserToken", { userId, device: token }, authToken);
        }
    }

    const removeToken = async (userId: string) => {
        const wasSuccessfullyDeleted = await deleteToken(messaging);
        if (wasSuccessfullyDeleted) {
            const token = getItem(fbcMessageTokenKey);
            await postAsync("UserFirebaseDevice/RemoveUserToken", { userId, device: token });
            removeItem(fbcMessageTokenKey);
        }
    }

    const markNotificationAsNotified = async (messageId:string) => await postAsync<boolean>("PatientMessages/MarkMessageAsNotified", messageId) || false;

    return { activateMessages, messaging, onMessage, removeToken, markNotificationAsNotified }
}