import { Box, CircularProgress } from '@mui/material';

type PrismLoadingType = {
    Text?: string;
    Size?: number | string;
    Color?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | 'inherit';
    Variant?: 'determinate' | 'indeterminate'
}

function PrismLoading(props: PrismLoadingType) {
    return (
        <Box sx={{display:'flex', flexDirection:'column', justifyContent:'center',alignItems:'center', height:'100%', width:'100%'}}>
            <CircularProgress size={props.Size} color={props.Color} variant={props.Variant}/>
            {props.Text && <Box sx={{mt:3}}>{props.Text}</Box>}
        </Box>
    );
}

export default PrismLoading;