import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs";
import { isUndefined, isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { ReportFilterModel } from "../../../Api/Model/ReportFilterModel";
import { useAxios } from "../../../hooks/useAxios";
import { DateDisplayFormat } from "../../../constants/global.constants";
import { useNavigate, useOutletContext } from "react-router-dom";
import { DepartmentModel } from "../../../Api/Model/DepartmentModel";
import { emptyGUID } from "../../../constants/guid.contast";
import { downloadFileResponse } from "../../../Shared/Files.provider";
import { usePatientReadings } from "../../../hooks/usePatientReadings";
import PrismLoading from "../../Shared/Loading/Loading.component";
import OfficeModel from "../../../Api/Model/DepartmentStructure/OfficeModel";
import ProviderModel from "../../../Api/Model/DepartmentStructure/ProviderModel";
import { sortStringsWithNumbers } from "../../../Shared/Sorting.provider";
import { CleanButton } from "../../../Shared/CleanHandler.provider";
import PageWrapper from "../../Shared/Wrapper/PageWrapper.component";
import { getDateOfToday, getFirstDayOfCurrentMonth } from "../../../Shared/DateTime.provider";
import RPMTable from "./RPMTable.component";
import { PatientMiniModel } from "../../../Api/Model/PatientMiniModel";


const BillableRPMReadings = (props: any) => {
  const { manageErrorAlert, showAlertSnack } = useOutletContext<{
    manageErrorAlert: Function;
    showAlertSnack: Function;
  }>();
  const [reportFilterModel, setReportFilterModel] = useState<ReportFilterModel>(
    { departmentId: "", officeId: "", providerId: "", patientId: "",endDate:getDateOfToday(),startDate:getFirstDayOfCurrentMonth()  }
  );
  const [datesErrors, setDatesErrors] = useState(false);

  const [isSubmited, setIsSubmited] = useState<boolean>(false);
  const [resubmit, setResubmit] = useState<boolean>(false);
  const [departments, setDepartments] = useState<DepartmentModel[]>([]);
  const [offices, setOffices] = useState<OfficeModel[]>([]);
  const [exportFilterModel, setExportFilterModel] = useState<ReportFilterModel>(
    {}
  );
  const [providers, setProviders] = useState<ProviderModel[]>([]);
  const [patients, setPatients] = useState<PatientMiniModel[]>([]);
  const [isExportEnabled, setIsExportEnabled] = useState(false);
  const { getAsync, axiosError, downloadFileAsync, axiosSuccess } = useAxios();
  const {
    loading,
  } = usePatientReadings({
    PatientId:
      reportFilterModel && !!reportFilterModel.patientId
        ? reportFilterModel.patientId
        : emptyGUID,
    InitialDate: reportFilterModel.startDate,
    EndDate: reportFilterModel.endDate, 
  });
  const navigate = useNavigate();

  const getDepartments = async () => {
    const axiosGet = await getAsync<DepartmentModel[]>(
      "Department/GetDepartments",
      {
        PageNumber: 0,
        PageSize: 0,
      }
    );

    if (!axiosGet) {
      manageErrorAlert(axiosError?.Messages[0]);
      return;
    }

    axiosGet.sort((departmentA, departmentB) =>
      sortStringsWithNumbers(
        departmentA.departmentName,
        departmentB.departmentName
      )
    );
    setDepartments(axiosGet);
  };

  const getOffices = async () => {
    const axiosGet = await getAsync<OfficeModel[]>("Office/GetOffices", {
      PageNumber: 0,
      PageSize: 0,
    });

    if (!axiosGet) {
      manageErrorAlert(axiosError?.Messages[0]);
      return;
    }

    axiosGet.sort((officeA, officeB) =>
      sortStringsWithNumbers(officeA.officeName, officeB.officeName)
    );
    setOffices(axiosGet);
  };

  const getFilterOffices = async (currentDepartment: string | undefined) => {
    const axiosGet = await getAsync<OfficeModel[]>(
      `Office/GetOfficesByDepartmentId?DepartmentId=${currentDepartment}`
    );

    if (!axiosGet) {
      manageErrorAlert(axiosError?.Messages[0]);
      return;
    }

    axiosGet.sort((officeA, officeB) =>
      sortStringsWithNumbers(officeA.officeName, officeB.officeName)
    );
    setOffices(axiosGet);
  };

  const getProviders = async () => {
    const axiosGet = await getAsync<ProviderModel[]>("Provider/GetProviders", {
      PageNumber: 0,
      PageSize: 0,
    });

    if (!axiosGet) {
      manageErrorAlert(axiosError?.Messages[0]);
      return;
    }

    axiosGet.sort((providerA, providerB) =>
      sortStringsWithNumbers(providerA.providerName, providerB.providerName)
    );
    setProviders(axiosGet);
  };

  const getFilterProviders = async (
    currentDepartment: string | undefined,
    currentOffice: string | undefined
  ) => {
    const axiosGet = await getAsync<ProviderModel[]>(
      `Provider/GetProvidersByDepartmentStructure?DepartmentId=${currentDepartment || emptyGUID
      }&OfficeId=${currentOffice || emptyGUID}`,
      {
        PageNumber: 0,
        PageSize: 0,
      }
    );

    if (!axiosGet) {
      manageErrorAlert(axiosError?.Messages[0]);
      return;
    }

    axiosGet.sort((providerA, providerB) =>
      sortStringsWithNumbers(providerA.providerName, providerB.providerName)
    );
    setProviders(axiosGet);
  };

  const getPatients = async () => {
    const axiosGet = await getAsync<PatientMiniModel[]>(
      `Patients/GetPatientsByDepartmentStructure?departmentId=${reportFilterModel.departmentId || emptyGUID
      }&officeId=${reportFilterModel.officeId || emptyGUID}&providerId=${reportFilterModel.providerId || emptyGUID
      }`
    );

    if (!axiosGet) {
      manageErrorAlert(axiosError?.Messages[0]);
      return;
    }

    axiosGet.sort((patientA, patientB) =>
      sortStringsWithNumbers(
        patientA.firstName + " " + patientA.lastName,
        patientB.firstName + " " + patientB.lastName
      )
    );
    setPatients(axiosGet);
  };

  const departmentFilterHandler = (event: any) => {
    setReportFilterModel({
      ...reportFilterModel,
      departmentId: event.target.value,
      officeId: "",
      providerId: "",
    });
    if (event.target.value === "") {
      getOffices();
      getProviders();
      return;
    }
    getFilterOffices(event.target.value);
    getFilterProviders(event.target.value, reportFilterModel.officeId);
  };

  const officeFilterHandler = (event: any) => {
    setReportFilterModel({
      ...reportFilterModel,
      officeId: event.target.value,
      providerId: "",
    });
    getFilterProviders(reportFilterModel.departmentId, event.target.value);
  };

  const providerFilterHandler = (event: any) =>
    setReportFilterModel({
      ...reportFilterModel,
      providerId: event.target.value,
    });

  const patientSelectHandler = (event: any) => {

    setReportFilterModel({
      ...reportFilterModel,
      patientId: event.target.value,
    });
    if (reportFilterModel.patientId !== event.target.value) {
      setIsSubmited(false);
    }
  }

  useEffect(() => {
    getDepartments();
    getOffices();
    getProviders();
  }, []);

  useEffect(() => {
    setReportFilterModel({ ...reportFilterModel, patientId: "" });
    getPatients();
    setIsSubmited(false);
    setResubmit(false);
  }, [
    reportFilterModel.departmentId,
    reportFilterModel.officeId,
    reportFilterModel.providerId,
    reportFilterModel.startDate,
    reportFilterModel.endDate,
  ]);

  useEffect(() => {
    setDatesErrors(reportFilterModel.endDate && reportFilterModel.startDate && (reportFilterModel.endDate < reportFilterModel.startDate) || false);
  }, [reportFilterModel.startDate, reportFilterModel.endDate]);

  useEffect(() => {
    if (axiosError && axiosError?.Code === '401') {
      manageErrorAlert("Unauthorized");
      navigate("/patientlist", { replace: true });
      return;
    }
  }, [axiosError]);

  const validationErrors = {
    ...(isUndefined(reportFilterModel.startDate) && {
      StartDate: "Start Date is required",
    }),
    ...(reportFilterModel.startDate?.toString() === "Invalid Date" && {
      StartDate: "Start Date must be a valid date",
    }),
    ...(isUndefined(reportFilterModel.endDate) && {
      EndDate: "End Date is required",
    }),
    ...(reportFilterModel.endDate?.toString() === "Invalid Date" && {
      EndDate: "End Date must be a valid date",
    }),
    ...(datesErrors) && {
      StartDate: "Date cannot be greater than End Date",
      EndDate: "Date cannot be less than Start Date",
    }
  };

  const onSubmitReportClick = async () => {
    setResubmit(isSubmited);
    setIsSubmited(true);
    setExportFilterModel(reportFilterModel);
  };

  const onExportReportClick = async () => {
    let reportFile = await downloadFileAsync(
      "PatientMeasurementRecords/ExportRPMReadingHistory",
      `initialDate=${exportFilterModel.startDate?.toLocaleDateString("en-US")}&endDate=${exportFilterModel.endDate?.toLocaleDateString("en-US")}&departmentId=${exportFilterModel.departmentId}&officeId=${exportFilterModel.officeId}&providerId=${exportFilterModel.providerId}&patientId=${exportFilterModel.patientId}`
    );
    
    if (!axiosSuccess) {
      manageErrorAlert(axiosError?.Messages[0]);
      return;
    }

    showAlertSnack("Report generated successfully.", "success");
    downloadFileResponse(
      reportFile,
      `RPMReport ${exportFilterModel.startDate?.toLocaleDateString(
        "en-US"
      )} to ${exportFilterModel.endDate?.toLocaleDateString("en-US")}.xlsx`
    );
  };

  const loadingComponent = <PrismLoading Size={80} Text={"Loading sections"} />;

  return (
    <PageWrapper title={"Billable RPM Reading Report"}>
      <Grid container spacing={2} rowSpacing={1}>
        <Grid item xs={3}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              disableFuture
              onChange={(newValue: Dayjs | null) =>
                setReportFilterModel({
                  ...reportFilterModel,
                  startDate: newValue ? newValue.toDate() : undefined,
                })
              }
              value={reportFilterModel.startDate || null}
              label="Start Date"
              inputFormat={DateDisplayFormat}
              renderInput={(params) => (
                <TextField
                  required
                  inputProps={{
                    tabIndex: 1,
                  }}
                  {...params}
                  error={!!validationErrors.StartDate}
                  helperText={validationErrors.StartDate}
                  fullWidth
                />
              )}
              PopperProps={{
                placement: "bottom-start",
                sx: {paddingBottom: 2}
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={3}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              onChange={(newValue: Dayjs | null) =>
                setReportFilterModel({
                  ...reportFilterModel,
                  endDate: newValue ? newValue.toDate() : undefined,
                })
              }
              value={reportFilterModel.endDate || null}
              label="End Date"
              inputFormat={DateDisplayFormat}
              renderInput={(params) => (
                <TextField
                  required
                  inputProps={{
                    tabIndex: 2,
                  }}
                  {...params}
                  error={!!validationErrors.EndDate}
                  helperText={validationErrors.EndDate}
                  fullWidth
                />
              )}
              PopperProps={{
                placement: "bottom-start",
                sx: {paddingBottom: 2}
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth>
            <InputLabel id="PatientDepartmentLabel" color="primary">
              Patient Department
            </InputLabel>
            <Select
              fullWidth
              id="PatientDepartmentCombo"
              defaultValue={""}
              unselectable="on"
              inputProps={{
                tabIndex: 3,
              }}
              label="Patient Department"
              labelId="PatientDepartmentLabel"
              value={reportFilterModel.departmentId || ""}
              onChange={departmentFilterHandler}
                endAdornment={
                    reportFilterModel?.departmentId !== "" && (
                        CleanButton(departmentFilterHandler)
                    )
                }
            >
              {departments.length === 0 && (
                <MenuItem value="">No departments found...</MenuItem>
              )}
              {departments &&
                departments.map((department: DepartmentModel) => (
                  <MenuItem
                    value={department.departmentId}
                    key={department.departmentId}
                  >
                    {department.departmentName}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth>
            <InputLabel id="PatientOfficeLabel" color="primary">
              Patient Office
            </InputLabel>
            <Select
              fullWidth
              id="PatientOfficeCombo"
              defaultValue={""}
              unselectable="on"
              inputProps={{
                tabIndex: 3,
              }}
              multiple={false}
              native={false}
              label="Patient Office"
              labelId="PatientOfficeLabel"
              value={reportFilterModel.officeId || ""}
              onChange={officeFilterHandler}
                endAdornment={
                    reportFilterModel?.officeId !== "" && (
                        CleanButton(officeFilterHandler)
                    )
                }
            >
              {offices.length === 0 && (
                <MenuItem value="">No offices found...</MenuItem>
              )}
              {offices &&
                offices.map((office: OfficeModel) => (
                  <MenuItem value={office.officeId} key={office.officeId}>
                    {office.officeName}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth>
            <InputLabel id="PatientProviderLabel" color="primary">
              Patient Provider
            </InputLabel>
            <Select
              fullWidth
              id="PatientProviderCombo"
              defaultValue={""}
              unselectable="on"
              inputProps={{
                tabIndex: 3,
              }}
              multiple={false}
              native={false}
              label="Patient Provider"
              labelId="PatientProviderLabel"
              value={reportFilterModel.providerId || ""}
              onChange={providerFilterHandler}
                endAdornment={
                    reportFilterModel?.providerId !== "" && (
                        CleanButton(providerFilterHandler)
                    )
                }
            >
              {providers.length === 0 && (
                <MenuItem value="">No providers found...</MenuItem>
              )}
              {providers &&
                providers.map((provider: ProviderModel) => (
                  <MenuItem
                    value={provider.providerId}
                    key={provider.providerId}
                  >
                    {provider.providerName}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl
            fullWidth
          >
            <InputLabel
              id="PatientLabel"
              color="primary"
            >
              Patient
            </InputLabel>
            <Select
              fullWidth
              id="PatientCombo"
              defaultValue={""}
              labelId="PatientLabel"
              label={"Patient"}
              inputProps={{
                tabIndex: 3,
              }}
              multiple={false}
              native={false}
              unselectable="on"
              value={reportFilterModel.patientId || ""}
              onChange={patientSelectHandler}
                endAdornment={
                    reportFilterModel?.patientId !== "" && (
                        CleanButton(patientSelectHandler)
                    )
                }
            >
              {patients.length === 0 && (
                <MenuItem value="">No patients found...</MenuItem>
              )}
              {patients &&
                patients.map((patient: PatientMiniModel) => (
                  <MenuItem
                    value={patient.patientId}
                    key={patient.patientId}
                  >
                    {`${patient.firstName} ${patient.lastName}`}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={1.5}>
          <Button
            disabled={!isEmpty(validationErrors)}
            fullWidth
            sx={{ height: 55 }}
            variant="contained"
            color="primary"
            onClick={onSubmitReportClick}
          >
            SUBMIT
          </Button>
        </Grid>
        <Grid item xs={1.5}>
          <Button
            disabled={
              !exportFilterModel.startDate ||
              !exportFilterModel.endDate || !isSubmited ||
              patients.length <=0 || !isExportEnabled
            }
            fullWidth
            sx={{ height: 55 }}
            variant="contained"
            color="primary"
            onClick={onExportReportClick}
          >
            EXPORT
          </Button>
        </Grid>
      </Grid>
      <Box sx={{ marginTop: 2 }}>
        {!isSubmited ? (
          <Box
            sx={{
              display: "flex",
              height: "100%",
              justifyContent: "center",
              textAlign: "center",
              alignItems: "center",
            }}
          >
            {"Click submit once you finished configuring your filters"}
          </Box>
        ) : isSubmited && loading ? (
          loadingComponent
        ) : (
            <RPMTable
              report={reportFilterModel}
              exportButtonHandler={setIsExportEnabled}
              resubmit={resubmit}
              resubmitHandler={setResubmit}
            />
        )}
      </Box>
    </PageWrapper>
  );
};

export default BillableRPMReadings;
