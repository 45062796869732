import { useState, useEffect } from "react";
import { isEmpty } from "lodash";
import { UserModel } from "../Api/Model/user/userModel";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { AuthUserModel } from "../Api/Model/user/authModel";
import { useAxios } from "./useAxios";
import { RolesModel } from "../Api/Model/user/RolesModel";

export const useUser = (props: any, param: any, manageErrorAlert: Function, showAlertSnack: Function) => {
  const IdUser = param?.id;
  const [user, setUser] = useState<UserModel>({});
  const [rolesDB, setRolesDB] = useState<RolesModel[]>([]);
  const [userNameInUse, setUserNameInUse] = useState(false);
  const [searchingUserName, setSearchingUserName] = useState(false);
  const [userExists, setUserExists] = useState(true);
  const navigate = useNavigate();
  const {
    getAsync,
    axiosError,
    postAsync,
    axiosSuccess,
    updateAsync,
  } = useAxios();
  const cookies = new Cookies();
  const userAuth = cookies.get("sessionUser") as AuthUserModel;

  const addUser = (user: UserModel) => {
    setUser(user);
  };

  const handleSetTextField = (event: any) => {
    let fieldS = event.target.id.toString();
    setUser({ ...user, [fieldS]: event.target.value });
  };

  const handleSubmit = async () => {
    if (user.userId === undefined) {
      await addUserFromBD(user);
    }
    else {
      await EditUserFromBD(user);
      setUser({});
      navigate("/userlist");
    }
  };


  async function addUserFromBD(user: any) {
    let newUser = {};
    let response: UserModel | undefined = await postAsync('User/AddUser', user);
    if (axiosSuccess) {
      newUser = response || {};
      setUser({});
      showAlertSnack("User added successfully", "success");
    }
    else {
      manageErrorAlert(axiosError?.Messages[0]);
    }
    return newUser;
  }

  async function addUserToPatientFromBD(user: any) {
    let newUser = {};
    let response: UserModel | undefined = await postAsync('User/AddUserToPatient', user);
    if (axiosSuccess) {
      showAlertSnack("Patient created successfully.", "success");
      newUser = response || {};
      setUser({});
    }
    else {
      manageErrorAlert(axiosError?.Messages[0]);
    }
    return newUser;
  }

  async function EditUserFromBD(user: any) {
    let editUser = {};
    const updateResult = await updateAsync<UserModel>('User/UpdateUser', user?.userId || '', user);
    if (updateResult && axiosSuccess) {
      editUser = updateResult;
      showAlertSnack("User was edited successfully", "success");
    }
    return editUser;
  }

  async function setRolesFromBD() {
    const axiosGet = await getAsync<RolesModel[]>("Role/GetRoles", {
      PageNumber: 0,
      PageSize: 0
    });

    if (!axiosGet) {
      manageErrorAlert(axiosError?.Messages[0]);
      return;
    }
    setRolesDB(axiosGet);
  }

  async function getUserById(id: string) {
    const axiosGet = await getAsync<UserModel>(`User/GetUserById/${id}`);

    if (!axiosGet) {
      manageErrorAlert(axiosError?.Messages[0]);
      return;
    }

    setUser(axiosGet);
  }

  async function validateUserById(id: string) {    
    const axiosGet = await getAsync<boolean>(`User/UserExistsById?guid=${id}`);

    if(!axiosGet) {
      setUserExists(false);
      return;
    }

    await getUserById(id);
  }

  useEffect(() => {
    if (userAuth?.authToken !== null && rolesDB.length === 0) {
      setRolesFromBD();
    }
  }, [userAuth?.authToken, rolesDB.length]);

  useEffect(() => {
    if (IdUser !== undefined) {
      validateUserById(IdUser);
    }
  }, []);

  const checkUserExist = async (username?: string) => {
    setSearchingUserName(true);
    const axiosGet = await getAsync<boolean>(`User/ValidateUserNameIsInUse?UserName=${username}`);
    setSearchingUserName(false);
    return axiosGet;
  }

  useEffect(() => {
    if (user && !IdUser) {
      if (!isEmpty(user.userName?.trim()) && !(user.userName!.trim().length <= 4)) {
        checkUserExist(user.userName?.trim()).then((exist) => {
          setUserNameInUse(exist || false);
        });
      }
      else {
        setUserNameInUse(false);
        setSearchingUserName(false);
      }
    }
  }, [user.userName]);

  const userIsInRoles = (roles: string[]) => {
    if (!roles || isEmpty(roles)) {
      return false;
    }
    const userRole: any = rolesDB.find((role: any) => role.roleId === userAuth.roleId);
    return roles.includes(userRole?.roleName);
  };

  return {
    user,
    userExists,
    setUser,
    validateUserById,
    rolesDB,
    userIsInRoles,
    addUser,
    handleSetTextField,
    handleSubmit,
    addUserFromBD,
    addUserToPatientFromBD,
    userNameInUse,
    searchingUserName,
    axiosError
  };
};