import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Select,
  Stack,
  ThemeProvider,
  Typography,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import "./PatientList.css";
import { GridCellParams, GridColDef, GridRenderCellParams, GridSortModel, GridValueFormatterParams } from "@mui/x-data-grid/models";
import { useCallback, useEffect, useState } from "react";
import PRISMCareTheme from "../../layout/PRISMCareTheme";
import MessagingIcon from "@mui/icons-material/ForumOutlined";
import TaskIcon from "@mui/icons-material/AssignmentOutlined";
import FlagOIcon from "@mui/icons-material/FlagOutlined";
import { usePatientList } from "../../hooks/usePatientList";
import AdvancedSearch from "../advancedSearch/advancedSearch.component";
import FlagIcon from "@mui/icons-material/Flag";
import ChatIcon from '@mui/icons-material/Chat';
import WarningIcon from "@mui/icons-material/Warning";
import BusinessIcon from '@mui/icons-material/Business';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import { Badge } from "@mui/material";
import StripedDataGrid, {
  CustomPatientPagination,
} from "../Shared/StripedDataGrid/StripedDatagrid.component";
import { useNavigate, useOutletContext } from "react-router-dom";
import { PatientListTasksModal } from "./PatientListTasksModal.component";
import { CleanButton } from "../../Shared/CleanHandler.provider";
import { PatientListMessagingModal } from "./PatientListMesagingModal.component";
import { colorsPalette } from "../../constants/styles.constants";
import OfficeProviderModel from "../../Api/Model/DepartmentStructure/OfficeProviderModel";
import { useFirebaseMessaging } from "../../hooks/useFirebaseMessaging";
import PatientNotificationMessage from "../../Api/Model/Patient/PatientMessages/PatientNotificationMessage.model";

const styles = {
  dashboardCard: {
    color: colorsPalette.white,
    fontSize: 20,
    paddingLeft: 2,
  },
  searchButtons: {
    width: "100%",
    height: 25,
    textAlign: "center",
    marginBottom: 1,
    textJustify: "center",
    display: "inline-block",
    verticalAlign: "top",
    lineHeight: 0,
  },
  cardIconSize: {
    width: 48,
    height: 48
  },
  iconStyle: {
    display: "flex",
    justifyContent: "end"
  },
  headerCardContentStyle: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 1,
    paddingRight: 1,
  },
  cardText: {
    paddingLeft: 1,
    paddingBottom: 0.5,
    color: colorsPalette.white,
    fontWeight: "medium",
    display: "flex",
    alignItems: "end",
  },
  pointerCursor: {
    cursor: "pointer"
  },
  normalCursor: {
    cursor: "default"
  },
  headerCardsContainer: {
    display: "inline-flex",
    flexWrap: "wrap",
    "& >:not(style)": {
      marginRight: 2,
      marginBottom: 2,
      width: 200,
      height: 80,
    },
  }
};

interface cardHeaderConfiguration {
  title: string;
  counter: number;
  handler: () => void;
  color: string;
  iconIndex: number;
  text?: string;
  clickable?: boolean;
}

const iconLibrary: JSX.Element[] = [
  <FlagOIcon htmlColor={colorsPalette.white} sx={styles.cardIconSize} />,
  <TaskIcon htmlColor={colorsPalette.white} sx={styles.cardIconSize} />,
  <MessagingIcon htmlColor={colorsPalette.white} sx={styles.cardIconSize} />,
];

const flagOrder = ['Red', 'Yellow', 'Danger', '0'];

const PatientList = (props: any) => {
  document.body.style.backgroundColor = colorsPalette.white;
  sessionStorage.removeItem("currentPatient");
  let measurementColumns: { [key: string]: string } = { 'spO2': 'isSpO2OverLimit', 'weight': 'isWeightOverLimit', 'glucose': 'isGlucoseOverLimit', 'bp': 'isBPOverLimit', 'pulse': 'isPulseOverLimit' };
  const alertFlagClickHandler = (params: any) => navigate(`/patientlist/editpatient/${params.id}`, { replace: true, state: { tabIndex: 2 } });
  const messageClickHandler = (params: any) => navigate(`/patientlist/editpatient/${params.id}`, { replace: true, state: { tabIndex: 4 } });
  function renderMessageIncome(params: GridRenderCellParams<number>) {
    if (!params.value) {
      return '';
    }
    return <IconButton onClick={x => messageClickHandler(params)}>
      <Badge badgeContent={params.value} color="primary">
        <ChatIcon color="action" />
      </Badge>
    </IconButton>;
  }
  function renderFlagAlert(params: GridRenderCellParams<string>) {
    if (!params.value) {
      return '';
    }
    let currentFlag;
    switch (params.value) {
      case "Red":
        currentFlag = <IconButton onClick={x => alertFlagClickHandler(params)}><FlagIcon sx={{ color: colorsPalette.red }} /></IconButton>;
        break;
      case "Danger":
        currentFlag = <IconButton onClick={x => alertFlagClickHandler(params)}><WarningIcon color="warning" /></IconButton>;
        break;
      case "Yellow":
        currentFlag = <IconButton onClick={x => alertFlagClickHandler(params)}><FlagIcon sx={{ color: colorsPalette.yellow }} /></IconButton>;
        break;
      default:
        currentFlag = "";
        break;
    }
    return currentFlag;

  }

  const sortAlertColumn = (firstAlert: string, secondAlert: string) => (flagOrder.indexOf(firstAlert) - flagOrder.indexOf(secondAlert));
  const columns: GridColDef[] = [
    {
      field: "internalCode",
      headerName: "ID",
      flex: 1,
      headerAlign: "center",
      align: "center",
      filterable: false,
      hideable: false
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.7,
      type: "singleSelect",
      headerAlign: "center",
      align: "center",
      filterable: false,
      hideable: false
    },
    {
      field: "firstName",
      headerName: "First Name",
      flex: 1.2,
      headerAlign: "center",
      align: "center",
      filterable: false,
      hideable: false
    },
    {
      field: "lastName",
      headerName: "Last Name",
      flex: 1.2,
      headerAlign: "center",
      align: "center",
      filterable: false,
      hideable: false
    },
    {
      field: "unReadMessages",
      headerName: "Messages",
      flex: 0.7,
      headerAlign: "center",
      align: "center",
      renderCell: renderMessageIncome,
      filterable: false,
      hideable: false
    },
    {
      field: "age",
      headerName: "Age",
      flex: 0.5,
      type: "number",
      headerAlign: "center",
      align: "center",
      filterable: false,
      hideable: false
    },
    {
      field: "sex",
      headerName: "Sex",
      flex: 0.5,
      headerAlign: "center",
      align: "center",
      filterable: false,
      hideable: false
    },
    {
      field: "lastReading",
      headerName: "Last Reading",
      flex: 1,
      headerAlign: "center",
      align: "center",
      filterable: false,
      hideable: false,
      sortComparator: (v1, v2) => {
        const daysAgo1 = v1 === undefined || v1 === '' || v1 === 'Never' ? -1 : v1 === 'Today' ? 0 : parseInt(v1);
        const daysAgo2 = v2 === undefined || v2 === '' || v2 === 'Never' ? -1 : v2 === 'Today' ? 0 : parseInt(v2);
        return daysAgo1 - daysAgo2;
      }
    },
    {
      field: "alerts",
      headerName: "Flags",
      flex: 0.5,
      headerAlign: "center",
      align: "center",
      renderCell: renderFlagAlert,
      sortComparator: sortAlertColumn,
      filterable: false,
      hideable: false
    },
    {
      field: "spO2",
      headerName: "SpO2",
      flex: 1,
      type: "number",
      headerAlign: "center",
      align: "center",
      valueFormatter: (params: GridValueFormatterParams<string>) => {
        if (!params.value || params.value === "0") {
          return "";
        }
        return `${params.value}%`;
      },
      filterable: false,
      hideable: false,
      sortComparator: (v1, v2) => {
        const spO21 = v1 === undefined || v1 === '' ? 0 : parseFloat(v1);
        const spO22 = v2 === undefined || v2 === '' ? 0 : parseFloat(v2);
        return spO21 - spO22;
      }
    },
    {
      field: "bp",
      headerName: "BP",
      flex: 1,
      headerAlign: "center",
      align: "center",
      filterable: false,
      hideable: false,
      valueFormatter: (params: GridValueFormatterParams<string>) => {
        if (!params.value || params.value === "0 / 0") {
          return "";
        }
        return params.value;
      },
      sortComparator: (v1, v2, p1, p2) => {
        const values1 = v1.split(" / ");
        const values2 = v2.split(" / ");

        const systolic1 = values1[0].trim() === undefined || values1[0].trim() === '' ? -1 : parseFloat(values1[0].trim());
        const systolic2 = values2[0].trim() === undefined || values2[0].trim() === '' ? -1 : parseFloat(values2[0].trim());

        if (systolic1 === systolic2) {
          const diastolic1 = values1[1].trim() === undefined || values1[1].trim() === '' ? -1 : parseFloat(values1[1].trim());
          const diastolic2 = values2[1].trim() === undefined || values2[1].trim() === '' ? -1 : parseFloat(values2[1].trim());
          return diastolic1 - diastolic2;
        }

        return systolic1 - systolic2;
      }
    },
    {
      field: "pulse",
      headerName: "Pulse",
      flex: 1,
      type: "number",
      headerAlign: "center",
      align: "center",
      valueFormatter: (params: GridValueFormatterParams<string>) => {
        if (!params.value || params.value === '0') {
          return "";
        }
        return `${params.value}`;
      },
      filterable: false,
      hideable: false,
      sortComparator: (v1, v2) => {
        const pulse1 = v1 === undefined || v1 === '' ? 0 : parseFloat(v1);
        const pulse2 = v2 === undefined || v2 === '' ? 0 : parseFloat(v2);
        return pulse1 - pulse2;
      }
    },
    {
      field: "weight",
      headerName: "Weight",
      flex: 1,
      type: "number",
      headerAlign: "center",
      align: "center",
      valueFormatter: (params: GridValueFormatterParams<string>) => {
        if (!params.value || params.value === "0") {
          return "";
        }
        return `${params.value} lbs`;
      },
      filterable: false,
      hideable: false,
      sortComparator: (v1, v2) => {
        const weight1 = v1 === undefined || v1 === '' ? 0 : parseFloat(v1);
        const weight2 = v2 === undefined || v2 === '' ? 0 : parseFloat(v2);
        return weight1 - weight2;
      }
    },
    {
      field: "glucose",
      headerName: "Glucose",
      flex: 1,
      type: "number",
      headerAlign: "center",
      align: "center",
      valueFormatter: (params: GridValueFormatterParams<string>) => {
        if (!params.value || params.value === "0") {
          return "";
        }
        return `${params.value} mg/dL`;
      },
      filterable: false,
      hideable: false,
      sortComparator: (v1, v2) => {
        const glucose1 = v1 === undefined || v1 === '' ? 0 : parseFloat(v1);
        const glucose2 = v2 === undefined || v2 === '' ? 0 : parseFloat(v2);
        return glucose1 - glucose2;
      }
    },
  ];

  const { manageErrorAlert } = useOutletContext<{
    manageErrorAlert: Function;
    showAlertSnack: Function;
  }>();
  const [openModal, setOpenModal] = useState(false);
  
  const [sortModel, setSortModel] = useState<GridSortModel>([{field: columns[0].field, sort: "asc"}]);

  //#region modalTasks hooks and functions
  const [taskCount, setTaskCount] = useState(0);
  const [openPatientListTasksModal, setOpenPatientListTasksModal] =
    useState(false);
  const [openPatientListMessagingModal, setOpenPatientListMessagingModal] =
    useState(false);

  const closePatientTasksModal = useCallback(() => {
    setOpenPatientListTasksModal(false);
  }, [setOpenPatientListTasksModal]);

  const closePatientMessagingModal = useCallback(() => {
    setOpenPatientListMessagingModal(false);
  }, [setOpenPatientListMessagingModal]);

  const HandlesAddNewTask = (): void => {
    setOpenPatientListTasksModal(true);
  };
  const HandlesAddNewMessaging = (): void => {
    setOpenPatientListMessagingModal(true);
  };
  //#endregion
  const navigate = useNavigate();

  const {
    numberOfAlerts,
    officeProviders,
    organizations,
    patients,
    setPatients,
    isLoading,
    setIsLoading,
    patientPagination,
    setPatientPagination,
    setPatientFilter,
    patientFilter,
    updatePatients,
    getPatientMessagesCount
  } = usePatientList(props);

  const { onMessage, messaging, markNotificationAsNotified } = useFirebaseMessaging();

  onMessage(messaging, (payload) => {
    const { data: payloadData } = payload;

    if (!payloadData) {
      return;
    }

    const notificationMessage = {
      userId: payloadData['userId'],
      patientId: payloadData['patientId'],
      patientName: payloadData['patientName'],
      messageId: payloadData['messageId'],
    } as PatientNotificationMessage

    setPatients((prevState: any) => {
      const patientsArray = [...prevState];
      const patientOnList = patientsArray.find((p: any) => p.id === notificationMessage.patientId);

      if (!patientOnList) {
        return patientsArray;
      }

      patientOnList.unReadMessages += 1;
      return patientsArray
    })

    markNotificationAsNotified(notificationMessage.messageId)
  })

  const handleLostNotifications = async () => {
    if (document.hidden) {
      return;
    }
    const patientUnreadMessages = await getPatientMessagesCount();
    setPatients((prevState: any) => {
      const patientsArray = [...prevState];

      patientsArray.forEach(patient => {
        const patientOnHasMessages = patientUnreadMessages.find((p: any) => p.patientId === patient.id);

        patient.unReadMessages = patientOnHasMessages ? patientOnHasMessages.count : 0;
      })

      return patientsArray;
    })
  }

  useEffect(() => {
    window.addEventListener('focus', handleLostNotifications);
    return () => {
      window.removeEventListener('focus', handleLostNotifications);
    }
  }, [])

  const applyAlertsSorting = () => {
    sessionStorage.setItem("prevSortFieldDashboard", JSON.stringify([{ field: "alerts", sort: "asc" }]));
    setSortModel([{ field: "alerts", sort: "asc" }]);
  }

  //#region Card header configurations
  const alertCardConfiguration: cardHeaderConfiguration = {
    title: "Current Flags",
    counter: numberOfAlerts,
    handler: applyAlertsSorting,
    color: colorsPalette.red,
    iconIndex: 0,
    text: undefined,
    clickable: true,
  };

  const taskCardConfiguration: cardHeaderConfiguration = {
    title: "My Tasks",
    counter: taskCount,
    handler: HandlesAddNewTask,
    color: colorsPalette.blue,
    iconIndex: 1,
    text: undefined,
    clickable: true,
  };

  const messagingCardConfiguration: cardHeaderConfiguration = {
    title: "Patient",
    counter: 0,
    handler: HandlesAddNewMessaging,
    color: colorsPalette.success,
    iconIndex: 2,
    text: "Messaging",
    clickable: true,
  };
  //#endregion

  const departmentChangeHandler = (event: any) => {
    setPatientFilter({
      ...patientFilter,
      DepartmentId: event.target.value || '',
      ProviderId: '',
      OfficeId: '',
    });

    sessionStorage.setItem('prevSearch', JSON.stringify({
      ...patientFilter,
      DepartmentId: event.target.value || '',
    }));

    sessionStorage.setItem('prevPageSize', JSON.stringify(paginationModel.pageSize));
    sessionStorage.setItem('prevPage', JSON.stringify(0));
    setPaginationModel({ ...paginationModel, page: 0 });

    setIsLoading(true);
    updatePatients(true);
  };

  const officeProviderChangeHandler = (event: any) => {
    const officeProviderSelectedInfo = officeProviders.find((op) => op.id === event.target.value);
    setPatientFilter((prevState) => ({
      ...{
        ...prevState,
        ProviderId: officeProviderSelectedInfo?.type === 'Provider' ? event.target.value : '',
        OfficeId: officeProviderSelectedInfo?.type === 'Office' ? event.target.value : '',
      }
    }));

    sessionStorage.setItem('prevSearch', JSON.stringify({
      ...patientFilter,
      ProviderId: officeProviderSelectedInfo?.type === 'Provider' ? event.target.value : '',
      OfficeId: officeProviderSelectedInfo?.type === 'Office' ? event.target.value : '',
    }));

    sessionStorage.setItem('prevPageSize', JSON.stringify(paginationModel.pageSize));
    sessionStorage.setItem('prevPage', JSON.stringify(0));
    setPaginationModel({ ...paginationModel, page: 0 });

    setIsLoading(true);
    updatePatients(true);
  };
  const clearSearchHandler = (event: any) => {
    setPatientFilter({ DepartmentId: '', ProviderId: '', OfficeId: '' });
    setIsLoading(true);
    updatePatients(true);
    sessionStorage.removeItem('prevSearch');
    sessionStorage.setItem('prevPageSize', JSON.stringify(paginationModel.pageSize));
    sessionStorage.setItem('prevPage', JSON.stringify(paginationModel.page));
  };
  const resetSearchHandler = () => {
    setPatientFilter((prevState) => ({
      ...{
        DepartmentId: prevState.DepartmentId,
        ProviderId: prevState.ProviderId,
        OfficeId: prevState.OfficeId,
        Sex: "",
        CareTeam: "",
        Status: "",
      },
    }));
    updatePatients(true);
    sessionStorage.removeItem('prevSearch');
    sessionStorage.setItem('prevPageSize', JSON.stringify(paginationModel.pageSize));
    sessionStorage.setItem('prevPage', JSON.stringify(paginationModel.page));
  };
  const applyAdvancedSearchHandler = () => {
    setPatientPagination({
      ...patientPagination,
      PageNumber: 1
    });

    setIsLoading(true);
    updatePatients(true);
    setOpenModal(false);
    sessionStorage.setItem('prevSearch', JSON.stringify(patientFilter));
  };
  const patientClickHandler = (params: any) => {
    navigate(`/patientlist/editpatient/${params.id}`, { replace: true });
  };

  useEffect((() => {
    const savedStateSearchString = sessionStorage.getItem('prevSearch');
    const previousPatientFilter = savedStateSearchString ? JSON.parse(savedStateSearchString) : undefined;
    if (previousPatientFilter !== undefined) {
      setPatientFilter(previousPatientFilter);
    }
  }), []);

  useEffect((() => {
    if (patients) {
      const savedStatePageString = sessionStorage.getItem('prevPage');
      const savedStatePageSizeString = sessionStorage.getItem('prevPageSize');
      const savedStateSortFieldString = sessionStorage.getItem('prevSortFieldDashboard');
      const previousPatientPage = savedStatePageString ? JSON.parse(savedStatePageString) : undefined;
      const previousPatientPageSize = savedStatePageSizeString ? JSON.parse(savedStatePageSizeString) : undefined;
      const previousPatientSortField = savedStateSortFieldString ? JSON.parse(savedStateSortFieldString) : undefined;
      if (previousPatientSortField !== undefined) {
        setSortModel(previousPatientSortField);
      }

      setPaginationModel({ page: (previousPatientPage !== undefined && patients.length > 0) ? previousPatientPage : 0, pageSize: previousPatientPageSize !== undefined ? previousPatientPageSize : 10 });
    }
  }), [patients]);

  const ui_PatientListTasks_modal = (
    <PatientListTasksModal
      open={openPatientListTasksModal}
      handleClose={closePatientTasksModal}
      setTaskCount={setTaskCount}
      taskCount={taskCount}
    />
  );

  const ui_PatientListMessaging_modal = (
    <PatientListMessagingModal
      open={openPatientListMessagingModal}
      closeHandler={closePatientMessagingModal}
    />
  );

  function ui_cardHeader(configuration: cardHeaderConfiguration): JSX.Element {
    const { text, handler, clickable, color, title, counter, iconIndex } =
      configuration;
    const fontSize: number = !text ? 34 : 24;

    return (
      <Card onClick={handler} sx={
        clickable
          ? { ...styles.pointerCursor, bgcolor: color }
          : { bgcolor: color }
      }>
        <Box sx={styles.dashboardCard}>{title}</Box>
        <CardContent sx={styles.headerCardContentStyle}>
          <Grid container>
            <Grid
              item
              xs={6}
              sx={{ ...styles.cardText, fontSize: fontSize, }}
            >
              {fontSize === 34 ? counter : text}
            </Grid>
            <Grid item xs={6} sx={styles.iconStyle}>
              {iconLibrary[iconIndex]}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }

  function _onPageSizeChange(newPageSize: number) {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
    sessionStorage.setItem('prevPageSize', JSON.stringify(newPageSize));
    sessionStorage.setItem('prevPage', JSON.stringify(paginationModel.page));
  }

  function _onPageChange(newPage: number) {
    setPaginationModel({ ...paginationModel, page: newPage });
    sessionStorage.setItem('prevPage', JSON.stringify(newPage));
    sessionStorage.setItem('prevPageSize', JSON.stringify(paginationModel.pageSize));
  }

  function _onSortChange(sortField: GridSortModel) {
    sessionStorage.setItem("prevSortFieldDashboard", JSON.stringify(sortField));
    setSortModel(sortField);
  }

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  return (
    <ThemeProvider theme={PRISMCareTheme}>
      <Grid container style={{ width: "100%" }}>
        <Grid
          item
          xs={12}
          sx={{
            overflow: "hidden",
          }}
        >
          <Box
            sx={styles.headerCardsContainer}
          >
            {ui_cardHeader(alertCardConfiguration)}
            {ui_cardHeader(taskCardConfiguration)}
            {ui_cardHeader(messagingCardConfiguration)}
          </Box>
          <Box
            sx={{
              float: "right",
              display: "inline-flex",
              flexWrap: "wrap",
              "& >:not(style)": { m: 1, width: 250, height: 60 },
            }}
            className="GothamBookFamily"
          >
            <FormControl>
              <InputLabel id="department-select-label" color="primary">
                DEPARTMENT
              </InputLabel>
              <Select
                id="department-select"
                unselectable="on"
                label={"DEPARTMENT"}
                multiple={false}
                native={false}
                labelId="department-select-label"
                value={patientFilter.DepartmentId}
                onChange={departmentChangeHandler}
                endAdornment={
                  patientFilter.DepartmentId && (
                    CleanButton(departmentChangeHandler)
                  )
                }
              >
                {organizations.length === 0 && (
                  <MenuItem value="">No organizations found...</MenuItem>
                )}
                {organizations &&
                  organizations.map((organization: any) => (
                    <MenuItem
                      value={organization.departmentId}
                      key={organization.departmentId}
                    >
                      {organization.departmentName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel id="ProviderCombo" color="primary">
                OFFICE / PROVIDER
              </InputLabel>
              <Select
                labelId="ProviderCombo"
                label={"OFFICE / PROVIDER"}
                unselectable="on"
                value={!!patientFilter.OfficeId ? patientFilter.OfficeId : patientFilter.ProviderId}
                onChange={officeProviderChangeHandler}
                endAdornment={
                  (patientFilter.ProviderId || patientFilter.OfficeId) && (
                    CleanButton(officeProviderChangeHandler)
                  )
                }
              >
                {officeProviders.length === 0 && (
                  <MenuItem value="">No providers found...</MenuItem>
                )}
                {officeProviders.map((officeProvider: OfficeProviderModel) => (
                  <MenuItem
                    value={officeProvider.id}
                    key={officeProvider.id}
                  >
                    {officeProvider.type === "Office" && <BusinessIcon sx={{ marginRight: 1, marginLeft: -1, color: 'darkblue' }} fontSize="small" />}
                    {officeProvider.type === "Provider" && <SupervisedUserCircleIcon sx={{ marginRight: 1, marginLeft: -1, color: 'green' }} fontSize="small" />}
                    {officeProvider.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box sx={{ display: "flow" }}>
              <Button
                onClick={(evnt) => setOpenModal(true)}
                sx={styles.searchButtons}
                variant="contained"
              >
                Advanced Search
              </Button>
              <Button
                onClick={clearSearchHandler}
                sx={styles.searchButtons}
                variant="contained"
              >
                Clear Search
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid xs={12} item sx={{ marginTop: 1, height: 700 }}>
          <Typography
            fontSize={28}
            variant="h1"
            align="left"
            marginBottom={2}
            color={colorsPalette.dark}
          >
            Patient List
          </Typography>
          <StripedDataGrid
            onRowDoubleClick={patientClickHandler}
            disableSelectionOnClick={true}
            loading={isLoading}
            disableColumnSelector
            disableColumnMenu
            experimentalFeatures={{ columnGrouping: true }}
            {...patients}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
            }
            components={{
              NoRowsOverlay: () => (
                <Stack height="100%" alignItems="center" justifyContent="center">
                  No results found
                </Stack>
              ),
              NoResultsOverlay: () => (
                <Stack height="100%" alignItems="center" justifyContent="center">
                  No results found
                </Stack>
              ),
              Footer: () =>
                CustomPatientPagination(
                  Math.ceil(
                    patients.length /
                    (paginationModel ? paginationModel.pageSize! : 1)
                  ),
                  paginationModel.page + 1,
                  _onPageChange
                ),
            }}
            onSortModelChange={(newSortModel) => { _onSortChange(newSortModel); }}
            sortModel={sortModel}
            sortingOrder={["asc", "desc"]}
            columns={columns}
            rows={patients}
            paginationMode="client"
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            page={paginationModel.page}
            onPageChange={(newPage) => _onPageChange(newPage)}
            pageSize={paginationModel.pageSize}
            onPageSizeChange={(newPageSize) => _onPageSizeChange(newPageSize)}
            rowsPerPageOptions={[5, 10, 20, 50]}
            getCellClassName={(params: GridCellParams<number>) => {
              if (measurementColumns[params.field] === undefined || params.value == null || params.value == 0) {
                return "";
              }
              return (!params.row[measurementColumns[params.field]] ? "" : "AlertMeasurement");
            }}
            columnGroupingModel={[
              {
                groupId: "Vital Readings",
                headerAlign: "center",
                children: [
                  { field: "spO2" },
                  { field: "bp" },
                  { field: "pulse" },
                  { field: "weight" },
                  { field: "glucose" },
                ],
              },
            ]}
          />
        </Grid>
        <AdvancedSearch
          manageErrorAlert={manageErrorAlert}
          openModal={openModal}
          setOpenModal={setOpenModal}
          userFilter={patientFilter}
          setUserFilter={setPatientFilter}
          handleResetSearch={resetSearchHandler}
          handleApplyAdvancedSearch={applyAdvancedSearchHandler}
        />
      </Grid>
      {ui_PatientListTasks_modal}
      {ui_PatientListMessaging_modal}
    </ThemeProvider>
  );
};
export default PatientList;
