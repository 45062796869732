import { useEffect } from 'react';
import { useUser } from './useUser';
import { useLocalStorage } from './useLocalStorage';
import { AuthUserModel } from '../Api/Model/user/authModel';
import { useError } from "../hooks/useError";
import Cookies from 'universal-cookie';
import { useFirebaseMessaging } from './useFirebaseMessaging';

export const useAuth = (props: any) => {
  const { manageErrorAlert}= useError();
  const { user, addUser, removeUser } = useUser(manageErrorAlert);
  const { getItem } = useLocalStorage();
  const cookies = new Cookies();
  
  const { activateMessages } = useFirebaseMessaging();

  useEffect(() => {
    const user = getItem('PrismCareUser');

    if (user) {
      addUser({ 
        name: JSON.parse(user)['name'],
        authToken: JSON.parse(user)['authToken'],
        roleId: JSON.parse(user)['roleId'],
        id: JSON.parse(user)['id'],
        expiration: JSON.parse(user)['expiration'],
        authPermissions: JSON.parse(user)['authPermissions']
      });
    }
  }, []);

  const login = async (user: AuthUserModel|null) => {
    if (user != null) {
      addUser(user);
      cookies.set("sessionUser", user, { path: '/' });      

      const permission = await Notification.requestPermission();
      if(permission === 'granted'){
        await activateMessages(user.id, user.authToken || '')
      }
    }
  };
  
  const logout = () => {
    removeUser();
  };
  
  return { user, login, logout };
};