import {
  Autocomplete,
  Button,
  FormControl,
  Grid,
  TextField,
  Typography,
  Divider,
  Paper,
  InputAdornment,
  IconButton,
  CircularProgress,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useCallback, useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { PatientNoteModel } from "../../Api/Model/PatientNoteModel";
import { UserModel } from "../../Api/Model/user/userModel";
import { useAxios } from "../../hooks/useAxios";
import { getFormatedShortDateTimeString } from "../../Shared/DateTime.provider";
import { PatientNoteModal } from "./PatientNotesModal.component";
import { Box } from "@mui/system";
import { Edit, History, Search, SystemUpdateAlt } from "@mui/icons-material";
import { getSessionUser } from "../../Shared/sessionUser.provider";
import { PatientNoteHistoryModal } from "./PatientNotesHistoryModal.component";
import { downloadFileResponse } from "../../Shared/Files.provider";

type PatientNotesType = {
  patientId: string | undefined;
  isInUse: boolean;
};

export const PatientNotesSection = (props: PatientNotesType) => {
  const { manageErrorAlert, showAlertSnack } = useOutletContext<{
    manageErrorAlert: Function;
    showAlertSnack: Function;
  }>();
  const { getAsync, postAsync,updateAsync, axiosError, axiosSuccess } = useAxios();
  const { downloadFileAsync, axiosLoading } = useAxios();
  const [patientNotes, setPatientNotes] = useState<PatientNoteModel[]>([]);
  const [refresh, setRefresh] = useState<boolean>(true);
  const [actualPatientNote,setActualPatientNote]= useState<PatientNoteModel>();
  let { id } = getSessionUser();

  //Get Initial Data Patient Notes
  const getPatientNotes = async () => {
    let filter = authorId !== "" ? "?authorId=" + authorId : "";
    const axiosGet = await getAsync<PatientNoteModel[]>(
      "PatientInfo/GetPatientNotesByPatientId/" + props.patientId + filter,
      {
        PageNumber: 0,
        PageSize: 0,
      }
    );
    setRefresh(false);
    if (!axiosGet) {
      manageErrorAlert(axiosError?.Messages[0]);
      return;
    }
    setPatientNotes(axiosGet);
  };

  //Get Initial Data Author of Patient Notes
  const [authorList, setAuthorList] = useState<UserModel[]>([]);
  const [authorId, setAuthorId] = useState<string>("");
  const [author, setAuthor] = useState<string>("");

  const getAuthors = async () => {
    const axiosGet = await getAsync<UserModel[]>(
      "PatientInfo/GetAuthorOfPatientNotesByPatientId/" + props.patientId + "",
      {
        PageNumber: 0,
        PageSize: 0,
      }
    );

    setRefresh(false);
    if (!axiosGet) {
      manageErrorAlert(axiosError?.Messages[0]);
      return;
    }

    setAuthorList(axiosGet);
  };
  // this does not use axios, interface is declared with Caps, UserModel can't be used here
  function findAuthors(element: any, value: string) {
    return element.firstName + " " + element.lastName === value;
  }

  const downloadNotesHandler = async () => {
    const response = await downloadFileAsync(`PatientInfo/DownloadPatientNotes`, `patientId=${props.patientId}${authorId !== ""?'&authorId='+authorId:authorId}`);
    downloadFileResponse(response, `Patient-Notes-${props.patientId}.xlsx`);
  }

  const authorChangeHandler = (value: string): void => {
    let data: UserModel[] = [...authorList];
    if (value === null) {
      setAuthor("");
      setAuthorId("");
      setRefresh(true);
      return;
    }
    // this does not use axios, interface is declared with Caps, UserModel can't be used here
    const findAuthor: any = data.find((author) => findAuthors(author, value));
    if (findAuthor === undefined) {
      setAuthor("");
      setAuthorId("");
      setRefresh(true);
      return;
    }

    setAuthor(value);
    setAuthorId(findAuthor.userId);
    setRefresh(true);
  };

  useEffect(() => {
    if (!refresh) {
      return;
    }
    getPatientNotes();
    getAuthors();
  }, [refresh]);

  const ui_selectAuthor = (
    <FormControl fullWidth>
      <Autocomplete
        size="medium"
        value={author || null}
        //Needs the event to catch the author change
        onChange={(event, value) => authorChangeHandler(value || "")}
        options={authorList
          // this does not use axios, interface is declared with Caps, UserModel can't be used here
          .map((author: any) => author.firstName + " " + author.lastName)
          .sort((a, b) =>
            a
              .toLowerCase()
              .localeCompare(b.toLowerCase(), "en", { numeric: true })
          )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Search by author"
            variant="outlined"
            fullWidth
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </FormControl>
  );

  // Create a Patient Notes section
  const [openPatientNotesModal, setOpenPatientNotesModal] = useState(false);
  const [openPatientNoteHistoryModal, setOpenPatientNoteHistoryModal] = useState(false);
  const [patientNoteType, setPatientNotesType] = useState<"create" | "update">(
    "create"
  );

  const addNewNotesHandler = (): void => {
    setPatientNotesType("create");
    setActualPatientNote({});
    setOpenPatientNotesModal(true);
  };

  const editNoteHandler = (note:PatientNoteModel): void => {
    setPatientNotesType("update");
    setActualPatientNote(note);
    setOpenPatientNotesModal(true);
  };

  const closePatientNotesModal = useCallback(() => {
    setOpenPatientNotesModal(false);
  }, [setOpenPatientNotesModal]);

  const closePatientNotesHistoryModal = useCallback(() => {
    setOpenPatientNoteHistoryModal(false);
  }, [setOpenPatientNoteHistoryModal]);

  const onPatientNotesModalConfirm = async (patientNote: PatientNoteModel) => {
    if (patientNoteType === "create") {
      patientNote.patientId = props.patientId;
      await postAsync("PatientInfo/AddPatientNote", patientNote);
      if (axiosSuccess) {
        showAlertSnack("The note was added successfully.", "success");
        setAuthor("");
        setAuthorId("");
        setRefresh(true);
      } else {
        manageErrorAlert(axiosError?.Messages[0]);
      }
      return;
    }
    const updateResult = await updateAsync<PatientNoteModel>("PatientInfo/EditPatientNote", patientNote.patientNoteId || "", patientNote);
    if (updateResult && axiosSuccess) {
      showAlertSnack("The note was successfully updated.", "success");
      setPatientNotes((dcs) => {
        const result = Array.from(dcs);
        const index = dcs.findIndex(dc => dc.patientNoteId === updateResult?.patientNoteId);
        result[index] = updateResult;
        return result;
      });
      setAuthor("");
      setAuthorId("");
      setRefresh(true);
    }
    else {
      manageErrorAlert(axiosError?.Messages[0]);
    }
  };

  const openNoteHistoryHandler = (note:PatientNoteModel)=>{
    setActualPatientNote(note);
    setOpenPatientNoteHistoryModal(true);
  }

  const formatName = (name:string)=>{
    if(!name || !name.includes(" ")){
      return name;
    }
    let splittedName= name.split(" ");
    let formattedName = splittedName.pop();
    return `${splittedName.join(" ")}, ${formattedName}` ;
  }

  //Patient Note Modal
  const ui_PatientNote_modal = (
    <PatientNoteModal
      open={openPatientNotesModal}
      closeHandler={closePatientNotesModal}
      type={patientNoteType}
      onConfirm={onPatientNotesModalConfirm}
      patientNoteParam={actualPatientNote}
    />
  );

  const ui_PatientNoteHistory_modal = (
    <PatientNoteHistoryModal
      open={openPatientNoteHistoryModal}
      closeHandler={closePatientNotesHistoryModal}
      boxHeaderText={boxHeaderText}
      patientNoteParam={actualPatientNote}
      formatName={formatName}
    />
  );

  const ui_addBtn = (
    <Button
      sx={{ height: "98%" }}
      variant="contained"
      color="success"
      onClick={addNewNotesHandler}
      disabled={props.isInUse}
    >
      <AddIcon sx={{ marginRight: 1 }} />
      Add Notes
    </Button>
  );

  function boxHeaderText(text: string, isBold: boolean = false): JSX.Element {
    const fontWeight: string = isBold ? "bold" : "regular";
    const fontSize: number = isBold ? 21 : 19;
    return (
      <Box
        sx={{
          display: "inline-flex",
          fontSize: fontSize,
          paddingLeft: 2,
          alignItems: "center",
          fontWeight: fontWeight,
        }}
      >
        {text}
      </Box>
    );
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              {ui_selectAuthor}
            </Grid>
            <Grid item xs={6} sx={{ display: "flex", justifyContent: "right" }}>
              {ui_addBtn}
              <Button
                sx={{ height: "98%", marginLeft: 1 }}
                variant="contained"
                color="primary"
                disabled={
                  axiosLoading ||
                  (patientNotes && patientNotes.length < 1)
                }
                onClick={downloadNotesHandler}
              >
                <SystemUpdateAlt sx={{ marginRight: 1 }} />
                {!axiosLoading ? "Download Notes" : "Downloading..."}
              </Button>
              {axiosLoading && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: "primary",
                    position: "absolute",
                    marginTop: 2,
                    marginRight: 12,
                  }}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ maxWidth: 150, width: 150 }}>
          {patientNotes.length > 0 && patientNotes ? (
            patientNotes
              .sort((a, b) => (a.createdDate! > b.createdDate! ? -1 : 1))
              .map((note) => (
                <>
                  <Divider
                    sx={{ marginTop: 2, marginBottom: 2 }}
                    flexItem
                    orientation="horizontal"
                    variant="middle"
                  />
                  <Paper
                    style={{
                      border: "1px solid lightgray",
                      padding: 10,
                      marginBottom: 10,
                    }}
                    variant="outlined"
                  >
                    <Grid container>
                      <Grid sx={{display:'flex',alignItems:"center"}} item xs={10}>
                        <Typography variant="h6">
                          {boxHeaderText(!note.modifiedDate?"Created:":"Amended:", false)}
                          {boxHeaderText(
                            getFormatedShortDateTimeString(new Date(!note.modifiedDate?note.createdDate!:note.modifiedDate)),
                            true
                          )}
                          {boxHeaderText("by", false)}
                          {boxHeaderText(formatName(note.createBy!), true)}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Box sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: 'end',
                          marginRight: 0,
                          width: '100%',
                        }} >
                          <IconButton sx={{visibility:(note.createById ===id)?'visible':'hidden'}} 
                            disabled={!(note.createById ===id)|| props.isInUse } onClick={e=> editNoteHandler(note)}>
                            <Edit />
                          </IconButton>
                          <IconButton  sx={{visibility:(!!note.modifiedDate)?'visible':'hidden'}} onClick={e=>openNoteHistoryHandler(note)}>
                            <History />
                          </IconButton>
                        </Box>
                      </Grid>
                    </Grid>

                    <Divider
                      orientation="horizontal"
                      variant="middle"
                      flexItem
                      sx={{ marginBottom: 1, marginTop: 1 }}
                    />
                    <Box
                      sx={{
                        paddingLeft: 2,
                      }}
                    >
                      <pre
                        style={{
                          fontFamily: "inherit",
                          overflowX: "hidden",
                          overflowY: "auto",
                          width: "100%",
                          marginLeft: "auto",
                          marginRight: "0",
                          fontSize: 15,
                          wordWrap: "break-word",
                          whiteSpace: 'pre-line',
                          maxHeight: 250,
                          display: 'inline'
                        }}
                      >
                          {note.note}
                      </pre>
                    </Box>
                  </Paper>
                </>
              ))
          ) : (
            <>
              <Divider
                sx={{ marginTop: 2, marginBottom: 2 }}
                flexItem
                orientation="horizontal"
                variant="middle"
              />
              <Typography>
                {boxHeaderText("There are no notes to display", true)}
              </Typography>
            </>
          )}
        </Grid>
      </Grid>
      {ui_PatientNote_modal}
      {ui_PatientNoteHistory_modal}
    </>
  );
};
