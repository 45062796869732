import { Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { PrismCareCustomAccordion } from "../../layout/CustomAccordion";
import { useState, useEffect } from "react";
import EmergencyContact from "../EmergencyContact/EmergencyContact.component";
import { PatientOrder } from "./patientorder/PatientOrder.component";
import { PatientThresholds } from "./patientthresholds/PatientThresholds.component";
import { PatientCarePlans } from "./patientcareplans/PatientCarePlans.component";
import { PatientDiseaseConditions } from "./patientdiseaseconditions/PatientDiseaseConditions.component";
import { PatientMedications } from "./patientmedications/PatientMedications.component";
import PatientDepartmentAssignment from "./PatientDepartmentAssignment/PatientDepartmentAssignment.component";
import { colorsPalette } from "../../constants/styles.constants";

const PatientInfo = (props: any) => {
  const { CustomAccordion, CustomAccordionSummary, CustomAccordionDetails } =
    PrismCareCustomAccordion;
  const { manageErrorAlert, showAlertSnack, isInUse } = props;
  const [isPatientInUse, setIsPatientInUse] = useState(false);
  const [carePlanStatus, setCarePlanStatus] = useState(0);
  let carePlanStatusDictionary: { [key: number]: string } = { 0: '-moz-initial', 1: colorsPalette.yellow, 2: colorsPalette.red };

  useEffect(() => {
    setIsPatientInUse(isInUse);
  }, [isInUse]);

  return (
    <>
      <CustomAccordion>
        <CustomAccordionSummary
          sx={{ height: 75 }}
          expandIcon={<ExpandMoreIcon fontSize="large" />}
        >
          <Typography sx={{ fontWeight: "bold", fontSize: 22 }}>
            Patient Orders
          </Typography>
        </CustomAccordionSummary>
        <CustomAccordionDetails>
          <PatientOrder showAlertSnack={showAlertSnack} isPatientInUse={isInUse} manageErrorAlert={manageErrorAlert} />
        </CustomAccordionDetails>
      </CustomAccordion>
      <CustomAccordion>
        <CustomAccordionSummary
          sx={{ height: 75 }}
          expandIcon={<ExpandMoreIcon fontSize="large" />}
        >
          <Typography sx={{ fontWeight: "bold", fontSize: 22 }}>
            Department Assignment
          </Typography>
        </CustomAccordionSummary>
        <CustomAccordionDetails>
          <PatientDepartmentAssignment isPatientInUse={isInUse} isAdding={false} />
        </CustomAccordionDetails>
      </CustomAccordion>
      <CustomAccordion>
        <CustomAccordionSummary
          sx={{ height: 75 }}
          expandIcon={<ExpandMoreIcon fontSize="large" />}
        >
          <Typography sx={{ fontWeight: "bold", fontSize: 22 }}>
            Care Team Assignment
          </Typography>
        </CustomAccordionSummary>
        <CustomAccordionDetails>Care Team module</CustomAccordionDetails>
      </CustomAccordion>
      <CustomAccordion>
        <CustomAccordionSummary
          sx={{ height: 75 }}
          expandIcon={<ExpandMoreIcon fontSize="large" />}
        >
          <Typography sx={{ fontWeight: "bold", fontSize: 22 }}>
            Alert Thresholds
          </Typography>
        </CustomAccordionSummary>
        <CustomAccordionDetails>
          <PatientThresholds showAlertSnack={showAlertSnack} isPatientInUse={isInUse} manageErrorAlert={manageErrorAlert} />
        </CustomAccordionDetails>
      </CustomAccordion>
      <CustomAccordion>
        <CustomAccordionSummary
          sx={[{ height: 75, bgcolor: carePlanStatusDictionary[carePlanStatus] }]}
          expandIcon={<ExpandMoreIcon fontSize="large" />}
        >
          <Typography sx={{ fontWeight: "bold", fontSize: 22,color:carePlanStatus == 2?colorsPalette.white:colorsPalette.dark }}>
            Care Plan
          </Typography>
        </CustomAccordionSummary>
        <CustomAccordionDetails>
          <PatientCarePlans setCarePlanStatus={setCarePlanStatus} showAlertSnack={showAlertSnack} isPatientInUse={isInUse} manageErrorAlert={manageErrorAlert} />
        </CustomAccordionDetails>
      </CustomAccordion>
      <CustomAccordion>
        <CustomAccordionSummary
          sx={{ height: 75 }}
          expandIcon={<ExpandMoreIcon fontSize="large" />}
        >
          <Typography sx={{ fontWeight: "bold", fontSize: 22 }}>
            Disease Conditions
          </Typography>
        </CustomAccordionSummary>
        <CustomAccordionDetails>
          <PatientDiseaseConditions showAlertSnack={showAlertSnack} isPatientInUse={isInUse} manageErrorAlert={manageErrorAlert} />
        </CustomAccordionDetails>
      </CustomAccordion>
      <CustomAccordion>
        <CustomAccordionSummary
          sx={{ height: 75 }}
          expandIcon={<ExpandMoreIcon fontSize="large" />}
        >
          <Typography sx={{ fontWeight: "bold", fontSize: 22 }}>
            Medications
          </Typography>
        </CustomAccordionSummary>
        <CustomAccordionDetails>
          <PatientMedications showAlertSnack={showAlertSnack} isPatientInUse={isInUse} manageErrorAlert={manageErrorAlert} />
        </CustomAccordionDetails>
      </CustomAccordion>
      <CustomAccordion>
        <CustomAccordionSummary
          sx={{ height: 75 }}
          expandIcon={<ExpandMoreIcon fontSize="large" />}
        >
          <Typography sx={{ fontWeight: "bold", fontSize: 22 }}>
            Emergency Contact
          </Typography>
        </CustomAccordionSummary>
        <CustomAccordionDetails>
          <EmergencyContact isPatientInUse={isPatientInUse} />
        </CustomAccordionDetails>
      </CustomAccordion>
    </>
  );
};

export default PatientInfo;
