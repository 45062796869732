import { createContext } from 'react';
import { AuthUserModel } from '../Api/Model/user/authModel';
import { useAuth } from '../hooks/useAuth';

interface AuthContext {
  user: AuthUserModel | null;
  setUser: (user: AuthUserModel | null) => void;
  logoutuser: () => void;
}

export const AuthContext = createContext<AuthContext>({
  user:null,
  setUser: () => {},
  logoutuser: ()=>{}
});

const AuthContextProvider = (props:any)=>{
  const { user, login, logout } = useAuth(props);

  return (<AuthContext.Provider value={{ user:user, setUser: login,logoutuser:logout }}>
    {props.children}
  </AuthContext.Provider>);
};

export default AuthContextProvider;