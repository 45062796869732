import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { ReasonsForEditingModel } from '../../Api/Model/Patient/PatientTimeTracker/PatientTimeTrackerModel';
import { TimeTrackingRegex } from '../../constants/validationRegex';
import { useAxios } from '../../hooks/useAxios';
import { CustomDialog } from '../Shared/Modal/Modal.component';

type TimeTrackingModalProps = {
    open: boolean,
    handleClose: Function,
    onConfirm: Function,
    time: string
};

export const PatientiTimeTrackingModal = (props: TimeTrackingModalProps) => {
    const [newTime, setNewTime] = useState<string>("");
    const [reason, setReason] = useState<ReasonsForEditingModel>();
    const [reasons, setReasons] = useState<ReasonsForEditingModel[]>([]);
    const [customReason, setCustomReason] = useState<string>("");
    const [requireCustomReason, setRequireCustomReason] = useState(false);
    const { getAsync, axiosSuccess } = useAxios();

    const closeModal = () => props.handleClose();
    const onConfirm = () => {
        closeModal();
        !!props.onConfirm && props.onConfirm(newTime, reason, customReason);
    }

    useEffect(() => {
        if(props.open){
            setNewTime(props.time);
            setCustomReason('');
            setReason(undefined);
            setRequireCustomReason(false);
        }
    }, [props.open]);

    useEffect(() => {
        const getReasonsForEditingOptions = async () => {
            const reasons = await getAsync<ReasonsForEditingModel[]>('PatientTimeTracker/GetReasonsForEdit');
            if (axiosSuccess && reasons) {
                const reasonOther = reasons.find(rsn => rsn.catalogName === 'Other') || {} as ReasonsForEditingModel;
                const filteredReasons = reasons.filter(rsn => rsn.id !== reasonOther?.id);
                const allReasons = [...filteredReasons, reasonOther] || [] as ReasonsForEditingModel[];
                setReasons(allReasons);
            }
        }
        getReasonsForEditingOptions();
    }, [])

    const timeFormatValidator = (value: string) => {
        return TimeTrackingRegex.test(value);
      };

    const validationErrors = {
        ...(isEmpty(newTime?.trim()) && {
            Time: "New time is required",
        }),
        ...(!isEmpty(newTime?.trim()) && props.time === newTime && {
            Time: "A new time value is required",
        }),
        ...(!isEmpty(newTime?.trim()) && !timeFormatValidator(newTime?.trim()) && {
            Time: "Time does not match the expected format.",
        }),
        ...(reason === undefined && {
            Reason: "Reason is required",
        }),
        ...(reason !== undefined && reason.catalogName === 'Other' && isEmpty(customReason.trim()) && {
            CustomReason: "Your reason is required",
        })
    };

    const handleSetNewTimer = (event: any) => setNewTime(event.target.value);

    const handleSetReason = (event: any) => {
        const current =  reasons[event.target.value - 1];
        setReason(current);
        setRequireCustomReason(current?.catalogName === "Other");
    };

    const handleSetCustomeReason = (event: any) => setCustomReason(event.target.value);

    const content = <>
        <TextField
            id="time-tracker-new-value-input"
            type={"text"}
            onChange={handleSetNewTimer}
            className='mb-2 mt-3'
            value={newTime}
            error={!!validationErrors.Time}
            label='Timer new value'
            helperText='The format should be MMM:SS'
            variant="outlined"
            autoComplete='off'
            required
            fullWidth
        />
        <FormControl fullWidth className='mt-3'>
            <InputLabel id="demo-simple-select-label">Select a reason</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                defaultValue=''
                label="Select a reason"
                onChange={handleSetReason}
                error={!!validationErrors.Reason}
            >
                {reasons && reasons.length > 0 && reasons.map((rsn: ReasonsForEditingModel) => {
                    const index = reasons.indexOf(rsn) + 1;
                    return <MenuItem key={rsn.id} value={index}>{rsn.catalogName}</MenuItem>
                })}
            </Select>
        </FormControl>
        {requireCustomReason && <TextField
            id="time-tracker-reason-input"
            type={"text"}
            onChange={handleSetCustomeReason}
            className='my-3'
            value={customReason}
            error={!!validationErrors.CustomReason}
            label="Tell us your reason"
            variant="outlined"
            autoComplete='off'
            required
            fullWidth
        />}
    </>;

    return <CustomDialog
        isOkButtonDisabled={(Object.keys(validationErrors).length) > 0}
        open={props.open}
        onConfirm={onConfirm}
        handleClose={props.handleClose}
        title='Update Time Tracker'
        subTitle='To update the time tracker manually you must provide the new time value and a reason for editting.'
        size='xs' >
        {content}
    </CustomDialog>
}
