import { Close } from "@mui/icons-material";
import { IconButton, InputAdornment } from "@mui/material";

export const handleClearSelect = (handler: (event: any) => void) =>
  handler({ target: { value: "" } });

export const CleanButton = (
  handler: (event: any) => void
): JSX.Element => {
  return (
    <InputAdornment position="start">
      <IconButton onClick={() => handleClearSelect(handler)}>
        <Close />
      </IconButton>
    </InputAdornment>
  );
};