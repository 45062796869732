import { Backdrop, Box, Button, Fade, Grid, Modal, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { MedicationsModel } from '../../Api/Model/MedicationsModel';
import { confirmationModalStyle } from "../../constants/styles.constants";
import { isEmpty } from "lodash";

type MedicationModalProps = {
    open: boolean,
    handleClose: Function,
    onConfirm: Function,
    type: "create" | "update",
    description?: string,
    name?: string,
    medicationParam?: MedicationsModel
};

export const MedicationModal = (props: MedicationModalProps) => {
    const [medication, setMedication] = useState<MedicationsModel>({} as MedicationsModel);

    const closeModal = () => props.handleClose();
    const onConfirm = () => {
        closeModal();
        !!props.onConfirm && props.onConfirm(medication);
    }

    useEffect(() => {
        if (!!props.medicationParam) {
            setMedication(props.medicationParam);
        }
        else {
            setMedication({} as MedicationsModel);
        }
    }, [props.open]);

    const validationErrors = {
        ...(isEmpty(medication.catalogName?.trim()) && {
            CatalogName: "Name is required",
        }),
        ...(medication.catalogName?.trim().length < 5 && {
            CatalogName: "Name must have more than 5 characters",
        }),
        ...(isEmpty(medication.description?.trim()) && {
            Description: "Description is required",
        })
    };

    const handleSetTextField = (event: any) => {
        let fieldS = event.target.id.toString();
        setMedication((dc: MedicationsModel) => {return { ...dc, [fieldS]: event.target.value }});
      };

    return <Modal
        open={props.open}
        onClose={closeModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}>
        <Fade in={props.open}>
            <Box sx={confirmationModalStyle}>
                <Typography className='mb-4' id="modal-title" variant="h6" component="h2">{props.type === 'create' ? 'Create' : 'Update'} Medication</Typography>

                <Grid container spacing={2} width={"100%"}>
                    <Grid item xs={12}>
                        <TextField
                            id="catalogName"
                            type={"text"}
                            onChange={handleSetTextField}
                            label="Name"
                            className='mb-2'
                            value={medication.catalogName || ""}
                            error={!!validationErrors.CatalogName}
                            helperText={validationErrors.CatalogName}
                            variant="outlined"
                            disabled={!!props.medicationParam}
                            required
                            inputProps={{
                                tabIndex: 0,
                                maxLength: 50,
                                minLength: 5,
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="description"
                            type={"text"}
                            onChange={handleSetTextField}
                            value={medication.description || ""}
                            label="Description"
                            error={!!validationErrors.Description}
                            helperText={validationErrors.Description}
                            variant="outlined"
                            inputProps={{
                                tabIndex: 0,
                                maxLength: 50,
                                minLength: 2,
                            }}
                            required
                            fullWidth
                        />
                    </Grid>
                </Grid>

                <Box className="mt-4" sx={{ display: 'flex', justifyContent: 'flex-end', gap: '5px' }}>
                    <Button onClick={closeModal} variant="contained" color="inherit" size="small">Cancel</Button>
                    <Button onClick={onConfirm} variant="contained" color='success' size="small" disabled={!isEmpty(validationErrors)}>Save</Button>
                </Box>
            </Box>
        </Fade>
    </Modal>
}
