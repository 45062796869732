import React, { ReactNode, createContext, useEffect, useState } from 'react';
import { PatientModel } from '../Api/Model/PatientModel';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { usePatient } from '../hooks/usePatient';

interface ProviderContextType {
    patientDepartmentHasChanged: boolean;
    setPatientDepartmentHasChanged: React.Dispatch<React.SetStateAction<boolean>>;
    currentPatient: PatientModel | undefined;
    setCurrentPatient: React.Dispatch<React.SetStateAction<PatientModel | undefined>>;
    patientIsReady: boolean;
    setPatientIsReady: React.Dispatch<React.SetStateAction<boolean>>;
}

interface ProviderContextProps {
    children: ReactNode;
}

const defaultValues: ProviderContextType = {
    patientDepartmentHasChanged: false,
    setPatientDepartmentHasChanged: () => { },
    currentPatient: undefined,
    setCurrentPatient: () => { },
    patientIsReady: false,
    setPatientIsReady: () => { },
};

// Context
export const PatientContext = createContext<ProviderContextType>(defaultValues);

// Context component
export const PatientContextProvider = ({ children }: ProviderContextProps) => {
    const { manageErrorAlert, showAlertSnack } = useOutletContext<{ manageErrorAlert: Function, showAlertSnack: Function }>();
    const { checkPatientExist, getPatientById } = usePatient(null, manageErrorAlert, showAlertSnack);
    const [patientDepartmentHasChanged, setPatientDepartmentHasChanged] = useState<boolean>(false);
    const [patientIsReady, setPatientIsReady] = useState<boolean>(false);
    const [currentPatient, setCurrentPatient] = useState<PatientModel | undefined>(undefined);
    const parameter = useParams();
    const navigate = useNavigate();

    const getPatient = async (patientId: string) => {
        let responsed = await getPatientById(patientId);
        if (!responsed) return;
        setCurrentPatient(responsed);
        setPatientIsReady(true);
    };

    const getPatientExists = async (patientId: string) => {
        const patientExists = await checkPatientExist(patientId);
        if (!patientExists) {
            navigate("/patientlist", { replace: true });
            manageErrorAlert("Patient not found");
            return;
        }

        await getPatient(patientId);
    }

    useEffect(() => {
        if (patientDepartmentHasChanged) {
            setPatientDepartmentHasChanged(false);
        }
    }, [patientDepartmentHasChanged]);

    useEffect(() => {
        getPatientExists(parameter.id as string);
    }, []);

    const value = {
        patientDepartmentHasChanged,
        setPatientDepartmentHasChanged,
        currentPatient,
        setCurrentPatient,
        patientIsReady,
        setPatientIsReady
    };

    return (
        <PatientContext.Provider value={value}>
            {children}
        </PatientContext.Provider>
    );
};