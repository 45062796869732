import { Box, Grid, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { isEmpty,isNaN } from "lodash";
import { ThresholdDefaultModel } from '../../Api/Model/ThresholdDefaultModel';
import { CustomDialog } from '../Shared/Modal/Modal.component';

type AlertDefaultsModalProps = {
    open: boolean,
    handleClose: Function,
    onConfirm: Function,
    description?: string,
    name?: string,
    alertDeafaultParam?: ThresholdDefaultModel
};

export const AlertDefaultModal = (props: AlertDefaultsModalProps) => {
  const [valuesError,setValuesError]=useState({both:false,minimum:false,maximum:false});
  const [alertDefault, setAlertDefault] = useState<ThresholdDefaultModel>(
    {limitValues:[{maximum:1,minimum:0}]} as ThresholdDefaultModel
  );

  const closeModal = () => props.handleClose();
  const onConfirm = async () => {
    closeModal();
    !!props.onConfirm && props.onConfirm(alertDefault);
  };

  useEffect(() => {
    if (!props.open) {
      return;
    }

    if (!!props.alertDeafaultParam) {
      setAlertDefault(props.alertDeafaultParam);
    } else {
      setAlertDefault({} as ThresholdDefaultModel);
    }
  }, [props.open]);

  useEffect(() => {
    setValuesError({ minimum: (+1 > +alertDefault?.limitValues[0].minimum), maximum:(+1 > +alertDefault?.limitValues[0].maximum),both: (+alertDefault.limitValues[0].maximum <= +alertDefault?.limitValues[0].minimum)});
  }, [alertDefault.limitValues]);


  const validationErrors = {
    ...(alertDefault.limitValues &&
      isEmpty(alertDefault.limitValues[0].minimum?.toString()) && {
        Minimum: "Minimum value is required with valid numbers",
      }),
    ...(alertDefault.limitValues &&
      isEmpty(alertDefault.limitValues[0].maximum?.toString()) && {
        Maximum: "Maximum value is required with valid numbers",
      }),
    ...(alertDefault.limitValues &&
      isNaN(alertDefault.limitValues[0].minimum) && {
        Minimum: "Minimum must have numerical values",
      }),
    ...(alertDefault.limitValues &&
      isNaN(alertDefault.limitValues[0].maximum) && {
        Maximum: "Maximum must have numerical values",
      }),
    ...(alertDefault.limitValues && valuesError.both && {
        Maximum: "This value must be greater than the minimun value",
        Minimum: "This value must be lower than the maximum value",
      }),
      ...(alertDefault.limitValues && valuesError.minimum && {
        Minimum: "This value must be at least 1",
      }),
      ...(alertDefault.limitValues && valuesError.maximum && {
        Maximum: "This value must be at least 1",
      }),
  };

  const handleSetMaximumOrMinimum = (event: any) => {
    if (
      (!(typeof +event.target.value === "number") ||
      !(+event.target.value === +event.target.value) ||
      !(+event.target.value > 0) && event.target.value !== "") 
    ) {
      return;}
    let fieldS = event.target.id.toString();
    if (fieldS === "maximum") {
      setAlertDefault((dc: ThresholdDefaultModel) => {
        return {
          ...dc,
          limitValues: [
            { minimum: dc.limitValues[0].minimum, maximum: event.target.value},
          ],
        };
      });
      return;
    }
    setAlertDefault((dc: ThresholdDefaultModel) => {
      return {
        ...dc,
        limitValues: [
          { maximum: dc.limitValues[0].maximum, minimum: event.target.value},
        ],
      };
    });
  };

  return (
    <CustomDialog
      isOkButtonDisabled={!isEmpty(validationErrors)}
      open={props.open}
      onConfirm={onConfirm}
      handleClose={closeModal}
      title={"Update " + alertDefault.measurementName + " Alert Default"}
      subTitle=""
      size="xs"
    >
      <Box sx={{ marginTop: 2 }}>
        <Grid container spacing={2} width={"100%"}>
          <Grid item xs={12}>
            <TextField
              id="minimum"
              name="minimum"
              type={"tel"}
              onChange={handleSetMaximumOrMinimum}
              label="Minimum"
              className="mb-2"
              InputLabelProps={{shrink:true}}
              value={
                alertDefault.limitValues
                  ? alertDefault.limitValues[0].minimum
                  : ""
              }
              error={!!validationErrors.Minimum}
              helperText={validationErrors.Minimum}
              variant="outlined"
              required
              fullWidth
              inputProps={{
                tabIndex: 0,
                maxLength: 6,
                minLength: 1,
                lang:"en-US"
              }}
              autoComplete="off"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="maximum"
              name="maximum"
              type={"tel"}
              onChange={handleSetMaximumOrMinimum}
              value={
                alertDefault.limitValues
                  ? alertDefault.limitValues[0].maximum
                  : ""
              }
              label="Maximum"
              error={!!validationErrors.Maximum}
              helperText={validationErrors.Maximum}
              variant="outlined"
              InputLabelProps={{shrink:true}}
              inputProps={{
                tabIndex: 1,
                maxLength: 6,
                minLength: 1,
                lang:"en-US"
              }}
              required
              fullWidth
              autoComplete="off"
            />
          </Grid>
        </Grid>
      </Box>
    </CustomDialog>
  );
}
