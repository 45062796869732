import { Box, Card, CardContent, CardHeader, ThemeProvider } from '@mui/material';
import React from 'react';
import PRISMCareTheme from '../../../layout/PRISMCareTheme';

interface PageWrapperProps {
    children: React.ReactNode;
    title?: string;
    subtitle?: string;
}

const PageWrapper = (props: PageWrapperProps) => {
    return (
        <Box className="noBorder">
            <ThemeProvider theme={PRISMCareTheme}>
                <Card className="noBorder">
                    {props.title && <CardHeader title={props.title} subheader={props.subtitle} />}
                    <CardContent sx={{maxHeight:'100%'}}>
                        {props.children}
                    </CardContent>
                </Card>
            </ThemeProvider>
        </Box>
    );
};

export default PageWrapper;