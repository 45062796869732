import { Stack } from "@mui/material";

export const colorsPalette = {
  secondary: "#e0e0e0",
  success: "#28a745",
  darkGray: "#242021",
  darkRed: "#8b0000",
  darkGrayAlt: "#525252",
  lightGray: "#aeafaf",
  lightShadeGray: "#ededed",
  gray: "#9b999a",
  dark: "#000",
  red: "#E92926",
  yellow: "#FCE655",
  orange: "#FF8E1E",
  blue: "#0d47a1",
  deepSkyBlue: "#00b0ff",
  green: "#4caf50",
  white: "#fff",
  purple: "#800080",
}

export const modalStyle = {
    position:'absolute' as 'absolute',
    top:'50%',
    left:'50%',
    transform:'translate(-50%,-50%)',
    width:600,
    display:'block',
    height: '64%',
    overflow:'auto',
    bgcolor: colorsPalette.white,
    p:4
};

export const confirmationModalStyle = {
    position:'absolute' as 'absolute',
    top:'50%',
    left:'50%',
    transform:'translate(-50%,-50%)',
    width:400,
    display:'block',
    height: 'auto',
    overflow:'hidden',
    bgcolor: 'background.paper',
    boxShadow:24,
    borderRadius: '10px',
    p:3
};

export const CustomNoRowsReportOverlay = (message:string) =>  (<Stack height={"100%"} alignItems="center" justifyContent={"center"} >{message}</Stack>);

export const SelectSizeProps= {
  PaperProps:{
    style:{
      maxHeight:255,
      width:250
    }
  }
}

export const muiPalette = {
  success: {
    main: colorsPalette.success,
    contrastText: colorsPalette.white,
  },
  secondary: {
    main: colorsPalette.secondary,
    contrastText: "#525252",
  },
};