import { Cancel, Create, AddCircle } from "@mui/icons-material";
import { ListItem, Chip, IconButton, Box, Button } from "@mui/material";
import { useCallback, useContext, useEffect, useState } from "react";
import { PatientCarePlanModel } from "../../../Api/Model/PatientCarePlan";
import { useAxios } from "../../../hooks/useAxios";
import { PatientInfoComponentParams } from "../../../Shared/PatientInfoComponentParams";
import ConfirmationModalComponent from "../../Shared/ConfirmationModal/ConfirmationModal.component";
import { PatientCarePlanModal } from "./PatientInfoPCarePlanModal";
import { PatientContext } from "../../../Context/PatientContext";
import { emptyGUID } from "../../../constants/guid.contast";
import { getDateIsBetweenLast30Days, getFirstDateIsBiggerThatSecondDate } from "../../../Shared/DateTime.provider";
import { colorsPalette } from "../../../constants/styles.constants";

interface PatientCarePlanProps extends PatientInfoComponentParams {
  setCarePlanStatus: Function;
}


const countTimes = (props: PatientCarePlanModel) => {
  return (
    Number(props.morning) +
    Number(props.afternoon) +
    Number(props.evening) +
    Number(props.anyTime)
  );
};

const countDays = (props: PatientCarePlanModel) => {
  return (
    Number(props.monday) +
    Number(props.tuesday) +
    Number(props.wednesday) +
    Number(props.thursday) +
    Number(props.friday) +
    Number(props.saturday) +
    Number(props.sunday)
  );
};

export const PatientCarePlans = (params: PatientCarePlanProps) => {
  const [patientCarePlan, setPatientCarePlan] = useState<
    PatientCarePlanModel[]
  >([]);
  const [
    openDeletePatientCarePlanConfirmation,
    setOpenDeletePatientCarePlanConfirmation,
  ] = useState(false);
  const [openPatientCarePlanModal, setOpenPatientCarePlanModal] =
    useState(false);
  const [currentPatientCarePlan, setCurrentPatientCarePlan] =
    useState<any>(undefined);
  const [patientCarePlanType, setPatientCarePlanType] = useState<
    "create" | "update"
  >("create");
  const {
    deleteAsync,
    postAsync,
    getAsync,
    updateAsync,
    axiosError,
    axiosSuccess,
  } = useAxios();
  const { showAlertSnack, isPatientInUse, manageErrorAlert, setCarePlanStatus} =
    params;
  const { currentPatient, patientIsReady } = useContext(PatientContext);

  const getPatientCarePlan = async (patientId: string) => {
    if (!patientId) {
      return;
    }
    const axiosGet = await getAsync<PatientCarePlanModel[]>(
      `PatientCarePlan/GetPatientCarePlans?PatientId=${patientId}`
    );
    if (!axiosGet) {
      setPatientCarePlan([]);
      return;
    }
    setPatientCarePlan(axiosGet);
    let hasExpiredCP = axiosGet.some((element: PatientCarePlanModel) => !element.repeatUntilCancelled && !!element.repeatUntil && getFirstDateIsBiggerThatSecondDate(new Date(),new Date(element.repeatUntil!)));
    if (hasExpiredCP) {
      setCarePlanStatus(2);
      return;
    }
    let hasCloseToExpireCP = axiosGet.some((element: PatientCarePlanModel) => !element.repeatUntilCancelled && !!element.repeatUntil && getDateIsBetweenLast30Days(new Date(element.repeatUntil!)));
    if (hasCloseToExpireCP) {
      setCarePlanStatus(1);
      return;
    }
    setCarePlanStatus(0);
  };

  const onDeletePatientCarePlanConfirmation = async () => {
    await deleteAsync(
      "PatientCarePlan/DeletePatientCarePlan",
      currentPatientCarePlan.patientCarePlanId || ""
    );
    if (axiosSuccess) {
      await getPatientCarePlan(currentPatient?.patientId || emptyGUID);
      showAlertSnack(
        "The patient care plan was successfully removed.",
        "success"
      );
    }
  };
  const closeConfirmModalPatientCarePlan = useCallback(() => {
    setOpenDeletePatientCarePlanConfirmation(false);
  }, [setOpenDeletePatientCarePlanConfirmation]);
  const ui_confirm_PatientCarePlan = (
    <ConfirmationModalComponent
      open={openDeletePatientCarePlanConfirmation}
      handleClose={closeConfirmModalPatientCarePlan}
      type="deletion"
      onConfirm={onDeletePatientCarePlanConfirmation}
    />
  );

  const openConfirmModalPatientCarePlan = (actual: PatientCarePlanModel) => {
    setCurrentPatientCarePlan(actual);
    setOpenDeletePatientCarePlanConfirmation(true);
  };

  const handleRemovePatientCarePlan = (index: any) => {
    let selectedPCarePlan = patientCarePlan[index];
    openConfirmModalPatientCarePlan(selectedPCarePlan);
    getPatientCarePlan(currentPatient?.patientId || emptyGUID);
  };
  const handleEditPatientCarePlan = (event: number) => {
    let selectedPCarePlan = patientCarePlan[event];
    setCurrentPatientCarePlan({ ...selectedPCarePlan });
    setPatientCarePlanType("update");
    setOpenPatientCarePlanModal(true);
  };
  const closePatientCarePlanModal = useCallback(() => {
    setOpenPatientCarePlanModal(false);
  }, [setOpenPatientCarePlanModal]);
  const onPatientCarePlanModalConfirm = async (
    patientCarePlanModel: PatientCarePlanModel
  ) => {
    patientCarePlanModel.patientId= currentPatient?.patientId || emptyGUID;
    if (patientCarePlanType === "create") {
      if (
        patientCarePlan.some((cp)=> cp.patientDeviceType === patientCarePlanModel.patientDeviceType)
      ) {
        showAlertSnack(
          "The patient care plan already exists, nothing added.",
          "info"
        );
        return;
      }
      await postAsync(
        `PatientCarePlan/AddPatientCarePlan`,
        patientCarePlanModel
      );
      if (!axiosSuccess) {
        manageErrorAlert(axiosError?.Messages[0]);
        return;
      }
      showAlertSnack(
        "The patient care plan was added successfully.",
        "success"
      );
      await getPatientCarePlan(currentPatient?.patientId || emptyGUID);
      return;
    }

    await updateAsync<PatientCarePlanModel>(
        "PatientCarePlan/UpdatePatientCarePlan",
        currentPatientCarePlan.PatientCarePlanId || "",
        patientCarePlanModel
      );
      if (!axiosSuccess) {
        manageErrorAlert(axiosError?.Messages[0]);
        return;
      }
      showAlertSnack("Patient care plan was updated successfully.", "success");
      getPatientCarePlan(currentPatient?.patientId || emptyGUID);
  };

  const ui_patientCarePlan_modal = (
    <PatientCarePlanModal
      open={openPatientCarePlanModal}
      handleClose={closePatientCarePlanModal}
      type={patientCarePlanType}
      onConfirm={onPatientCarePlanModalConfirm}
      patientCarePlanParam={currentPatientCarePlan}
      currentPatientId={currentPatient?.patientId || emptyGUID}
    />
  );

  const addNewPatientCarePlan = (): void => {
    setCurrentPatientCarePlan(null);
    setPatientCarePlanType("create");
    setOpenPatientCarePlanModal(true);
  };

  useEffect(() => {
    if(currentPatient === undefined || !patientIsReady)
    {
      return;
    }
    getPatientCarePlan(currentPatient.patientId || emptyGUID);
  }, [patientIsReady]);

  const careplans_ui = (
    <>
      {patientCarePlan.map((input: PatientCarePlanModel, index: any) => (
        <ListItem key={"PCarePlan" + index}>
          <Chip
            sx={{
              "& .MuiChip-icon": {
                order: 1,
                marginRight: "2px",
                cursor: "pointer"
              },
              "& .MuiChip-deleteIcon": {
                order: 2,
              },
              color: (!input.repeatUntilCancelled && !!input.repeatUntil) ?
              (getFirstDateIsBiggerThatSecondDate(new Date(), new Date(input.repeatUntil!)) ? 
              colorsPalette.white:colorsPalette.dark):colorsPalette.dark,
              bgcolor: 
                  (!input.repeatUntilCancelled && !!input.repeatUntil) ?
                    (getFirstDateIsBiggerThatSecondDate(new Date(), new Date(input.repeatUntil!)) ? 
                    colorsPalette.red : getDateIsBetweenLast30Days(new Date(input.repeatUntil!)) ? 
                    colorsPalette.yellow : '-moz-initial') 
                    : '-moz-initial'
            }}
            deleteIcon={
              <IconButton  disabled={isPatientInUse}>
                {" "}
                <Cancel sx={{color:(!input.repeatUntilCancelled && !!input.repeatUntil) ?
                (getFirstDateIsBiggerThatSecondDate(new Date(), new Date(input.repeatUntil!)) ? 
                colorsPalette.white:colorsPalette.gray):colorsPalette.gray}} />{" "}
              </IconButton >
            }
            onDelete={() => handleRemovePatientCarePlan(index)}
            label={`Take ${input.patientDeviceType} ${countTimes(
              input
            )} time(s) per day, ${countDays(input)} day(s) per week`}
            icon={
              <IconButton
                disabled={isPatientInUse}
                onClick={(e) => {
                  handleEditPatientCarePlan(index);
                }}
              >
                {" "}
                <Create sx={{color:(!input.repeatUntilCancelled && !!input.repeatUntil) ?
                (getFirstDateIsBiggerThatSecondDate(new Date(), new Date(input.repeatUntil!)) ? 
                colorsPalette.white:colorsPalette.gray):colorsPalette.gray}} />{" "}
              </IconButton>
            }
          />
        </ListItem>
      ))}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          disabled={isPatientInUse}
          onClick={addNewPatientCarePlan}
          sx={{
            width: 3 / 10,
            height: 40,
            marginBlock: 2,
            fontWeight: "bold",
            textTransform: "capitalize",
          }}
          color="success"
          variant="contained"
        >
          <AddCircle /> Add to care plan
        </Button>
      </Box>
    </>
  );

  return <>
  {ui_confirm_PatientCarePlan}
  {ui_patientCarePlan_modal}
  {careplans_ui}
  </>;
};
