import logo from "../../resources/img/logo.png";
import { useAuth } from "../../hooks/useAuth";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Container,
  CssBaseline,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  Link,
  Slide,
  Snackbar,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { Email, Lock, Person } from "@mui/icons-material";
import { UserModel } from "../../Api/Model/user/userModel";
import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { themeLogin } from "../../layout/PRISMCareTheme";
import { colorsPalette } from "../../constants/styles.constants";
import { useAxios } from "../../hooks/useAxios";
import { version } from '../../version'
import { emailvalidator } from "../../Api/Model/Shared/Validations/Validations";

const LoginComponent = (props: any) => {
  document.body.style.backgroundColor = colorsPalette.darkGray;

  const [forgotPasswordisOpen, setForgotPasswordisOpen] = useState(false)
  const [userEmail, setUserEmail] = useState("");
  const [alertConfiguration, setAlertConfiguration] = useState({isOpen: false, isError: true, message: ""});
  const [user, setUser] = useState<UserModel>({ userName: "", password: "" });
  const { axiosSuccess, postAsync, axiosError } = useAxios();
  const cookies = new Cookies();

  const { login } = useAuth(props);

  const { getItem, setItem } = useLocalStorage();
  const [remembermeChecked, setRememberMeChecked] = useState(false);  
  const navigate = useNavigate();

  const handleSetTextField = (event: any) => {
    let fieldS = event.target.id.toString();
    let checkValue = event.target.value.replace(" ", "");
    if (fieldS !== "password") {
      checkValue = checkValue.replace(/[^\w ]/g, "");
    }
    setUser({ ...user, [fieldS]: checkValue });
  };

  useEffect(() => {
    if(cookies.get("sessionUser")){
      navigate('/patientlist', { replace: true });
    }
    if (getItem("RememberMe")) {
      setRememberMeChecked(true);
      setUser({ ...user, userName: getItem("RememberedUsr") ?? "" });
    }
  }, []);

  useEffect(() => {
    setUserEmail("");
    if (forgotPasswordisOpen) {
      setUser({...user, userName: ""})
    }
    if (!forgotPasswordisOpen && getItem("RememberMe")) {
      setRememberMeChecked(true);
      setUser({ ...user, userName: getItem("RememberedUsr") ?? "" });
    }
  }, [forgotPasswordisOpen])
  

  const keyPressHandler= (event:any)=>{
    setAlertConfiguration({...alertConfiguration, isOpen: false});
    if (event.keyCode===13 && !forgotPasswordisOpen) {
      loginHandler();
    }
  }

  const loginHandler = async () => {
    if (!isFormValid) {
      return;
    }
    let expDate = new Date();
    expDate.setDate(expDate.getDate() + 1);
    let response: any = await postAsync("Login/Authenticate", { username: user.userName, password: user.password});

    if (!axiosSuccess) {
      setAlertConfiguration({message: axiosError?.Messages[0] || "", isOpen: true, isError: true});
      return;
    }

    if (!response.userId) {
      setAlertConfiguration({message: response, isOpen: true, isError: true});
      return;
    }

    if (response.patientId) {
      setAlertConfiguration({message: "You dont have the necessary permissions to access", isOpen: true, isError: true});
      return;
    }

    login({
      id: response.userId,
      name: response.userName,
      roleId: response.roleId,
      authToken: response.token,
      expiration: expDate,
      authPermissions: response.authPermissions
    });

    if (remembermeChecked) {
      setItem("RememberMe", "true");
      setItem("RememberedUsr", user.userName || "");
    } else {
      setItem("RememberMe", "false");
      setItem("RememberedUsr", "");
    }
    navigate('/patientlist', { replace: true });
  };

  let validationErrors = {
    ...(isEmpty(user.userName?.trim()) && {
      UserName: "Username is required",
    }),
    ...(!isEmpty(user.userName?.trim()) &&
      user.userName!.trim().length < 5 && {
        UserName: "Username must have more than 5 characters",
      }),
    ...(isEmpty(user.password?.trim()) && {
      Password: "Password is required",
    }),
    ...(!isEmpty(user.password?.trim()) &&
      user.password!.trim().length < 8 && {
        Password: "Password must have more than 8 characters",
      }),
  };

  let forgotPasswordValidationErrors = {
    ...(userEmail.trim().length > 0 &&
      !emailvalidator(userEmail.trim()) && {
        UserEmail: "Invalid email format",
      }),
    ...(isEmpty(userEmail.trim()) && {
      UserEmail: "Email required",
    }),
  }

  const isFormValid = isEmpty(validationErrors);

  const onForgotPasswordHandler = (active: boolean) => setForgotPasswordisOpen(active);
  const onUserEmailChangeHandler = (newEmail: string) => setUserEmail(newEmail);
  const onResetPasswordButtonHandler = async() => {
    const resetPasswordResponse = await postAsync(`Login/ResetPassword`, {email: userEmail, username: user.userName});
    if (resetPasswordResponse as string === "Email or user not found") {
      setAlertConfiguration({message: resetPasswordResponse as string || "", isOpen: true, isError: true});
      return;
    }

    setAlertConfiguration({message: resetPasswordResponse as string, isOpen: true, isError: false});
    setForgotPasswordisOpen(false);
  };

  const ui_loginForm: JSX.Element = (
    <>
      <TextField
        required
        autoComplete="false"
        error={!!validationErrors.Password}
        onChange={handleSetTextField}
        id="password"
        label="Password"
        onKeyDownCapture={keyPressHandler}
        type={"password"}
        inputProps={{
          tabIndex: 2,
          maxLength: 15,
          minLength: 3,
        }}
        value={user.password || ""}
        fullWidth
        style={{ backgroundColor: colorsPalette.white }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Lock />
            </InputAdornment>
          ),
        }}
        variant="filled"
      />
      {(!isEmpty(validationErrors)) && (
        <Grid item xs={2} marginTop={2}>
          <Typography
            fontSize={14}
            variant="body2"
            align="center"
            color={"red"}
          >
            {(!!validationErrors.UserName && validationErrors.UserName)}
            {(!!validationErrors.Password && !validationErrors.UserName && validationErrors.Password)}
          </Typography>
        </Grid>
      )}
      <Button
        type="button"
        onClick={loginHandler}
        fullWidth
        variant="contained"
        disabled={!isEmpty(validationErrors)}
        sx={{ mt: 2, mb: 2 }}
      >
        Login
      </Button>

      <Grid paddingBottom={3} item xs={2}>
        <FormControl
          sx={{
            float: "left",
            display: "inline-block",
          }}
        >
          <FormControlLabel
            label={"Remember me"}
            control={
              <Checkbox
                onChange={(event) => {
                  setRememberMeChecked(event?.target.checked);
                }}
                checked={remembermeChecked}
              />
            }
          />
        </FormControl>
        <Typography fontSize={14} variant="body2" align="right" marginTop={1.5}>
          Forgot your password?
        </Typography>
        <Typography fontSize={14} variant="body2" align="right">
          <Link color={"inherit"} href="#" onClick={() => onForgotPasswordHandler(true)}>
            Click here
          </Link>
        </Typography>
      </Grid>
    </>
  );

  const ui_forgotPasswordForm: JSX.Element = (
    <>
      <TextField
        required
        autoComplete="false"
        id="userEmail"
        type="email"
        value={userEmail}
        onChange={(event) => onUserEmailChangeHandler(event.target.value)}
        label="Email"
        fullWidth
        style={{ backgroundColor: colorsPalette.white }}
        onKeyDownCapture={keyPressHandler}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Email />
            </InputAdornment>
          ),
        }}
        variant="filled"
        error={!!forgotPasswordValidationErrors.UserEmail}
      />
      {(!isEmpty(forgotPasswordValidationErrors) || validationErrors.UserName !== undefined) && (
        <Grid item xs={2} marginTop={2}>
          <Typography
            fontSize={14}
            variant="body2"
            align="center"
            color={"red"}
          >
            {!!validationErrors.UserName && validationErrors.UserName}
            {!!forgotPasswordValidationErrors.UserEmail && !validationErrors.UserName && forgotPasswordValidationErrors.UserEmail}
          </Typography>
        </Grid>
      )}
      <Button
        type="button"
        fullWidth
        variant="contained"
        sx={{ mt: 2, mb: 2 }}
        onClick={onResetPasswordButtonHandler}
        disabled={!isEmpty(forgotPasswordValidationErrors) || !!validationErrors.UserName}
      >
        Reset my password
      </Button>
      <Typography fontSize={14} variant="body2" align="right" paddingBottom={3}>
        <Link color={"inherit"} href="#" onClick={() => onForgotPasswordHandler(false)}>
          Return to login page
        </Link>
      </Typography>
    </>
  );
  
  return (
    <ThemeProvider theme={themeLogin}>
      <Snackbar
        TransitionComponent={(props) => <Slide {...props} direction="right" />}
        autoHideDuration={4000}
        open={alertConfiguration.isOpen}
        onClose={() => {
          setAlertConfiguration({ ...alertConfiguration, isOpen: false });
        }}
      >
        <Alert
          onClose={() => {
            setAlertConfiguration({ ...alertConfiguration, isOpen: false });
          }}
          severity={alertConfiguration.isError ? "error" : "success"}
          sx={{ width: "100%" }}
        >
          {alertConfiguration.message}
        </Alert>
      </Snackbar>
      <Container
        sx={{ backgroundColor: colorsPalette.secondary, borderRadius: "4%" }}
        component="main"
        maxWidth="xs"
      >
        <CssBaseline />
        <Box
          sx={{
            marginTop: "60%",
            alignSelf: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Grid>
            <img
              style={{
                height: "80%",
                width: "80%",
                paddingTop: 20,
                display: "block",
                marginLeft: "10%",
                marginRight: "auto",
              }}
              alt="Prism Care Logo"
              src={logo}
            />
          </Grid>
          <Box component="form" noValidate sx={{ mt: 1 }}>
            <TextField
              required
              autoComplete="false"
              id="userName"
              type={"text"}
              onKeyDownCapture={keyPressHandler}
              value={user.userName || ""}
              onChange={handleSetTextField}
              label="Username"
              error={!!validationErrors.UserName}
              fullWidth
              inputProps={{
                tabIndex: 2,
                maxLength: 15,
                minLength: 3,
              }}
              style={{ backgroundColor: colorsPalette.white }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Person />
                  </InputAdornment>
                ),
              }}
              variant="filled"
              sx={{mb: 2}}
            />
            {!forgotPasswordisOpen ? ui_loginForm : ui_forgotPasswordForm}
          </Box>
        </Box>
      </Container>
      <Grid
        container
        justifyContent={"center"}
        display="flex"
        direction={"column"}
        justifyItems="center"
        textAlign={"center"}
        marginTop={2}
      >
        <Typography fontSize={12} variant="body2" color={colorsPalette.gray}>
          {"Copyright © " +
            new Date().getFullYear() +
            " Prism Care®. All rights reserved."}
        </Typography>
        <Typography
          marginTop={2}
          paddingX={"33%"}
          fontSize={12}
          variant="body2"
          color={colorsPalette.gray}
        >
          Access to this system is for authorized users only. Use of this system
          constitutes consent to monitoring, inerception, recoding, reading,
          copying, or capturing by authorized personnel of all activities.
          Unauthorized use of this system is prohibited and subject to criminal
          and civil penalties. We use persistent cookies on our website in order
          to increase our ability to help users find the content they want.
          Persistent cookies will allow us to analyze the effectiveness of the
          site, make design improvements to enhance site performance, which will
          improve customer service overall. By logging in to this site you agree
          terms. For more details, please refer to our Privacy Policy.
        </Typography>
        <Typography fontSize={12} variant="body2" color={colorsPalette.gray} sx={{'fontWeight':'bold', mt:2}}>
          Version: {version}
        </Typography>
      </Grid>
    </ThemeProvider>
  );
};
export default LoginComponent;
