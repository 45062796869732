import { Box, MenuItem, Select, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { DataGrid, gridClasses, GridFooterContainer, GridPagination } from '@mui/x-data-grid';
import { GlobalFontSize } from '../../../constants/global.constants';
import { colorsPalette } from '../../../constants/styles.constants';

const ODD_OPACITY = 0.2;

export function CustomPagination(totalPages: number, page: number, pageChange: Function) {
  let numberOfPages = Array.from(Array(totalPages), (_, i) => i + 1).filter(n => n !== 1);
  return (
    <GridFooterContainer sx={{ justifyContent: 'end' }}>
      <Typography marginTop={-0.35} marginRight={1} color={colorsPalette.darkGray} fontSize={GlobalFontSize} fontWeight={400}>
        {'Page: '}
      </Typography>
      <Select
        defaultValue={""}
        displayEmpty
        SelectDisplayProps={{
          style: {
            alignContent: 'center',
          }
        }}
        sx={{ textAlign: 'right', width: 'auto', height: 20, boxShadow: 'none' }}
        disableUnderline
        label="Jump to page"
        labelId="PageSelect"
        value={(page).toString() || ''}
        variant='standard'
        onChange={(event) => pageChange((+event.target.value - 1))}
        required
      >
        <MenuItem value={1} key={1}>
          {1}
        </MenuItem>
        {numberOfPages && numberOfPages.map((pageNumber: any) =>
          <MenuItem value={pageNumber} key={pageNumber} sx={{ textOverflow: 'unset !important' }}>
            {pageNumber}
          </MenuItem>)
        }
      </Select>
      <GridPagination />
    </GridFooterContainer>
  )
}

export function CustomPatientPagination(totalPages: number, page: number, pageChange: Function) {
  let numberOfPages = Array.from(Array(totalPages), (_, i) => i + 1).filter(n => n !== 1);
  return (
    <GridFooterContainer sx={{ justifyContent: 'end' }}>
      <GridPagination labelDisplayedRows={() => (<Box  sx={{ justifyContent: 'end',width:'auto',display:'inline-flex'}} >
        <Typography display={'flex'} marginTop={-0.35} marginRight={1} color={colorsPalette.darkGray} fontSize={GlobalFontSize} fontWeight={400}>
          {'Page: '}
        </Typography>
        <Select
          defaultValue={""}
          displayEmpty
          SelectDisplayProps={{
            style: {
              alignContent: 'center',
            }
          }}
          sx={{ textAlign: 'right', width: 'auto', height: 20, boxShadow: 'none',display:'flex' }}
          disableUnderline
          label="Jump to page"
          labelId="PageSelect"
          value={(page).toString() || ''}
          variant='standard'
          onChange={(event) => pageChange((+event.target.value - 1))}
          required
        >
          <MenuItem value={1} key={1}>
            {1}
          </MenuItem>
          {numberOfPages && numberOfPages.map((pageNumber: any) =>
            <MenuItem value={pageNumber} key={pageNumber} sx={{ textOverflow: 'unset !important' }}>
              {pageNumber}
            </MenuItem>)
          }
        </Select>
        <Typography display={'flex'} marginTop={-0.35} marginRight={1} color={colorsPalette.darkGray} fontSize={GlobalFontSize} fontWeight={400}>
          {' of ' + (numberOfPages.slice(-1).toString() !== "" ? numberOfPages.slice(-1) : '1')}
        </Typography>
        </Box>)} />
    </GridFooterContainer>
  )
}


const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-selected': {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity,
      ),
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
          theme.palette.action.selectedOpacity +
          theme.palette.action.hoverOpacity,
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  },
}));

export default StripedDataGrid;