import { KeyboardArrowLeftOutlined, KeyboardArrowRightOutlined } from "@mui/icons-material"
import { Box, Button, ButtonGroup, TextField, Typography } from "@mui/material"
import SortableTree from "@nosferatu500/react-sortable-tree";
import { useState } from "react";
import { getNodeKey } from "../../../Reducers/DepartmentStructure.reducer";

interface DepartmentStructureProps {
    treeData: any;
    height: string;
    generateNodeProps: (rowInfo: any) => any;
    canDrop: (dropInfo: any) => boolean;
    canDrag: (dropInfo: any) => boolean;
    onMoveNode: (dropInfo: any) => void;
    onChange: (treeData: any) => void;
    toggleExpand: (expanded: boolean) => void;
    dradAndDropType?: string | undefined;
}

const DepartmentStructureTree = (props: DepartmentStructureProps) => {
    const [searchFocusIndex, setSearchFocusIndex] = useState(0);
    const [searchFoundCount, setSearchFoundCount] = useState<number>(0);
    const [searchString, setSearchString] = useState('');

    const selectPrevMatch = () => {
        setSearchFocusIndex(
            searchFocusIndex !== null ?
                (searchFoundCount + searchFocusIndex - 1) % searchFoundCount :
                searchFoundCount - 1
        )
    }
    const selectNextMatch = () => {
        setSearchFocusIndex(
            searchFocusIndex !== null ?
                (searchFocusIndex + 1) % searchFoundCount :
                0
        )
    }

    const _toggleExpanded = (expanded: boolean) => props.toggleExpand(expanded);

    const no_data_ui = <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        opacity: 0.5,
        height: '100%'
    }}>
        <Typography variant="h5" sx={{ marginTop: 2 }}>No structure data to show</Typography>
    </Box>

    const results_ui = searchFoundCount <= 0 ? 
    <Typography variant="subtitle1" sx={{ ml: 1, mt: 0.5 }}>No results</Typography> :
    searchFoundCount > 1 ?
    <Box sx={{ display: 'flex', marginLeft: 2, justifyContent: 'center', alignItems: 'center' }}>
        <ButtonGroup variant="contained" sx={{ height: '60%' }}>
            <Button color="primary" size="small" startIcon={<KeyboardArrowLeftOutlined />} variant="outlined" onClick={selectPrevMatch} className='deptstruct-move-btn' />
            <Button color="primary" size="small" startIcon={<KeyboardArrowRightOutlined />} variant="contained" onClick={selectNextMatch} className='deptstruct-move-btn' />
        </ButtonGroup>
        <Typography variant="h6" sx={{ ml: 1 }}>{searchFoundCount && searchFoundCount > 0 ? searchFocusIndex + 1 : 0} / {searchFoundCount || 0}</Typography>
    </Box> : 
    <Typography variant="h6" sx={{ ml: 1 }}>{searchFoundCount && searchFoundCount > 0 ? searchFocusIndex + 1 : 0} / {searchFoundCount || 0}</Typography>;

    const tree_ui = <>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex' }}>
                <TextField
                    onChange={ev => setSearchString(ev.target.value)}
                    type='search'
                    autoComplete="off"
                    size="small"
                    label='Search'
                    variant="outlined"
                    value={searchString} />
                {searchString && results_ui}
            </Box>
            <ButtonGroup variant="contained" sx={{ height: '50%' }}>
                <Button color="primary" size="small" variant="outlined" onClick={() => _toggleExpanded(false)}>Collapse</Button>
                <Button color="primary" size="small" variant="contained" onClick={() => _toggleExpanded(true)}>Expand</Button>
            </ButtonGroup>
        </Box>
        <Box sx={{ height: props.height, mt: 2 }}>
            <SortableTree
                canDrag={props.canDrag}
                getNodeKey={getNodeKey}
                canDrop={props.canDrop}
                onMoveNode={props.onMoveNode}
                canNodeHaveChildren={node => node.type !== 'provider'}
                generateNodeProps={props.generateNodeProps}
                onChange={props.onChange}
                searchMethod={(params: any) => {
                    const { node, searchQuery } = params;
                    const { title, subTitle } = node;

                    const titleMatches = title && title.toUpperCase().indexOf(searchQuery.toUpperCase()) > -1;
                    const subTitleMatches = subTitle && subTitle.toUpperCase().indexOf(searchQuery.toUpperCase()) > -1;

                    return searchQuery && (titleMatches || subTitleMatches);
                }}
                searchFinishCallback={matches => {
                    setSearchFoundCount(matches.length)
                    setSearchFocusIndex(matches.length > 0 ? searchFocusIndex % matches.length : 0)
                }}
                searchFocusOffset={searchFocusIndex}
                searchQuery={searchString}
                treeData={props.treeData} 
                dndType={props.dradAndDropType}/>
        </Box>
    </>;

    return (
        props.treeData.length === 0 ? no_data_ui : tree_ui
    );
};

export default DepartmentStructureTree;