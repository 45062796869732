import { useCallback, useEffect, useReducer, useState } from "react";
import { TreeItem } from '@nosferatu500/react-sortable-tree';
import "./DepartmentStructure.component.css";
import '@nosferatu500/react-sortable-tree/style.css';
import { Box, Button, IconButton, Tooltip, Typography } from "@mui/material";
import { AddCircleOutlineOutlined, DeleteOutlineRounded, Edit } from "@mui/icons-material";
import ConfirmationModalComponent from "../Shared/ConfirmationModal/ConfirmationModal.component";
import DepartmentStructureModal from "./DepartmentStructureModal.component";
import {
  departmentStructureReducer,
  departmentStructureInit,
  initializeTree,
  updateTree,
  toggleExpanded,
  changeNode,
  addNode,
  addNodeAsChild,
  deleteNode
} from "../../Reducers/DepartmentStructure.reducer";
import PrismLoading from "../Shared/Loading/Loading.component";
import ProviderModel from "../../Api/Model/DepartmentStructure/ProviderModel";
import { CustomDialog } from "../Shared/Modal/Modal.component";
import { useDepartmentStructure } from "../../hooks/useDepartmentStructure";
import DepartmentStructureRequestModel from "../../Api/Model/DepartmentStructure/DepartmentStructureRequestModel";
import DepartmentStructureTree from "../Shared/DepartmentStructureTree/DepartmentStructureTree.component";
import { useNavigate, useOutletContext } from "react-router-dom";
import PageWrapper from "../Shared/Wrapper/PageWrapper.component";

const DepartementStructure = (props: any) => {
  const { manageErrorAlert } = useOutletContext<{ manageErrorAlert: Function, showAlertSnack: Function }>();
  const [currentRowInfo, setCurrentRowInfo] = useState<any>(null);
  const [loadStructure, setLoadStructure] = useState<boolean>(true);
  const {
    isLoading,
    getDepartmentStructureAsync,
    addDepartmentAsync,
    removeDepartmentStructureAsync,
    updateDepartmentStructureAsync,
    updateDepartmentAsync,
    removeDepartmentAsync,
    addOfficeAsync,
    updateOfficeAsync,
    removeOfficeAsync,
    addProviderAsync,
    updateProviderAsync,
    addDepartmentStructureAsync,
    axiosError
  } = useDepartmentStructure();
  const [componentShouldWait, setComponentShouldWait] = useState<boolean>(false);
  const navigate = useNavigate();

  const [{ treeData }, dispatch] = useReducer(departmentStructureReducer, departmentStructureInit);

  const apiMetodError = useCallback(
    () => {
      setComponentShouldWait(false);
    },
    [],
  );

  useEffect(() => {
    if (axiosError && axiosError?.Code === '401') {
      manageErrorAlert("Unauthorized");
      navigate("/patientlist", { replace: true });
      return;
    }
  }, [axiosError]);

  useEffect(() => {
    const getStructrue = async () => {
      setLoadStructure(false);
      const depStructure = await getDepartmentStructureAsync(apiMetodError);

      if (depStructure) {
        const treeInit = initializeTree(depStructure);
        dispatch({ type: treeInit.type, payload: treeInit.payload });
      }
    }

    if (!loadStructure) {
      return;
    }

    getStructrue();
  }, [getDepartmentStructureAsync, apiMetodError, loadStructure])

  const _handleUpdateTreeData = (treeData: any) => {
    const updatedTree = updateTree(treeData);
    dispatch({ type: updatedTree.type, payload: updatedTree.payload });
  }

  const addNewDeparment = useCallback(
    async (name: string) => {
      const addDepartmentRequest = await addDepartmentAsync(name, apiMetodError);

      if (!addDepartmentRequest) {
        return;
      }

      const addNodeAction = addNode(addDepartmentRequest.departmentName, addDepartmentRequest.code, addDepartmentRequest.departmentId);
      dispatch({ type: addNodeAction.type, payload: addNodeAction.payload });
    },
    [addDepartmentAsync, apiMetodError],
  );
  const addNewOffice = useCallback(
    async (name: string) => {
      const addOfficeRequest = await addOfficeAsync(name, apiMetodError);

      if (!addOfficeRequest) {
        return;
      }

      const { path } = currentRowInfo;
      const _departmentId = currentRowInfo.node.id;

      const addDepartmentStructure = await addDepartmentStructureAsync({
        departmentId: _departmentId,
        officeId: addOfficeRequest.officeId,
        providerId: null
      }, apiMetodError);

      if (!addDepartmentStructure) {
        return;
      }

      const addNodeAction = addNodeAsChild(addOfficeRequest.officeName, addOfficeRequest.code, addOfficeRequest.officeId, path[path.length - 1], true, true, 'office');
      dispatch({ type: addNodeAction.type, payload: addNodeAction.payload });
    },
    [currentRowInfo, addOfficeAsync, addDepartmentStructureAsync, apiMetodError],
  );
  const addNewProvider = useCallback(
    async (name: string, providerModel: ProviderModel | null) => {
      const { path, parentNode } = currentRowInfo;
      let _providerId = "";
      let _providerName = "";
      let _providerCode = "";
      let _departmentId = "";
      let _officeId = "";

      /** If there is no providerModel we need to add a new provider */
      if (!providerModel) {
        const addProviderRequest = await addProviderAsync(name, apiMetodError);

        if (!addProviderRequest) {
          return;
        }
        _providerId = addProviderRequest.providerId;
        _providerName = addProviderRequest.providerName;
        _providerCode = addProviderRequest.code;
      }
      else {
        _providerId = providerModel.providerId;
        _providerName = providerModel.providerName;
        _providerCode = providerModel.code;
      }

      /** If there is no parent node the user is adding a provider directly under department */
      if (!parentNode) {
        _departmentId = currentRowInfo.node.id;
      }
      else {
        _departmentId = path[0];
        _officeId = path[1];
      }

      const addDepartmentStructure = await addDepartmentStructureAsync({
        departmentId: _departmentId,
        officeId: _officeId,
        providerId: _providerId
      }, apiMetodError);

      if (!addDepartmentStructure) {
        return;
      }

      const addNodeAction = addNodeAsChild(_providerName, _providerCode, _providerId, path[path.length - 1], false, false, 'provider');
      dispatch({ type: addNodeAction.type, payload: addNodeAction.payload });
    },
    [currentRowInfo, addProviderAsync, addDepartmentStructureAsync, apiMetodError]
  );
  const editNode = useCallback(
    (name: string, code: string) => {
      if (!currentRowInfo) {
        setLoadStructure(true);
        return;
      }

      const { node, path } = currentRowInfo;

      if (node.type === 'provider') {
        setLoadStructure(true);
        return;
      }

      const editItemAction = changeNode(name, code, node, path);
      dispatch({ type: editItemAction.type, payload: editItemAction.payload });
    },
    [currentRowInfo],
  );
  const updateStructure = useCallback(
    async (removeModelRequest: DepartmentStructureRequestModel, addModelRequest: DepartmentStructureRequestModel) => {
      const updateResult = await updateDepartmentStructureAsync(removeModelRequest, addModelRequest, apiMetodError);

      if (!updateResult) {
        setLoadStructure(true);
        return;
      }
    }, [updateDepartmentStructureAsync, apiMetodError]
  );
  const deleteStructure = useCallback(
    async () => {
      if (!currentRowInfo) {
        setLoadStructure(true);
        return;
      }

      switch (currentRowInfo.node.type) {
        case 'department':
          const deleteDepartmentResult = await removeDepartmentAsync(currentRowInfo.node.id, apiMetodError);

          if (!deleteDepartmentResult) {
            setLoadStructure(true);
            return;
          }
          break;
        case 'office':
          const deleteOfficeResult = await removeOfficeAsync(currentRowInfo.node.id, apiMetodError);

          if (!deleteOfficeResult) {
            setLoadStructure(true);
            return;
          }
          break;
        case 'provider':
          const officeId = currentRowInfo.parentNode && currentRowInfo.parentNode.type === 'office' ? currentRowInfo.parentNode.id : null;
          const payload = {
            departmentId: currentRowInfo.path[0],
            officeId: officeId,
            providerId: currentRowInfo.node.id
          };
          const deleteProviderResult = await removeDepartmentStructureAsync(payload, apiMetodError);

          if (!deleteProviderResult) {
            setLoadStructure(true);
            return;
          }
          break;
      }

      const { path } = currentRowInfo;
      const deleteNodeAction = deleteNode(path);
      dispatch({ type: deleteNodeAction.type, payload: deleteNodeAction.payload });
    },
    [currentRowInfo, removeDepartmentAsync, removeOfficeAsync, removeDepartmentStructureAsync, apiMetodError],
  );

  // Add/Edit structure
  const [openModal, setOpenModal] = useState(false);
  const [modalIsAdding, setModalIsAdding] = useState(true);
  const [typeOfAdding, setTypeOfAdding] = useState<'department' | 'office' | 'provider'>('department');
  const handleAddOnDepartmentStructure = async (name: string, providerModel: ProviderModel | null, addAsAProvider: boolean) => {
    // Add new top node
    if (!currentRowInfo) {
      await addNewDeparment(name);
    }
    // Add new child node as provider
    else if (addAsAProvider || typeOfAdding === 'provider') {
      await addNewProvider(name, providerModel);
    }
    // Add new child node as office
    else {
      await addNewOffice(name);
    }
  }
  const handleEditOnDepartmentStructure = async (name: string) => {
    if (!currentRowInfo) {
      return;
    }

    const { node } = currentRowInfo;
    const { type, id } = node;
    let _code = '';
    let _name = '';

    switch (type) {
      case 'department':
        const result = await updateDepartmentAsync(id, name, apiMetodError);
        if (!result) {
          return;
        }
        _code = result.code;
        _name = result.departmentName;
        break;
      case 'office':
        const resultOffice = await updateOfficeAsync(id, name, apiMetodError);
        if (!resultOffice) {
          return;
        }
        _code = resultOffice.code;
        _name = resultOffice.officeName;
        break;
      case 'provider':
        const resultProvider = await updateProviderAsync(id, name, apiMetodError);
        if (!resultProvider) {
          return;
        }
        _code = resultProvider.code;
        _name = resultProvider.providerName;
        break;
      default:
        break;
    }

    editNode(_name, _code);
  }
  const onConfirmStructure = async (result: string, providerModel: ProviderModel | null, addAsAProvider: boolean) => {
    setComponentShouldWait(true);
    const name = providerModel !== null ? providerModel.providerName : result;

    // Edit node
    if (!modalIsAdding) {
      await handleEditOnDepartmentStructure(name);
    }
    else {
      await handleAddOnDepartmentStructure(name, providerModel, addAsAProvider);
    }

    setComponentShouldWait(false);
  }
  const ui_modal = <DepartmentStructureModal
    HandleClose={() => setOpenModal(false)}
    Open={openModal}
    IsAdding={modalIsAdding}
    Type={typeOfAdding}
    Name={currentRowInfo?.node?.title || ''}
    Id={currentRowInfo?.node?.id}
    OnConfirm={onConfirmStructure}
    RowInfo={currentRowInfo}
  />;

  // Delete a structure
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const ui_confirm = <ConfirmationModalComponent
    open={openDeleteConfirmation}
    handleClose={() => setOpenDeleteConfirmation(false)}
    type='deletion'
    onConfirm={deleteStructure}
    message={`Are you sure you want to delete this ${currentRowInfo?.node?.type || 'structure'}?`}
    title="Delete Confirmation Required"
    warningAlert={`If you remove this ${currentRowInfo?.node?.type || 'structure'}, all relationships with patients and users will be removed as well.`}
  />;

  const handleClickRemoveNode = (rowInfo: any) => {
    setCurrentRowInfo(rowInfo);
    setOpenDeleteConfirmation(true);
  }
  const handleClickAddNewNode = (): void => {
    setCurrentRowInfo(null);
    setModalIsAdding(true);
    setOpenModal(true);
    setTypeOfAdding('department');
  }
  const handleClickEditNodeChild = (rowInfo: any): void => {
    setCurrentRowInfo(rowInfo);
    setModalIsAdding(false);
    setOpenModal(true);
    setTypeOfAdding(rowInfo.node.type);
  }
  const handleClickAddNodeChild = (rowInfo: any): void => {
    setCurrentRowInfo(rowInfo);
    setModalIsAdding(true);
    setOpenModal(true);
    setTypeOfAdding(rowInfo.node.type === 'department' ? 'office' : 'provider');
  }
  const handleCanDrop = (dropInfo: any) => {
    const { node, nextParent, nextPath } = dropInfo;
    const moving = node.type;
    const providerId = node.id;

    if (nextPath.length <= 1) {
      return false;
    }

    if (moving === 'department') {
      return false;
    }

    if (!nextParent) {
      return false;
    }

    const [departmentId, officeId] = nextPath;
    const departmentParent = treeData?.find((item: any) => item.id === departmentId);
    const departmentChildrens = departmentParent?.children as TreeItem[];

    if (!departmentChildrens && moving === 'office') {
      return true;
    }

    const officeParent = !departmentChildrens ? undefined : departmentChildrens.find((item: TreeItem) => item.id === officeId);

    if (moving === 'office') {
      return officeParent === undefined;
    }

    if (moving === 'provider') {
      /** Moving to an office */
      if (officeParent !== undefined && officeParent.type === 'office') {
        const officeChildrens = officeParent.children as TreeItem[];
        const providerElement = !officeChildrens ? undefined : officeChildrens.find((item: TreeItem) => item.id === providerId);

        return providerElement === undefined;
      }

      /** Moving to an department */
      const providerInDepartment = !departmentChildrens ? undefined : departmentChildrens.find((item: TreeItem) => item.id === providerId);
      return providerInDepartment === undefined;
    }

    return false;
  }
  const handleOnMoveNode = async (dropInfo: any) => {
    const { node, prevPath, nextPath } = dropInfo;
    const { type, id } = node;
    const [departmentSrcId, firstSrcId, secondSrcId] = prevPath;
    const [departmentDestId, firstDestId, seconfDestId] = nextPath;

    if (!departmentSrcId || !departmentDestId) {
      return;
    }

    if (departmentSrcId === departmentDestId && firstSrcId === firstDestId && secondSrcId === seconfDestId) {
      return;
    }

    if (departmentSrcId === id || departmentDestId === id) {
      return;
    }

    const payloadToRemove = {
      departmentId: departmentSrcId,
      officeId: type === 'provider' && secondSrcId === undefined ? undefined : firstSrcId,
      providerId: type === 'provider' ? id : undefined,
    };

    const payloadToAdd = {
      departmentId: departmentDestId,
      officeId: type === 'provider' && seconfDestId === undefined ? undefined : firstDestId,
      providerId: type === 'provider' ? id : undefined,
    };

    await updateStructure(payloadToRemove, payloadToAdd);
  }
  const ui_control = <DepartmentStructureTree
    treeData={treeData || [] as TreeItem[]}
    canDrag={({ node }) => node.type && node.type !== 'department'}
    canDrop={handleCanDrop}
    onMoveNode={handleOnMoveNode}
    onChange={_handleUpdateTreeData}
    toggleExpand={(value: boolean) => {
      const expandedTree = toggleExpanded(value);
      dispatch({ type: expandedTree.type, payload: expandedTree.payload });
    }}
    height={'650px'}
    generateNodeProps={rowInfo => ({
      buttons: [
        <Tooltip title={`Add new ${rowInfo.node.type === 'department' ? 'office / provider' : rowInfo.node.type === 'office' ? 'provider' : ''}`} arrow={true}>
          <IconButton hidden={!rowInfo.node.isDirectory} onClick={(event) => handleClickAddNodeChild(rowInfo)} aria-label="edit" className='unbordered' color="primary" sx={{ px: 0.5 }}>
            <AddCircleOutlineOutlined />
          </IconButton>
        </Tooltip>,
        <Tooltip title={`Edit the ${rowInfo.node.type}`} arrow={true}>
          <IconButton onClick={(event) => handleClickEditNodeChild(rowInfo)} aria-label="edit" className='unbordered' color="primary" sx={{ px: 0.5 }}>
            <Edit />
          </IconButton>
        </Tooltip>,
        <Tooltip title={`Delete the ${rowInfo.node.type}`} arrow={true}>
          <IconButton onClick={(event) => handleClickRemoveNode(rowInfo)} aria-label="delete" className='unbordered' color="error" sx={{ px: 0.5 }}>
            <DeleteOutlineRounded />
          </IconButton>
        </Tooltip>
      ]
    })}
  />

  const loading_modal = <CustomDialog
    open={isLoading || componentShouldWait}
    handleClose={() => { }}
    hideButtons
    isOkButtonDisabled
    onConfirm={() => { }}
    size='xs'
    fullWidth={false}
  >
    <Box sx={{ display: 'flex', m: 1, alignItems: 'center', justifyContent: 'center' }}>
      <PrismLoading Size={60} />
      <Typography variant="h6" sx={{ whiteSpace: 'nowrap', ml: 5, mr: 1 }}>Loading ...</Typography>
    </Box>
  </CustomDialog>

  const ui_render: JSX.Element = !!treeData && !treeData.length && isLoading ?
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      opacity: 0.5,
      height: '100%'
    }}>
      <Typography variant="h4" sx={{ marginTop: 2 }}>No structure data to show</Typography>
    </Box> :
    ui_control

  return (
    <PageWrapper title={"Department Structure"} >
      <Button sx={{ mb: 2 }} variant="contained" color="success" onClick={handleClickAddNewNode} size='small'>Add department</Button>
      {!isLoading && ui_render}
      {ui_confirm}
      {ui_modal}
      {loading_modal}
    </PageWrapper>
  )
}

export default DepartementStructure;