import { Send, SystemUpdateAlt } from "@mui/icons-material";
import { Grid, Box, List, ListItem, ListItemText, Typography, Divider, Chip, IconButton, FormControl, InputAdornment, InputLabel, OutlinedInput, FormHelperText, Button } from "@mui/material";
import { isEmpty } from "lodash";
import { Fragment, useEffect, useRef, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { PatientMessageModel } from "../../Api/Model/Patient/PatientMessages/PatientMessage.model";
import { useAxios } from "../../hooks/useAxios";
import { getSessionUser } from "../../Shared/sessionUser.provider";
import PrismLoading from "../Shared/Loading/Loading.component";
import { downloadFileResponse } from "../../Shared/Files.provider";
import { useUser } from "../../hooks/useUserInfo";
import { SystemRoles } from "../../constants/global.constants";
import { UriDirection, containsUrl } from "../../constants/validationRegex";

type PatientMessagePropsType = {
    patientId: string | undefined;
    isInUse: boolean;
    shouldUpdate: boolean;
    onMessagesUpdate: () => void
};

const formatMessage = (message: string): JSX.Element => {
    let formattedMessage = message.replace(
      containsUrl,
      (url) =>
        `<a href=${
          url.toLowerCase().match(UriDirection) ? "" : "https://"
        }${url} target="_blank">${url}</a>`
    );

    return <span dangerouslySetInnerHTML={{ __html: formattedMessage }}></span>;
}

export const PatientMessages = (props: PatientMessagePropsType) => {
    const { manageErrorAlert, showAlertSnack } = useOutletContext<{ manageErrorAlert: Function; showAlertSnack: Function }>();
    const messageEndRef = useRef<null | HTMLDivElement>(null);
    const autoInputRef = useRef<null | HTMLDivElement>(null);
    const { getAsync, axiosError, axiosSuccess, postAsync, axiosLoading } = useAxios();
    const { downloadFileAsync, axiosLoading: downloading } = useAxios();
    const [patientMessages, setPatientMessages] = useState<PatientMessageModel[][] | undefined>([[{ createdDate: undefined }]]);
    const [newMessage, setNewMessage] = useState<PatientMessageModel>({});
    const [initialLoading, setInitialLoading] = useState<boolean>(true);
    const currentUser = getSessionUser();
    const [messagesReadOnly, setMessagesReadOnly] = useState<boolean>(true);
    const { rolesDB, userIsInRoles } = useUser(undefined, currentUser.id, manageErrorAlert, showAlertSnack);

    useEffect(() => {
        if (!rolesDB || isEmpty(rolesDB)) {
            return;
        }
        const allowedRoles = [SystemRoles.Admin, SystemRoles.PES, SystemRoles.Nurse];
        const isAllowedToModify = userIsInRoles(allowedRoles);
        setMessagesReadOnly(!isAllowedToModify);
    }, [rolesDB]);

    const getPatientMessages = async () => {
        if (!props.patientId) {
            return;
        }
        const axiosGet = await getAsync<[PatientMessageModel[]]>(
            `PatientMessages/GetPatientMessages/${props.patientId}`, {
            PageNumber: 0,
            PageSize: 0
        }
        );
        if (initialLoading) {
            setInitialLoading(false);
        }
        if (!axiosGet) {
            setPatientMessages([[]]);
            return;
        }
        const reversedAxiosGet = axiosGet.reverse();
        reversedAxiosGet.forEach((item) => item.reverse());
        setPatientMessages(reversedAxiosGet);
    }

    const setReadAllMessages = async () => {
        if (!props.patientId) {
            return;
        }
        await postAsync("PatientMessages/ReadAllMessagesByPatientId", props.patientId);
    }

    useEffect(() => {
        getPatientMessages();
        return () => {
            setReadAllMessages();
        }
    }, []);
    
    useEffect(() => {
        messageEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [patientMessages]);

    const setMessageTextHandler = (event: any) => {
        setNewMessage({ ...newMessage, message: event.target.value });
    }

    const noDataComponent = <Box key={'NoContentKey'} sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        opacity: 0.5,
        height: '100%'
    }}>No messages to display</Box>

    const sendMessageHandler = async () => {
        if (!props.patientId || isEmpty(newMessage.message?.trim()) || !currentUser || messagesReadOnly) {
            return;
        }

        newMessage.patientId = props.patientId;
        newMessage.incoming = false;
        newMessage.userId = currentUser.id;
        await postAsync("PatientMessages/AddPatientMessage", newMessage);
        if (!axiosSuccess) {
            manageErrorAlert(axiosError?.Messages[0]);
            return;
        }
        setNewMessage({});
        autoInputRef.current?.focus();
        await getPatientMessages();
    }

    let validationErrors = {
        ...(isEmpty(newMessage.message?.trim()) && {
            Message: "Message is required",
        }),
    };

    const downloadHandler = async () => {
        const response = await downloadFileAsync(`PatientMessages/DownloadPatientMessages`, `patientId=${props.patientId}`);
        downloadFileResponse(response, `Patient-Messages-${props.patientId}.xlsx`);
    }

    useEffect(() => {
        if(props.shouldUpdate){
            getPatientMessages()
            props.onMessagesUpdate();
        }
    }, [props.shouldUpdate]);

    const loadingComponent = <PrismLoading Size={80} Text={'Loading messages'} />
    return (<>
        <List sx={{ height: messagesReadOnly ? 650 : 470, overflow: 'auto', pr: 2, mb: 1 }}>
            {axiosLoading && initialLoading ? loadingComponent : patientMessages && patientMessages.length > 0 ?
                patientMessages.map((input) => (<>
                    <Divider>
                        <Chip label={input[0].createdDateFormatted || ''} />
                    </Divider>
                    <Box>
                        {input.map((item) => (
                            <ListItem key={item.id} sx={{ alignItems: 'start' }} >
                                <Grid container>
                                    <Grid item xs={1} >
                                        <ListItemText primary={<Typography sx={{ display: 'inline' }} variant={'body2'} color="gray">
                                            {item.createdTimeFormatted || ''}
                                        </Typography>} />
                                    </Grid>
                                    <Grid item xs={11} sx={{ inlineSize: '100px', overflowWrap: 'break-word' }}>
                                        <ListItemText primary={
                                            <Fragment>
                                                <Typography sx={{ display: 'inline' }} variant={'body1'} fontWeight="bold">
                                                    {(item.incoming ? item.patientName : item.userName) + ":  "}
                                                </Typography>
                                                <Box sx={{
                                                    fontWeight: (!item.incoming && item.patientRead) || (item.incoming && item.administrativeRead) ? "regular" : "bold",
                                                    wordWrap: "break-word", width: "100%", display: 'inline',
                                                    maxWidth: "100%", overflowX: "auto", whiteSpace: 'pre-line'
                                                }} >
                                                    {formatMessage(item.message || "")}
                                                </Box>
                                            </Fragment>
                                        } />
                                    </Grid>
                                </Grid>
                            </ListItem>
                        ))}
                    </Box>
                </>)
                ) : noDataComponent
            }
            <Box ref={messageEndRef}></Box>
        </List>
        <Button
            disabled={downloading || (patientMessages === undefined || patientMessages.length < 1)}
            aria-label="edit"
            size="large"
            variant="contained"
            color="primary"
            sx={{ fontWeight: 900, mb: 1, float: 'right' }}
            onClick={downloadHandler}
        >
            <SystemUpdateAlt sx={{ marginRight: "5px" }} />
            {!downloading ? "Download" : 'Downloading...'}
        </Button>
        {!messagesReadOnly && <FormControl variant="outlined" fullWidth error={!!validationErrors.Message && !props.isInUse} >
            <InputLabel htmlFor="outlined-adornment-password">
                Message
            </InputLabel>
            <OutlinedInput
                disabled={props.isInUse}
                inputProps={{
                    tabIndex: 1,
                }}
                id="message"
                fullWidth
                inputRef={autoInputRef}
                onChange={setMessageTextHandler}
                value={newMessage.message || ""}
                multiline
                autoFocus
                rows={5}
                type={"text"}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="Send Message"
                            color="primary"
                            onClick={sendMessageHandler}
                            disabled={props.isInUse || !!validationErrors.Message}
                            edge="end"
                        >
                            <Send />
                        </IconButton>
                    </InputAdornment>
                }
                label="Password"
            />
            <FormHelperText>{!props.isInUse && validationErrors.Message}</FormHelperText>
        </FormControl>}
    </>);
}