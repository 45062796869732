import React from "react"; 
import type {AlertColor} from '@mui/material';
export const useError = () => {
  const [openAlertMessage, setOpenAlertMessage] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [alertType, setAlertType] = React.useState<AlertColor>("error");
  const manageErrorAlert = (code: any) => {
    setAlertType("error");
    switch (code) {
      case "ERR_NETWORK":
        setAlertMessage(
          "We had a problem connecting to the server, please try again later."
        );
        setOpenAlertMessage(true);
        break;
      case "ERR_BAD_REQUEST":
        setAlertMessage(
          "The server cannot recognize your request, please check and try again."
        );
        setOpenAlertMessage(true);
        break;
      case "ERR_BAD_RESPONSE":
        setAlertMessage(
          "We had a problem processing your request, please try again."
        );
        setOpenAlertMessage(true);
        break;
      default:
        setAlertMessage(code);
        setOpenAlertMessage(true);
        break;
    }
  };

  const showAlertSnack = (message: any, SnackType: any) => {
    setAlertType(SnackType);
    setAlertMessage(message);
    setOpenAlertMessage(true);
  };
  
  return {
    openAlertMessage,
    setOpenAlertMessage,
    alertMessage,
    setAlertMessage,
    manageErrorAlert,
    setAlertType,
    alertType,
    showAlertSnack,
  };
};
