import { useEffect, useCallback, useState } from 'react';
import { Grid, IconButton } from '@mui/material';
import { GridColDef, GridSortModel } from '@mui/x-data-grid';
import { Edit } from "@mui/icons-material";
import { AlertDefaultModal } from './AlertDefaultModal.component';
import StripedDataGrid from '../Shared/StripedDataGrid/StripedDatagrid.component';
import { useOutletContext } from 'react-router-dom';
import { useAxios } from '../../hooks/useAxios';
import { ThresholdDefaultModel } from '../../Api/Model/ThresholdDefaultModel';
import PageWrapper from '../Shared/Wrapper/PageWrapper.component';

const AlertDefaults = (props: any) => {
  const { manageErrorAlert, showAlertSnack } = useOutletContext<{ manageErrorAlert: Function, showAlertSnack: Function }>();
  const [alertDefaults, setAlertDefaults] = useState<ThresholdDefaultModel[]>([]);
  const [currentAlertDefault, setCurrentAlertDefault] = useState<ThresholdDefaultModel | undefined>(undefined);
  const [refresh, setRefresh] = useState<boolean>(true);
  const { getAsync, updateAsync, axiosLoading, axiosError, axiosSuccess } = useAxios();
  const [sortModel, setSortModel] = useState<GridSortModel>([] as GridSortModel);

  const getAlertsDefaults = async () => {
    const axiosGet = await getAsync<ThresholdDefaultModel[]>("ManagementAlerts/GetAlertsDefaults");

    setRefresh(false);

    if (!axiosGet) {
      manageErrorAlert(axiosError?.Messages[0]);
      return;
    }

    setAlertDefaults(axiosGet);
  };

  useEffect(() => {
    if (!refresh) {
      return;
    }
    getAlertsDefaults();
  }, [refresh]);

  const [openAlertDefaultModal, setOpenAlertDefaultModal] = useState(false);
  const closeAlertDefaultModal = useCallback(
    () => {
      setOpenAlertDefaultModal(false);
    },
    [setOpenAlertDefaultModal],
  );
  const onAlertDefaultModalConfirm = async (alertDefault: ThresholdDefaultModel) => {
    const updateResult = await updateAsync<ThresholdDefaultModel>('ManagementAlerts/UpdateAlertDefault', currentAlertDefault?.alertId || '', alertDefault);
    if (updateResult && axiosSuccess) {
      showAlertSnack("The alert default was successfully updated.", "success");
      setAlertDefaults((dcs) => {
        const result = Array.from(dcs);
        const index = dcs.findIndex(dc => dc.alertId === updateResult?.alertId);
        result[index] = updateResult;
        return result;
      });
    }
    else {
      manageErrorAlert(axiosError?.Messages[0]);
    }
  }

  const ui_alertdefault_modal = <AlertDefaultModal
    open={openAlertDefaultModal}
    handleClose={closeAlertDefaultModal}
    onConfirm={onAlertDefaultModalConfirm}
    alertDeafaultParam={currentAlertDefault}
  />

  const definedColumns: GridColDef[] = [
    {
      field: "deviceName",
      headerName: "Device Type",
      headerAlign: "left",
      align: "left",
      flex: 1,
      hideable: false,
      filterable: false
    },
    {
      field: "measurementName",
      headerName: "Measurement Type",
      flex: 1,
      headerAlign: "left",
      align: "left",
      hideable: false,
      filterable: false
    },
    {
      field: "defaultMinimum",
      headerName: "Default Minimum",
      flex: 1,
      headerAlign: "left",
      align: "left",
      hideable: false,
      filterable: false,
      valueGetter: (params) => {
        return params.row.limitValues[0].minimum;
      }
    },
    {
      field: "defaultMaximum",
      headerName: "Default Maximum",
      flex: 1,
      headerAlign: "left",
      align: "left",
      hideable: false,
      filterable: false,
      valueGetter: (params) => {
        return params.row.limitValues[0].maximum;
      }
    },
    {
      field: "action",
      headerName: "Actions",
      sortable: false,
      flex: 0,
      headerAlign: "center",
      align: "center",
      hideSortIcons: true,
      disableExport: true,
      disableColumnMenu: true,
      hideable: false,
      filterable: false,
      renderCell: (params) => {
        const openEditModal = () => {
          setCurrentAlertDefault({
            alertId: params.row.alertId,
            deviceName: params.row.deviceName,
            measurementName: params.row.measurementName,
            deviceTypeId: params.row.deviceTypeId,
            limitValues: params.row.limitValues,
          } as ThresholdDefaultModel);
          setOpenAlertDefaultModal(true);
        };

        return <Grid sx={{ alignContent: "center", justifyContent: "center" }} container>
          <Grid item xs={6}>
            <IconButton onClick={openEditModal} aria-label="edit" className='unbordered' color="primary">
              <Edit />
            </IconButton>
          </Grid>
        </Grid>;
      }
    },
  ];

  return (
    <PageWrapper title={"Alert Defaults"} >
      <StripedDataGrid
        sx={{ height: 700 }}
        getRowId={(row) => row.alertId}
        loading={axiosLoading}
        rows={alertDefaults}
        columns={definedColumns}
        hideFooterPagination={true}
        disableSelectionOnClick={true}
        disableColumnSelector
        sortModel={sortModel}
        onSortModelChange={(sModel) => { setSortModel(sModel); }}
      />
      {ui_alertdefault_modal}
    </PageWrapper>);
};

export default AlertDefaults;