import { Box, Button, Divider, Grid, Paper, Typography } from "@mui/material";
import { CustomDialog } from "../Shared/Modal/Modal.component";
import { useEffect, useState } from "react";
import { PatientNoteModel } from "../../Api/Model/PatientNoteModel";
import { getFormatedShortDateTimeString } from "../../Shared/DateTime.provider";
import { useAxios } from "../../hooks/useAxios";
import { useOutletContext } from "react-router-dom";

type PatientNoteHistoryModalProps = {
    open: boolean;
    closeHandler: Function;
    patientNoteParam?: PatientNoteModel;
    boxHeaderText: Function;
    formatName: Function;
};

const ReadMore = ({ children,index }: any) => {
    const text = children as string;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => setIsReadMore(!isReadMore);
    return (
        <Box sx={{
            wordWrap: "break-word", width: "100%", height: isReadMore ? 90 : '100%',
            maxWidth: "100%", overflowX: "auto", overflowY: 'hidden', whiteSpace: 'pre-line',
            display: 'inline'
        }} >
            <Typography sx={{ display: '-webkit-box',textDecorationLine:index == 0?'':'line-through',textDecorationColor:'red', overflow: 'hidden', WebkitBoxOrient: 'vertical', WebkitLineClamp: isReadMore ? 2 : undefined }}>
                {isReadMore
                    ? (text.slice(0, 200))
                    : text}
                {(text.length > 2 || text.length > 200) && isReadMore ? '... ' : '\n'}
            </Typography>
            <Divider sx={{ visibility: text.length > 2 || text.length > 200 ? 'visible' : 'collapse' }}>
                <Button onClick={toggleReadMore} >
                    {isReadMore ? "Show more" : "Show less"}
                </Button>
            </Divider>
        </Box>
    );
}

export const PatientNoteHistoryModal = (props: PatientNoteHistoryModalProps) => {
    const { manageErrorAlert } = useOutletContext<{ manageErrorAlert: Function; }>();
    const [patientNoteHistory, setPatientNoteHistory] = useState<PatientNoteModel[]>([]);
    const { getAsync, axiosError } = useAxios();

    const getPatientNoteHistory = async () => {
        const axiosGet = await getAsync<PatientNoteModel[]>(
            `PatientInfo/GetPatientNotesHistory/${props.patientNoteParam?.patientNoteId}`);

        if (!axiosGet) {
            manageErrorAlert(axiosError?.Messages[0]);
            return;
        }

        setPatientNoteHistory(axiosGet);
    };

    useEffect(() => {
        if (!!props.patientNoteParam) {
            getPatientNoteHistory();
        }
    }, [props.open]);

    return (
        <CustomDialog
            isOkButtonDisabled
            hideButtons
            showCloseButton
            unlockCloseModal
            title={"Note History" + (!!props.patientNoteParam ? " of " + props.patientNoteParam?.createBy : "")}
            size="lg"
            onConfirm={() => { }}
            open={props.open}
            handleClose={props.closeHandler}
        >
            <Grid item xs={12}>
                {patientNoteHistory && patientNoteHistory.length > 0 ? (
                    patientNoteHistory.map((note, index) => (

                        <Paper
                            key={'PatientHistory' + index}
                            style={{
                                border: "1px solid lightgray",
                                padding: 10,
                                marginBottom: 10,
                            }}
                            variant="outlined"
                        >
                            <Grid container>
                                <Grid sx={{ display: 'flex', alignItems: "center" }} item xs={10}>
                                    <Typography variant="h6">
                                        {props.boxHeaderText("Amended:", false)}
                                        {props.boxHeaderText(
                                            getFormatedShortDateTimeString(new Date(note.createdDate!)),
                                            true
                                        )}
                                        {props.boxHeaderText("by", false)}
                                        {props.boxHeaderText(props.formatName(props.patientNoteParam?.createBy) ||"", true)}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Divider
                                orientation="horizontal"
                                variant="middle"
                                flexItem
                                sx={{ marginBottom: 1, marginTop: 1 }}
                            />
                            <Box
                                sx={{
                                    paddingLeft: 2,
                                }}
                            >
                                <ReadMore index={index}>
                                        {note.note}
                                </ReadMore>
                            </Box>
                        </Paper>
                    ))
                ) : (
                    <Typography>
                    {props.boxHeaderText("No note history to display", true)}
                  </Typography>
                )}
            </Grid>
        </CustomDialog>
    );
};