import {
  Backdrop,
  Box,
  Button,
  Fade,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { colorsPalette, confirmationModalStyle } from "../../../constants/styles.constants";
import { isEmpty, isNaN } from "lodash";
import { PatientThresholdModel } from "../../../Api/Model/PatientThreshold";
import {
  DeviceTypeCatalogName,
  MeasurementTypeCatalogName,
} from "../../../constants/global.constants";
import { ThresholdDefaultModel } from "../../../Api/Model/ThresholdDefaultModel";
import {
  CleanButton,
  handleClearSelect,
} from "../../../Shared/CleanHandler.provider";
import { useAxios } from "../../../hooks/useAxios";
import { CatalogModel } from "../../../Api/Model/GenericCatalog/CatalogModelAxios";

type PatientThresholdModalProps = {
  open: boolean;
  handleClose: Function;
  onConfirm: Function;
  type: "create" | "update";
  description?: string;
  name?: string;
  patientThresholdParam?: PatientThresholdModel;
  currentPatientId?: string;
};

export const PatientThresholdModal = (props: PatientThresholdModalProps) => {
  const [patientThreshold, setPatientThreshold] =
    useState<PatientThresholdModel>({
      limitValues: [{ minimum: 0, maximum: 1 }],
      sendThresholdByEmail: false,
    } as PatientThresholdModel);
  const [deviceTypes, setDeviceTypes] = useState<CatalogModel[]>([]);
  const [valuesError, setValuesError] = useState(false);
  const [thresholdsDefaults, setThresholdsDefaults] = useState<ThresholdDefaultModel[]>([]);
  const [measurementTypes, setMeasurementTypes] = useState<CatalogModel[]>([]);
  const [unFilteredMeasurementTypes, setUnFilteredMeasurementTypes] = useState<CatalogModel[]>([]);
  const [selectedThresholdDefault, setSelectedThresholdDefault] =
    useState<ThresholdDefaultModel>();

  const { getAsync } = useAxios();
  const closeModal = () => props.handleClose();
  const onConfirm = () => {
    closeModal();
    !!props.onConfirm && props.onConfirm(patientThreshold);
  };

  const handleMeasurementTypeChange = (event: any) => {
    if (event.target.value === "") {
      setPatientThreshold((dc: PatientThresholdModel) => {
        return {
          ...dc,
          measurementName: event.target.value as string,
        };
      });
      return;
    }
    let ThresholdDefaultFound =
      thresholdsDefaults[
        thresholdsDefaults.findIndex(
          (item) => item.measurementName === event.target.value
        )
      ];
    setSelectedThresholdDefault(ThresholdDefaultFound);
    setPatientThreshold((dc: PatientThresholdModel) => {
      return {
        ...dc,
        limitValues: [{ maximum: 1, minimum: 0 }],
        measurementName: event.target.value as string,
        alertId: ThresholdDefaultFound.alertId,
      };
    });
  };
  const handleDeviceTypeChange = (event: any) => {
    setPatientThreshold((dc: PatientThresholdModel) => {
      return { ...dc, deviceType: event.target.value };
    });
    handleClearSelect(handleMeasurementTypeChange);
  };

  const handleDefaultMaximum = (index: number) => {
    if (!selectedThresholdDefault) {
      return;
    }
    setPatientThreshold({
      ...patientThreshold,
      limitValues: [
        {
          minimum: patientThreshold.limitValues[index].minimum,
          maximum: selectedThresholdDefault.limitValues
            ? selectedThresholdDefault.limitValues[index].maximum
            : 0,
        },
      ],
    });
  };

  const handleDefaultMinimum = (index: number) => {
    if (!selectedThresholdDefault) {
      return;
    }
    setPatientThreshold({
      ...patientThreshold,
      limitValues: [
        {
          maximum: patientThreshold.limitValues[index].maximum,
          minimum: selectedThresholdDefault.limitValues
            ? selectedThresholdDefault.limitValues[index].minimum
            : 0,
        },
      ],
    });
  };

  useEffect(() => {
    setValuesError(
      !patientThreshold.limitValues ||
        patientThreshold.limitValues.length < 1 ||
        +patientThreshold.limitValues[0].maximum <=
          +patientThreshold?.limitValues[0].minimum
    );
  }, [patientThreshold.limitValues]);

  const getThresholdDefaults = async () => {
    const axiosGet = await getAsync<ThresholdDefaultModel[]>("ManagementAlerts/GetPatientAlertsDefaults?patientId="+props.currentPatientId);
    if (!axiosGet) {
      return;
    }
    setThresholdsDefaults(axiosGet);
  };

  const getCatalogsInfo = async () => {
    const axiosGetDeviceTypes = await getAsync<CatalogModel[]>("Catalogs/GetSubCatalogsByName?catalogName="+DeviceTypeCatalogName);
    if (!!axiosGetDeviceTypes) {
      setDeviceTypes(axiosGetDeviceTypes);
    }

    const axiosGetMeasurementTypes = await getAsync<CatalogModel[]>("Catalogs/GetSubCatalogsByName?catalogName="+MeasurementTypeCatalogName);
    if (!!axiosGetMeasurementTypes) {
      setMeasurementTypes(axiosGetMeasurementTypes);
      setUnFilteredMeasurementTypes(axiosGetMeasurementTypes)
    }

  }
  useEffect(() => {
    if (props.open) {
      getThresholdDefaults();
      getCatalogsInfo();
      if (!!props.patientThresholdParam) {
        setPatientThreshold(props.patientThresholdParam);
      } else {
        setPatientThreshold({
          limitValues: [{ maximum: 1, minimum: 0 }],
        } as PatientThresholdModel);
      }
    }
  }, [props.open]);

  useEffect(() => {
    if (
      props.open &&
      !!props.patientThresholdParam &&
      thresholdsDefaults.length > 0
    ) {
      let ThresholdDefalutFound =
        thresholdsDefaults[
          thresholdsDefaults.findIndex(
            (item) => item.alertId === props.patientThresholdParam?.alertId
          )
        ];
      setSelectedThresholdDefault(ThresholdDefalutFound);
      setPatientThreshold({
        ...patientThreshold,
        deviceType: ThresholdDefalutFound.deviceName,
      });
    }
  }, [thresholdsDefaults]);

  useEffect(() => {
    let ThresholdDefaultFound = thresholdsDefaults.filter(
      (item) => item.deviceName === patientThreshold.deviceType
    );

    let DeviceTypesFound = unFilteredMeasurementTypes.filter((mt) => {
      return ThresholdDefaultFound.some(
        (td: any) => td.measurementName === mt.catalogName
      );
    });

    setMeasurementTypes(DeviceTypesFound);
  }, [patientThreshold.deviceType]);

  const validationErrors = {
    ...(isEmpty(patientThreshold.measurementName?.trim()) && {
      MeasurementName: "Measurement is required",
    }),
    ...(patientThreshold.limitValues &&
      patientThreshold.limitValues.length > 0 &&
      isEmpty(patientThreshold.limitValues[0].minimum?.toString()) && {
        Minimum: "Minimum value is required with valid numbers",
      }),
    ...(patientThreshold.limitValues &&
      patientThreshold.limitValues.length > 0 &&
      isEmpty(patientThreshold.limitValues[0].maximum?.toString()) && {
        Maximum: "Maximum value is required with valid numbers",
      }),
    ...(patientThreshold.limitValues &&
      patientThreshold.limitValues.length > 0 &&
      isNaN(patientThreshold.limitValues[0].minimum) && {
        Minimum: "Minimum must have numerical values",
      }),
    ...(patientThreshold.limitValues &&
      patientThreshold.limitValues.length > 0 &&
      isNaN(patientThreshold.limitValues[0].maximum) && {
        Maximum: "Maximum must have numerical values",
      }),
    ...(patientThreshold.limitValues &&
      valuesError && {
        Maximum: "This value cannot be less or equal than the minimum value",
        Minimum: "This value cannot be greater or equal than the maximum value",
      }),
  };

  const handleSetTextField = (event: any) => {
    if (
      !(typeof +event.target.value === "number") ||
      !(+event.target.value === +event.target.value) ||
      (!(+event.target.value > 0) && event.target.value !== "")
    ) {
      return;
    }
    let fieldS = event.target.id.toString();
    if (fieldS === "Maximum") {
      setPatientThreshold((dc: PatientThresholdModel) => {
        return {
          ...dc,
          limitValues: [
            {
              minimum:
                dc.limitValues && dc.limitValues.length > 0
                  ? dc.limitValues[0].minimum
                  : 1,
              maximum: event.target.value,
            },
          ],
        };
      });
      return;
    }
    setPatientThreshold((dc: PatientThresholdModel) => {
      return {
        ...dc,
        limitValues: [
          {
            maximum:
              dc.limitValues && dc.limitValues.length > 0
                ? dc.limitValues[0].maximum
                : 2,
            minimum: event.target.value,
          },
        ],
      };
    });
  };

  const handleEmailMeChange = (event: any) => {
    setPatientThreshold((dc: PatientThresholdModel) => {
      return { ...dc, sendThresholdByEmail: event.target.checked };
    });
  };

  return (
    <Modal
      open={props.open}
      onClose={closeModal}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
    >
      <Fade in={props.open}>
        <Box sx={{ ...confirmationModalStyle, width: 500 }}>
          <Typography
            className="mb-4"
            id="modal-title"
            variant="h6"
            component="h2"
          >
            {props.type === "create" ? "Create" : "Update"} Patient Threshold
          </Typography>

          <Grid container spacing={2} width={"100%"}>
            <Grid item xs={12}>
              <FormControl sx={{ width: 1 / 1 }}>
                <InputLabel id="DeviceTypeCombo" color="primary">
                  Device Type
                </InputLabel>
                <Select
                  id="DeviceType"
                  fullWidth
                  label=" Device Type"
                  defaultValue={""}
                  displayEmpty
                  multiple={false}
                  disabled={props.type !== "create"}
                  native={false}
                  onChange={handleDeviceTypeChange}
                  labelId="DeviceTypeCombo"
                  value={patientThreshold.deviceType || ""}
                  endAdornment={
                    patientThreshold.deviceType &&
                    props.type === "create" &&
                    CleanButton(handleDeviceTypeChange)
                  }
                >
                  {deviceTypes.length === 0 && (
                    <MenuItem value="">No device types found...</MenuItem>
                  )}
                  {deviceTypes.map((deviceCat: any) => (
                    <MenuItem
                      value={deviceCat.catalogName}
                      key={deviceCat.catalogId}
                    >
                      {deviceCat.catalogName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl sx={{ width: 1 / 1 }}>
                <InputLabel id="MeasurementTypeCombo" color="primary">
                  Measurement Type
                </InputLabel>
                <Select
                  id="MeasurementName"
                  fullWidth
                  label="Measurement Type"
                  defaultValue={""}
                  displayEmpty
                  disabled={
                    patientThreshold.deviceType ? props.type !== "create" : true
                  }
                  multiple={false}
                  error={!!validationErrors.MeasurementName}
                  native={false}
                  labelId="MeasurementTypeCombo"
                  value={patientThreshold.measurementName || ""}
                  onChange={handleMeasurementTypeChange}
                  endAdornment={
                    !!patientThreshold.measurementName &&
                    props.type === "create" &&
                    CleanButton(handleMeasurementTypeChange)
                  }
                >
                  {measurementTypes.map((measurementCat: any) => (
                    <MenuItem
                      value={measurementCat.catalogName}
                      key={measurementCat.catalogId}
                    >
                      {measurementCat.catalogName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={7.5}>
              <TextField
                id="Minimum"
                type={"tel"}
                onChange={(event) => handleSetTextField(event)}
                label="Measurement Minimum"
                className="mb-2"
                value={
                  patientThreshold.limitValues &&
                  patientThreshold.limitValues.length > 0
                    ? patientThreshold.limitValues[0].minimum
                    : ""
                }
                inputProps={{
                  tabIndex: 1,
                  maxLength: 6,
                  minLength: 1,
                  lang: "en-US",
                }}
                error={!!validationErrors.Minimum}
                helperText={validationErrors.Minimum}
                variant="outlined"
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={4.5}>
              <Button
                onClick={(event) => handleDefaultMinimum(0)}
                sx={{
                  height: 55,
                  bgcolor: colorsPalette.darkGray,
                }}
                fullWidth
                variant="contained"
              >
                Use Default
              </Button>
            </Grid>
            <Grid item xs={7.5}>
              <TextField
                id="Maximum"
                type={"tel"}
                onChange={(event) => handleSetTextField(event)}
                value={
                  patientThreshold.limitValues &&
                  patientThreshold.limitValues.length > 0
                    ? patientThreshold.limitValues[0].maximum
                    : ""
                }
                label="Measurement Maximum"
                error={!!validationErrors.Maximum}
                helperText={validationErrors.Maximum}
                variant="outlined"
                inputProps={{
                  tabIndex: 2,
                  maxLength: 6,
                  minLength: 1,
                  lang: "en-US",
                }}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={4.5}>
              <Button
                onClick={(event) => handleDefaultMaximum(0)}
                sx={{
                  height: 55,
                  bgcolor: colorsPalette.darkGray,
                }}
                fullWidth
                variant="contained"
              >
                Use Default
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" component="h5" fontSize={15}>
                If patient reports a masurement out of the above range:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={patientThreshold.sendThresholdByEmail}
                      onChange={handleEmailMeChange}
                    />
                  }
                  label="Email me"
                ></FormControlLabel>
              </FormGroup>
            </Grid>
          </Grid>

          <Box
            className="mt-4"
            sx={{ display: "flex", justifyContent: "flex-end", gap: "5px" }}
          >
            <Button
              onClick={closeModal}
              variant="contained"
              color="inherit"
              size="small"
            >
              Cancel
            </Button>
            <Button
              onClick={onConfirm}
              variant="contained"
              color="success"
              size="small"
              disabled={!isEmpty(validationErrors)}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};
