import {
  Backdrop,
  Box,
  Button,
  Fade,
  Grid,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { RolesModel } from "../../Api/Model/user/RolesModel";
import { confirmationModalStyle } from "../../constants/styles.constants";
import { isEmpty } from "lodash";
import { useAxios } from "../../hooks/useAxios";

type RolesModalProps = {
  open: boolean;
  handleClose: Function;
  onConfirm: Function;
  type: "create" | "update";
  description?: string;
  name?: string;
  rolesParam?: RolesModel;
};

export const RolesModal = (props: RolesModalProps) => {
  const [roles, setRoles] = useState<RolesModel>({} as RolesModel);
  const [roleNameInUse, setRoleNameInUse] = useState(false);
  const { getAsync } = useAxios();

  const closeModal = () => props.handleClose();
  const onConfirm = () => {
    closeModal();
    !!props.onConfirm && props.onConfirm(roles);
  };
  const GetExists = async () => {
    const axiosGet = await getAsync<any>(
      "Role/GetExistRoleByName?name=" + roles?.roleName || ""
    );
    const resp = await axiosGet;

    setRoleNameInUse(resp);
  };
  useEffect(() => {
    if (roles && !isEmpty(roles.roleName?.trim()) &&
        !(roles.roleName!.trim().length < 5)
      ) {
        GetExists();
      }
  }, [roles.roleName]);

  useEffect(() => {
    if (!!props.rolesParam) {
      setRoles(props.rolesParam);
    } else {
      setRoles({} as RolesModel);
    }
  }, [props.open]);

  let validationErrors = {
    ...(isEmpty(roles.roleName?.trim()) && {
      RoleName: "Name is required",
    }),
    ...(roles.roleName?.trim().length <= 2 && {
        RoleName: "Name must be at least 3 characters long",
    }),
    ...(!isEmpty(roles.roleName?.trim()) &&
      !(roles.roleName!.trim().length <= 2) &&
      roleNameInUse &&
      roles.roleId === undefined && {
        RoleName: "Name is already used.",
      }),
    ...(isEmpty(roles.roleDescription?.trim()) && {
      RoleDescription: "Description is required",
    }),
    ...(roles.roleDescription?.trim().length <= 4 && {
        RoleDescription: "Description must be at least 5 characters long",
    }),
  };

  const handleSetTextField = (event: any) => {
    let fieldS = event.target.id.toString();
    setRoles((dc: RolesModel) => {
      return { ...dc, [fieldS]: event.target.value };
    });
  };

  return (
    <Modal
      open={props.open}
      onClose={closeModal}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
    >
      <Fade in={props.open}>
        <Box sx={confirmationModalStyle}>
          <Typography
            className="mb-4"
            id="modal-title"
            variant="h6"
            component="h2"
          >
            {props.type === "create" ? "Create" : "Update"} Role
          </Typography>

          <Grid container spacing={2} width={"100%"}>
            <Grid item xs={12}>
              <TextField
                id="roleName"
                type={"text"}
                onChange={handleSetTextField}
                label="Name"
                className="mb-2"
                value={roles.roleName || ""}
                error={!!validationErrors.RoleName}
                helperText={validationErrors.RoleName}
                variant="outlined"
                inputProps={{
                  tabIndex: 0,
                  maxLength: 50,
                  minLength: 3,
                }}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="roleDescription"
                type={"text"}
                onChange={handleSetTextField}
                value={roles.roleDescription || ""}
                label="Description"
                error={!!validationErrors.RoleDescription}
                helperText={validationErrors.RoleDescription}
                variant="outlined"
                inputProps={{
                  tabIndex: 1,
                  maxLength: 100,
                  minLength: 5,
                }}
                required
                fullWidth
              />
            </Grid>
          </Grid>

          <Box
            className="mt-4"
            sx={{ display: "flex", justifyContent: "flex-end", gap: "5px" }}
          >
            <Button
              onClick={closeModal}
              variant="contained"
              color="inherit"
              size="small"
              tabIndex={2}
            >
              Cancel
            </Button>
            <Button
              onClick={onConfirm}
              variant="contained"
              color="success"
              size="small"
              disabled={!isEmpty(validationErrors)}
              tabIndex={3}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};
