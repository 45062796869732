import { useEffect, useState } from "react";
import ConfigurationSettingModel from "../../Api/Model/ConfigurationSetting/ConfigurationSettingModel";
import { useAxios } from "../../hooks/useAxios";
import { isEmpty } from "lodash";
import PageWrapper from "../Shared/Wrapper/PageWrapper.component";
import { Box, Button, Grid, TextField } from "@mui/material";
import { useOutletContext } from "react-router-dom";

const ConfigurationSettings = (props: any) => {
    const { getAsync, updateAsync, axiosSuccess, axiosError, axiosLoading } = useAxios();
    const [sessionTimeout, setSessionTimeout] = useState({numberValue: 0} as ConfigurationSettingModel);
    const [timeBeforeCloseSession, setTimeBeforeCloseSession] = useState({ numberValue: 0 } as ConfigurationSettingModel);
    const [isEdition, setIsEdition] = useState(false);    
    const { manageErrorAlert, showAlertSnack } = useOutletContext<{ manageErrorAlert: Function, showAlertSnack: Function }>();

    const getSessionTimeout = async () => {
        const axiosGet = await getAsync<ConfigurationSettingModel>(
            "ConfigurationSettings/GetSessionTimeoutConfigSetting"
        );
        if (axiosGet) {
            setSessionTimeout(axiosGet);
        }
    };

    const getTimeBeforeCloseSession = async () => {
        const axiosGet = await getAsync<ConfigurationSettingModel>(
            "ConfigurationSettings/GetTimeBeforeCloseSessionConfigSetting"
        );
        if (axiosGet) {
            setTimeBeforeCloseSession(axiosGet);
        }
    };

    const UpdateConfigurationSetting = async (configSetting: ConfigurationSettingModel) => {
        await updateAsync<ConfigurationSettingModel>('ConfigurationSettings/UpdateConfigurationSetting', configSetting?.id || '', configSetting);
        if (axiosSuccess) {
            showAlertSnack("The configuration settings was saved succesfully.", "success");
        }
        else {
            manageErrorAlert(axiosError?.Messages[0]);
        }
    }

    useEffect(() => {
        getSessionTimeout();
        getTimeBeforeCloseSession();
    }, []);

    let validationErrors = {
        ...(!sessionTimeout.numberValue && {
            SessionTimeOut: "Time of Session is required",
        }),
        ...(sessionTimeout.numberValue! <= 0 && {
            SessionTimeOut: "Time of Session must greater than 0",
        }),
        ...(!timeBeforeCloseSession.numberValue && {
            TimeUserResponse: "Time of Session is required",
        }),
        ...(timeBeforeCloseSession.numberValue! <= 0 && {
            TimeUserResponse: "Time of user response must greater than 0",
        }),
    };

    const isFormValid = isEmpty(validationErrors);

    const editSettingsButton = () => setIsEdition(true);

    const cancelHandler = () => {
        setIsEdition(false);
        getSessionTimeout();
        getTimeBeforeCloseSession();
    }

    const updateSettingsHandler = () => {
        UpdateConfigurationSetting(sessionTimeout);
        UpdateConfigurationSetting(timeBeforeCloseSession);
        setIsEdition(false);
    }

    const settingHandler = (value:number, callback: (state: React.SetStateAction<ConfigurationSettingModel>) => void) => 
    {
        if(isNaN(value)){
            value = 0;
        }
        callback(prevState => { return { ...prevState, numberValue: value }});
    }
    
    return (
        <PageWrapper title={"Configuration Settings"}>
            <Box component="form" autoComplete="off" >
                <Grid container spacing={2} width={"100%"}>
                    <Grid item sm={6} xs={12}>
                        <TextField
                            id="SessionTimeout"
                            type={"number"}
                            onChange={(event) => settingHandler(parseInt(event.target.value), setSessionTimeout)}
                            label="Session timeout (Minutes)"
                            value={sessionTimeout.numberValue?.toString()}
                            variant="outlined" 
                            required                           
                            disabled={!isEdition}
                            error={!!validationErrors.SessionTimeOut}
                            helperText={validationErrors.SessionTimeOut}
                            inputProps={{
                                tabIndex: 0,
                                min: 1,
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <TextField
                            id="TimeBeforeCloseSession"
                            type={"number"}
                            onChange={(event) => settingHandler(parseInt(event.target.value), setTimeBeforeCloseSession)}
                            value={timeBeforeCloseSession.numberValue?.toString()}
                            label="Time waiting for response (Minutes)"
                            error={!!validationErrors.TimeUserResponse}
                            helperText={validationErrors.TimeUserResponse}
                            variant="outlined"
                            inputProps={{
                                tabIndex: 1,
                                min: 1,
                            }}
                            required
                            fullWidth
                            disabled={!isEdition}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {!isEdition && <Box
                            justifyContent="flex-end"
                            alignItems="flex-end"
                            sx={{ display: "flex", flexDirection: "row", right: 5 }}
                        >
                            <Button
                                sx={{
                                    width: 1 / 7,
                                    height: 40,
                                    marginBlock: 2,
                                    display: "inline-block",
                                }}
                                onClick={editSettingsButton}
                                color="primary"
                                variant="contained"
                                tabIndex={4}
                            >
                                Edit
                            </Button>
                        </Box>
                        }
                        {isEdition && <Box
                            justifyContent="flex-end"
                            alignItems="flex-end"
                            sx={{ display: "flex", flexDirection: "row", right: 5 }}
                        >
                            <Button
                                sx={{
                                    width: 1 / 7,
                                    height: 40,
                                    mr: 2,
                                    marginBlock: 2,
                                    display: "inline-block",
                                }}
                                onClick={updateSettingsHandler}
                                color="primary"
                                variant="contained"
                                disabled={!isFormValid}
                                tabIndex={4}
                            >
                                Save
                            </Button>
                            <Button
                                onClick={cancelHandler}
                                sx={{
                                    width: 1 / 7,
                                    height: 40,
                                    marginBlock: 2,
                                    display: "inline-block",
                                }}
                                color="secondary"
                                variant="contained"
                                tabIndex={3}
                            >
                                Cancel
                            </Button>
                        </Box>}
                    </Grid>
                </Grid>
            </Box>
        </PageWrapper>
    );
};

export default ConfigurationSettings;