import { Navigate, Outlet, useLocation } from 'react-router-dom';
import Cookies from 'universal-cookie'
import { AuthUserModel } from '../../Api/Model/user/authModel';

let protectRouting = false;
const cookies = new Cookies();

const useAuth = () => {
    const sessionUser = cookies.get("sessionUser") as AuthUserModel;

    if (sessionUser?.id) {
        let expDate = new Date();
        let expirationDate = new Date(sessionUser?.expiration);
        if (expirationDate < expDate) {
            protectRouting = false;
        }
        protectRouting = true;
    } else {
        protectRouting = false;
    }

    const user = { loggedIn: protectRouting }
    return user && user.loggedIn
}

const usePermission = () => {
    const sessionUser = cookies.get("sessionUser") as AuthUserModel;

    let locationPage = useLocation();

    if(locationPage.pathname === "/unauthorized") return true;

    if(sessionUser === undefined) return true;
    
    let foundPermission = sessionUser.authPermissions?.some(permission => locationPage.pathname.includes(permission.path?? permission.permissionName));
    
    return foundPermission;

}

const ProtectedRoutes = () => {
    const isAuth = useAuth()
    const hasPermission = usePermission();

    if(isAuth && hasPermission && sessionStorage.getItem('menuOpen') === null){
        sessionStorage.setItem('menuOpen', `${true}`);
    }

    return isAuth && hasPermission ? <Outlet /> : isAuth && !hasPermission ? <Navigate to="/unauthorized" />: <Navigate to="/" />
}

export default ProtectedRoutes;
