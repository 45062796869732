import { Box, Button} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { keyframes } from "tss-react";
import HomeIcon from '@mui/icons-material/Home';
import { useNavigate } from "react-router-dom";
import { colorsPalette } from "../constants/styles.constants";

const useStyles = makeStyles()({
    custom404Top: {
        marginTop: "30px",
        marginBottom: "30px",
        textAlign: "center"
    },
    custom404Container: {
        margin: "0 auto",
        position: "relative",
        width: "250px",
        height: "250px",
        marginTop: "-40px"
    },
    custom404Ghost: {
        width: "50%",
        height: "53%",
        left: "25%",
        top: "10%",
        position: "absolute",
        borderRadius: "50% 50% 0 0",
        background: `${colorsPalette.lightShadeGray}`,
        border: `1px solid ${colorsPalette.lightGray}`,
        borderBottom: "none",
        animation: `${keyframes`
        50% {
            transform: translateY(15px);
        }
        `} 2s ease-out infinite`
    },
    custom404GhostCopy: {
        width: "50%",
        height: "53%",
        left: "25%",
        top: "10%",
        position: "absolute",
        borderRadius: "50% 50% 0 0",
        background: `${colorsPalette.lightShadeGray}`,
        border: `1px solid ${colorsPalette.lightGray}`,
        borderBottom: "none",
        animation: `${keyframes`
        50% {
            transform: translateY(15px);
        }
        `}  2s ease-out infinite`,
        zIndex: "0",
    },
    custom404Face: {
        position: "absolute",
        width: "100%",
        height: "60%",
        top: "20%",
    },
    custom404Eye: {
        position: "absolute",
        background: `${colorsPalette.darkGrayAlt}`,
        width: "13px",
        height: "13px",
        borderRadius: "50%",
        top: "40%",
        left: "25%",
    }, 
    custom404EyeRight: {
        position: "absolute",
        background: `${colorsPalette.darkGrayAlt}`,
        width: "13px",
        height: "13px",
        borderRadius: "50%",
        top: "40%",
        right: "25%",
    },
    custom404Mouth: {
        position: "absolute",
        top: "50%",
        left: "45%",
        width: "10px",
        height: "10px",
        border: "3px solid",
        borderRadius: "50%",
        borderColor: `transparent ${colorsPalette.darkGrayAlt} ${colorsPalette.darkGrayAlt} transparent`,
        transform: "rotate(45deg)",
    },
    custom404One: {
        borderRadius: "0 0 100% 30%",
        left: "-1px",
        position: "absolute",
        background: `${colorsPalette.lightShadeGray}`,
        top: "85%",
        width: "25%",
        height: "23%",
        border: `1px solid ${colorsPalette.lightGray}`,
        zIndex: "0",
    },
    custom404Two: {
        left: "23%",
        borderRadius: "0 0 50% 50%",
        position: "absolute",
        background: `${colorsPalette.lightShadeGray}`,
        top: "85%",
        width: "25%",
        height: "23%",
        border: `1px solid ${colorsPalette.lightGray}`,
        zIndex: "0",
    },

    custom404Three: {
        left: "50%",
        borderRadius: "0 0 50% 50%",
        position: "absolute",
        background: `${colorsPalette.lightShadeGray}`,
        top: "85%",
        width: "25%",
        height: "23%",
        border: `1px solid ${colorsPalette.lightGray}`,
        zIndex: "0",
    },
    custom404Four: {
        left: "74.5%",
        borderRadius: "0 0 30% 100%",
        position: "absolute",
        background: `${colorsPalette.lightShadeGray}`,
        top: "85%",
        width: "25%",
        height: "23%",
        border: `1px solid ${colorsPalette.lightGray}`,
        zIndex: "0",
    },
    custom404Shadow: {
        position: "absolute",
        width: "30%",
        height: "7%",
        background: `${colorsPalette.lightGray}`,
        left: "35%",
        top: "80%",
        borderRadius: "50%",
        animation: `${keyframes`
        0% {
            transform: scale(1);
        }
        50% {
            transform: scale(1.1);
        }
        100% {
            transform: scale(1);
        }
        `}  2s infinite`,
    },
    custom404Bottom: {
        marginTop: "10px",
        textAlign: "center"
    },
    custom404H1: {
        color: `${colorsPalette.darkGrayAlt}`,
        textAlign: "center",
        fontSize: "9em",
        margin: "0",
        textShadow: `-1px 0 ${colorsPalette.lightShadeGray}, 0 1px ${colorsPalette.lightShadeGray}, 1px 0 ${colorsPalette.lightShadeGray}, 0 -1px ${colorsPalette.lightShadeGray}`,
    },
    custom404H3: {
        fontSize: "2em",
        textTransform: "uppercase",
        textAlign: "center",
        color: `${colorsPalette.darkGrayAlt}`,
        marginTop: "-20px",
        fontWeight: "900",
    },
    custom404P: {
        textAlign:  "center",
        color: `${colorsPalette.darkGrayAlt}`,
        fontSize:  "2em",
        marginTop:  "-20px",
        textTransform:  "uppercase",
    },
    custom404Buttons: {
        marginTop: "10px",
    }
});

const NotFoundPage = () => {  
    const { classes } = useStyles();
    const navigate = useNavigate();

    const returnToHomeHandler = () => navigate("/patientlist", { replace: true });

    return (
        <>
            <Box className={classes.custom404Top}>
                <h1 className={classes.custom404H1}>404</h1>
                <h3 className={classes.custom404H3}>page not found</h3>
            </Box>
            <Box className={classes.custom404Container}>
                <Box className={classes.custom404GhostCopy}>
                    <Box className={classes.custom404One}></Box>
                    <Box className={classes.custom404Two}></Box>
                    <Box className={classes.custom404Three}></Box>
                    <Box className={classes.custom404Four}></Box>
                </Box>
                <Box className={classes.custom404Ghost}>
                    <Box className={classes.custom404Face}>
                        <Box className={classes.custom404Eye}></Box>
                        <Box className={classes.custom404EyeRight}></Box>
                        <Box className={classes.custom404Mouth}></Box>
                    </Box>
                </Box>
                <Box className={classes.custom404Shadow}></Box>
            </Box>
            <Box className={classes.custom404Bottom}>
                <p className={classes.custom404P}>Looks like this page does not exist!</p>
                <Box className={classes.custom404Buttons}>
                    <Button
                        onClick={returnToHomeHandler}
                        sx={{
                            height: 40,
                            mr: 2,
                            marginBlock: 2,
                            display: "inline-block",
                        }}
                        type="button"
                        color="info"
                        variant="contained"
                        startIcon={<HomeIcon />}
                    >
                        Return to PRISM Care
                    </Button>
                </Box>
            </Box>
        </>
    );
}

export default NotFoundPage;