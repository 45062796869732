import {
  Box,
  Button,
  Grid,
  TextField,
  IconButton,
  FormHelperText,
  FormControl,
  InputAdornment,
  OutlinedInput,
  InputLabel,
} from "@mui/material";
import { useEffect, useState } from "react";
import { AccountUserModel } from "../../Api/Model/user/accountUserModel";
import { isEmpty } from "lodash";
import { useAxios } from "../../hooks/useAxios";
import { useOutletContext } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { AuthUserModel } from "../../Api/Model/user/authModel";
import Cookies from "universal-cookie";
import PageWrapper from "../Shared/Wrapper/PageWrapper.component";

const Setting = (props: any) => {
  const { manageErrorAlert, showAlertSnack } = useOutletContext<{
    manageErrorAlert: Function;
    showAlertSnack: Function;
  }>();
  const [accounts, setAccounts] = useState<AccountUserModel>(
    {} as AccountUserModel
  );
  const { getAsync, postAsync, updateAsync, axiosError, axiosSuccess } = useAxios();
  const [refresh, setRefresh] = useState<boolean>(true);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [currentPasswordCorrect, setCurrentPasswordCorrect] = useState(false);
  const cookies = new Cookies();
  const sessionUser = cookies.get("sessionUser") as AuthUserModel;

  const handleClickShowCurrentPassword = () =>
    setShowCurrentPassword((show) => !show);
  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleMouseDownCurrentPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const handleMouseDownNewPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const handleMouseDownConfirmPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const ValidateCurrentPassword = async () => {
    const axiosPost = await postAsync<any>(
      `AccountSettings/ValidateCurrentPassword/${accounts.userId}`, accounts.currentPassword
    );
    const resp = await axiosPost;

    setCurrentPasswordCorrect(resp);
  };

  useEffect(() => {
    if (
      accounts &&
      !isEmpty(accounts.currentPassword?.trim()) &&
      !(accounts.currentPassword!.trim().length < 8)
    ) {
      ValidateCurrentPassword();
    }
  }, [accounts.currentPassword]);

  const getAccounts = async () => {
    const axiosGet = await getAsync<AccountUserModel>(
      "AccountSettings/GetAccountSettingsById/" + sessionUser.id || ""
    );

    setRefresh(false);
    if (!axiosGet) {
      manageErrorAlert(axiosError?.Messages[0]);
      return;
    }

    setAccounts(axiosGet);
  };

  useEffect(() => {
    if (!refresh) {
      return;
    }
    getAccounts();
  }, [refresh]);

  let validationErrors = {
    ...(isEmpty(accounts.currentPassword?.trim()) && {
      CurrentPassword: "Current Password is required",
    }),
    ...(!isEmpty(accounts.currentPassword?.trim()) &&
      accounts.currentPassword!.trim().length < 8 && {
      CurrentPassword: "Current Password must be at least 8 characters long",
    }),
    ...(!isEmpty(accounts.currentPassword?.trim()) &&
      !(accounts.currentPassword!.trim().length < 8) &&
      !currentPasswordCorrect && {
      CurrentPassword: "The Password does not match the current one",
    }),
    ...(isEmpty(accounts.newPassword?.trim()) && {
      NewPassword: "Password is required",
    }),
    ...(!isEmpty(accounts.newPassword?.trim()) &&
      accounts.newPassword!.trim().length < 8 && {
      NewPassword: "Password must be at least 8 characters long",
    }),
    ...(!isEmpty(accounts.newPassword?.trim()) &&
      !(accounts.newPassword!.trim().length < 8) &&
      accounts.newPassword?.trim() === accounts.currentPassword?.trim() && {
      NewPassword:
        "The new password must be different from the current password",
    }),
    ...(isEmpty(accounts.confirmPassword?.trim()) && {
      ConfirmPassword: "Confirm password is required",
    }),
    ...(!isEmpty(accounts.confirmPassword?.trim()) &&
      accounts.confirmPassword!.trim().length < 8 && {
      ConfirmPassword: "Confirm password must be at least 8 characters long",
    }),
    ...(!isEmpty(accounts.confirmPassword?.trim()) &&
      !(accounts.confirmPassword!.trim().length < 8) &&
      accounts.confirmPassword?.trim() !== accounts.newPassword?.trim() && {
      ConfirmPassword: "Confirm password does not match",
    }),
  };

  const isFormValid = isEmpty(validationErrors);

  const handleCancel = () => {
    setRefresh(true);
  };

  const handleSubmit = async () => {
    const updateResult = await updateAsync<AccountUserModel>(
      "AccountSettings/UpdateAccountSetings",
      accounts?.userId || "",
      accounts
    );
    if (updateResult && axiosSuccess) {
      showAlertSnack(
        "The Account was successfully updated.",
        "success"
      );
      setRefresh(true);
    } else {
      manageErrorAlert(axiosError?.Messages[0]);
    }
  };

  return (
    <PageWrapper title="Account Settings" >
      <Box component="form" autoComplete="off">
        <Grid container spacing={2} width={"100%"}>
          <Grid item xs={12}>
            <TextField
              id="FirstName"
              type={"text"}
              label="First name"
              className="mb-2"
              value={accounts.firstName || ""}
              variant="outlined"
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="LastName"
              type={"text"}
              value={accounts.lastName || ""}
              label="Last Name"
              variant="outlined"
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="Email"
              value={accounts.email || ""}
              type={"email"}
              multiline
              label="Email"
              variant="outlined"
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="UserName"
              type={"text"}
              value={accounts.userName || ""}
              label="Username"
              variant="outlined"
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl
              fullWidth
              variant="outlined"
              error={!!validationErrors.CurrentPassword}
            >
              <InputLabel htmlFor="outlined-adornment-password">
                Current Password
              </InputLabel>
              <OutlinedInput
                id="CurrentPassword"
                type={showCurrentPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowCurrentPassword}
                      onMouseDown={handleMouseDownCurrentPassword}
                      edge="end"
                    >
                      {showCurrentPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                label="Current Password"
                value={accounts.currentPassword || ""}
                onChange={(event) =>
                  setAccounts({
                    ...accounts,
                    currentPassword: event.target.value as string,
                  })
                }
                inputProps={{
                  tabIndex: 0,
                  maxLength: 15,
                  minLength: 8,
                }}
                required
                fullWidth
              />
              <FormHelperText>
                {validationErrors.CurrentPassword}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl
              fullWidth
              variant="outlined"
              error={!!validationErrors.NewPassword}
            >
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                id="NewPassword"
                type={showNewPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowNewPassword}
                      onMouseDown={handleMouseDownNewPassword}
                      edge="end"
                    >
                      {showNewPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                label="New Password"
                value={accounts.newPassword || ""}
                onChange={(event) =>
                  setAccounts({
                    ...accounts,
                    newPassword: event.target.value as string,
                  })
                }
                inputProps={{
                  tabIndex: 1,
                  maxLength: 15,
                  minLength: 8,
                }}
                required
                fullWidth
              />
              <FormHelperText>
                {validationErrors.NewPassword}
              </FormHelperText>
            </FormControl>
          </Grid>{" "}
          <Grid item xs={12}>
            <FormControl
              fullWidth
              variant="outlined"
              error={!!validationErrors.ConfirmPassword}
            >
              <InputLabel htmlFor="outlined-adornment-password">
                Confirm password
              </InputLabel>
              <OutlinedInput
                id="ConfirmPassword"
                type={showConfirmPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownConfirmPassword}
                      edge="end"
                    >
                      {showConfirmPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                label="Confirm password"
                value={accounts.confirmPassword || ""}
                onChange={(event) =>
                  setAccounts({
                    ...accounts,
                    confirmPassword: event.target.value as string,
                  })
                }
                inputProps={{
                  tabIndex: 2,
                  maxLength: 15,
                  minLength: 8,
                }}
                required
                fullWidth
              />
              <FormHelperText>
                {validationErrors.ConfirmPassword}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
              <Box
                justifyContent="flex-end"
                alignItems="flex-end"
                sx={{ display: "flex", flexDirection: "row", right: 5 }}
              >
                <Button
                  sx={{
                    width: 1 / 7,
                    height: 40,
                    mr: 2,
                    marginBlock: 2,
                    display: "inline-block",
                  }}
                  onClick={handleSubmit}
                  color="success"
                  variant="contained"
                  disabled={!isFormValid}
                  tabIndex={3}
                >
                  Save
                </Button>
                <Button
                  onClick={handleCancel}
                  sx={{
                    width: 1 / 7,
                    height: 40,
                    marginBlock: 2,
                    display: "inline-block",
                  }}
                  color="secondary"
                  variant="contained"
                  tabIndex={4}
                >
                  Cancel
                </Button>
              </Box>
          </Grid>
        </Grid>
      </Box>
    </PageWrapper>
  );
};

export default Setting;
