import { VisibilityOff, Visibility, Contacts, Close } from "@mui/icons-material";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Dialog,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { isEmpty, isNull, isUndefined } from "lodash";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { usePatient } from "../../hooks/usePatient";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import ConfirmationModalComponent from "../Shared/ConfirmationModal/ConfirmationModal.component";
import { PatientInsuranceModel } from "../../Api/Model/Patient/PatientInsurance";
import { colorsPalette } from "../../constants/styles.constants";
import PatientDepartmentAssignment from "../patientInfo/PatientDepartmentAssignment/PatientDepartmentAssignment.component";
import DepartmentStructureModel from "../../Api/Model/DepartmentStructure/DepartmentStructureModel";
import { CleanButton } from "../../Shared/CleanHandler.provider";
import { phoneNumberValidator, emailvalidator } from "../../Api/Model/Shared/Validations/Validations";
import PageWrapper from "../Shared/Wrapper/PageWrapper.component";
import { PatientModel } from "../../Api/Model/PatientModel";
import utc from 'dayjs/plugin/utc'
import { PatientContext } from "../../Context/PatientContext";
import AddIcon from '@mui/icons-material/Add';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { PatientPhoneNumber } from "../../Api/Model/PatientPhoneNumberModel";
import { emptyGUID } from "../../constants/guid.contast";
import { green } from "@mui/material/colors";
import { getFirstDateIsBiggerThatSecondDate } from "../../Shared/DateTime.provider";

const AddPatient = (props: any) => {
  const { ModuleUbication, isInUse, setShowNavAlertHandler } = props;
  const { manageErrorAlert, showAlertSnack } = useOutletContext<{
    manageErrorAlert: Function;
    showAlertSnack: Function;
  }>();
  const parameter = useParams();
  const navigate = useNavigate();
  const [newPatient, setNewPatient] = useState<PatientModel>({});
  const [patientPhoneNumbers, setPatientPhoneNumbers] = useState<PatientPhoneNumber[]>([])
  const [patientPhoneNumbersEditing, setPatientPhoneNumbersEditing] = useState<PatientPhoneNumber[]>([])
  const [patientInsurances, setPatientInsurances] = useState<
    PatientInsuranceModel[]
  >([{ numberOfInsurance: 1 }, { numberOfInsurance: 2 }]);
  const {
    sexs,
    communicationPreferences,
    contactMethods,
    patientStatus,
    checkUserExist,
    addNewPatient,
    generateRandomPwd,
    existentPatient,
    updatePatient,
    deletePatient,
    retrivePatientId,
    getPatientPhoneNumbers,
    updatePatientPhoneNumbers,
    axiosError
  } = usePatient(props, manageErrorAlert, showAlertSnack);
  const [localStructure, setLocalStructure] = useState<
    DepartmentStructureModel[]
  >([]);
  const patientContext = useContext(PatientContext);
  const [totalPhoneNumbers, setTotalPhoneNumbers] = useState(0);

  const [resetKey, setResetKey] = useState(0);

  const handleReset = () => {
    setResetKey((prev) => prev + 1);
  }

  useEffect(() => {
    if (parameter.id) {
      getPatientPhoneNumbers(parameter.id).then(
        phoneNumbers => setPatientPhoneNumbers(phoneNumbers)
      );
    }
    setTotalPhoneNumbers(patientPhoneNumbers.length);
  }, []);

  useEffect(() => {
    setTotalPhoneNumbers(patientPhoneNumbers.length);
    if (patientPhoneNumbers.some(phone => phone.isMain)) {
      setNewPatient({...newPatient, phoneNumber: patientPhoneNumbers.find(phone => phone.isMain)?.phoneNumber});
    } else {
      setNewPatient({...newPatient, phoneNumber: ""});
    }
  }, [patientPhoneNumbers]);
  
  const handleSexChange = (event: any) => {
    setNewPatient({ ...newPatient, gender: event.target.value });
  };

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleContactMethodChange = (event: any) => {
    setNewPatient({
      ...newPatient,
      contactMethod: event.target.value,
    });
  };

  const handleCommunicationPreferenceChange = (event: any) => {
    setNewPatient({
      ...newPatient,
      communicationType: event.target.value,
    });
  };

  const handleStatusChange = (event: any) => {
    const status = patientStatus.find(
      (status) => status.catalogName === event.target.value
    );
    setNewPatient({
      ...newPatient,
      patientStatusName: event.target.value,
      patientStatusId: status?.catalogId,
    });
  };

  const handleGetPatientStructure = (structure: DepartmentStructureModel[]) =>
    setLocalStructure(structure);

  const handleOnAddUser = async (event: any) => {
    if (!parameter.id) {
      event.preventDefault();
      const newPatientId = await addNewPatient(newPatient, patientInsurances, localStructure);
      if (!!newPatientId && !isEmpty(newPatientId)) {
        patientPhoneNumbers.map(phoneItem => {
          phoneItem.patientId = newPatientId as string;
        })
        await updatePatientPhoneNumbers(patientPhoneNumbers, newPatientId as string);
        navigate(`/patientlist/editpatient/${newPatientId}`, { replace: true });
      }
      handleOnResetForm();
    } else {
      event.preventDefault();
      await updatePatient(parameter.id, newPatient, patientInsurances);
      patientPhoneNumbers.map(phoneItem => {
        phoneItem.patientId = parameter.id || emptyGUID;
      })
      await updatePatientPhoneNumbers(patientPhoneNumbers, parameter.id);
      sessionStorage.setItem('currentPatient', `${newPatient.firstName} ${newPatient.lastName}`);
      patientContext.setCurrentPatient(newPatient);
    }
  };

  const handleSetTextField = async (event: any) => {
    let fieldS = event.target.id.toString();
    let checkValue = event.target.value.replace("  ", " ");
    if (fieldS === "email" || fieldS === "password" || fieldS === "userName") {
      checkValue = checkValue.replace(" ", "");
    }
    if (fieldS !== "email" && fieldS !== "password") {
      checkValue = checkValue.replace(/[^\w ]/g, "");
    }
    if (
      fieldS === "phoneNumber" ||
      fieldS === "officePhoneNumber" ||
      fieldS === "zipCode"
    ) {
      checkValue = checkValue.replace(/[^\d]/g, "");
    }
    if (fieldS === "userName" || fieldS === "password" || fieldS === "email") {
      setNewPatient({ ...newPatient, user: { ...newPatient.user, [fieldS]: checkValue } });
      return;
    }
    if (fieldS === "streetLine1" || fieldS === "streetLine2" || fieldS === "city" || fieldS === "state" || fieldS === "zipCode") {
      setNewPatient({ ...newPatient, patientAddress: { ...newPatient.patientAddress, [fieldS]: checkValue } });
      return;
    }
    setNewPatient({ ...newPatient, [fieldS]: checkValue });
  };

  const handleSetPatientId = () => {
    if (!parameter.id) {
      return;
    }
    retrivePatientId(parameter.id)
    .then(patientIdRes => {
        setNewPatient({...newPatient, internalCode: patientIdRes})
        patientContext.setCurrentPatient(prevState => {return {...prevState,internalCode:patientIdRes}})
    });
  }

  useEffect(() => {
    if (patientContext.patientDepartmentHasChanged) {
      handleSetPatientId();
    }
  }, [patientContext.patientDepartmentHasChanged]);

  useEffect(() => {
    if (!newPatient) {
      return;
    }
    const usrname = patientContext.currentPatient?.user?.userName;
    
    if (
      !isEmpty(newPatient.user?.userName?.trim()) &&
      !((!!newPatient.user?.userName && newPatient.user?.userName?.trim().length <= 4)) &&
      newPatient.user?.userName !== usrname
    ) {
      checkUserExist(newPatient.user?.userName || "");
    }
  }, [newPatient.user?.userName]);

  useEffect(() => {
    if (isEmpty(newPatient)) {
      handleGetPatientStructure([] as DepartmentStructureModel[]);
    }
  }, [newPatient]);

  useEffect(() => {
    if (axiosError && axiosError?.Code === '401') {
      manageErrorAlert("Unauthorized");
      navigate("/patientlist", { replace: true });
      return;
    }
  }, [axiosError]);

  const setPatientInsurancePropertieHandle = (
    event: any,
    index: number,
    propertyName: string
  ) => {
    // This line creates a new data type based on the properties contained in the PatientInsuranceModel
    // setting a string of this type allows access to the currentPatientInsurance properties dynamically
    type ObjectKey = keyof PatientInsuranceModel;
    let currentPatientInsurance = [...patientInsurances];
    let fieldS = propertyName as ObjectKey;
    let cleanValue =
      propertyName == "groupNumber"
        ? event.target.value.replace(/[^\d]/g, "")
        : event.target.value.replace("  ", " ");
    currentPatientInsurance[index][fieldS] = cleanValue;
    setPatientInsurances(currentPatientInsurance);
  };

  let validationErrors = {
    ...(isEmpty(newPatient.user?.userName?.trim()) && {
      Username: "Username is required",
    }),
    ...(!isEmpty(newPatient.user?.userName?.trim()) &&
      !!newPatient.user?.userName && newPatient.user?.userName!.trim().length < 5 && {
      Username: "Username must be at least 5 characters long",
    }),
    ...(!isEmpty(newPatient.user?.userName?.trim()) &&
      !(!!newPatient.user?.userName && newPatient.user?.userName!.trim().length < 5) &&
      existentPatient && {
      Username: "Username is already used.",
    }),
    ...(isEmpty(newPatient.firstName?.trim()) && {
      FirstName: "First Name is required",
    }),
    ...(!isEmpty(newPatient.firstName?.trim()) &&
      newPatient.firstName!.trim().length < 3 && {
      FirstName: "First Name must be at least 3 characters long",
    }),
    ...(isEmpty(newPatient.lastName?.trim()) && {
      LastName: "Last Name is required",
    }),
    ...(!isEmpty(newPatient.lastName?.trim()) &&
      newPatient.lastName!.trim().length < 3 && {
      LastName: "Last Name must be at least 3 characters long",
    }),
    ...(!patientContext.currentPatient && isEmpty(newPatient.user?.password?.trim()) && {
      Password: "Password is required",
    }),
    ...(!patientContext.currentPatient && !isEmpty(newPatient.user?.password?.trim()) &&
      !!newPatient.user?.password && newPatient.user?.password!.trim().length < 1 && {
      Password: "Password must be at least 1 character",
    }),
    ...(isEmpty(newPatient.user?.email?.trim()) &&
      isEmpty(newPatient.phoneNumber?.trim()) && {
      Email: "Email or Phone Number is required",
      Phone: "Phone Number or Email is required",
    }),
    ...(!parameter.id && !isEmpty(newPatient.phoneNumber?.trim()) && !phoneNumberValidator(newPatient.phoneNumber?.trim()) && {
      Phone: "Phone Number must be number",
    }),
    ...(!isEmpty(newPatient.user?.email?.trim()) &&
      !emailvalidator(newPatient.user?.email?.trim()) && {
      Email: "Email is invalid",
    }),
    ...(isUndefined(newPatient.birthDateTime) && {
      DateOfBirth: "Date Of Birth is required",
    }),
    ...(isNull(newPatient.birthDateTime) && {
      DateOfBirth: "Date Of Birth is required",
    }),
    ...(newPatient.birthDateTime?.toString() === "Invalid Date" && {
      DateOfBirth: "Date Of Birth must be a valid date",
    }),
    ...(!!newPatient.birthDateTime && getFirstDateIsBiggerThatSecondDate(new Date(newPatient.birthDateTime), new Date()) && {
      DateOfBirth: "Date Of Birth cannot be a future date"
    }),
    ...(isEmpty(newPatient.patientStatusName) && {
      Status: "Patient status is required",
    })
  };

  const isFormValid = isEmpty(validationErrors);

  const handleCheckField = (event: any) => {
    let propertyName = event.target.id.toString();
    type ObjectKey = keyof PatientModel;
    let fieldS = propertyName as ObjectKey;
    setNewPatient({ ...newPatient, [fieldS]: !newPatient[fieldS] });
  };

  const handleOnResetForm = () => {
    if (!parameter.id) {
      setNewPatient({});
      setPatientInsurances([{ numberOfInsurance: 1 }, { numberOfInsurance: 2 }]);
      setPatientPhoneNumbers([]);
      handleReset();
      return;
    }
    navigate("/patientlist", { replace: true });
  };

  const handleOnDelete = async () => {
    if (parameter.id) {
      setOpenDeleteConfirmation(true);
    }
  };

  const handleGenerateRandomPwd = (event: any) => {
    setNewPatient({ ...newPatient, user: { ...newPatient.user, password: generateRandomPwd() as string } });
  };

  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const onDeleteConfirmation = async () => {
    await deletePatient(parameter.id || "");
    setShowNavAlertHandler(false);
    navigate("/patientlist", { replace: true });
  };
  const closeConfirmModal = useCallback(() => {
    setOpenDeleteConfirmation(false);
  }, [setOpenDeleteConfirmation]);
  const ui_confirm = (
    <ConfirmationModalComponent
      open={openDeleteConfirmation}
      handleClose={closeConfirmModal}
      type="deletion"
      onConfirm={onDeleteConfirmation}
    />
  );

  useEffect(() => {
    if (!patientContext.patientIsReady || isEmpty(patientContext?.currentPatient)) {
      return;
    }

    setNewPatient(patientContext?.currentPatient || {});
    sessionStorage.setItem("currentPatient", `${patientContext.currentPatient.firstName} ${patientContext.currentPatient.lastName}`);
    if (patientContext?.currentPatient?.patientInsurancesList && patientContext?.currentPatient?.patientInsurancesList.length > 0) {
      setPatientInsurances(patientContext?.currentPatient?.patientInsurancesList || []);
    }
  }, [patientContext.patientIsReady])

  dayjs.extend(utc);
  (dayjs.utc as any).Ls = dayjs.Ls;

  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  
  const handleDialogOpens = () => {
    setPatientPhoneNumbersEditing([...patientPhoneNumbers]);
    setDialogIsOpen(true);
  }

  const handleDialogCloses = () => {
    setDialogIsOpen(false);
    
    let newPatientPhoneNumber: PatientPhoneNumber = {
      phoneNumber: "",
      category: "Home",
      description: "",
      patientId: patientContext.currentPatient?.patientId || parameter.id || emptyGUID,
      index: 0,
      isMain: true
    };

    setPatientPhoneNumbersEditing([newPatientPhoneNumber]);
  }

  const handledialogSaveAndCloses = () => {
    const numbersToSave = patientPhoneNumbersEditing.filter(phoneItem => phoneItem.phoneNumber !== "");
    setPatientPhoneNumbers(numbersToSave);
    handleDialogCloses();
  }

  const handlePhoneNumberItemChanged = (newValue: string, valueType: string, phoneIndex: number) => {
    setPatientPhoneNumbersEditing((prevState) => 
      prevState.map((phoneNumberItem, i) => i === phoneIndex ? {...phoneNumberItem, [valueType]: newValue, index: phoneIndex } : phoneNumberItem)
    );
  }

  const handlePhoneNumberItemMarkedAsMain = (phoneNumberItem: PatientPhoneNumber) => {
    setPatientPhoneNumbersEditing((prevState) => 
      prevState.map((stateNumberItem) => stateNumberItem === phoneNumberItem ? {...stateNumberItem, isMain: true} : {...stateNumberItem, isMain: false})
    );
  }

  const handlePhoneNumberItemAdded = () => {
    let newPatientPhoneNumber: PatientPhoneNumber = {
      phoneNumber: "",
      category: "Home",
      description: "",
      patientId: patientContext.currentPatient?.patientId || parameter.id || emptyGUID,
      index: patientPhoneNumbersEditing.length,
      isMain: patientPhoneNumbersEditing.length === 0
    };

    setPatientPhoneNumbersEditing([...patientPhoneNumbersEditing, newPatientPhoneNumber]);
  }

  const handlePhoneNumberItemRemoved = (phoneNumberItem: PatientPhoneNumber) => {
    // remove the item from the list
    const filteredList = patientPhoneNumbersEditing.filter((phoneItem) => phoneItem !== phoneNumberItem);
    
    // update the index of the other items and set main number
    let mainExists = filteredList.some(phone => phone.isMain === true);
    const mappedList = filteredList.map((phoneThatStays, phoneIndex) => {
      if (mainExists) {
        return {
          ...phoneThatStays,
          index: phoneIndex,
        }
      } else {
        mainExists = true;
        return {
          ...phoneThatStays,
          index: phoneIndex,
          isMain: true
        }
      }
    });

    setPatientPhoneNumbersEditing(mappedList);
  }

  const [arePhonesValid, setArePhonesValid] = useState(false);

  useEffect(() => {
    setArePhonesValid(true);
    patientPhoneNumbersEditing.map(phoneItem => {
      if (isEmpty(phoneItem.phoneNumber)) {
        if (patientPhoneNumbersEditing.length !== 1) {
          setArePhonesValid(false);
        }
      }

      if (!isEmpty(phoneItem.phoneNumber) && !phoneNumberValidator(phoneItem.phoneNumber.trim())) {
        setArePhonesValid(false);
      }

      if (phoneItem.category === "Other" && (isEmpty(phoneItem.description?.trim()) || (phoneItem.description?.length || 0) < 2)) {
        setArePhonesValid(false);
      }
    });

    if (patientPhoneNumbersEditing === patientPhoneNumbers) {
      setArePhonesValid(false);
    }

    if (patientPhoneNumbersEditing.length === 0) {

      let newPatientPhoneNumber: PatientPhoneNumber = {
        phoneNumber: "",
        category: "Home",
        description: "",
        patientId: patientContext.currentPatient?.patientId || parameter.id || emptyGUID,
        index: 0,
        isMain: true
      };

      setPatientPhoneNumbersEditing([newPatientPhoneNumber]);
    }
  }, [patientPhoneNumbersEditing, dialogIsOpen])

  return (
    <PageWrapper title={`${parameter.id ? "Patient info" : "Add Patient"}`}>
      <Box
        component="form"
        sx={{ maxWidth: "100%" }}
        flexDirection={"row"}
        autoComplete="off"
      >
        <Grid container spacing={4} rowSpacing={5} width={"100%"}>
          <Grid item xs={4}>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <TextField
                  type={"text"}
                  onChange={handleSetTextField}
                  multiline
                  fullWidth
                  disabled
                  id="internalCode"
                  value={ newPatient.cvInternalCode || newPatient.internalCode || ""}
                  label="Patient ID"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={8}>
                <FormControl fullWidth error={!!validationErrors.Status}>
                  <InputLabel color="primary" id="PatientStatusLabelId">Status</InputLabel>
                  <Select
                    fullWidth
                    defaultValue={""}
                    inputProps={{
                      tabIndex: 1,
                    }}
                    multiple={false}
                    unselectable="on"
                    native={false}
                    disabled={isInUse}
                    label="Status"
                    labelId="PatientStatusLabelId"
                    value={newPatient.patientStatusName || ""}
                    onChange={handleStatusChange}
                    error={!!validationErrors.Status}
                    endAdornment={
                      !!newPatient.patientStatusName && (
                        CleanButton(handleStatusChange)
                      )
                    }
                  >
                    {patientStatus.length === 0 && (
                      <MenuItem value="">
                        No Statuses found...
                      </MenuItem>
                    )}
                    {patientStatus &&
                      patientStatus.sort().map(
                        (StatusCat: any) => (
                          <MenuItem
                            value={StatusCat.catalogName}
                            key={StatusCat.catalogId}
                          >
                            {StatusCat.catalogName}
                          </MenuItem>
                        )
                      )}
                  </Select>
                  <FormHelperText>
                    {validationErrors.Status}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <TextField
              type="text"
              onChange={handleSetTextField}
              fullWidth
              id="userName"
              inputProps={{
                tabIndex: 6,
                maxLength: 15,
                minLength: 5,
                autoComplete: "no",
              }}
              disabled={isInUse}
              error={!!validationErrors.Username}
              helperText={validationErrors.Username}
              label="Username"
              required
              autoComplete="no"
              tabIndex={1}
              value={newPatient.user?.userName || ""}
              variant="outlined"
              autoFocus
            />
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel
                id="CommunicationPreferenceLabel"
                color="primary"
              >
                Communication Preference
              </InputLabel>
              <Select
                fullWidth
                id="CommunicationPreference"
                defaultValue={""}
                inputProps={{
                  tabIndex: 14,
                }}
                multiple={false}
                unselectable="on"
                native={false}
                disabled={isInUse}
                label="Communication Preference"
                labelId="CommunicationPreferenceLabel"
                value={newPatient.communicationType || ""}
                onChange={handleCommunicationPreferenceChange}
                endAdornment={
                  !!newPatient.communicationType && (
                    CleanButton(handleCommunicationPreferenceChange)
                  )
                }
              >
                {communicationPreferences.length === 0 && (
                  <MenuItem value="">
                    No communication preferences found...
                  </MenuItem>
                )}
                {communicationPreferences &&
                  communicationPreferences.map(
                    (CommunicationCat: any) => (
                      <MenuItem
                        value={CommunicationCat.catalogName}
                        key={CommunicationCat.catalogId}
                      >
                        {CommunicationCat.catalogName}
                      </MenuItem>
                    )
                  )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <TextField
              type={"text"}
              onChange={handleSetTextField}
              fullWidth
              id="firstName"
              required
              inputProps={{
                tabIndex: 1,
                maxLength: 50,
                minLength: 3,
              }}
              disabled={isInUse}
              error={!!validationErrors.FirstName}
              helperText={validationErrors.FirstName}
              value={newPatient.firstName || ""}
              label="First Name"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={4}>
            <FormControl
              required={!patientContext.currentPatient}
              sx={{ width: 7 / 10, paddingRight: 1 }}
              variant="outlined"
              error={!!validationErrors.Password}
            >
              <InputLabel htmlFor="outlined-adornment-password"
                sx={{ opacity: !!patientContext.currentPatient ? 0.5 : 1 }}>
                Password
              </InputLabel>
              <OutlinedInput
                value={newPatient.user?.password || ""}
                error={!!validationErrors.Password}
                disabled={isInUse}
                onChange={handleSetTextField}
                required
                inputProps={{
                  tabIndex: 7,
                  maxLength: 15,
                  minLength: 8,
                  autoComplete:'new-password'
                }}
                id="password"
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      disabled={isInUse}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
              <FormHelperText>
                {validationErrors.Password}
              </FormHelperText>
            </FormControl>
            <Button
              disabled={isInUse}
              onClick={handleGenerateRandomPwd}
              sx={{
                width: 3 / 10,
                height: 55,
                bgcolor: colorsPalette.darkGray,
              }}
              variant="contained"
            >
              Generate Password
            </Button>
          </Grid>
          <Grid item xs={4}>
            <FormGroup row>
              <FormLabel
                sx={{ marginTop: "-13px" }}
                component={"legend"}
              >
                Compliance Messaging
              </FormLabel>
              <FormControlLabel
                sx={{ height: 26 }}
                control={
                  <Checkbox
                    disabled={isInUse}
                    id="nurseWeeklyCall"
                    onChange={handleCheckField}
                    checked={newPatient.nurseWeeklyCall || false}
                  />
                }
                label="Nurse Weekly Call"
              />
              <FormControlLabel
                sx={{ height: 26 }}
                control={
                  <Checkbox
                    disabled={isInUse}
                    id="nurseMonthlyCall"
                    onChange={handleCheckField}
                    checked={newPatient.nurseMonthlyCall || false}
                  />
                }
                label="Nurse Monthly Call"
              />
            </FormGroup>
            <FormControlLabel
              sx={{ height: 26 }}
              control={
                <Checkbox
                  disabled={isInUse}
                  id="noComplianceMessaging"
                  onChange={handleCheckField}
                  checked={newPatient.noComplianceMessaging || false}
                />
              }
              label="No Compilance Messaging"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              type={"text"}
              onChange={handleSetTextField}
              fullWidth
              id="lastName"
              required
              disabled={isInUse}
              inputProps={{
                tabIndex: 2,
                maxLength: 50,
                minLength: 3,
              }}
              error={!!validationErrors.LastName}
              helperText={validationErrors.LastName}
              label="Last Name"
              value={newPatient.lastName || ""}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              type={"email"}
              onChange={handleSetTextField}
              fullWidth
              disabled={isInUse}
              id="email"
              inputProps={{
                tabIndex: 8,
                maxLength: 250,
                minLength: 5,
              }}
              error={!!validationErrors.Email}
              helperText={validationErrors.Email}
              required
              label="Email"
              value={newPatient.user?.email || ""}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              type="tel"
              fullWidth
              disabled={true}
              id="phoneNumber"
              sx={{ width: 9 / 10, paddingRight: 1 }}
              required
              label="Phone Numbers"
              error={!!validationErrors.Phone}
              helperText={validationErrors.Phone}
              value={patientPhoneNumbers.find(number => number.isMain)?.phoneNumber || ''}
              variant="outlined"
            />
            <IconButton edge="end" size="large" sx={{ width: 1 / 10, marginTop: 0.5}} onClick={() => handleDialogOpens()}>
              <Badge badgeContent={totalPhoneNumbers} color="success" max={99}>
                <Contacts color="primary"/>
              </Badge>
            </IconButton>

            <Dialog open={dialogIsOpen} fullWidth maxWidth={'md'}>
              <DialogTitle>
                <Grid container>
                  <Grid item xs={11} sx={{paddingLeft: 3}}>
                    Edit patient's phone numbers
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton sx={{marginLeft: 1.5}} edge="start" onClick={() => handleDialogCloses()}><Close/></IconButton>
                  </Grid>
                </Grid>
              </DialogTitle>
              <List sx={{ pt: 0 }}>

              {patientPhoneNumbersEditing &&
                  patientPhoneNumbersEditing.map((phoneNumberItem: PatientPhoneNumber, index) => (
                    
                    <ListItem key={index}>
                      <Grid container>

                        <Grid item xs={1}>
                          {phoneNumberItem.isMain && (
                            <IconButton
                              size="large"
                              color="warning"
                              sx={{ marginTop: 0.5, marginLeft: 2.5 }}
                              edge="end"
                            >
                              <StarIcon />
                            </IconButton>
                          )}

                          {!phoneNumberItem.isMain && (
                            <IconButton
                              size="large"
                              color="warning"
                              sx={{ marginTop: 0.5, marginLeft: 2.5 }}
                              edge="end"
                              onClick={() => {handlePhoneNumberItemMarkedAsMain(phoneNumberItem)}}
                            >
                              <StarBorderIcon />
                            </IconButton>
                          )}
                        </Grid>

                        <Grid item xs={phoneNumberItem.category=== "Other" ? 3 : 6}>
                          <TextField
                            type="tel"
                            fullWidth
                            sx={{ paddingRight: 1 }}
                            label={"Phone number"}
                            value={phoneNumberItem.phoneNumber}
                            id="phoneNumber"
                            onChange={(event) => {handlePhoneNumberItemChanged(event.target.value, "phoneNumber", index)}}
                            error={!phoneNumberValidator(phoneNumberItem.phoneNumber?.trim())}
                            helperText={!phoneNumberValidator(phoneNumberItem.phoneNumber?.trim()) ? "Invalid number" : " "}
                          />
                        </Grid>

                        <Grid item xs={phoneNumberItem.category=== "Other" ? 3 : 4}>
                          <FormControl fullWidth sx={{paddingRight: phoneNumberItem.category === "Other" ? 1 : 0}}>
                            <InputLabel id="demo-simple-select-label">
                              Type
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              label="Type"
                              value={phoneNumberItem.category}
                              id="category"
                              onChange={(event) => {handlePhoneNumberItemChanged(event.target.value, "category", index)}}
                              >
                              <MenuItem value={"Home"}>Home</MenuItem>
                              <MenuItem value={"Mobile"}>Mobile</MenuItem>
                              <MenuItem value={"Other"}>Other</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>

                        {phoneNumberItem.category === "Other" && (
                          <Grid item xs={4}>
                            <TextField
                              type="text"
                              fullWidth
                              label={"Description"}
                              value={phoneNumberItem.description}
                              id="description"
                              onChange={(event) => {handlePhoneNumberItemChanged(event.target.value, "description", index)}}
                              error={(phoneNumberItem.category === "Other" && isEmpty(phoneNumberItem.description?.trim()) || (phoneNumberItem.description?.length || 0) < 2)}
                              helperText={(phoneNumberItem.category === "Other" && (isEmpty(phoneNumberItem.description?.trim()) || (phoneNumberItem.description?.length || 0) < 2)) ? "Invalid description" : " "}
                              />
                          </Grid>
                        )}

                        <Grid item xs={1}>
                          <IconButton
                            size="large"
                            color="error"
                            sx={{ marginTop: 0.5 }}
                            edge="end"
                            disabled={isEmpty(phoneNumberItem.phoneNumber) && patientPhoneNumbersEditing.length === 1}
                            onClick={() => handlePhoneNumberItemRemoved(phoneNumberItem)}
                          >
                            <RemoveCircleIcon />
                          </IconButton>
                        </Grid>

                      </Grid>
                    </ListItem>
                  ))}

                <ListItem disableGutters sx={{marginTop: -3}}>
                  <ListItemButton onClick={() => handlePhoneNumberItemAdded()} sx={{marginLeft: 4.5}}>
                    <Avatar sx={{ bgcolor: green[500], width: 25, height: 25 }}>
                      <AddIcon/>
                    </Avatar>
                    <ListItemText sx={{marginLeft: 1}} primary="Add new phone number"></ListItemText>
                  </ListItemButton>
                </ListItem>

              </List>
              <Button disabled={!arePhonesValid} color="success" variant="contained" sx={{width: 250, marginLeft: 76 , marginBottom: 2.5}} onClick={()=>handledialogSaveAndCloses()}>
                Accept
              </Button>
            </Dialog>
          </Grid>

          <Grid item xs={4}>
            <TextField
              type={"text"}
              onChange={handleSetTextField}
              fullWidth
              disabled={isInUse}
              inputProps={{
                tabIndex: 14,
                maxLength: 100,
                minLength: 5,
              }}
              id="streetLine1"
              value={newPatient.patientAddress?.streetLine1 || ""}
              label="Address Line 1"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              type={"text"}
              onChange={handleSetTextField}
              fullWidth
              disabled={isInUse}
              id="streetLine2"
              inputProps={{
                tabIndex: 15,
                maxLength: 100,
                minLength: 5,
              }}
              value={newPatient.patientAddress?.streetLine2 || ""}
              label="Address Line 2"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              type={"text"}
              onChange={handleSetTextField}
              fullWidth
              disabled={isInUse}
              inputProps={{
                tabIndex: 3,
                maxLength: 15,
                minLength: 3,
              }}
              id="nickname"
              value={newPatient.nickname || ""}
              label="Nickname"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs.utc}>
              <DatePicker
                disableFuture
                onChange={(newValue: Dayjs | null) => {
                  setNewPatient({ ...newPatient, birthDateTime: newValue?.utc().toDate() })
                }
                }
                disabled={isInUse}
                value={newPatient.birthDateTime || null}
                label="Date Of Birth"
                inputFormat="MM/DD/YYYY"
                renderInput={(params) => (
                  <TextField
                    required
                    inputProps={{
                      tabIndex: 4,
                    }}
                    {...params}
                    error={!!validationErrors.DateOfBirth}
                    helperText={validationErrors.DateOfBirth}
                    fullWidth
                  />
                )}
                PopperProps={{
                  placement: "bottom-start",
                  sx: { zIndex: 1200, paddingBottom: 2 }
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={4}>
            <TextField
              type="text"
              disabled={isInUse}
              onChange={handleSetTextField}
              sx={{ width: 5 / 10, paddingRight: 1 }}
              id="provider"
              inputProps={{
                tabIndex: 10,
                maxLength: 75,
                minLength: 5,
              }}
              label="Provider"
              value={newPatient.provider || ""}
              variant="outlined"
            />
            <TextField
              type="tel"
              disabled={isInUse}
              onChange={handleSetTextField}
              sx={{ width: 5 / 10 }}
              inputProps={{
                tabIndex: 11,
                maxLength: 30,
                minLength: 5,
              }}
              id="officePhoneNumber"
              label="Office Number"
              value={newPatient.officePhoneNumber || ""}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              disabled={isInUse}
              type="text"
              inputProps={{
                tabIndex: 16,
                maxLength: 30,
                minLength: 3,
              }}
              onChange={handleSetTextField}
              sx={{ width: 5 / 10, paddingRight: 1 }}
              multiline
              id="city"
              label="City"
              value={newPatient.patientAddress?.city || ""}
              variant="outlined"
            />

            <TextField
              disabled={isInUse}
              type={"text"}
              inputProps={{
                tabIndex: 17,
                maxLength: 30,
                minLength: 3,
              }}
              onChange={handleSetTextField}
              sx={{ width: 5 / 10 }}
              multiline
              id="state"
              value={newPatient.patientAddress?.state || ""}
              label="State"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={4}>
            <FormControl
              fullWidth
            >
              <InputLabel id="SexCombo" color="primary">
                Sex
              </InputLabel>
              <Select
                fullWidth
                id="Sex"
                disabled={isInUse}
                defaultValue={""}
                inputProps={{
                  tabIndex: 5,
                }}
                required
                multiple={false}
                unselectable="on"
                native={false}
                label="gender"
                labelId="SexCombo"
                value={newPatient.gender || ""}
                onChange={handleSexChange}
                endAdornment={
                  !!newPatient.gender && (
                    CleanButton(handleSexChange)
                  )
                }
              >
                {sexs.length === 0 && (
                  <MenuItem value="">No sexs found...</MenuItem>
                )}
                {sexs.map((genderCat: any) => (
                  <MenuItem
                    value={genderCat.catalogName}
                    key={genderCat.catalogId}
                  >
                    {genderCat.catalogName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel id="ContacMethodLabel" color="primary">
                Contact Method
              </InputLabel>
              <Select
                fullWidth
                id="ContactMethod"
                defaultValue={""}
                unselectable="on"
                disabled={isInUse}
                multiple={false}
                inputProps={{
                  tabIndex: 12,
                }}
                native={false}
                label="Contact Method"
                labelId="ContacMethodLabel"
                value={newPatient.contactMethod || ""}
                onChange={handleContactMethodChange}
                endAdornment={
                  !!newPatient.contactMethod && (
                    CleanButton(handleContactMethodChange)
                  )
                }
              >
                {contactMethods.length === 0 && (
                  <MenuItem value="">
                    No contact methods found...
                  </MenuItem>
                )}
                {contactMethods.map((ContactCat: any) => (
                  <MenuItem
                    value={ContactCat.catalogName}
                    key={ContactCat.catalogId}
                  >
                    {ContactCat.catalogName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <TextField
              type={"tel"}
              onChange={handleSetTextField}
              fullWidth
              disabled={isInUse}
              inputProps={{
                tabIndex: 18,
                maxLength: 6,
                minLength: 5,
              }}
              id="zipCode"
              value={newPatient.patientAddress?.zipCode || ""}
              label="Zip Code"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              type={"text"}
              onChange={(event) =>
                setPatientInsurancePropertieHandle(event, 0, "name")
              }
              fullWidth
              disabled={isInUse}
              inputProps={{
                tabIndex: 19,
                maxLength: 60,
                minLength: 3,
              }}
              id="PrimaryInsuranceName"
              value={patientInsurances[0].name || ""}
              label="Primary Insurance Name"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              type={"text"}
              onChange={(event) =>
                setPatientInsurancePropertieHandle(event, 0, "idNumber")
              }
              fullWidth
              disabled={isInUse}
              inputProps={{
                tabIndex: 20,
                maxLength: 12,
                minLength: 3,
              }}
              id="PrimaryInsuranceId"
              value={patientInsurances[0].idNumber || ""}
              label="Primary Insurance ID Number"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              type={"text"}
              onChange={(event) =>
                setPatientInsurancePropertieHandle(
                  event,
                  0,
                  "groupNumber"
                )
              }
              fullWidth
              disabled={isInUse}
              inputProps={{
                tabIndex: 21,
                maxLength: 6,
                minLength: 3,
              }}
              id="PrimaryInsuranceGroup"
              value={patientInsurances[0].groupNumber || ""}
              label="Primary Insurance Group Number"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              type={"text"}
              onChange={(event) =>
                setPatientInsurancePropertieHandle(event, 1, "name")
              }
              fullWidth
              disabled={isInUse}
              inputProps={{
                tabIndex: 22,
                maxLength: 60,
                minLength: 3,
              }}
              id="SecondaryInsuranceName"
              value={patientInsurances[1].name || ""}
              label="Secondary Insurance Name"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              type={"text"}
              onChange={(event) =>
                setPatientInsurancePropertieHandle(event, 1, "idNumber")
              }
              fullWidth
              disabled={isInUse}
              inputProps={{
                tabIndex: 23,
                maxLength: 12,
                minLength: 3,
              }}
              id="SecondaryInsuranceId"
              value={patientInsurances[1].idNumber || ""}
              label="Secondary Insurance ID Number"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              type={"text"}
              onChange={(event) =>
                setPatientInsurancePropertieHandle(
                  event,
                  1,
                  "groupNumber"
                )
              }
              fullWidth
              disabled={isInUse}
              inputProps={{
                tabIndex: 24,
                maxLength: 6,
                minLength: 3,
              }}
              id="SecondaryInsuranceGroup"
              value={patientInsurances[1].groupNumber || ""}
              label="Secondary Insurance Group Number"
              variant="outlined"
            />
          </Grid>
        </Grid>
      </Box>
      {parameter.id === undefined && (
        <Card className="noBorder">
          <CardHeader
            title="Department Assignment"
            sx={{ pl: 0, mt: 2 }}
          />
          <CardContent sx={{ pl: 0 }}>
            <PatientDepartmentAssignment
              key={resetKey}
              isAdding
              onStructureChanged={handleGetPatientStructure}
              patientStructure={localStructure}
            />
          </CardContent>
        </Card>
      )}
      <Grid item xs={12}>
        <Box
          justifyContent="flex-end"
          alignItems="flex-end"
          sx={{ display: "flex", flexDirection: "row", right: 5 }}
        >
          <Button
            onClick={handleOnAddUser}
            sx={{
              width: 1 / 7,
              height: 40,
              mr: 2,
              marginBlock: 2,
              display: "inline-block",
            }}
            type="button"
            color="success"
            variant="contained"
            disabled={!isFormValid || isInUse}
          >
            {`${parameter.id ? "Update" : "Save"}`}
          </Button>

          <Button
            onClick={handleOnResetForm}
            sx={{
              width: 1 / 7,
              height: 40,
              mr: 2,
              marginBlock: 2,
              display: "inline-block",
            }}
            disabled={isInUse}
            color="secondary"
            variant="contained"
          >
            Cancel
          </Button>
          {ModuleUbication && (
            <Button
              onClick={handleOnDelete}
              sx={{
                width: 1 / 7,
                height: 40,
                marginBlock: 2,
                display: "inline-block",
              }}
              color="error"
              variant="contained"
              disabled={isInUse}
            >
              Delete
            </Button>
          )}
        </Box>
      </Grid>
      {ui_confirm}
    </PageWrapper>
  );
};

export default AddPatient;
