import {
  Button,
  Grid,
  TextField,
} from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs";
import { isUndefined, isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { ReportFilterModel } from "../../../Api/Model/ReportFilterModel";
import { PaginationResult } from "../../../Api/Model/Shared/PaginationResult";
import { BillingReportModel } from "../../../Api/Model/Reports/BillingReportModel";
import { CustomNoRowsReportOverlay } from "../../../constants/styles.constants";
import { useAxios } from "../../../hooks/useAxios";
import StripedDataGrid from "../../Shared/StripedDataGrid/StripedDatagrid.component";
import { DateDisplayFormat } from "../../../constants/global.constants";
import PageWrapper from "../../Shared/Wrapper/PageWrapper.component";

const PatientReadings = () => {
  const [reportFilterModel, setReportFilterModel] = useState<ReportFilterModel>({});
  const [timeTrackingReport, setTimeTrackingReport] = useState<BillingReportModel[]>([]);
  const { axiosLoading, axiosPagination } = useAxios();
  const [rowCountState, setRowCountState] = useState(1);
  const [page, setPage] = useState(0);
  const [pagination, setPagination] = useState<PaginationResult>({ TotalRecords: 1, PageSize: 10 });
  const [refresh, setRefresh] = useState<boolean>(false);

  const handlePageSize = (newPageSize: number) => {
    setPagination({ ...pagination, PageSize: newPageSize });
    setRefresh(true);
  }

  useEffect(() => {
    if (!refresh) {
      return;
    }
    getTimeTrackingReport();
  }, [refresh]);

  useEffect(() => {
    setRowCountState((prevState) =>
      axiosPagination?.TotalRecords !== undefined ? axiosPagination?.TotalRecords : prevState
    );
  }, [axiosPagination?.TotalRecords]);

  useEffect(() => {
    setPagination({ ...pagination, PageNumber: page + 1 });
    setRefresh(true);
  }, [page]);

  const validationErrors = {
    ...((isUndefined(reportFilterModel.startDate))) && {
      StartDate: "Start date is required",
    },
    ...((reportFilterModel.startDate?.toString() === "Invalid Date")) && {
      StartDate: "Start date must be a valid date",
    },
    ...((isUndefined(reportFilterModel.endDate))) && {
      EndDate: "End date is required",
    },
    ...((reportFilterModel.endDate?.toString() === "Invalid Date")) && {
      EndDate: "End date must be a valid date",
    }
  };

  const getTimeTrackingReport = async () => {
    setTimeTrackingReport([]);
  }

  const onSubmitReportClick = async () => {
    setRefresh(true);
  };

  const definedColumns: GridColDef[] = [
    {
      field: "patientId",
      headerName: "Patient Id",
      headerAlign: "left",
      align: "left",
      flex: 1,
      hideable: false,
      filterable: false
    },
    {
      field: "lastName",
      headerName: "Last Name",
      flex: 1,
      headerAlign: "left",
      align: "left",
      hideable: false,
      filterable: false
    },
    {
      field: "firstName",
      headerName: "First Name",
      flex: 1,
      headerAlign: "left",
      align: "left",
      hideable: false,
      filterable: false
    },
    {
      field: "dob",
      headerName: "Date of Birth",
      flex: 1,
      headerAlign: "left",
      align: "left",
      hideable: false,
      filterable: false
    },
    {
      field: "sex",
      headerName: "Sex",
      flex: 1,
      headerAlign: "left",
      align: "left",
      hideable: false,
      filterable: false
    },
    {
      field: "date99453",
      headerName: "999453 Date",
      flex: 1,
      headerAlign: "left",
      align: "left",
      hideable: false,
      filterable: false
    },
    {
      field: "99453",
      headerName: "99453",
      flex: 0.5,
      headerAlign: "left",
      align: "left",
      hideable: false,
      filterable: false
    },
    {
      field: "99457",
      headerName: "99457",
      flex: 0.5,
      headerAlign: "left",
      align: "left",
      hideable: false,
      filterable: false
    },
    {
      field: "99458",
      headerName: "99458",
      flex: 0.5,
      headerAlign: "left",
      align: "left",
      hideable: false,
      filterable: false
    },
  ];

  return <PageWrapper title={"Patient Reading Report"}>
    <Grid container spacing={2} rowSpacing={1} width={"100%"}>
      <Grid item xs={2}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            disableFuture
            onChange={(newValue: Dayjs | null) =>
              setReportFilterModel({
                ...reportFilterModel,
                startDate: newValue ? newValue.toDate() : undefined,
              })
            }
            value={reportFilterModel.startDate || null}
            label="Start Date"
            inputFormat={DateDisplayFormat}
            renderInput={(params) => (
              <TextField
                required
                inputProps={{
                  tabIndex: 1,
                }}
                {...params}
                error={!!validationErrors.StartDate}
                helperText={validationErrors.StartDate}
                fullWidth
              />
            )}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={2}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            disableFuture
            onChange={(newValue: Dayjs | null) =>
              setReportFilterModel({
                ...reportFilterModel,
                endDate: newValue ? newValue.toDate() : undefined,
              })
            }
            value={reportFilterModel.endDate || null}
            label="End Date"
            inputFormat={DateDisplayFormat}
            renderInput={(params) => (
              <TextField
                required
                inputProps={{
                  tabIndex: 2,
                }}
                {...params}
                error={!!validationErrors.EndDate}
                helperText={validationErrors.EndDate}
                fullWidth
              />
            )}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={1}>
        <Button disabled={!isEmpty(validationErrors)} fullWidth sx={{ mb: 2, height: 55 }} variant="contained" color="secondary" onClick={onSubmitReportClick}>SUBMIT</Button>
      </Grid>
      <Grid item xs={1}>
        <Button disabled={!isEmpty(validationErrors)} fullWidth sx={{ mb: 2, height: 55 }} variant="contained" color="secondary" >EXPORT</Button>
      </Grid>
      <Grid item xs={12}>
        <StripedDataGrid
          sx={{ height: 700 }}
          loading={axiosLoading}
          rows={timeTrackingReport}
          columns={definedColumns}
          rowsPerPageOptions={[5, 10, 20, 50]}
          disableSelectionOnClick={true}
          disableColumnSelector
          pageSize={axiosPagination?.PageSize}
          page={page}
          components={{
            NoResultsOverlay: CustomNoRowsReportOverlay
          }}
          rowCount={rowCountState}
          onPageChange={(newPage) => setPage(newPage)}
          onPageSizeChange={(newPageSize) => handlePageSize(newPageSize)}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
          }
          paginationMode="server"
        />
      </Grid>
    </Grid>
  </PageWrapper>;
};

export default PatientReadings;
