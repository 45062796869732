import { useRoutes } from "react-router-dom";
import { useError } from "../../hooks/useError";
import LoginComponent from "../../components/login/Login.component";
import UserList from "../../components/userlist/UserList.component";
import AddUser from "../../components/adduser/AddUser.component";
import MainLayout from "../MainLayout";
import Attributes from "../../components/attributes/Attributes.component";
import PatientList from "../../components/PatientList/PatientList.component";
import AddPatient from "../../components/addpatient/AddPatient.component";
import BillingCodes from "../../components/billingcodes/BillingCodes.component";
import AlertDefaults from "../../components/alertdefaults/AlertDefaults.component";
import Setting from "../../components/settings/Settings.component";
import Messaging from "../../components/messaging/Messaging.component";
import BulkUploadPatient from "../../components/bulkuploadpatient/BulkUploadPatient.component";
import BulkUploadUser from "../../components/bulkuploaduser/BulkUploadUser.component";
import BillableRPMReadings from "../../components/Reports/billablerpmreadings/BillableRPMReadings.component";
import Roles from "../../components/roles/Roles.component";
import TimeTracking from "../../components/Reports/timetracking/TimeTracking.component";
import BillableActivity from "../../components/Reports/billableactivity/BillableActivity.component";
import PatientReadings from "../../components/Reports/patientreadings/PatientReadings.component";
import DepartementStructure from "../../components/DepartmentStructure/DepartmentStructure.component";
import DiseaseCondition from "../../components/diseasecondition/DiseaseCondition.component";
import ProtectedRoutes from "../../components/login/ProtectedRoutes.component";
import LogOutComponent from "../../components/login/Logout.component";
import EditPatient from "../../components/editpatient/editPatient.component";
import MedicationList from "../../components/medicationList/MedicationList.component";
import BillingCode from "../../components/billingcodes/BillingCodes.component";
import TimeReport from "../../components/Reports/TimeReport/TimeReport.Component";
import ProviderAlerts from "../../components/ProviderAlerts/ProviderAlerts.component";
import UnauthorizedTemplate from "../../components/Unauthorized/Unauthorized.component";
import NotFoundPage from "../NotFound.component";
import ConfigurationSettings from "../../components/ConfigurationSettings/ConfigurationSettings.component";
import SummaryReport from "../../components/Reports/SummaryReport/SummaryReport.Component";

export default function RoutesApp() {
    const {manageErrorAlert, showAlertSnack}= useError();

    return useRoutes([
        {
            path: '/',
            element: <LoginComponent manageErrorAlert={manageErrorAlert}/>
        },        
        {   path: '/logout',
            element:  <LogOutComponent manageErrorAlert={manageErrorAlert}/>            
        },
        {
            path: '',
            element: <ProtectedRoutes />,
            children: [
                {
                    path: "",
                    element: <MainLayout manageErrorAlert={manageErrorAlert} showAlertSnack={showAlertSnack}/>,
                    children: [
                        { path: '/userlist', element: <UserList manageErrorAlert={manageErrorAlert} showAlertSnack={showAlertSnack} /> },
                        { path: '/userlist/edituser/:id', element: <AddUser manageErrorAlert={manageErrorAlert} showAlertSnack={showAlertSnack} /> },
                        { path: '/createuser', element: <AddUser manageErrorAlert={manageErrorAlert} showAlertSnack={showAlertSnack} /> },
                        { path: '/patientlist', element: <PatientList manageErrorAlert={manageErrorAlert} showAlertSnack={showAlertSnack} /> },
                        { path: '/addpatient', element: <AddPatient  manageErrorAlert={manageErrorAlert} showAlertSnack={showAlertSnack} /> },
                        { path: '/patientlist/editpatient/:id', element: <EditPatient manageErrorAlert={manageErrorAlert} showAlertSnack={showAlertSnack} /> },
                        { path: '/billingcodes', element: <BillingCodes /> },
                        { path: '/attributes', element: <Attributes /> },
                        { path: '/alertsdefaults', element: <AlertDefaults /> },
                        { path: '/provideralerts', element: <ProviderAlerts /> },
                        { path: '/settings', element: <Setting  manageErrorAlert={manageErrorAlert} showAlertSnack={showAlertSnack} />},
                        { path: '/messaging', element: <Messaging />},
                        { path: '/bulkuploadpatient', element: <BulkUploadPatient />},
                        { path: '/bulkuploaduser', element: <BulkUploadUser />},
                        { path: '/billablerpmreadings', element: <BillableRPMReadings manageErrorAlert={manageErrorAlert}/>},
                        { path: '/roles', element: <Roles />},
                        { path: '/timetracking', element: <TimeTracking manageErrorAlert={manageErrorAlert}/>},
                        { path: '/timereport', element: <TimeReport manageErrorAlert={manageErrorAlert}/>},
                        { path: '/summaryReport', element: <SummaryReport manageErrorAlert={manageErrorAlert}/>},
                        { path: '/billableactivity', element: <BillableActivity manageErrorAlert={manageErrorAlert} />},
                        { path: '/patientreadings', element: <PatientReadings />},
                        { path: '/departmentstructure', element: <DepartementStructure manageErrorAlert={manageErrorAlert} />},
                        { path: '/diseasecondition', element: <DiseaseCondition manageErrorAlert={manageErrorAlert} showAlertSnack={showAlertSnack} />},
                        { path: '/medicationlist', element: <MedicationList />},
                        { path: '/billingcodes', element: <BillingCode manageErrorAlert={manageErrorAlert} showAlertSnack={showAlertSnack} />},
                        { path: '/unauthorized', element: <UnauthorizedTemplate manageErrorAlert={manageErrorAlert} showAlertSnack={showAlertSnack} />},
                        { path: '/configurationsettings', element: <ConfigurationSettings />},
                    ]
                },
            ]
        },
        {
            path: '*',
            element:  <NotFoundPage />
        }, 
    ]);
}
