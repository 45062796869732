import { AddCircle, Cancel, Download } from "@mui/icons-material";
import { Box, Button, Chip, IconButton, ListItem } from "@mui/material";
import { useCallback, useContext, useEffect, useState } from "react";
import { PatientOrderModel } from "../../../Api/Model/PatientOrderModel";
import { useAxios } from "../../../hooks/useAxios";
import { downloadFileResponse } from "../../../Shared/Files.provider";
import { PatientInfoComponentParams } from "../../../Shared/PatientInfoComponentParams";
import ConfirmationModalComponent from "../../Shared/ConfirmationModal/ConfirmationModal.component";
import { PatientOrderModal } from "./PatientOrderModal.component";
import { emptyGUID } from "../../../constants/guid.contast";
import { PatientContext } from "../../../Context/PatientContext";

export const PatientOrder = (params: PatientInfoComponentParams) => {
  const { showAlertSnack, isPatientInUse, manageErrorAlert } =
    params;
    
  const {
    getAsync,
    deleteAsync,
    uploadAsync,
    downloadFileAsync,
    axiosError,
    axiosSuccess,
  } = useAxios();
  
  const [openPatientOrderModal, setOpenPatientOrderModal] = useState(false);
  const closePatientOrderModal = useCallback(() => {
    setOpenPatientOrderModal(false);
  }, [setOpenPatientOrderModal]);

  const [patientOrders, setPatientOrders] = useState<PatientOrderModel[]>([]);
  const [currentPatientOrder, setCurrentPatientOrder] = useState<
    PatientOrderModel | undefined
  >(undefined);
  const [patientOrderRefresh, setPatientOrderRefresh] = useState<boolean>(true);
  const { currentPatient } = useContext(PatientContext);

  const getPatientOrder = async (patientId: string) => {
    const axiosGet = await getAsync<PatientOrderModel[]>(
      "PatientOrder/GetPatientOrderByPatientId/" + patientId
    );

    setPatientOrderRefresh(false);
    if (!axiosGet) {
      manageErrorAlert(axiosError?.Messages[0]);
      return;
    }
    setPatientOrders(axiosGet);
  };

  useEffect(() => {
    if (!patientOrderRefresh || currentPatient === undefined) {
      return;
    }
    getPatientOrder(currentPatient.patientId || emptyGUID);
  }, [patientOrderRefresh, currentPatient]);

  // Delete a Patient Order section
  const [
    openDeletePatientOrderConfirmation,
    setOpenDeletePatientOrderConfirmation,
  ] = useState(false);

  const onPatientOrderDeleteConfirmation = async () => {
    await deleteAsync(
      "PatientOrder/DeletePatientOrderUpload",
      currentPatientOrder?.patientOrderId || ""
    );

    if (axiosSuccess) {
      showAlertSnack("Patient Order has been successfully removed.", "success");
      setPatientOrderRefresh(true);
    }
  };

  const closePatientOrderConfirmModal = useCallback(() => {
    setOpenDeletePatientOrderConfirmation(false);
  }, [setOpenDeletePatientOrderConfirmation]);

  const openConfirmModalPatientOrder = (actual: PatientOrderModel) => {
    setCurrentPatientOrder(actual);
    setOpenDeletePatientOrderConfirmation(true);
  };

  const handleDeletePatientOrder = (patientOrder: PatientOrderModel) => {
    openConfirmModalPatientOrder(patientOrder);
    getPatientOrder(currentPatient?.patientId || emptyGUID);
  };

  const handleDownload = async (patientOrder: PatientOrderModel) => {
    let response = await downloadFileAsync(
      "PatientOrder/DownloadFile",
      "patientOrderId=" + patientOrder.patientOrderId
    );
    if (axiosSuccess) {
      showAlertSnack("Download started, check the status in the browser.", "success");
      downloadFileResponse(response, patientOrder.name || "");
    } else {
      manageErrorAlert(axiosError?.Messages[0]);
    }
  };

  const onPatientOrderModalConfirm = async (
    patientOrder: PatientOrderModel
  ) => {
    if (!patientOrder.file) {
      manageErrorAlert("Please select a file to upload.");
      return;
    }
    await uploadAsync(
      "PatientOrder/PatientOrderUpload?patientId=" + patientOrder.patientId,
      patientOrder.file
    );
    if (axiosSuccess) {
      showAlertSnack("The file has been uploaded successfully.", "success");
      setPatientOrderRefresh(true);
    } else {
      manageErrorAlert(axiosError?.Messages[0]);
    }
    setPatientOrderRefresh(true);
  };

  const addNewPatientOrder = (): void => {
    setCurrentPatientOrder((dc) => {
      return { ...dc, patientId: currentPatient?.patientId || emptyGUID };
    });
    setOpenPatientOrderModal(true);
  };

  //Patient Order Modal
  const ui_PatientOrder_modal = (
    <PatientOrderModal
      open={openPatientOrderModal}
      handleClose={closePatientOrderModal}
      onConfirm={onPatientOrderModalConfirm}
      patientOrderParam={currentPatientOrder}
    />
  );

 //Patient Orders Confirm
 const ui_PatientOrder_confirm = (
    <ConfirmationModalComponent
      open={openDeletePatientOrderConfirmation}
      handleClose={closePatientOrderConfirmModal}
      type="deletion"
      onConfirm={onPatientOrderDeleteConfirmation}
    />
  );

  // Upload a Patient Order section
  const ui_addBtnPatientOrder = (
    <Box
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Button
        sx={{ mb: 2, textTransform: "capitalize" }}
        variant="contained"
        color="success"
        disabled={isPatientInUse}
        onClick={addNewPatientOrder}
      >
        <AddCircle /> {"Add patient order"}
      </Button>
    </Box>
  );

  const ui_PatientOrder = (
  <>
  {patientOrders.map((input: PatientOrderModel, index: any) => (
    <ListItem key={"POrder" + index}>
      <Chip
        sx={{
          "& .MuiChip-icon": {
            order: 1,
            marginRight: "2px",
            cursor: "pointer",
          },
          "& .MuiChip-deleteIcon": {
            order: 2,
          },
        }}
        deleteIcon={
          <IconButton disabled={isPatientInUse}>
            <Cancel />
          </IconButton>
        }
        onDelete={() => handleDeletePatientOrder(input)}
        label={`Order ${input.name}`}
        icon={
          <IconButton  onClick={() => handleDownload(input)}>
            <Download />
          </IconButton>
        }
      />
    </ListItem>
  ))}
  {ui_addBtnPatientOrder}
  </>
  );

  
  return (
    <>
      {ui_PatientOrder_confirm}
      {ui_PatientOrder_modal}
      {ui_PatientOrder}
    </>
  );
};
