import { Box } from "@mui/system";
import { CustomDialog } from "../Shared/Modal/Modal.component";
import CloseIcon from "@mui/icons-material/Close";
import {
  Avatar,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Tab,
  Tabs,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { useState, SyntheticEvent, useEffect } from "react";
import {
  DesktopDatePicker,
  DesktopTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs";
import {
  DateDisplayFormat,
  GlobalFontSize,
} from "../../constants/global.constants";
import { CleanButton } from "../../Shared/CleanHandler.provider";
import { isEmpty, isUndefined } from "lodash";
import { emailvalidator, phoneNumberValidator } from "../../Api/Model/Shared/Validations/Validations";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./PatientListMesagingModal.css";
import { getActualHourPlusXMins, getFormatedShortDateTimeString, removeHoursFromDate } from "../../Shared/DateTime.provider";
import { colorsPalette } from "../../constants/styles.constants";
import ProviderModel from "../../Api/Model/DepartmentStructure/ProviderModel";
import { sortStringsWithNumbers } from "../../Shared/Sorting.provider";
import { useAxios } from "../../hooks/useAxios";
import { useOutletContext } from "react-router-dom";
import { DepartmentModel } from "../../Api/Model/DepartmentModel";
import { MessageHistory } from "../../Api/Model/Messages/MessageHistoryModel";
import { PredefinedMessage } from "../../Api/Model/Messages/PredefinedMessageModel";
import {  FileDownloadOutlined, MarkChatRead, ScheduleSend } from "@mui/icons-material";
import {  htmlOpenTagExpression } from "../../constants/validationRegex";
import { downloadFileResponse } from "../../Shared/Files.provider";
import { PatientMiniModel } from "../../Api/Model/PatientMiniModel";


//#region Types, interfaces and enums
type PatientListMessagingModalProps = {
  open: boolean;
  closeHandler: Function;
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const messageStatusValues = {
  Sent: colorsPalette.green,
  Scheduled: colorsPalette.blue,
}
//#endregion

//#region UI configuration constants
const TabPanel = (props: TabPanelProps): JSX.Element => {
  const { children, value, index, ...other } = props;

  return (
    <Box
      sx={{ height: 550, overflow: "auto", maxHeight: 550 }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ height: "100%" }}>{children}</Box>}
    </Box>
  );
};

const QuillToolbarModules = {
  toolbar: [
    [{ size: ["small", false, "large"] }, { font: [] }],
    ["bold", "italic", "underline", { list: "bullet" }, { list: "ordered" }],
  ],
};

const QuillAdmittedFormats: string[] = [
  "size",
  "font",
  "bold",
  "italic",
  "underline",
  "list",
  "bullet",
];

const predefinedMessage: string = "";



const styles = {
  radioStyle: {
    paddingBottom: 0,
    paddingTop: 0,
  },
  headerStyle: {
    fontWeight: "bold",
    marginBottom: 2,
  },
};
//#endregion


export const PatientListMessagingModal = (
  props: PatientListMessagingModalProps
): JSX.Element => {
  //#region Hooks
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [recipient, setRecipient] = useState<string>("Individual");
  const [whenToSend, setWhenToSend] = useState<string>("Now");
  const [individual, setIndividual] = useState<string>("");
  const [date, setDate] = useState<Date>(new Date());
  const [time, setTime] = useState<Date>(getActualHourPlusXMins(10));
  const [messageType, setMessageType] = useState<string>("Predefined");
  const [testMessage, setTestMessage] = useState<string>("");
  const [isSending, setIsSending] = useState<boolean>(false);
  const [communication, setCommunication] = useState<string>("Email");
  const [composeMessage, setComposeMessage] = useState<string>(
    `<p>${predefinedMessage}</p>`
  );
  const [selectedPredefinedMessage, setSelectedPredefinedMessage] = useState<string>("");

  const [timeError, setTimeError] = useState<boolean>(false);
  const [composeMessageError, setComposeMessageError] =
    useState<boolean>(false);
  const [selectedProviderDepartment, setSelectedProviderDepartment] = useState<string>("");
  const [departments, setDepartments] = useState<DepartmentModel[]>([]);
  const [providers, setProviders] = useState<ProviderModel[]>([]);
  const [patients, setPatients] = useState<PatientMiniModel[]>([]);
  const [predefinedMessages, setPredefinedMessages] = useState<PredefinedMessage[]>([]);
  const [messageHistory, setMessageHistory] = useState<MessageHistory[]>([]);
  const { getAsync, axiosError, postAsync, axiosSuccess, axiosLoading, downloadFileAsync } = useAxios();
  const { manageErrorAlert, showAlertSnack } = useOutletContext<{
    manageErrorAlert: Function;
    showAlertSnack: Function;
  }>();

  useEffect(() => {
    recipient === "All"
      ? setSelectedProviderDepartment(recipient)
      : setSelectedProviderDepartment("");
    switch (recipient) {
      case "All":
        setSelectedProviderDepartment(recipient);
        setIndividual("");
        break;
      case "Provider":
        setIndividual("");
        break;
      case "Department":
        setIndividual("");
        break;
      default:
        break;
    }
  }, [recipient]);

  useEffect(() => {
    messageType === "Predefined"
      ? setComposeMessage(`<p>${predefinedMessage}</p>`)
      : (communication === "Email")
      ? setComposeMessage("<p><br></p>")
      : setComposeMessage("");
  }, [messageType]);

  useEffect(() => {
    messageType === "Compose" && (communication === "SMS" || communication === "Inapp") && setComposeMessage("");
    setIndividual("")
    setTestMessage("");
  }, [communication]);

  useEffect(() => {
    const actualTime = getActualHourPlusXMins(5);
    if (!!time && !!date && date.toString() !== "Invalid Date") {
      time.setDate(date!.getDate());
      time.setMonth(date!.getMonth());
      time.setFullYear(date!.getFullYear());
    }
    setTimeError(!!time && time < actualTime && whenToSend === "Future");
  }, [time, date, whenToSend]);

  useEffect(() => {
    setDate(removeHoursFromDate(new Date()));
    setTime(getActualHourPlusXMins(10));
  }, [whenToSend]);

  useEffect(() => {
    if (messageType === "Compose") {
      if (communication === "SMS" || communication === "Inapp") {
        composeMessage.trim() === ""
          ? setComposeMessageError(true)
          : setComposeMessageError(false);
      } else {
        composeMessage.replaceAll("</p>","\n").replaceAll("</li>","\n").replace(htmlOpenTagExpression,"").trim() === ""
          ? setComposeMessageError(true)
          : setComposeMessageError(false);
      }
    } else {
      setComposeMessageError(false);
    }
  }, [composeMessage]);

  const getProviders = async () => {
    const axiosGet = await getAsync<ProviderModel[]>("Provider/GetProviders", {
      PageNumber: 0,
      PageSize: 0,
    });

    if (!axiosGet) {
      manageErrorAlert(axiosError?.Messages[0]);
      return;
    }

    axiosGet.sort((providerA, providerB) =>
      sortStringsWithNumbers(providerA.providerName, providerB.providerName)
    );
    setProviders(axiosGet);
  };

  const getDepartments = async () => {
    const axiosGet = await getAsync<DepartmentModel[]>(
      "Department/GetDepartments",
      {
        PageNumber: 0,
        PageSize: 0,
      }
    );

    if (!axiosGet) {
      manageErrorAlert(axiosError?.Messages[0]);
      return;
    }

    axiosGet.sort((departmentA, departmentB) =>
      sortStringsWithNumbers(
        departmentA.departmentName,
        departmentB.departmentName
      )
    );
    setDepartments(axiosGet);
  };

  const getPatients = async () => {
    const axiosGet = await getAsync<PatientMiniModel[]>(
      "Patients/GetPatientListWithRoleFilter"
    )

    if (!axiosGet) {
      manageErrorAlert(axiosError?.Messages[0]);
      return;
    }

    axiosGet.sort((patientA, patientB) => 
    sortStringsWithNumbers(
      patientA.firstName || "",
      patientB.firstName || ""
    ));

    setPatients(axiosGet);
  }
  
  const getPredefinedMessages = async () => {
    const axiosGet = await getAsync<PredefinedMessage[]>(
      "Messages/GetPredefinedMessages"
    );

    if (!axiosGet) {
      manageErrorAlert(axiosError?.Messages[0]);
      return;
    }

    axiosGet.sort((predefinedMessageA,predefinedMessageB ) =>
      sortStringsWithNumbers(
        predefinedMessageA.name,
        predefinedMessageB.name
      )
    );
    setPredefinedMessages(axiosGet);
  };

  const getMessageHistory = async () => {
    const axiosGet = await getAsync<MessageHistory[]>(
      "Messages/GetMessagesHistoy"
    );

    if (!axiosGet) {
      manageErrorAlert(axiosError?.Messages[0]);
      return;
    }

    setMessageHistory(axiosGet);
  };

  useEffect(()=>{
    getProviders();
    getDepartments();
    getPredefinedMessages();
    getPatients();
  },[]);
  //#endregion

  //#region Validations
  let validationErrors = {
    ...(isEmpty(recipient) && {
      Recipient: "Select a recipient",
    }),
    ...(isEmpty(individual?.toString().trim()) &&
      !isEmpty(recipient) &&
      recipient === "Individual" && {
      Individual: `Individual ${communication === "SMS" ? "number" : communication === "Email" ? "Email" : 'selection'} is required`,
      }),
    ...(!isEmpty(individual?.trim()) && communication === "SMS" &&
      !phoneNumberValidator(individual?.trim()) && {
      Individual: "Individual number is invalid",
    }),
    ...(!isEmpty(individual?.trim()) && communication === "Email" &&
      !emailvalidator(individual?.trim())  && {
      Individual: "Individual email is invalid",
    }),
    ...(isEmpty(selectedProviderDepartment?.trim()) &&
      recipient !== "Individual" && {
        ProviderDepartment: `${recipient} is required`,
      }),
    ...(isEmpty(individual?.trim()) &&
      recipient === "Individual" &&  communication === "Inapp" && {
        Patient: `${recipient} is required`,
      }),
    ...(isEmpty(selectedPredefinedMessage?.trim()) &&
      messageType !== "Compose" && {
        PredefinedMessage: `${messageType} message is required`,
    }),
    ...(isUndefined(date) &&
      whenToSend === "Future" && {
        Date: "Date is required",
      }),
    ...(date?.toString() === "Invalid Date" &&
      whenToSend === "Future" && {
        Date: "Invalid date",
      }),
    ...(!!date
      ? date < removeHoursFromDate(new Date()) &&
        whenToSend === "Future" && {
          Date: "Date cannot be before today's date",
        }
      : false),
    ...(isUndefined(time) &&
      whenToSend === "Future" && {
        Time: "Time is required",
      }),
    ...(timeError && {
      Time: "Pick at least +5 mins from current time",
    }),
    ...(time?.toString() === "Invalid Date" &&
      whenToSend === "Future" && {
        Time: "Invalid time",
      }),
    ...(isEmpty(communication?.trim()) && {
      Communication: "Communication method is required",
    }),
    ...(composeMessageError && {
      ComposeMessage: "Composed message is required",
    }),
  };

  let validationErrorsTestMessage = {
    ...(!isEmpty(testMessage?.trim()) &&
      !phoneNumberValidator(testMessage?.trim()) && communication === "SMS" && {
      TestMessage: "Test message number is invalid",
    }),
    ...(!isEmpty(testMessage?.trim()) && !emailvalidator(testMessage?.trim()) && communication === "Email" && {
      TestMessage: "Test email is invalid",
    }),
    ...(isEmpty(selectedPredefinedMessage?.trim()) &&
      messageType !== "Compose" && {
        PredefinedMessage: `${messageType} message is required`,
    }),
    ...(composeMessageError && {
      ComposeMessage: "Composed message is required",
    }),
  };
  //#endregion

  //#region Modal methods
  const submitMessageHandler = async (): Promise<void> => {
    setIsSending(true);
    let validatedDate: Date = new Date();
    if (whenToSend === "Future") {
      validatedDate.setDate(date!.getDate());
      validatedDate.setTime(time!.getTime());
    }
    let messageObject: MessageHistory = {
      recipientType: recipient, sendDate: validatedDate, messageType: messageType,
      communicationType: communication, individual: individual, messageBody: composeMessage.replaceAll("`","'"), sent: false,
      isProgrammed: whenToSend === "Future", isTestMessage: false,
      providerId: recipient === "Provider" ? selectedProviderDepartment : null,
      departmentId: recipient === "Department" ? selectedProviderDepartment : null,
      predefinedMessageId: messageType === "Compose" ? null : selectedPredefinedMessage
    };
    await postAsync<MessageHistory>(`Messages/AddMessageRecord`, messageObject);
    if (axiosSuccess) {
      showAlertSnack(`The message(s) were ${(whenToSend === "Future")? " scheduled ":" sent "} successfully.`, "success");
      cleanModal();
    }
    setIsSending(false);
  };

  const submitTestMessageHandler = async (): Promise<void> => {
    setIsSending(true);
    let messageObject: MessageHistory = {
      recipientType: "Individual", sendDate: new Date(), messageType: messageType,
      communicationType: communication, individual: testMessage, messageBody: composeMessage.replaceAll("`","'"), sent: false,
      isProgrammed: false, isTestMessage: true, providerId: null, departmentId: null,
      predefinedMessageId: messageType === "Compose" ? null : selectedPredefinedMessage
    };

    await postAsync<MessageHistory>(`Messages/AddMessageRecord`, messageObject);
    if (axiosSuccess) {
      showAlertSnack(`The message was sent successfully.`, "success");
      cleanModal();
    }
    setIsSending(false);
  };

  const downloadMessagesHistory = async (): Promise<void> => {
    let response = await downloadFileAsync("Messages/DownloadMessageHistory","");
    if (axiosSuccess) {
      downloadFileResponse(response, `MessageHistory_${Date.now().toString()}.xlsx`);
    } else {
      manageErrorAlert(axiosError?.Messages[0]);
    }
  };

  const cleanModal = (): void => {
    setTabIndex(0);
    setSelectedPredefinedMessage("");
    setRecipient("Individual");
    setWhenToSend("Now");
    setIndividual("");
    setDate(new Date());
    setTime(getActualHourPlusXMins(10));
    setMessageType("Predefined");
    setTestMessage("");
    setCommunication("Email");
    setComposeMessage(`<p>${predefinedMessage}</p>`);
    setTimeError(false);
    setComposeMessageError(false);
    setSelectedProviderDepartment("");
  };

  const closeModal = (): void => {
    props.closeHandler();
    cleanModal();
  };

  const communicationChangeHandler = (event: any) => {
    setComposeMessage(event.target.value === "SMS" ? "" : composeMessage);
    setIndividual("")
    setCommunication(event.target.value);
  }


  const tabMenuChangeHandler = (event: SyntheticEvent, newValue: number) =>{
    if (newValue === 1) {
      getMessageHistory();
    }

    setTabIndex(newValue);
  }

  const whenToSendHandler = (event: any) => setWhenToSend(event.target.value);

  const messageTypeHandler = (event: any) => {
    setComposeMessage((communication === "SMS" || communication === "Inapp") ? "" : "<p><br></p>");
    setMessageType(event.target.value);
  }

  const patientHandler = (event: any) => setIndividual(event.target.value);

  const providerDepartmentHandler = (event: any) => setSelectedProviderDepartment(event.target.value);

  const predefinedMessageHandler = (event: any) => setSelectedPredefinedMessage(event.target.value);

  function ui_label(label: string): JSX.Element {
    return <span className={"TabLabel TabLabel-Message"}>{label}</span>;
  }
  //#endregion

  //#region UI components
  const ui_CustomTitle: JSX.Element = (
    <Box
      sx={{
        padding: 1,
        bgcolor: colorsPalette.success,
        color: colorsPalette.white,
        fontSize: 34,
        fontWeight: "medium",
      }}
    >
      <Grid container>
        <Grid item xs={6}>
          <Tabs
            sx={{ paddingLeft: 1 }}
            value={tabIndex}
            onChange={tabMenuChangeHandler}
            classes={{ indicator: "MuiTabs-indicator-Message" }}
          >
            <Tab
              label={ui_label("Send Message")}
              {...allProps(0)}
              sx={{
                marginRight: 6,
                textTransform: "none",
                fontSize: GlobalFontSize + 4,
              }}
            />
            <Tab
              label={ui_label("Message History")}
              {...allProps(1)}
              sx={{ textTransform: "none", fontSize: GlobalFontSize + 4 }}
            />
          </Tabs>
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
          }}
        >
          <IconButton onClick={closeModal}>
            <CloseIcon sx={{ stroke: colorsPalette.white, strokeWidth: 2 }} />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );

  function allProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const selectRecipientsRadioButtonsGroup_ui: JSX.Element = (
    <Grid container>
      <Grid item xs={12}>
        <Box sx={styles.headerStyle}>Select Recipients:</Box>
      </Grid>
      <Grid item xs={12}>
        <FormControl error={!!validationErrors.Recipient}>
          <RadioGroup
            name="recipientsRadioButtonGroup"
            value={recipient}
            onChange={(e) => setRecipient(e.target.value)}
          >
            <Grid container>
              <Grid item xs={6}>
                <FormControlLabel
                  value="Individual"
                  control={<Radio sx={styles.radioStyle} />}
                  label="Individual"
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  value="Provider"
                  control={<Radio sx={styles.radioStyle} />}
                  label="Provider"
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  value="Department"
                  control={<Radio sx={styles.radioStyle} />}
                  label="Department"
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  value="All"
                  control={<Radio sx={styles.radioStyle} />}
                  label="All"
                />
              </Grid>
            </Grid>
          </RadioGroup>
          <FormHelperText error={!!validationErrors.Recipient}>
            {validationErrors.Recipient}
          </FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  );

  const recipientSelect_ui: JSX.Element = (
    <Grid container>
      <Grid item xs={12}>
        <Box
          sx={{
            fontWeight: "bold",
            color:
              recipient === "All" ? colorsPalette.gray : colorsPalette.dark,
            marginBottom: 2,
          }}
        >
          {recipient === "Individual" ? "Individual:" : `${recipient}:`}
        </Box>
      </Grid>
      <Grid item xs={12}>
        {recipient === "Individual" ? (
          <>
            {communication !== "Inapp" ? (
              <TextField
                id="Individual"
                label={communication === "SMS" ? "area code + phone" : "Email"}
                variant="outlined"
                type={communication === "SMS" ? "tel" : "email"}
                size="small"
                fullWidth
                onChange={patientHandler}
                value={individual || ""}
                error={!!validationErrors.Individual}
                helperText={validationErrors.Individual}
              />
            ) : (
              <FormControl
                fullWidth
                error={!!validationErrors.Patient}
                size="small"
              >
                <InputLabel id="recipientSelectLabel">{`Select one ${recipient.toLowerCase()}`}</InputLabel>  
                <Select
                  labelId="recipientSelectLabel"
                  id="recipientSelect"
                  label={`Select one ${recipient.toLowerCase()}`}
                  value={individual || ""}
                  onChange={(e) => setIndividual(e.target.value)}
                  error={!!validationErrors.Patient}
                  fullWidth
                  endAdornment={
                    individual !== "" && CleanButton(patientHandler)
                  }
                >
                  {patients.map((patient) =>(
                    <MenuItem
                      value={patient.patientId}
                      key={patient.patientId}
                    >
                      {`${patient.firstName} ${patient.lastName}`}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error={!!validationErrors.Patient}>
                  {validationErrors.Patient}
                </FormHelperText>
              </FormControl>
            )}
          </>
        ) : (
          <FormControl
            fullWidth
            error={!!validationErrors.ProviderDepartment && recipient !== "All"}
            size="small"
          >
            {recipient !== "All" && (
              <InputLabel id="recipientSelectLabel">{`Select one ${recipient.toLowerCase()}`}</InputLabel>
            )}
            <Select
              labelId="recipientSelectLabel"
              id="recipientSelect"
              label={
                recipient !== "All"
                  ? `Select one ${recipient.toLowerCase()}`
                  : null
              }
              value={selectedProviderDepartment || ""}
              onChange={providerDepartmentHandler}
              error={!!validationErrors.ProviderDepartment}
              fullWidth
              disabled={recipient === "All"}
              endAdornment={
                !!selectedProviderDepartment &&
                recipient !== "All" &&
                  CleanButton(providerDepartmentHandler)
              }
            >
              {recipient === "Provider"
                ? providers.map((provider) => (
                    <MenuItem
                      value={provider.providerId}
                      key={provider.providerId}
                    >
                      {provider.providerName}
                    </MenuItem>
                  ))
                : departments.map((department) => (
                    <MenuItem
                      value={department.departmentId}
                      key={department.departmentId}
                    >
                      {department.departmentName}
                    </MenuItem>
                  ))}
            </Select>
            <FormHelperText
              error={
                !!validationErrors.ProviderDepartment && recipient !== "All"
              }
            >
              {recipient !== "All" && validationErrors.ProviderDepartment}
            </FormHelperText>
          </FormControl>
        )}
      </Grid>
    </Grid>
  );

  const whenToSendButtonGroup_ui: JSX.Element = (
    <Grid container>
      <Grid item xs={12}>
        <Box sx={styles.headerStyle}>When To Send:</Box>
      </Grid>
      <Grid item xs={12}>
        <ToggleButtonGroup
          exclusive
          fullWidth
          value={whenToSend}
          onChange={whenToSendHandler}
          color={"info"}
        >
          <ToggleButton value="Now">Now</ToggleButton>
          <ToggleButton value="Future">Future</ToggleButton>
        </ToggleButtonGroup>
      </Grid>
    </Grid>
  );

  const dateAndtimePickers_ui: JSX.Element = (
    <Grid container>
      <Grid item xs={12}>
        <Box sx={styles.headerStyle}>Date & Time:</Box>
      </Grid>
      <Grid item xs={6} sx={{ paddingRight: 1 }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            disablePast
            disabled={whenToSend === "Now"}
            onChange={(newDate: Dayjs | null) =>
              setDate(newDate?.toDate() || new Date())
            }
            value={date || null}
            inputFormat={DateDisplayFormat}
            renderInput={(params) => (
              <TextField
                {...params}
                error={!!validationErrors.Date}
                helperText={validationErrors.Date}
                size="small"
                label="Date"
              />
            )}
            PopperProps={{              
              placement: "bottom-start"
            }}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={6} sx={{ paddingLeft: 1 }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopTimePicker
            value={time || null}
            disabled={whenToSend === "Now" || !date}
            onChange={(newTime: Dayjs | null) =>
              setTime(newTime?.toDate() || new Date())
            }
            renderInput={(params) => (
              <TextField
                {...params}
                error={!!validationErrors.Time}
                helperText={validationErrors.Time}
                size="small"
                label="Time"
              />
            )}
          />
        </LocalizationProvider>
      </Grid>
    </Grid>
  );

  const communicationSelect_ui: JSX.Element = (
    <Grid container>
      <Grid item xs={12}>
        <Box sx={styles.headerStyle}>Communication:</Box>
      </Grid>
      <Grid item xs={12}>
        <FormControl
          fullWidth
          error={!!validationErrors.Communication}
          size="small"
        >
          <InputLabel id="communicationSelectLabel">
            Send message from
          </InputLabel>
          <Select
            labelId="communicationSelectLabel"
            id="communicationSelect"
            label="Send message from"
            value={communication}
            onChange={communicationChangeHandler}
            endAdornment={
              !!communication && (
                CleanButton(communicationChangeHandler)
              )
            }
          >
            <MenuItem value={"SMS"}>SMS</MenuItem>
            <MenuItem value={"Email"}>Email</MenuItem>
            <MenuItem value={"Inapp"}>In-app</MenuItem>
          </Select>
          <FormHelperText error={!!validationErrors.Communication}>
            {validationErrors.Communication}
          </FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  );

  const sendMessage_firstColumn_ui: JSX.Element = (
    <Grid
      container
      sx={{ display: "flex", height: "100%", alignContent: "space-between" }}
    >
      {selectRecipientsRadioButtonsGroup_ui}
      {recipientSelect_ui}
      {whenToSendButtonGroup_ui}
      {whenToSend === "Future" && dateAndtimePickers_ui}
      {communicationSelect_ui}
    </Grid>
  );
  //#endregion

  //#region Second column
  const messageTypeCheckbox_ui: JSX.Element = (
    <Grid container>
      <Grid item xs={12}>
        <Box sx={styles.headerStyle}>Message Type:</Box>
      </Grid>
      <Grid item xs={12}>
        <ToggleButtonGroup
          exclusive
          fullWidth
          value={messageType}
          onChange={messageTypeHandler}
          color={"info"}
        >
          <ToggleButton value="Predefined">Predefined</ToggleButton>
          <ToggleButton value="Compose">Compose</ToggleButton>
        </ToggleButtonGroup>
      </Grid>
    </Grid>
  );

  const composeMessageTextField_ui: JSX.Element = (
    <Grid container sx={{ height: 200 }}>
      <Grid item xs={12}>
        <Box sx={styles.headerStyle}>Compose Message:</Box>
        <FormControl error={composeMessageError} fullWidth size="small">
          {messageType === "Compose" ? (
            communication !== "SMS" && communication !== "Inapp" ? (
              <ReactQuill
                value={composeMessage}
                onChange={setComposeMessage}
                style={{ height: 145 }}
                modules={QuillToolbarModules}
                formats={QuillAdmittedFormats}
                theme="snow"
              />
            ) : (
              <TextField
                id="SMSComposeMessage"
                label={`${communication === "Inapp" ? "In-app" : communication} Compose Message`}
                variant="outlined"
                type="text"
                multiline
                rows={5}
                fullWidth
                inputProps={communication === "SMS" ? { maxLength: 140} : {}}
                size="small"
                onChange={(e) => setComposeMessage(e.target.value)}
                value={composeMessage}
                error={!!validationErrors.ComposeMessage}
                helperText={
                  isEmpty(validationErrors.ComposeMessage) && communication === "SMS" &&
                  `${(composeMessage.length - 140) * -1} characters left`
                }
              />
            )
          ) : (
                      <FormControl
            fullWidth
            error={
              !!validationErrors.PredefinedMessage && messageType !== "Compose"
            }
            size="small"
          >
            <InputLabel id="predefinedMessageSelectLabel">{`Select one predefined message`}</InputLabel>
            <Select
              labelId="predefinedMessageSelectLabel"
              id="predefinedMessageSelect"
              label={
                `Select one predefined message`
              }
              value={selectedPredefinedMessage || ""}
              onChange={predefinedMessageHandler}
              error={!!validationErrors.PredefinedMessage}
              fullWidth
              disabled={messageType === "Compose"}
              endAdornment={
                !!selectedPredefinedMessage &&
                recipient !== "All" && (
                  CleanButton(predefinedMessageHandler)
                )
              }
            >
              {predefinedMessages.map((predefinedMessage) => (
                    <MenuItem value={predefinedMessage.predefinedMessageId} key={predefinedMessage.predefinedMessageId}>
                      {predefinedMessage.name}
                    </MenuItem>
                  ))
              }
            </Select>
            <FormHelperText
              error={
                !!validationErrors.PredefinedMessage && messageType !== "Compose"
              }
            >
              { messageType !== "Compose" && validationErrors.PredefinedMessage}
            </FormHelperText>
          </FormControl>
          )}
          <FormHelperText error={!!validationErrors.ComposeMessage}>
            {validationErrors.ComposeMessage}
          </FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  );

  const sendAndCancelButton_ui: JSX.Element = (
    <Grid
      item
      xs={12}
      sx={{ display: "flex", justifyContent: "flex-end", marginTop: 1 }}
    >
      <Button
        variant="contained"
        size="small"
        sx={{ width: 100, marginRight: 1 }}
        disabled={isSending || Object.keys(validationErrors).length > 0}
        onClick={submitMessageHandler}
      >
        Send
      </Button>
      <Button
        variant="contained"
        size="small"
        sx={{ width: 100 }}
        color="inherit"
        onClick={cleanModal}
      >
        Cancel
      </Button>
    </Grid>
  );

  const testMessageTextField_ui: JSX.Element = (
    <Grid container>
      <Grid item xs={12}>
        <Box sx={{...styles.headerStyle, color: communication === "Inapp" ? colorsPalette.gray : colorsPalette.dark }}>Test Message:</Box>
      </Grid>
      <Grid item xs={8} sx={{ paddingRight: 1,paddingBottom:0.5 }}>
        <TextField
          id="TestMessage"
          label={communication === "SMS"? "area code + phone": communication === "Inapp" ? "" : "Email"}
          variant="outlined"
          type= {communication === "SMS"? "tel":"Email"}
          size="small"
          fullWidth
          onChange={(e) => setTestMessage(e.target.value)}
          value={testMessage || ""}
          error={!!validationErrorsTestMessage.TestMessage}
          helperText={validationErrorsTestMessage.TestMessage}
          disabled={communication === "Inapp"}
        />
      </Grid>
      <Grid item xs={4}>
        <Button
          fullWidth
          variant="contained"
          sx={{ height: 44 }}
          onClick={submitTestMessageHandler}
          disabled={ isSending || communication === "Inapp" ||
            Object.keys(validationErrorsTestMessage).length > 0 || isEmpty(testMessage) || isEmpty(communication)
          }
        >
          Send Test
        </Button>
      </Grid>
    </Grid>
  );

  const sendMessage_secondColumn_ui: JSX.Element = (
    <Grid
      container
      sx={{ display: "flex", height: "100%", alignContent: "space-between" }}
    >
      {messageTypeCheckbox_ui}
      {composeMessageTextField_ui}
      {sendAndCancelButton_ui}
      {testMessageTextField_ui}
    </Grid>
  );
  //#endregion

  const sendMessage_ui: JSX.Element = (
    <Grid container spacing={4}>
      <Grid item xs={6} sx={{ height: 550 }}>
        {sendMessage_firstColumn_ui}
      </Grid>
      <Grid item xs={6} sx={{ height: 550 }}>
        {sendMessage_secondColumn_ui}
      </Grid>
    </Grid>
  );

  const ui_messageModalBody: JSX.Element = (
    <Grid container sx={{ overflow: "hidden" }}>
      <Grid item xs={12}>
        <TabPanel value={tabIndex} index={0}>
          {sendMessage_ui}
        </TabPanel>
      </Grid>
      <TabPanel value={tabIndex} index={1}>
        {!!messageHistory && messageHistory.length !== 0
          ? ui_messageList(messageHistory)
          : "There are no messages, try sending one!"}
      </TabPanel>
    </Grid>
  );

  function ui_MessageStatus(message: MessageHistory): JSX.Element {
    const { sent,messageHistoryId } = message;
    const color = sent? messageStatusValues.Sent:messageStatusValues.Scheduled;

    return (
      <Avatar
        key={messageHistoryId}
        sx={{ bgcolor: color, width: 50, height: 50, borderRadius: 3 }}
        variant={"rounded"}
      >
        {color === messageStatusValues.Sent ? (
          <>
            <MarkChatRead/>
          </>
        ) : (
          <>

            <ScheduleSend/>
          </>
        )}
      </Avatar>
    );
  }

  function ui_messageList(messageList: MessageHistory[]): JSX.Element {
    const getColorTitle = (messageStatus: boolean = true): string =>
      !messageStatus
        ? colorsPalette.gray
        : colorsPalette.dark;

    const getColorBody = (messageStatus: boolean = true): string =>
      !messageStatus
        ? colorsPalette.gray
        : colorsPalette.dark;

    const messageGroupList = messageList.map((m) => (
      <Grid container key={m.messageHistoryId} sx={{ overflow: "hidden" }}>
        <Grid item xs={11.5}>
          <Grid container key={m.messageHistoryId} sx={{ marginBottom: 4 }}>
            {ui_MessageStatus(m)}
            <Grid
              item
              xs={10}
              sx={{ justifyContent: "start", marginLeft: 2, minWidth: "88%" }}
            >
              <Grid container>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "106%",
                      maxWidth: "105%",
                      fontWeight: "bold",
                      color: getColorTitle(m.sent),
                      whiteSpace: "nowrap",
                      fontSize: 18,
                    }}
                  >
                    {`${m.senderName}. ${getFormatedShortDateTimeString(new Date(m.sendDate))}/${m.communicationType}/${m.recipientType}: ${m.individual}`}
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    marginTop: -0.5,
                  }}
                >
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    wordWrap: "break-word",
                    width: "106%",
                    maxWidth: "105%",
                    maxHeight: 150,
                    overflowY: "auto",
                    color: getColorBody(m.sent),
                    whiteSpace: "pre-line",
                  }}
                >
                  {`${m.messageBody.replaceAll("</p>","\n").replaceAll("</li>","\n").replace(htmlOpenTagExpression,"")}`}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Divider orientation="horizontal" flexItem sx={{ marginBottom: 2 }} />
      </Grid>
    ));

    return <>{messageGroupList}</>;
  }

  const ui_messageHistoryHeader: JSX.Element = (
    <Grid container sx={{ display: 'flex', alignItems: 'center' }}>
      <Grid item xs={10} sx={styles.headerStyle}>Message History</Grid>
      <Grid item xs={2} sx={styles.headerStyle}>
      <Button
              disabled={axiosLoading }
              size="medium"
              onClick={downloadMessagesHistory}
              variant="contained"
              color="success"
              startIcon={
                <FileDownloadOutlined className="BiggerIconButton " />
              }
              sx={{
                width: 140,
                marginRight: 1,
                marginLeft: "auto",
                paddingLeft: 2,
                textTransform: "none",
                lineHeight: 1.2,
              }}
            >
              {"Download History"}
            </Button>
      </Grid>
    </Grid>
  );

  const ui_dialog: JSX.Element = (
    <CustomDialog
      isOkButtonDisabled={false}
      open={props.open}
      onConfirm={closeModal}
      handleClose={props.closeHandler}
      size="md"
      customTitle={ui_CustomTitle}
      hideButtons
    >
      <>
      {tabIndex ===1 && messageHistory.length > 0 && ui_messageHistoryHeader}
      {ui_messageModalBody}
      </>
    </CustomDialog>
  );
  //#endregion

  return ui_dialog;
};
