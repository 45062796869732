import { GridColDef } from "@mui/x-data-grid"
import { useEffect, useState } from "react"
import { useOutletContext } from "react-router-dom"
import { PatientMeasurementReadingsModel } from "../../../../Api/Model/Patient/PatientReadings/PatientReadingModel"
import { useAxios } from "../../../../hooks/useAxios"
import StripedDataGrid, { CustomPagination } from "../../../Shared/StripedDataGrid/StripedDatagrid.component"
import { renderReadingValue, renderReadingDate, renderReadingHasMedication, renderReadingOutOfLimit } from "../CustomCellRenderers"

type GlucometerReadingType = {
  patientId: string | undefined
  initialDate?: Date
  endDate?: Date
}

export const GlucometerReadingSection = (props: GlucometerReadingType) => {
  const { manageErrorAlert } = useOutletContext<{ manageErrorAlert: Function }>();
  const { getAsync, axiosLoading, axiosSuccess, axiosError, axiosPagination } = useAxios();
  const [readings, setReadings] = useState<PatientMeasurementReadingsModel[]>([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [rowCountState, setRowCountState] = useState(1);
  const fetchReadings = async (pNumber: number | undefined, pSize: number | undefined) => {
    const fetched = await getAsync<PatientMeasurementReadingsModel[]>(`PatientMeasurementRecords/GetPatientMeasurementRecordsByPatientAndType?patientId=${props.patientId}&type=BloodGlucose${!!props.initialDate && !!props.endDate ? '&initialDate=' + props.initialDate.toLocaleDateString('en-US') + '&endDate=' + props.endDate.toLocaleDateString('en-US') : ''}`, {
      PageNumber: pNumber || page,
      PageSize: pSize || pageSize
    });

    if (axiosSuccess && fetched) {
      setReadings(fetched);
    }
    else if (!axiosSuccess) {
      manageErrorAlert(axiosError?.Messages[0]);
    }
  }

  useEffect(() => {
    setRowCountState((prevState) =>
      axiosPagination?.TotalRecords !== undefined ? axiosPagination?.TotalRecords : prevState
    );
  }, [axiosPagination?.TotalRecords]);

  useEffect(() => {
    fetchReadings(page + 1, pageSize);
  }, [page, pageSize]);

  const definedColumns: GridColDef[] = [
    {
      field: "date",
      headerName: "Time",
      headerAlign: "center",
      align: "left",
      hideable: false,
      filterable: false,
      disableColumnMenu: true,
      sortable: false,
      flex: 1.2,
      valueGetter: renderReadingDate
    },
    {
      field: "value",
      headerName: "Glucose (mg/dL)",
      headerAlign: "center",
      align: "center",
      hideable: false,
      filterable: false,
      disableColumnMenu: true,
      sortable: false,
      flex: 0.8,
      valueGetter: renderReadingValue,
      cellClassName: renderReadingOutOfLimit
    },
    {
      field: "hasMedication",
      headerName: "Has Medication",
      headerAlign: "center",
      align: "center",
      hideable: false,
      filterable: false,
      disableColumnMenu: true,
      sortable: false,
      flex: 0.5,
      renderCell: renderReadingHasMedication
    },
  ];

  return (
      <StripedDataGrid
        sx={{ maxHeight: '93%' }}
        getRowId={(row) => row.results[0].patientMeasurementRecordId}
        loading={axiosLoading}
        rows={readings}
        columns={definedColumns}
        rowsPerPageOptions={[5, 10, 20, 50]}
        disableSelectionOnClick={true}
        disableColumnSelector
        pageSize={axiosPagination?.PageSize}
        page={page}
        components={{ Footer: () => CustomPagination(Math.ceil(rowCountState / (axiosPagination ? axiosPagination?.PageSize! : 1)), page + 1, setPage) }}
        rowCount={rowCountState}
        onPageChange={(newPage) => setPage(newPage)}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
        }
        paginationMode="server"
      />
  )
}
