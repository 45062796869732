import {
  Box,
  Button,
  CardActions,
  Checkbox,
  Collapse,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  IconButtonProps,
  InputLabel,
  MenuItem,
  Select,
  styled,
  Typography,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import "./advancedSearch.css";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { Dayjs } from "dayjs";
import { ExpandMoreSharp } from "@mui/icons-material";
import { isEmpty } from "lodash";
import { CleanButton } from "../../Shared/CleanHandler.provider";
import { CustomDialog } from "../Shared/Modal/Modal.component";
import { useAxios } from "../../hooks/useAxios";
import CareTeamModel from "../../Api/Model/CareTeam/CareTeamModel";
import { CatalogModel } from "../../Api/Model/GenericCatalog/CatalogModelAxios";
interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }: any) => ({
  transform: !expand ? "rptate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const AdvancedSearch = (props: any) => {
  const {
    openModal,
    setOpenModal,
    userFilter,
    setUserFilter,
    handleResetSearch,
    handleApplyAdvancedSearch,
    manageErrorAlert,
  } = props;
  const [expanded, setExpanded] = useState(false);
  const [sexs, setSexs] = useState<CatalogModel[]>([]);
  const [patientStatuses, setPatientStatuses] = useState<CatalogModel[]>([]);
  const [careTeams, setCareTeams] = useState<CareTeamModel[]>([]);
  const { getAsync, axiosError } = useAxios();
  const handleCloseModal = () => setOpenModal(false);
  const [ageRangeError, setAgeRangeError] = useState(false);

  const getCareTeams = async () => {
    const axiosGet = await getAsync<CareTeamModel[]>("CareTeam/GetCareTeams");
    if (!axiosGet) {
      manageErrorAlert(axiosError?.Messages[0]);
      return;
    }
    setCareTeams(axiosGet);
  }

  const getGenderCatalog = async () => {
    const axiosGet = await getAsync<CatalogModel[]>(
      "Catalogs/GetSubCatalogsByCatalogName/Gender"
    );
    if (!axiosGet) {
      manageErrorAlert(axiosError?.Messages[0]);
      return;
    }
    setSexs(axiosGet);
  }

  const getStatusCatalog = async () => {
    const axiosGetStatus = await getAsync<CatalogModel[]>(`Catalogs/GetSubCatalogsByCatalogName/${"Patient Status"}`);
      if (!!axiosGetStatus) {
        setPatientStatuses(axiosGetStatus);
      }
  }

  useEffect(() => {
    if (openModal) {
      getGenderCatalog();
      getCareTeams();
      getStatusCatalog();
      return;
    }
    setTimeout(() => {
      setExpanded(false);
    }, 600);
  }, [openModal]);

  const handleExpandClick = () => setExpanded(!expanded);

  const handleSetNumericField = (event: any) => {
    let fieldS = event.target.id.toString();
    let checkValue = event.target.value.replace("  ", " ");
    checkValue = checkValue.replace(/[^\w ]/g, "");
    checkValue = checkValue.replace(/[^\d]/g, "");
    setUserFilter({ ...userFilter, [fieldS]: !!checkValue ? checkValue : undefined });
  };
  const handleSetTextField = (event: any) => {
    let fieldS = event.target.id.toString();
    let checkValue = event.target.value.replace("  ", " ");
    checkValue = checkValue.replace(/[^\w ]/g, "");
    setUserFilter({ ...userFilter, [fieldS]: checkValue });
  };
  const handleCheckField = (event: any) => {
    let fieldS = event.target.id.toString();
    setUserFilter({ ...userFilter, [fieldS]: !userFilter[fieldS] });
  };

  const handleSexChange = (event: any) => setUserFilter({ ...userFilter, Sex: event.target.value as string });

  const handleCareTeamChange = (event: any) => setUserFilter({ ...userFilter, CareTeam: event.target.value as string });

  const handleStatusChange = (event: any) => setUserFilter({ ...userFilter, Status: event.target.value as string });

  useEffect(() => {
    if (userFilter === undefined) {
      return;
    }

    if (userFilter.InitialAge && userFilter.FinalAge && (+userFilter.FinalAge < +userFilter.InitialAge)) {
      setAgeRangeError(true);
    } else {
      setAgeRangeError(false);
    }
  }, [userFilter]);

  const validationErrors = {
    ...(ageRangeError && {
      InitialAge: "Age cannot be greater than Final Age",
      FinalAge: "Age cannot be less than Initial Age",
    }),
  };

  return (<CustomDialog
    size="sm"
    title="Advanced Search"
    onConfirm={() => { }}
    isOkButtonDisabled
    hideButtons
    showCloseButton
    unlockCloseModal
    handleClose={() => { handleResetSearch(); handleCloseModal();}}
    open={openModal}>
    <Box
      component="form"
      noValidate
      autoComplete="off"
    >
      <Grid container spacing={2} rowSpacing={1}>
        <Grid item xs={12}>
          <TextField
            type={"text"}
            fullWidth
            onChange={handleSetTextField}
            id="InternalCode"
            label="ID"
            value={userFilter?.InternalCode || ""}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            type={"text"}
            onChange={handleSetTextField}
            id="FirstName"
            fullWidth
            value={userFilter?.FirstName || ""}
            label="First Name"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            type={"text"}
            onChange={handleSetTextField}
            id="LastName"
            fullWidth
            label="Last Name"
            value={userFilter?.LastName || ""}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} marginX={0}>
          <Typography fontWeight={"bold"}>Age:</Typography>
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={userFilter?.InitialAge || ""}
            onChange={handleSetNumericField}
            type="tel"
            fullWidth
            helperText={validationErrors.InitialAge || ""}
            error={ageRangeError}
            id="InitialAge"
            label="From"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={userFilter?.FinalAge || ""}
            onChange={handleSetNumericField}
            type="tel"
            fullWidth
            helperText={validationErrors.FinalAge || ""}
            error={ageRangeError}
            id="FinalAge"
            label="To"
            variant="outlined"
          />
        </Grid>
        <Grid marginTop={3} item xs={12}>
          <TextField
            type="tel"
            fullWidth
            id="Phone"
            value={userFilter?.Phone || ""}
            onChange={handleSetNumericField}
            label="Phone"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl sx={{ width: 1 / 1 }}>
            <InputLabel id="ProviderCombo" color="primary">
              Sex
            </InputLabel>
            <Select
              id="Sex"
              fullWidth
              label="Sex"
              defaultValue={""}
              unselectable="on"
              multiple={false}
              native={false}
              labelId="SexCombo"
              value={userFilter?.Sex || ""}
              onChange={handleSexChange}
              endAdornment={
                userFilter.Sex !== "" && (
                  CleanButton(handleSexChange)
                )
              }
            >{sexs.length === 0 && (
              <MenuItem value="">No sexs found...</MenuItem>
            )}
              {sexs.map((genderCat: CatalogModel) => (
                <MenuItem
                  value={genderCat.catalogName}
                  key={genderCat.catalogId}
                >
                  {genderCat.catalogName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <CardActions sx={{justifyContent:'end', pr: 0}}>
            <Box onClick={handleExpandClick} sx={{display:'flex', alignItems:'center', cursor:'pointer'}}>
              <Typography
                fontWeight={"bold"}
                justifyContent={"end"}
                textAlign={"right"}
              >
                {(expanded && "Less") || "More"} Options
              </Typography>
              <ExpandMore
                expand={expanded}
                aria-expanded={expanded}
                placeholder="more"
                aria-label="More search options"
              >
                <ExpandMoreSharp />
              </ExpandMore>
            </Box>
          </CardActions>
        </Grid>
      </Grid>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Grid
          container
          spacing={2}
          rowSpacing={1}
          marginBottom={3}
        >
          <Grid item xs={12} marginX={0}>
            <Typography fontWeight={"bold"}>Last Reading:</Typography>
          </Grid>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid item xs={6}>
              <DesktopDatePicker
                disableFuture
                maxDate={userFilter?.ToDate}
                onChange={(newValue: Dayjs | null) =>
                  setUserFilter({ ...userFilter, FromDate: newValue })
                }
                value={userFilter?.FromDate || null}
                label="From"
                inputFormat="DD/MM/YYYY"
                renderInput={(params) => <TextField {...params} />}
                PopperProps={{
                  placement: "bottom-start",
                  sx: {paddingBottom: 2}
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <DesktopDatePicker
                disableFuture
                minDate={userFilter?.FromDate}
                onChange={(newValue: Dayjs | null) =>
                  setUserFilter({ ...userFilter, ToDate: newValue })
                }
                value={userFilter?.ToDate || null}
                label="To"
                inputFormat="DD/MM/YYYY"
                renderInput={(params) => <TextField {...params} />}
                PopperProps={{
                  placement: "bottom",
                  sx: {paddingBottom: 2}
                }}
              />
            </Grid>
          </LocalizationProvider>
          <Grid marginTop={3} item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="CareTeamCombo" color="primary">
                Care Team
              </InputLabel>
              <Select
                fullWidth
                defaultValue={""}
                unselectable="on"
                label={"Care Team"}
                multiple={false}
                native={false}
                labelId="CareTeamCombo"
                value={userFilter?.CareTeam || ""}
                onChange={handleCareTeamChange}
                endAdornment={
                  userFilter.CareTeam !== "" && (
                    CleanButton(handleCareTeamChange)
                  )
                }
              >{careTeams.length === 0 && (
                <MenuItem value="">No care teams found...</MenuItem>
              )}
                {careTeams &&
                  careTeams.map((careTeam: CareTeamModel) => (
                    <MenuItem
                      value={careTeam.name}
                      key={careTeam.careTeamId}
                    >
                      {careTeam.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid my={3} item xs={12}>
            <FormControl fullWidth>
              <InputLabel color="primary">
                Patient status
              </InputLabel>
              <Select
                fullWidth
                defaultValue={""}
                unselectable="on"
                label={"Patient status"}
                multiple={false}
                native={false}
                value={userFilter?.Status || ""}
                onChange={handleStatusChange}
                endAdornment={
                  userFilter.Status !== undefined && userFilter.Status !== '' && (
                    CleanButton(handleStatusChange)
                  )
                }
              >{patientStatuses.length === 0 && (
                <MenuItem value="">No status found...</MenuItem>
              )}
                {patientStatuses &&
                  patientStatuses.map((status: CatalogModel) => (
                    <MenuItem
                      value={status.catalogName}
                      key={status.catalogId}
                    >
                      {status.catalogName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid marginLeft={1} item xs={12}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    id="SpO2Alert"
                    onChange={handleCheckField}
                    checked={userFilter?.SpO2Alert || false}
                  />
                }
                label="At least one SpO2 reading out of normal range in the past 7 days"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    id="BPAlert"
                    onChange={handleCheckField}
                    checked={userFilter?.BPAlert || false}
                  />
                }
                label="At least one Systolic BP reading out of normal range in the past 7 days"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    id="WeightAlert"
                    onChange={handleCheckField}
                    checked={userFilter?.WeightAlert || false}
                  />
                }
                label="At least one Weight reading out of normal range in the past 7 days"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    id="GlucoseAlert"
                    onChange={handleCheckField}
                    checked={userFilter?.GlucoseAlert || false}
                  />
                }
                label="At least one Blood Glucose reading out of normal range in the past 7 days"
              />
            </FormGroup>
          </Grid>
        </Grid>
      </Collapse>
      <Grid container spacing={2} rowSpacing={1} >
        <Grid item xs={6}>
          <Button
            onClick={() => { handleApplyAdvancedSearch(); handleCloseModal(); }}

            sx={{
              height: 40,
            }}
            disabled={!isEmpty(validationErrors)}
            variant="contained"
            fullWidth
          >
            Search
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            onClick={() => { handleResetSearch(); }}
            sx={{
              height: 40,
            }}
            variant="contained"
            fullWidth
          >
            Clear Search
          </Button>
        </Grid>
      </Grid>
    </Box>
  </CustomDialog>
  );
};

export default AdvancedSearch;
