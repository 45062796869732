import { Grid, TextField, FormControl, InputLabel, Select, MenuItem, Button, Avatar, Box, Card, CardContent, CardHeader, Chip, Divider, List, ListItem, Typography, FormHelperText } from "@mui/material";
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs";
import { isEmpty, isUndefined } from "lodash";
import { useState, useEffect } from "react";
import { useOutletContext, useNavigate } from "react-router-dom";
import { DepartmentModel } from "../../../Api/Model/DepartmentModel";
import OfficeModel from "../../../Api/Model/DepartmentStructure/OfficeModel";
import ProviderModel from "../../../Api/Model/DepartmentStructure/ProviderModel";
import { ReportFilterModel } from "../../../Api/Model/ReportFilterModel";
import { CleanButton } from "../../../Shared/CleanHandler.provider";
import { getDateOfToday, getFirstDayOfCurrentMonth, getUTCDate } from "../../../Shared/DateTime.provider";
import { sortStringsWithNumbers } from "../../../Shared/Sorting.provider";
import { DateDisplayFormat, LocaleStringFormat } from "../../../constants/global.constants";
import { emptyGUID } from "../../../constants/guid.contast";
import { useAxios } from "../../../hooks/useAxios";
import PageWrapper from "../../Shared/Wrapper/PageWrapper.component";
import { SummaryReportModel } from "../../../Api/Model/Reports/SummaryReportModel";
import { Analytics, Person } from "@mui/icons-material";
import { CustomNoRowsReportOverlay, colorsPalette } from "../../../constants/styles.constants";
import { GridColDef } from "@mui/x-data-grid";
import StripedDataGrid from "../../Shared/StripedDataGrid/StripedDatagrid.component";
import { usePDF } from "@react-pdf/renderer";
import { SummaryReportDocumment } from "./SummaryReport.template";

interface PatientModelSelect {
  patientId: string;
  internalCode: string;
  firstName: string;
  lastName: string;
}

const definedColumns: GridColDef[] = [
  {
    field: "vitalName",
    headerName: "Vital Measured",
    headerAlign: "left",
    align: "left",
    flex: 1,
    hideable: false,
    filterable: false,
  },
  {
    field: "average",
    headerName: "Average",
    flex: 1,
    headerAlign: "center",
    align: "center",
    hideable: false,
    filterable: false,
    valueGetter: (params) => {
      if ((params.row.average) === "") {
        return "";
      }
      return (+params.row.average).toFixed(2);
    },
  },
  {
    field: "highest",
    headerName: "Highest",
    flex: 1,
    headerAlign: "center",
    align: "center",
    hideable: false,
    filterable: false,
  },
  {
    field: "lowest",
    headerName: "Lowest",
    flex: 1,
    headerAlign: "center",
    align: "center",
    hideable: false,
    filterable: false,
  },
  {
    field: "threshold",
    headerName: "Threshold",
    flex: 1,
    headerAlign: "center",
    align: "center",
    hideable: false,
    filterable: false,
  },
];

const SummaryReport = (props: any) => {

  //#region Hooks

  //#region States

  const [reportFilterModel, setReportFilterModel] = useState<ReportFilterModel>(
    { departmentId: "", officeId: "", providerId: "", patientId: "", endDate: getDateOfToday(), startDate: getFirstDayOfCurrentMonth() }
  );
  const [summaryReport, setSummaryReport] = useState<SummaryReportModel>();
  const [departments, setDepartments] = useState<DepartmentModel[]>([]);
  const [offices, setOffices] = useState<OfficeModel[]>([]);
  const [providers, setProviders] = useState<ProviderModel[]>([]);
  const [patients, setPatients] = useState<PatientModelSelect[]>([]);
  const [exportFilterModel, setExportFilterModel] = useState<ReportFilterModel>(
    {}
  );
  const [refresh, setRefresh] = useState<boolean>(false);
  const [datesErrors, setDatesErrors] = useState(false);
  let report = <></>;

  const [instance, updateInstance] = usePDF({ document: report });
  //#endregion

  //#region Others

  const { manageErrorAlert } = useOutletContext<{
    manageErrorAlert: Function;}>();

  const {
    getAsync,
    axiosError,
    axiosLoading
  } = useAxios();

  const navigate = useNavigate();

  //#endregion

  //#region Effects

  useEffect(() => {
    setDatesErrors(
      (reportFilterModel.endDate &&
        reportFilterModel.startDate &&
        reportFilterModel.endDate < reportFilterModel.startDate) ||
      false
    );
  }, [reportFilterModel.startDate, reportFilterModel.endDate]);

  useEffect(() => {
    if (axiosError && axiosError?.Code === '401') {
      manageErrorAlert("Unauthorized");
      navigate("/patientlist", { replace: true });
      return;
    }
  }, [axiosError]);

  useEffect(() => {
    getDepartments();
    getOffices();
    getProviders();
  }, []);

  useEffect(() => {
    setReportFilterModel({ ...reportFilterModel, patientId: "" });
    getPatients();
  }, [
    reportFilterModel.departmentId,
    reportFilterModel.officeId,
    reportFilterModel.providerId,
  ]);

  useEffect(() => {
    if (!!reportFilterModel.patientId && refresh) {
      let newInstance = <SummaryReportDocumment reportPeriod={`${exportFilterModel.startDate?.toLocaleDateString(LocaleStringFormat)} - ${exportFilterModel.endDate?.toLocaleDateString(LocaleStringFormat)}`} report={summaryReport} />;
      updateInstance(!!newInstance ? newInstance : <></>);
      setRefresh(false);
    }
  }, [refresh]);
  //#endregion

  //#endregion


  //#region Handlers

  //#region Department Structure Handlers

  const departmentFilterHandler = (event: any) => {
    setReportFilterModel({
      ...reportFilterModel,
      departmentId: event.target.value,
      officeId: "",
      providerId: "",
    });
    if (event.target.value === "") {
      getOffices();
      getProviders();
      return;
    }
    getFilterOffices(event.target.value);
    getFilterProviders(event.target.value, reportFilterModel.officeId);
  };

  const officeFilterHandler = (event: any) => {
    setReportFilterModel({
      ...reportFilterModel,
      officeId: event.target.value,
      providerId: "",
    });
    getFilterProviders(reportFilterModel.departmentId, event.target.value);
  };

  const providerFilterHandler = (event: any) =>
    setReportFilterModel({
      ...reportFilterModel,
      providerId: event.target.value,
    });

  const patientSelectHandler = (event: any) =>
    setReportFilterModel({
      ...reportFilterModel,
      patientId: event.target.value,
    });

  //#endregion

  //#region Button Handlers

  const onSubmitReportClick = async () => {
    await getSummaryReport();
    setExportFilterModel(reportFilterModel);
  };
  //#endregion

  //#endregion


  //#region Component Functions

  const getDepartments = async () => {
    const axiosGet = await getAsync<DepartmentModel[]>(
      "Department/GetDepartments",
      {
        PageNumber: 0,
        PageSize: 0,
      }
    );

    if (!axiosGet) {
      manageErrorAlert(axiosError?.Messages[0]);
      return;
    }
    setDepartments(axiosGet);
  };

  const getOffices = async () => {
    const axiosGet = await getAsync<OfficeModel[]>("Office/GetOffices", {
      PageNumber: 0,
      PageSize: 0,
    });

    if (!axiosGet) {
      manageErrorAlert(axiosError?.Messages[0]);
      return;
    }

    axiosGet.sort((officeA, officeB) =>
      sortStringsWithNumbers(officeA.officeName, officeB.officeName)
    );
    setOffices(axiosGet);
  };

  const getFilterOffices = async (currentDepartment: string | undefined) => {
    const axiosGet = await getAsync<OfficeModel[]>(
      `Office/GetOfficesByDepartmentId?DepartmentId=${currentDepartment}`
    );

    if (!axiosGet) {
      manageErrorAlert(axiosError?.Messages[0]);
      return;
    }

    axiosGet.sort((officeA, officeB) =>
      sortStringsWithNumbers(officeA.officeName, officeB.officeName)
    );
    setOffices(axiosGet);
  };

  const getProviders = async () => {
    const axiosGet = await getAsync<ProviderModel[]>("Provider/GetProviders", {
      PageNumber: 0,
      PageSize: 0,
    });

    if (!axiosGet) {
      manageErrorAlert(axiosError?.Messages[0]);
      return;
    }

    axiosGet.sort((providerA, providerB) =>
      sortStringsWithNumbers(providerA.providerName, providerB.providerName)
    );
    setProviders(axiosGet);
  };

  const getFilterProviders = async (
    currentDepartment: string | undefined,
    currentOffice: string | undefined
  ) => {
    const axiosGet = await getAsync<ProviderModel[]>(
      `Provider/GetProvidersByDepartmentStructure?DepartmentId=${currentDepartment || emptyGUID
      }&OfficeId=${currentOffice || emptyGUID}`,
      {
        PageNumber: 0,
        PageSize: 0,
      }
    );

    if (!axiosGet) {
      manageErrorAlert(axiosError?.Messages[0]);
      return;
    }

    axiosGet.sort((providerA, providerB) =>
      sortStringsWithNumbers(providerA.providerName, providerB.providerName)
    );
    setProviders(axiosGet);
  };
  const getPatients = async () => {
    const axiosGet = await getAsync<PatientModelSelect[]>(
      `Patients/GetPatientsByDepartmentStructure?departmentId=${reportFilterModel.departmentId || emptyGUID
      }&officeId=${reportFilterModel.officeId || emptyGUID}&providerId=${reportFilterModel.providerId || emptyGUID
      }`
    );

    if (!axiosGet) {
      manageErrorAlert(axiosError?.Messages[0]);
      return;
    }

    axiosGet.sort((patientA, patientB) =>
      sortStringsWithNumbers(
        patientA.firstName + " " + patientA.lastName,
        patientB.firstName + " " + patientB.lastName
      )
    );
    setPatients(axiosGet);
  };

  const getSummaryReport = async () => {
    const axiosGet = await getAsync<SummaryReportModel>(
      `Reports/GetSummaryReport?` +
      `&dateIni=${reportFilterModel.startDate?.toLocaleDateString("en-US")}` +
      `&dateEnd=${reportFilterModel.endDate?.toLocaleDateString("en-US")}` +
      `${!!reportFilterModel.patientId ? "&patientId=" + reportFilterModel.patientId : ""}`
    );
    if (!axiosGet) {
      manageErrorAlert(axiosError?.Messages[0]);
      return;
    }

    setSummaryReport(axiosGet);
    setRefresh(true);
  };

  //#endregion


  //#region Validations

  const validationErrors = {
    ...(isUndefined(reportFilterModel.startDate) && {
      StartDate: "Start date is required",
    }),
    ...(reportFilterModel.startDate?.toString() === "Invalid Date" && {
      StartDate: "Start date must be a valid date",
    }),
    ...(isUndefined(reportFilterModel.endDate) && {
      EndDate: "End date is required",
    }),
    ...(reportFilterModel.endDate?.toString() === "Invalid Date" && {
      EndDate: "End date must be a valid date",
    }),
    ...(datesErrors && {
      StartDate: "Date cannot be greater than end date",
      EndDate: "Date cannot be less than start date",
    }),
    ...(isEmpty(reportFilterModel.patientId) && {
      Patient: "Patient is required",
    }),
  };

  //#endregion


  //#region UI - JSX Components

  const ui_Header_search = (
    <>
      <Grid item xs={3}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            disableFuture
            onChange={(newValue: Dayjs | null) =>
              setReportFilterModel({
                ...reportFilterModel,
                startDate: newValue ? newValue.toDate() : undefined,
              })
            }
            value={reportFilterModel.startDate || null}
            label="Start Date"
            inputFormat={DateDisplayFormat}
            renderInput={(params) => (
              <TextField
                required
                inputProps={{
                  tabIndex: 1,
                }}
                {...params}
                error={!!validationErrors.StartDate}
                helperText={validationErrors.StartDate}
                fullWidth
              />
            )}
            PopperProps={{
              placement: "bottom-start",
              sx: { zIndex: 1200, paddingBottom: 2 }
            }}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={3}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            onChange={(newValue: Dayjs | null) =>
              setReportFilterModel({
                ...reportFilterModel,
                endDate: newValue ? newValue.toDate() : undefined,
              })
            }
            value={reportFilterModel.endDate || null}
            label="End Date"
            inputFormat={DateDisplayFormat}
            renderInput={(params) => (
              <TextField
                required
                inputProps={{
                  tabIndex: 2,
                }}
                {...params}
                error={!!validationErrors.EndDate}
                helperText={validationErrors.EndDate}
                fullWidth
              />
            )}
            PopperProps={{
              placement: "bottom-start",
              sx: { zIndex: 1200, paddingBottom: 2 }
            }}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <InputLabel id="PatientDepartmentLabel" color="primary">
            Patient Department
          </InputLabel>
          <Select
            fullWidth
            id="PatientDepartmentCombo"
            defaultValue={""}
            unselectable="on"
            inputProps={{
              tabIndex: 3,
            }}
            label="Patient Department"
            labelId="PatientDepartmentLabel"
            value={reportFilterModel.departmentId || ""}
            onChange={departmentFilterHandler}
            endAdornment={
              reportFilterModel?.departmentId !== "" && (
                CleanButton(departmentFilterHandler)
              )
            }
          >
            {departments.length === 0 && (
              <MenuItem value="">No departments found...</MenuItem>
            )}
            {departments &&
              departments.map((department: DepartmentModel) => (
                <MenuItem
                  value={department.departmentId}
                  key={department.departmentId}
                >
                  {department.departmentName}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <InputLabel id="PatientOfficeLabel" color="primary">
            Patient Office
          </InputLabel>
          <Select
            fullWidth
            id="PatientOfficeCombo"
            defaultValue={""}
            unselectable="on"
            inputProps={{
              tabIndex: 3,
            }}
            multiple={false}
            native={false}
            label="Patient Office"
            labelId="PatientOfficeLabel"
            value={reportFilterModel.officeId || ""}
            onChange={officeFilterHandler}
            endAdornment={
              reportFilterModel?.officeId !== "" && (
                CleanButton(officeFilterHandler)
              )
            }
          >
            {offices.length === 0 && (
              <MenuItem value="">No offices found...</MenuItem>
            )}
            {offices &&
              offices.map((office: OfficeModel) => (
                <MenuItem value={office.officeId} key={office.officeId}>
                  {office.officeName}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <InputLabel id="PatientProviderLabel" color="primary">
            Patient Provider
          </InputLabel>
          <Select
            fullWidth
            id="PatientProviderCombo"
            defaultValue={""}
            unselectable="on"
            inputProps={{
              tabIndex: 3,
            }}
            multiple={false}
            native={false}
            label="Patient Provider"
            labelId="PatientProviderLabel"
            value={reportFilterModel.providerId || ""}
            onChange={providerFilterHandler}
            endAdornment={
              reportFilterModel?.providerId !== "" && (
                CleanButton(providerFilterHandler)
              )
            }
          >
            {providers.length === 0 && (
              <MenuItem value="">No providers found...</MenuItem>
            )}
            {providers &&
              providers.map((provider: ProviderModel) => (
                <MenuItem
                  value={provider.providerId}
                  key={provider.providerId}
                >
                  {provider.providerName}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl
          fullWidth
          required
          error={!!validationErrors.Patient}
        >
          <InputLabel
            id="PatientLabel"
            color={"primary"}
          >
            Patient
          </InputLabel>
          <Select
            fullWidth
            id="PatientCombo"
            defaultValue={""}
            labelId="PatientLabel"
            label={"Patient"}
            inputProps={{
              tabIndex: 3,
            }}
            multiple={false}
            error={!!validationErrors.Patient}
            native={false}
            unselectable="on"
            value={reportFilterModel.patientId || ""}
            onChange={patientSelectHandler}
            endAdornment={
              reportFilterModel?.patientId !== "" && (
                CleanButton(patientSelectHandler)
              )
            }
          >
            {patients.length === 0 && (
              <MenuItem value="">No patients found...</MenuItem>
            )}
            {patients &&
              patients.map((patient: PatientModelSelect) => (
                <MenuItem
                  value={patient.patientId}
                  key={patient.patientId}
                >
                  {`${patient.firstName} ${patient.lastName}`}
                </MenuItem>
              ))}
          </Select>
          <FormHelperText>{validationErrors.Patient}</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={1}>
        <Button
          disabled={!isEmpty(validationErrors)}
          fullWidth
          sx={{ mb: 2, height: 55 }}
          variant="contained"
          color="primary"
          onClick={onSubmitReportClick}
        >
          SUBMIT
        </Button>
      </Grid>
      <Grid item xs={1}>
        <Button
          disabled={
            !exportFilterModel.startDate ||
            !exportFilterModel.endDate ||
            !summaryReport?.id ||
            instance?.loading ||
            !!instance?.error
          }
          fullWidth
          sx={{ mb: 2, height: 55,"&:hover":{color:'white'}}}
          variant="contained"
          color="primary"
          href={instance?.url ?? ""}
          download={`SummaryReport_${summaryReport?.patientName.replaceAll(' ', '_') ?? ""}`}
        >
          EXPORT
        </Button>
      </Grid>
    </>
  );
  function ui_generate_card_element(color: string, title: string, value: string | undefined, size: number = 110, isDynamic: boolean = false): JSX.Element {
    let numberElements = !isDynamic ? 3 : 4 + (!!summaryReport ? summaryReport?.patientCarePlans.length - 1 : 0);
    return (
      <ListItem
        key={`${title}-${value}`}
        alignItems="center"
        sx={{
          padding: 0,
          paddingBottom: 2,
          marginBottom: 1,
          justifyContent: "space-between",
          alignContent: "center",
          height: size / numberElements,
        }}
      >
        <Box sx={{ fontWeight: "bold" }}>
          {title + (!title ? "" : ":")}
        </Box>
        <Chip
          sx={{ fontWeight: "bold" }}
          label={!!value ? value : "n/a"}
          style={{ backgroundColor: color, color: colorsPalette.white }}
          size="small"
        />
      </ListItem>)
  }
  //#endregion


  return <PageWrapper title={"Summary Report"}>
    <Grid container spacing={2} rowSpacing={1} width={"100%"}>
      {ui_Header_search}
      {!!summaryReport?.id ?
        <>
          <Grid item xs={9}>
            <Card elevation={5} sx={{ minHeight: 300 }}>
              <CardHeader
                title={
                  <Typography
                    noWrap
                    variant="h6"
                    fontFamily={"inherit"}
                    fontWeight={400}
                  >
                    {"Patient information"}
                  </Typography>
                }
                titleTypographyProps={{ style: { fontSize: 24 } }}
                avatar={
                  <Avatar sx={{ bgcolor: colorsPalette.blue }}>
                    <Person />
                  </Avatar>
                }
              />
              <CardContent
                sx={{
                  paddingTop: 0,
                  paddingBottom: "0!important",
                  height: 200
                }}
              >
                <Divider
                  orientation="horizontal"
                  sx={{ marginTop: -1, marginBottom: 1, paddingTop: 0 }}
                />
                <List sx={{ width: "100%", height: "100%" }}>
                  {ui_generate_card_element(colorsPalette.blue, "Patient name", summaryReport?.patientName, 150, true)}
                  {ui_generate_card_element(colorsPalette.blue, "Patient DOB", getUTCDate(new Date(summaryReport?.patientDOB ?? "")).toLocaleDateString(LocaleStringFormat), 150, true)}
                  {!!summaryReport && (summaryReport?.patientCarePlans.length > 0) ?
                    summaryReport?.patientCarePlans.map((cp, index) => (
                      ui_generate_card_element(colorsPalette.blue, index == 0 ? "Patient RPM care plan" : "", cp, 150, true)

                    )) : ui_generate_card_element(colorsPalette.blue, "Patient RPM care plan", "", 150, true)}
                  {ui_generate_card_element(colorsPalette.blue, "Provider", summaryReport?.provider, 150, true)}
                </List>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card elevation={5} sx={{ minHeight: 300 }}>
              <CardHeader
                title={
                  <Typography
                    noWrap
                    variant="h6"
                    fontFamily={"inherit"}
                    fontWeight={400}
                  >
                    {"Patient status"}
                  </Typography>
                }
                titleTypographyProps={{ style: { fontSize: 24 } }}
                avatar={
                  <Avatar sx={{ bgcolor: colorsPalette.green }}>
                    <Analytics />
                  </Avatar>
                }
              />

              <CardContent
                sx={{
                  paddingTop: 0,
                  paddingBottom: "0!important",
                  height: 200,
                }}
              >
                <Divider
                  orientation="horizontal"
                  sx={{ marginTop: -1, marginBottom: 1, paddingTop: 0 }}
                />
                <List sx={{ width: "100%", height: "100%" }}>
                  {ui_generate_card_element(colorsPalette.green, "Electronic daily readings", summaryReport?.electronicReadings.toString())}
                  {ui_generate_card_element(colorsPalette.green, "Manual daily readings", summaryReport?.manualReadings.toString())}
                  {ui_generate_card_element(colorsPalette.green, "Total readings", summaryReport?.totalReadings.toString())}
                  {ui_generate_card_element(colorsPalette.green, "Compliant", summaryReport?.compliant)}
                  {ui_generate_card_element(colorsPalette.green, "Diagnosis code for RPM services", summaryReport?.diagnosisCode)}
                </List>
              </CardContent>
            </Card>

          </Grid>
        </>
        : ""}
      {!!summaryReport && (summaryReport?.patientVitalsDetail.length > 0) &&
        <>
          <Grid item xs={12} sx={{ marginTop: 2, marginBottom: 2, marginLeft: 1 }}>
            <Typography fontWeight={"bold"}>{"Time spent on RPM services: " + summaryReport.timeSpent}</Typography>
          </Grid>
          <Grid item xs={12}>
            <StripedDataGrid
              sx={{ height: 250 }}
              loading={axiosLoading}
              disableColumnSelector
              disableColumnMenu
              disableSelectionOnClick
              rowCount={6}
              getRowId={(row) => row.vitalName}
              paginationMode="server"
              sortingMode="server"
              rows={summaryReport?.patientVitalsDetail}
              columns={definedColumns}
              hideFooterPagination
              hideFooter
              components={{
                NoResultsOverlay: () =>
                  CustomNoRowsReportOverlay("No data available"),
                NoRowsOverlay: () =>
                  CustomNoRowsReportOverlay("No data available"),
              }}
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0
                  ? "even"
                  : "odd"
              }
            />
          </Grid>
        </>
      }
    </Grid>
  </PageWrapper>;
};

export default SummaryReport;