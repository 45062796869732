import {Text, View, StyleSheet} from '@react-pdf/renderer';
import { colorsPalette } from '../../../../constants/styles.constants';

const borderColor = colorsPalette.dark
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        borderBottomColor: borderColor,
        backgroundColor: colorsPalette.white,
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        textAlign: 'center',
        fontFamily:'Helvetica-Bold',
        fontSize:10,
        flexGrow: 1,
    },
    vital: {
        width: '30%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    average: {
        width: '13%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    highest: {
        width: '13%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    lowest: {
        width: '13%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    threshold: {
        width: '31%'
    },
    itemFormatter:{
        height: 24,
        paddingTop:6
    }
  });

  const VitalsTableHeader = () => (
    <View style={styles.container}>
        <Text style={[styles.vital,styles.itemFormatter]}>Vital Measured</Text>
        <Text style={[styles.average,styles.itemFormatter]}>Avg. for Mo.</Text>
        <Text style={[styles.highest,styles.itemFormatter]}>Highest</Text>
        <Text style={[styles.lowest,styles.itemFormatter]}>Lowest</Text>
        <Text style={[styles.threshold,styles.itemFormatter]}>Ordered Thresholds</Text>
    </View>
  );
  
  export default VitalsTableHeader