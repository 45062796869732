import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { colorsPalette } from '../../../../constants/styles.constants';
import { SummaryVitalsResultModel } from '../../../../Api/Model/Reports/SummaryVitalsResult.model';

const borderColor = colorsPalette.dark;
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: colorsPalette.dark,
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        fontSize: 10
    },
    vitalMeasure: {
        width: '30%',
        textAlign: 'center',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    average: {
        width: '13%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
    },
    highest: {
        width: '13%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
    },
    lowest: {
        width: '13%',
        textAlign: 'center',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    thresholds: {
        width: '31%',
        textAlign: 'center',
    },
    itemFormatter: {
        height: 24,
        paddingTop: 6
    }
});


const SummaryTableRow = (items: any) => {
    const rows = items.items.data.map((item: SummaryVitalsResultModel, index: number) =>
        <View style={styles.row} key={index + "measured"}>
            <Text style={[styles.vitalMeasure, styles.itemFormatter]}>{item.vitalName}</Text>
            <Text style={[styles.average, styles.itemFormatter]}>{item.average == "" ? "" : (+item.average).toFixed(2)}</Text>
            <Text style={[styles.highest, styles.itemFormatter]}>{item.highest}</Text>
            <Text style={[styles.lowest, styles.itemFormatter]}>{item.lowest}</Text>
            <Text style={[styles.thresholds, styles.itemFormatter]}>{item.threshold}</Text>
        </View>
    )
    return (<>{rows}</>)
};

export default SummaryTableRow