import { Box, Button, Chip, Grid, IconButton } from "@mui/material";
import { useOutletContext } from "react-router";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { Cancel } from "@mui/icons-material";
import { useEffect, useRef, useState } from "react";
import { useAxios } from "../../hooks/useAxios";
import { isEmpty } from "lodash";
import PageWrapper from "../Shared/Wrapper/PageWrapper.component";

export interface BulkPatientFileModel {
  name?: string;
  path?: string;
  file?: File;
};

export interface UploadFileResponse {
  message?: string;
  status?: number;
  isError?: boolean;
}

const BulkUploadPatient = (props: any) => {
  const { manageErrorAlert, showAlertSnack } = useOutletContext<{ manageErrorAlert: Function, showAlertSnack: Function }>();
  const { uploadAsync, axiosError } = useAxios();

  let inputRef = useRef<any>();

  const [uploadFile, setUploadFile] = useState<BulkPatientFileModel>({} as BulkPatientFileModel);

  const UploadFileHandler = (event: any) => {
    let file = event.target.files[0] as File;
    if (file === undefined) {
      return;
    }

    setUploadFile((dc: BulkPatientFileModel) => {
      return { ...dc, file: file, name: file.name };
    });
  };

  const DeleteFileHandler = () => {
    if (inputRef.current) {
      inputRef.current.value = null;
    }
    setUploadFile((dc: any) => {
      return { ...dc, file: undefined, name: undefined };
    });
  };

  let validationErrors = {
    ...(uploadFile.file === undefined && {
      Name: "File is required",
    }),
  };

  useEffect(() => {
    if (axiosError?.Code === "ERR_NETWORK") {
      manageErrorAlert("Failed to upload the file. Please delete current file and try once again.");
      return;
    }
  }, [axiosError])

  const UploadBulkPatientFile = async () => {
    if (!uploadFile.file) {
      manageErrorAlert("Please select a file to upload.");
      return;
    }

    const response = await uploadAsync<UploadFileResponse>(
      "Patients/BulkUploadPatients",
      uploadFile.file
    );

    if (response === undefined) {
      return;
    }

    if (!response) {
      manageErrorAlert(axiosError?.Messages[0]);
      return;
    }

    if (response?.isError) {
      manageErrorAlert(response?.message);
      return;
    }
    showAlertSnack(response?.message, "success");
  };

  return (
    <PageWrapper title={"Bulk Upload Patient"}>
      <Box component="form" autoComplete="off" >
        <Grid container spacing={2} width={"100%"}>
          <Grid item xs={12}
            sx={{ display: "flex", justifyContent: "flex-start", gap: "5px" }}>
            <Button component="label" variant="contained" size="small" color="success">
              <FileUploadIcon />
              Choose File
              <input
                type="file"
                accept=".xlsx, .xls"
                hidden
                ref={inputRef}
                onChange={UploadFileHandler}
                id="addBulkPatientFile"
              />
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "flex-start", gap: "5px" }}
          >
            {uploadFile.file && (
              <Chip
                sx={{
                  "& .MuiChip-icon": {
                    order: 1,
                    marginRight: "2px",
                    cursor: "pointer",
                  },
                  "& .MuiChip-deleteIcon": {
                    order: 2,
                  },
                }}
                deleteIcon={
                  <IconButton>
                    <Cancel />
                  </IconButton>
                }
                onDelete={DeleteFileHandler}
                label={`${uploadFile.name}`}
              />)}
          </Grid>
          <Grid item xs={12}>
            <Box
              className="mt-4"
              sx={{ display: "flex", justifyContent: "flex-start", gap: "5px" }}
            >
              <Button
                onClick={DeleteFileHandler}
                variant="contained"
                color="inherit"
                size="small"
              >
                Cancel
              </Button>
              <Button
                onClick={UploadBulkPatientFile}
                variant="contained"
                color="success"
                size="small"
                disabled={!isEmpty(validationErrors)}
                tabIndex={1}
              >
                Upload
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </PageWrapper>
  );
};

export default BulkUploadPatient;