import { Box, Drawer, List } from "@mui/material";
import SideBarItem from "./SideBarItemimplementation.component";
import { colorsPalette } from "../../constants/styles.constants";

const drawerWidth = 250;
const SideBarComponent = (props: any) => {
  const { roleMenu, open } = props;

  return (
    <Drawer 
      variant="persistent"   
      anchor="left"
      open={open}
      sx={{
        bgcolor: colorsPalette.darkGray,
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: drawerWidth,
          boxSizing: "border-box",
          bgcolor: colorsPalette.darkGray,
          top: '64px',
          maxHeight: 'calc(100vh - 64px)',
          [`& .MuiListItem-root`]: {
            fontsize: ".9rem",
            fontWeight: "bold",
          }
        },
      }}
    >      
      <Box sx={{ overflow: 'auto' }}> 
        <List
          sx={{
            width: "100%",
            Width: 12 / 13,
            bgcolor: colorsPalette.darkGray,
            color: colorsPalette.secondary
          }}
        disablePadding>        
        {roleMenu && roleMenu.map((item: any, index: number) => (
          <SideBarItem
            {...item}
            key={index}
            sx={{
              bgcolor: colorsPalette.darkGray,
              color: colorsPalette.secondary,
              textDecoder: "none",
              borderBottomWidth: "1px",
              borderBottomColor: colorsPalette.darkGrayAlt,
              borderBottomStyle: "solid",
              fontsize: ".9rem"
            }}
          />        
        ))}
      </List> 
      </Box>       
    </Drawer>  
  );
};

export default SideBarComponent;