import { useState } from "react";
import { AuthUserModel } from "../Api/Model/user/authModel";
import { useLocalStorage } from "./useLocalStorage";

export const useUser = (props: any) => {
  const [user, setUser] = useState<AuthUserModel | null>(null);
  const { setItem, removeItem } = useLocalStorage();

  const addUser = (user: AuthUserModel) => {
    setUser(user);
    setItem("PrismCareUser", JSON.stringify(user));
  };

  const removeUser = () => {
    setUser(null);
    removeItem("PrismCareUser");
  };

  return {
    user,
    addUser,
    removeUser,
  };
};
