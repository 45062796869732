import { SupervisorAccountTwoTone } from "@mui/icons-material";
import { Autocomplete, Box, Grid, TextField, Typography } from "@mui/material";
import { isEmpty } from "lodash";
import { SyntheticEvent, useCallback, useEffect, useState } from "react";
import DepartmentStructureRequestModel from "../../Api/Model/DepartmentStructure/DepartmentStructureRequestModel";
import ProviderModel from "../../Api/Model/DepartmentStructure/ProviderModel";
import { useAxios } from "../../hooks/useAxios";
import { useDepartmentStructure } from "../../hooks/useDepartmentStructure";
import PrismSwitch from "../Shared/Inputs/PrismSwitch.component";
import { CustomDialog } from "../Shared/Modal/Modal.component";

type DepartmentStructureModalProps = {
    Name: string,
    Open: boolean,
    IsAdding: boolean,
    Type: 'department' | 'office' | 'provider',
    OnConfirm: (provider: string, providerModel: ProviderModel | null, addAsProvider: boolean) => void,
    HandleClose: () => void,
    Id: string | null,
    RowInfo: any
}

const modalInputMinLength = 3;

const DepartmentStructureModal = (props: DepartmentStructureModalProps) => {
    const [name, setName] = useState<string>("");
    const [providerOptions, setProviderOptions] = useState<readonly ProviderModel[]>([]);
    const [providerQuery, setProviderQuery] = useState('');
    const [provider, setProvider] = useState<ProviderModel | null>(null);
    const [addAsAProvider, setAddAsAProvider] = useState<boolean>(false);
    const { postAsync, axiosLoading } = useAxios();
    const [departmentExist, setDepartmentExist] = useState<boolean>(false);
    const [officeExist, setOfficeExist] = useState<boolean>(false);
    const [providerExist, setProviderExist] = useState<boolean>(false);
    const [providerToAddExist, setProviderToAddExist] = useState<boolean>(false);
    const { departmentExistsAsync, officeExistsAsync, existDepartmentStructureAsync, existsProviderAsync } = useDepartmentStructure();

    const closeModal = () => props.HandleClose();
    const onConfirm = async () => {
        setProviderToAddExist(false);
        if (props.Type === 'department') {
            const exists = await departmentExists();
            if (exists) {
                return;
            }
        }
        else if (props.Type === 'office' && !addAsAProvider) {
            const exists = await officeExists();
            if (exists) {
                return;
            }
        }
        else if (!provider && (props.Type === 'provider' || (props.Type === 'office' && addAsAProvider))) {
            const except = !props.IsAdding ? props.Name : undefined;
            const provider = !props.IsAdding ? name.trim() : providerQuery.trim();
            if(!props.IsAdding && except === provider){
                closeModal();
                return;
            }

            const exists = await existsProviderAsync(provider);
            if (exists) {
                setProviderToAddExist(true);
                return;
            }
        }

        const nameResult = props.IsAdding ? (props.Type === 'provider' || (props.Type === 'office' && addAsAProvider) ? providerQuery : name) : name;
        !!props.OnConfirm && props.OnConfirm(nameResult.trim(), provider, addAsAProvider);
        closeModal();
    }

    const getProvidersFiltered = useCallback(async (query: string) => {
        if (isEmpty(query)) {
            setProviderOptions([]);
            return;
        }

        const response = await postAsync<ProviderModel[]>(`Provider/FindProvidersByNameOrCode`, query);
        setProviderOptions(response ?? [] as ProviderModel[]);
    }, [postAsync]);

    const departmentExists = useCallback(async () => {
        if (!name) {
            setDepartmentExist(false);
            return;
        }
        const exists = await departmentExistsAsync(name, props.IsAdding ? null : props.Id);
        setDepartmentExist(exists ?? false);
        return exists ?? false;
    }, [name]);

    const officeExists = useCallback(async () => {
        if (!name) {
            setOfficeExist(false);
            return;
        }
        const exists = await officeExistsAsync(name, props.IsAdding ? null : props.Id);
        setOfficeExist(exists ?? false);
        return exists ?? false;
    }, [name]);

    useEffect(() => {
        if (!props.Open) {
            setProvider(null);
            setProviderOptions([]);
            return;
        }

        setName(props.IsAdding ? '' : props.Name);
        setProviderQuery('');
        setProvider(null);
        setAddAsAProvider(false);
        setProviderExist(false);
        setProviderToAddExist(false);
        setDepartmentExist(false);
        setOfficeExist(false);
    }, [props.Open, props.Name, props.IsAdding]);

    useEffect(() => {
        setProviderExist(false);
        setProviderToAddExist(false);
        setProvider(null);
        if (!providerQuery) {
            return;
        }

        const delayAutocompleteFn = setTimeout(() => {
            getProvidersFiltered(providerQuery);
        }, 400)

        return () => clearTimeout(delayAutocompleteFn)
    }, [providerQuery]);

    useEffect(() => {
        const delayAutocompleteFn = setTimeout(() => {
            if (props.Type === 'department') {
                departmentExists();
                return;
            }
            else if (props.Type === 'office') {
                officeExists();
                return;
            }
        }, 400)

        return () => clearTimeout(delayAutocompleteFn)
    }, [name, departmentExists, officeExists, props.Type]);

    const validationErrors = {
        ...((!props.IsAdding && isEmpty(name?.trim())) && {
            Name: "Name is required.",
        }),
        ...((!props.IsAdding && isEmpty(name?.trim()) && (name?.trim().length || 0) < modalInputMinLength) && {
            Name: `Name length must be at least ${modalInputMinLength} characters long.`,
        }),
        ...((props.Type === 'department' || (props.Type === 'office' && !addAsAProvider)) && isEmpty(name?.trim()) && {
            Name: "Name is required.",
        }),
        ...((props.Type === 'department' || (props.Type === 'office' && !addAsAProvider)) && !isEmpty(name?.trim())) && (name?.trim().length || 0) < modalInputMinLength && {
            Name: `Name length must be at least ${modalInputMinLength} characters long.`,
        },
        ...(props.Type === 'department' && departmentExist && {
            Name: "Department already exists.",
        }),
        ...(props.Type === 'office' && !addAsAProvider && officeExist && {
            Name: "Office already exists.",
        }),
        ...(props.IsAdding && (props.Type === 'provider' || (props.Type === 'office' && addAsAProvider)) && isEmpty(providerQuery?.trim()) && {
            Provider: "Provider is required.",
        }),
        ...(props.IsAdding && (props.Type === 'provider' || (props.Type === 'office' && addAsAProvider)) && !isEmpty(providerQuery?.trim()) && providerQuery?.trim().length < modalInputMinLength && {
            Provider: `Provider length must be at least ${modalInputMinLength} characters long.`,
        }),
        ...(providerExist && {
            Provider: `Provider already exists on this ${props.RowInfo.node.type}.`,
        }),
        ...(providerToAddExist && {
            Provider: `Provider name is already taken.`,
            Name: `Provider name is already taken.`,
        })
    };

    async function handleProviderChange(event: SyntheticEvent<Element, Event>, value: string | ProviderModel | null): Promise<void> {
        if (typeof value === 'string') {
            const newProvider = { providerName: value } as ProviderModel;
            setProviderOptions([newProvider, ...providerOptions]);
            setProvider(newProvider);
        } else {
            if (value === null) {
                setProvider(null);
                return;
            }
            setProviderToAddExist(false);
            const { path, parentNode } = props.RowInfo;
            let _departmentId = "";
            let _officeId = "";
            if (!parentNode) {
                _departmentId = props.RowInfo.node.id;
              }
              else {
                _departmentId = path[0];
                _officeId = path[1];
              }
            const payload = {
                providerId: value.providerId,
                departmentId: _departmentId,
                officeId: _officeId === '' ? null : _officeId
            } as DepartmentStructureRequestModel;

            const providerExists = await existDepartmentStructureAsync(payload);
            if(providerExists){
                setProviderExist(true);
            }

            if (providerOptions.indexOf(value) === -1)
                setProviderOptions([value, ...providerOptions]);
            setProvider(value);
        }
        event.stopPropagation();
    }

    const name_content = <TextField
        type={"text"}
        onChange={(e) => setName(e.target.value)}
        value={name}
        error={!!validationErrors.Name}
        helperText={validationErrors.Name}
        label='Name'
        variant="outlined"
        autoComplete='off'
        required
        fullWidth
        sx={{ mt: 1 }}
        onKeyDown={(ev) => {
            setProviderToAddExist(false);
            if (ev.key === 'Enter' && (Object.keys(validationErrors).length) <= 0) {
                ev.preventDefault();
                onConfirm();
            }
        }}
        inputProps={{
            tabIndex: 0,
            maxLength: 50,
            minLength: modalInputMinLength,
        }}
    />;

    const autocomplete_content = <>
        <Autocomplete
            freeSolo
            onChange={(event, value) => handleProviderChange(event, value)}
            loading={axiosLoading}
            getOptionLabel={(option: string | ProviderModel) => typeof option === 'string' ? option : option.providerName}
            isOptionEqualToValue={(option, value) => option.providerName === value.providerName}
            value={provider}
            noOptionsText="No providers found"
            filterOptions={(x) => x}
            options={[...providerOptions]}
            onInputChange={(event, newInputValue) => {
                setProviderQuery(newInputValue);
            }}
            blurOnSelect={true}
            renderInput={(params) => (
                <TextField
                    sx={{ mt: 1 }}
                    {...params}
                    inputProps={{ ...params.inputProps, maxLength: 50, minLength: modalInputMinLength}}
                    label="Provider"
                    required
                    error={!!validationErrors.Provider}
                />
            )}
            renderOption={(props, option) => {
                return (
                    <li {...props} key={option.providerId}>
                        <Grid container alignItems="center" spacing={1}>
                            <Grid item>
                                <SupervisorAccountTwoTone />
                            </Grid>
                            <Grid item>
                                {option.providerName} - {option.code}
                            </Grid>
                        </Grid>
                    </li>
                );
            }}
        />
        {validationErrors.Provider && <Typography variant="body2" color="error" sx={{ mt: 0.5, ml: 1, fontSize:14 }}>{validationErrors.Provider}</Typography>}
    </> ;

    const handleAddAsAProvider = (event: React.SyntheticEvent, checked: boolean) => {
        setAddAsAProvider(checked);
        setProviderQuery('');
    }

    const office_content = <>
        {addAsAProvider ? autocomplete_content : name_content}
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
            <PrismSwitch Label="Add as a Provider" OnChange={handleAddAsAProvider} DefaultChecked={addAsAProvider} />
        </Box>
    </>;

    const getTitle = (): string => {
        if (props.IsAdding) {
            let result = 'Add ';

            if (props.Type === 'department') {
                result += 'New Department';
            } else if (props.Type === 'office') {
                result += `New ${addAsAProvider ? 'Provider' : 'Office'}`;
            } else if (props.Type === 'provider') {
                result += `New Provider`;
            }

            return result;
        }

        return 'Edit';
    }

    const getSubTitle = (): string | undefined => {
        let subTitleResult: string | undefined = undefined;
        if (!props.IsAdding) {
            return subTitleResult;
        }

        if (props.Type === 'department') {
            subTitleResult = '';
        } else if (props.Type === 'office') {
            subTitleResult = `As a ${props.Name} ${addAsAProvider ? 'provider' : 'office'}`;
        } else if (props.Type === 'provider') {
            subTitleResult = `As a ${props.Name} provider`;
        }

        return subTitleResult;
    }

    const adding_content = <>
        {props.Type === 'department' && name_content}

        {props.Type === 'office' && office_content}

        {props.Type === 'provider' && autocomplete_content}
    </>;

    return (<CustomDialog
        isOkButtonDisabled={(Object.keys(validationErrors).length) > 0}
        open={props.Open}
        onConfirm={onConfirm}
        shouldWaitForConfirm={true}
        handleClose={props.HandleClose}
        title={getTitle()}
        subTitle={getSubTitle()}
        size='xs'>
        <>
            {props.IsAdding ? adding_content : name_content}
        </>
    </CustomDialog>)
};

export default DepartmentStructureModal;