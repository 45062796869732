import * as React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import TablePagination from "@mui/material/TablePagination";
import { ControlPointTwoTone } from '@mui/icons-material';
import { Button, MenuItem, Select } from '@mui/material';
import PRISMCareTheme from '../../../layout/PRISMCareTheme';
import { GlobalFontSize } from '../../../constants/global.constants';
import './CollapsibleTable.component.css';
import { colorsPalette } from '../../../constants/styles.constants';

export type CollapsibleTableRowsType<T> = {
    id: string
    item: T
    headings: Array<string>
    rowHeadings: Array<string>
    rowTitle: string
    row: Array<JSX.Element>
    onAdd?: (row: T) => void
    addButtonName?: string
    index?: number
    deleteRowElement?: JSX.Element
}
interface CollapsibleTableProps<T> {
    tableHeadingCells: Array<string>;
    tableRowHeadingCells: Array<string>;
    tableRowsCells: Array<CollapsibleTableRowsType<T>>;
    rowsPerPageOptions: Array<number>;
    rowsPerPage: number;
    rowCount: number;
    page: number;
    onPageChange: (event: unknown, page: number) => void;
    onRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

function Row<T>(props: CollapsibleTableRowsType<T>) {
    const [open, setOpen] = React.useState(false);

    return (
        <>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' }, backgroundColor: (props.index || 0) % 2 ? 'white' : PRISMCareTheme.palette.grey[200] }}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                {props.headings.map((heading, index) => {
                    return <TableCell key={index}><Typography variant='body1'>{heading}</Typography></TableCell>
                })}
                {props.deleteRowElement ? <TableCell>{props.deleteRowElement}</TableCell> : <TableCell />}
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0, backgroundColor: (props.index || 0) % 2 ? 'white' : PRISMCareTheme.palette.grey[100] }} colSpan={6} sx={{ borderBottom: '1px solid rgb(224, 224, 224)' }}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', pl: 2, py: 2 }}>
                                <Typography variant="h6" gutterBottom component="div">
                                    {props.rowTitle}
                                </Typography>
                                {props.onAdd && <Button
                                    startIcon={<ControlPointTwoTone />}
                                    sx={{ mb: 2 }}
                                    variant="contained"
                                    color="success"
                                    size="small"
                                    onClick={() => props.onAdd && props.onAdd(props.item)}>{props.addButtonName}</Button>}
                            </Box>
                            <Table size="small" sx={{ mb: 4 }}>
                                <TableHead>
                                    <TableRow>
                                        {props.rowHeadings.map((heading, index) => {
                                            return <TableCell key={index}>
                                                <Typography variant="body2" fontWeight={'bold'}>
                                                    {heading}
                                                </Typography>
                                            </TableCell>
                                        })}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {props.row}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}

function CollapsibleTable<T>(props: CollapsibleTableProps<T>) {
    let numberOfPages = Array.from(Array(Math.ceil(props.rowCount / (props.rowsPerPage))), (_, i) => i + 1).filter(n => n !== 1);
    const haveActionsColumn = props.tableRowsCells.length > 0 && props.tableRowsCells.some(s => s.deleteRowElement !== undefined);
    const columnsWidth = haveActionsColumn ? `${94 / (props.tableHeadingCells.length + 1)}%` : `${97 / props.tableHeadingCells.length}%`;
    return (<>
        <TableContainer component={Paper} sx={{
            overflowY: 'auto',
            maxHeight: 'calc(100vh - 250px)',
            minWidth: '100%',
            border: '1px solid rgb(224, 224, 224)',
            borderBottom: 'none',
            borderBottomRightRadius: 0,
            borderBottomLeftRadius: 0,
            boxShadow: 'none'
        }}>
            <Table stickyHeader aria-label="collapsible table" size='small'>
                <TableHead sx={{ height: '56px' }}>
                    <TableRow>
                        <TableCell width='3%' />
                        {props.tableHeadingCells.map((heading, index) => {
                            return <TableCell key={index} className='collapsible-table-header' width={columnsWidth}>{heading}</TableCell>
                        })}
                        {haveActionsColumn && <TableCell className='collapsible-table-header' width={'3%'}>Actions</TableCell>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        props.tableRowsCells.length === 0 &&
                        <TableRow>
                            <TableCell colSpan={props.tableHeadingCells.length + (haveActionsColumn ? 2 : 1)}>
                                <Typography color={colorsPalette.darkGray} fontSize={GlobalFontSize} align='center'>No records to display.</Typography>
                            </TableCell>
                        </TableRow>
                    }
                    {props.tableRowsCells.length > 0 && props.tableRowsCells.map((row, index) => (
                        <Row
                            key={row.id}
                            id={row.id}
                            headings={row.headings}
                            row={row.row}
                            rowTitle={row.rowTitle}
                            rowHeadings={props.tableRowHeadingCells}
                            item={row.item}
                            onAdd={row.onAdd}
                            addButtonName={row.addButtonName}
                            index={index}
                            deleteRowElement={row.deleteRowElement}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        {props.tableRowsCells.length > 0 && <Box sx={{
            display: 'flex',
            justifyContent: 'end',
            minWidth: '100%',
            float: 'right',
            alignItems: 'center',
            border: '1px solid rgb(224, 224, 224)'
        }} >
            <Typography marginTop={-0.35} marginRight={1} color={colorsPalette.darkGray} fontSize={GlobalFontSize} fontWeight={400}>
                {'Page: '}
            </Typography>
            <Select
                id="selectedPage"
                defaultValue={""}
                displayEmpty
                SelectDisplayProps={{
                    style: {
                        alignContent: 'center',
                    }
                }}
                sx={{ textAlign: 'right', width: 'auto', height: 20, fontSize: GlobalFontSize, boxShadow: 'none' }}
                disableUnderline
                label="Jump to page"
                labelId="PageSelect"
                value={(props.page + 1).toString() || ''}
                variant='standard'
                onChange={(event) => props.onPageChange(event, (+event.target.value - 1))}
                required
            >
                <MenuItem value={1} key={1}>
                    {1}
                </MenuItem>
                {numberOfPages && numberOfPages.map((pageNumber: any) =>
                    <MenuItem value={pageNumber} key={pageNumber} sx={{ textOverflow: 'unset !important' }}>
                        {pageNumber}
                    </MenuItem>)
                }
            </Select>
            <TablePagination
                rowsPerPageOptions={props.rowsPerPageOptions}
                component="div"
                count={props.rowCount}
                rowsPerPage={props.rowsPerPage}
                page={props.page}
                onPageChange={props.onPageChange}
                onRowsPerPageChange={props.onRowsPerPageChange} />
        </Box>}</>
    );
}

export default CollapsibleTable;