import { Cancel } from '@mui/icons-material';
import { Box, Grid, IconButton, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { isEmpty } from 'lodash';
import { PatientMedicationsModel } from '../../../Api/Model/PatientMedications';
import { useEffect, useState } from 'react';

interface PatientMedicationRowState {
    isFixedRow: boolean;
    disabled: boolean;
    patientId: string;
    onRemoveMedication?: () => void;
    medication?: PatientMedicationsModel,
    onMedicationChange?: (medication: PatientMedicationsModel | undefined) => void;
}

const entityLength = 2;

export const PatientMedicationRow = (props: PatientMedicationRowState) => {
    const [medication, setMedication] = useState<PatientMedicationsModel | undefined>();
    const textBoxSize = 'small';
    useEffect(() => {
        setMedication(props.medication);
    }, [props.medication]);

    const validationErrors = {
        ...(medication !== undefined && isEmpty(medication.medication?.trim()) && {
            Medication: "Medication's name is required",
        }),
        ...(medication !== undefined && !isEmpty(medication.medication?.trim()) && medication.medication.trim().length < entityLength && {
            Medication: `Medication's name must be at least ${entityLength} characters long`,
        }),
        ...(medication !== undefined && isEmpty(medication.dosage?.trim()) && {
            Dosage: "Dosage is required",
        }),
        ...(medication !== undefined && !isEmpty(medication.dosage?.trim()) && medication.dosage.trim().length < entityLength && {
            Dosage: `Dosage description must be at least ${entityLength} characters long`,
        }),
        ...(medication !== undefined && isEmpty(medication.frequency?.trim()) && {
            Frequency: "Frequency is required",
        }),
        ...(medication !== undefined && !isEmpty(medication.frequency?.trim()) && medication.frequency.trim().length < entityLength && {
            Frequency: `Frequency description must be at least ${entityLength} characters long`,
        }),
        ...(medication !== undefined && isEmpty(medication.route?.trim()) && {
            Route: "Route is required",
        }),
        ...(medication !== undefined && !isEmpty(medication.route?.trim()) && medication.route.trim().length < entityLength && {
            Route: `Route description must be at least ${entityLength} characters long`,
        })
    };

    const setMedicationHandler = (key: 'medication' | 'dosage' | 'frequency' | 'route' | 'notes' |'medicationStatus', value: string) => {
        let current: PatientMedicationsModel = medication || { medication: '', dosage: '', frequency: '', route: '',notes: '',medicationStatus:'Active', patientId: props.patientId } as PatientMedicationsModel;
        current[key] = value;
        current.hasError = isEmpty(current.medication.trim()) || isEmpty(current.dosage.trim()) || isEmpty(current.frequency.trim()) || isEmpty(current.route.trim()) || (!isEmpty(current.medication.trim()) && current.medication.trim().length < entityLength) || (!isEmpty(current.dosage.trim()) && current.dosage.trim().length < entityLength) || (!isEmpty(current.frequency.trim()) && current.frequency.trim().length < entityLength) || (!isEmpty(current.route.trim()) && current.route.trim().length < entityLength);

        if (isEmpty(current.dosage.trim()) && isEmpty(current.frequency.trim()) && isEmpty(current.medication.trim()) && isEmpty(current.route.trim())) {
            setMedication(undefined);
            props.onMedicationChange && props.onMedicationChange(undefined);
            return;
        }

        setMedication(prevState => ({ ...prevState, ...current }));
        props.onMedicationChange && props.onMedicationChange(current);
    }

    const cleanMedicationHandler = () => {
        if(props.isFixedRow) {
            setMedication(undefined);
            props.onMedicationChange && props.onMedicationChange(undefined);
            return;
        }
        props.onRemoveMedication && props.onRemoveMedication()
    }

    return (
        <Box sx={{display: 'flex',marginBottom:1, borderRadius:2, bgcolor: medication?.medicationStatus == 'Discontinued' ? 'lightgray' : '-moz-initial', flexDirection: 'row', justifyContent: 'space-evenly', pr: 2, pl: 1 }}>
            <Grid
                sx={{ flexGrow: 1, alignItems: 'start' }}
                container
                spacing={1}
                rowSpacing={1}
                justifyContent="left"
                alignItems="center"
                marginTop={1}
                key={medication?.patientMedicationId}
            >
                <Grid item xs={2.5}>
                    <TextField
                        type={"text"}
                        onChange={(e) => setMedicationHandler('medication', e.target.value)}
                        label="Name"
                        className='mb-2'
                        size= {textBoxSize}
                        value={medication?.medication || ''}
                        error={!!validationErrors.Medication}
                        helperText={validationErrors.Medication}
                        variant="outlined"
                        required
                        fullWidth
                        inputProps={{
                            tabIndex: 0,
                            maxLength: 80,
                            minLength: 2,
                        }}
                        autoComplete='off'
                        disabled={props.disabled}
                    />
                </Grid>
                <Grid item xs={1}>
                    <TextField
                        type={"text"}
                        onChange={(e) => setMedicationHandler('dosage', e.target.value)}
                        label="Dosage"
                        size= {textBoxSize}
                        className='mb-2'
                        value={medication?.dosage || ''}
                        error={!!validationErrors.Dosage}
                        helperText={validationErrors.Dosage}
                        variant="outlined"
                        required
                        fullWidth
                        inputProps={{
                            tabIndex: 0,
                            maxLength: 80,
                            minLength: 2,
                        }}
                        autoComplete='off'
                        disabled={props.disabled}
                    />
                </Grid>
                <Grid item xs={1.6}>
                    <TextField
                        type={"text"}
                        onChange={(e) => setMedicationHandler('frequency', e.target.value)}
                        label="Frequency"
                        className='mb-2'
                        size= {textBoxSize}
                        value={medication?.frequency || ''}
                        error={!!validationErrors.Frequency}
                        helperText={validationErrors.Frequency}
                        variant="outlined"
                        required
                        fullWidth
                        inputProps={{
                            tabIndex: 0,
                            maxLength: 120,
                            minLength: 2,
                        }}
                        autoComplete='off'
                        disabled={props.disabled}
                    />
                </Grid>
                <Grid item xs={1.6}>
                    <TextField
                        type={"text"}
                        onChange={(e) => setMedicationHandler('route', e.target.value)}
                        label="Route"
                        size= {textBoxSize}
                        className='mb-2'
                        value={medication?.route || ''}
                        error={!!validationErrors.Route}
                        helperText={validationErrors.Route}
                        variant="outlined"
                        required
                        fullWidth
                        inputProps={{
                            tabIndex: 0,
                            maxLength: 80,
                            minLength: 2,
                        }}
                        autoComplete='off'
                        disabled={props.disabled}
                    />
                </Grid>
                <Grid item xs={2.8}>
                    <TextField
                        type={"text"}
                        onChange={(e) => setMedicationHandler('notes', e.target.value)}
                        label="Notes"
                        size= {textBoxSize}
                        className='mb-2'
                        multiline
                        maxRows={3}
                        value={medication?.notes || ''}
                        variant="outlined"
                        fullWidth
                        inputProps={{
                            tabIndex: 0,
                            minLength: 2,
                        }}
                        autoComplete='off'
                        disabled={props.disabled}
                    />
                </Grid>
                <Grid item xs={2} >
                    <ToggleButtonGroup
                        color="primary"
                        value={medication?.medicationStatus || 'Active'}
                        exclusive
                        sx={{maxWidth:'100%',height:43}}
                        fullWidth
                        size='small'
                        disabled={props.disabled}
                        onChange={(e:any) => setMedicationHandler('medicationStatus', e.target.value)}
                        aria-label="Platform"
                    >
                        <ToggleButton sx={{maxWidth:'35%',fontSize:10}} color='success' value="Active">Active</ToggleButton>
                        <ToggleButton sx={{maxWidth:'65%',fontSize:10}} color='error'  value="Discontinued">Discontinued</ToggleButton>
                    </ToggleButtonGroup>
                </Grid>
                <Grid item xs={0.5} >
                <IconButton
                disabled={props.disabled || medication === undefined}
                onClick={cleanMedicationHandler}
                color="error"
                sx={{
                    width: 35,
                    height: 35,
                    top: 5,
                    fontWeight: "bold",
                }}
            >
                <Cancel />
            </IconButton>
                </Grid>
            </Grid>
        </Box>
    );
};