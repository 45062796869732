import { useEffect, useCallback, useState } from 'react';
import { Button, Grid, IconButton } from '@mui/material';
import { GridColDef, GridSortModel } from '@mui/x-data-grid';
import { DeleteOutlineRounded, Edit } from "@mui/icons-material";
import ConfirmationModalComponent from '../Shared/ConfirmationModal/ConfirmationModal.component';
import { MedicationsModel } from '../../Api/Model/MedicationsModel';
import { MedicationModal } from './MedicationModal.component';
import StripedDataGrid, { CustomPagination } from '../Shared/StripedDataGrid/StripedDatagrid.component';
import { useNavigate, useOutletContext } from 'react-router-dom';
import PageWrapper from '../Shared/Wrapper/PageWrapper.component';
import { useAxios } from '../../hooks/useAxios';

const MedicationList = () => {

  //#region Column Definitions

  const definedColumns: GridColDef[] = [
    {
      field: "catalogName",
      headerName: "Name",
      headerAlign: "left",
      align: "left",
      flex: 1,
      filterable: false,
      hideable: false
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      headerAlign: "left",
      align: "left",
      filterable: false,
      hideable: false
    },
    {
      field: "action",
      headerName: "Actions",
      type: 'actions',
      sortable: false,
      flex: 0,
      headerAlign: "center",
      align: "center",
      filterable: false,
      hideable: false,
      cellClassName: "diseasecondition-actions",
      renderCell: (params) => {
        const openEditModal = () => {
          setCurrentMedication(params.row);
          setMedicationType('update');
          setOpenMedicationModal(true);
        };

        const openConfirmModal = () => {
          setCurrentMedication(params.row);
          setOpenDeleteConfirmation(true)
        };

        return <Grid container>
          <Grid item xs={6}>
            <IconButton onClick={openEditModal} aria-label="edit" className='unbordered' color="primary">
              <Edit />
            </IconButton>
          </Grid>
          <Grid item xs={6}>
            <IconButton onClick={openConfirmModal} aria-label="delete" className='unbordered' color="error">
              <DeleteOutlineRounded />
            </IconButton>
          </Grid>
        </Grid>;
      }
    },
  ];

  //#endregion
  

  //#region Hooks

  //#region States

  const [medications, setMedications] = useState<MedicationsModel[]>([]);
  const [currentMedication, setCurrentMedication] = useState<any>(undefined);
  const [openMedicationModal, setOpenMedicationModal] = useState(false);
  const [medicationType, setMedicationType] = useState<'create' | 'update'>('create');
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [refresh, setRefresh] = useState(true);
  const [rowCountState, setRowCountState] = useState(1);
  const [sortModel, setSortModel] = useState<GridSortModel>([{field: definedColumns[0].field, sort: "asc"}]);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0
  });
  
  //#endregion

  //#region Others
  
  const { getAsync, deleteAsync, updateAsync, postAsync, axiosPagination, axiosLoading, axiosError, axiosSuccess } = useAxios();
  const navigate = useNavigate();
  const { manageErrorAlert, showAlertSnack } = useOutletContext<{ manageErrorAlert: Function, showAlertSnack: Function }>();
  const onCloseConfirmModalHandler = useCallback(
    () => {
      setOpenDeleteConfirmation(false);
    },
    [setOpenDeleteConfirmation],
  );


  const onCloseMedicationModalHandler = useCallback(
    () => {
      setOpenMedicationModal(false);
    },
    [setOpenMedicationModal],
  );

  //#endregion
  
  //#region Effects

  useEffect(() => {
    if (axiosError && axiosError?.Code === '401') {
      manageErrorAlert("Unauthorized");
      navigate("/patientlist", { replace: true });
      return;
    }
  }, [axiosError]);

  useEffect(() => {
    if (!refresh) { return; }

    const {pageSize, page} = paginationModel;
    const {field, sort} = sortModel[0] !== undefined ? sortModel[0] : {field: definedColumns[0].field, sort: "asc"};

    getMedications(pageSize, page, field, sort || "");
  }, [refresh]);

  useEffect(() => {
    setRowCountState((prevState) =>
      axiosPagination?.TotalRecords !== undefined ? axiosPagination?.TotalRecords : prevState
    );
  }, [axiosPagination?.TotalRecords]);

  useEffect(() => {
    if (sortModel[0] === undefined) {
      setSortModel([{field: definedColumns[0].field, sort: "asc"}])
    }
    setRefresh(true);
  }, [paginationModel, sortModel]);

  //#endregion

  //#endregion


  //#region Functions

  //#region Medication List Methods

  const getMedications = async (pageSize: number, page: number, field: string, sort: string) => {

    const axiosGet = await getAsync<MedicationsModel[]>(`Medication/GetMedications` +  
    `?orderBy=${field}&sort=${sort}`,
    {
      PageNumber: page + 1,
      PageSize: pageSize,
    });

    setRefresh(false);

    if (!axiosGet) {
      manageErrorAlert(axiosError?.Messages[0]);
      return;
    }

    setMedications(axiosGet);
  };

  const addNewMedication = (): void => {
    setCurrentMedication(undefined);
    setMedicationType('create');
    setOpenMedicationModal(true);
  }

  //#endregion

  //#region Handlers

  //#region Pagination Handlers

  const onPageSizeChangeHandler = (newPageSize: number) => setPaginationModel({...paginationModel, pageSize: newPageSize});
  const onPageChangeHandler = (newPage: number) => setPaginationModel({...paginationModel, page: newPage});

  //#endregion

  //#region Modal Handlers

  const onDeleteConfirmationHandler = async () => {
    await deleteAsync('Medication/DeleteMedication', currentMedication?.catalogId || '');

    if (axiosSuccess) {
      showAlertSnack("The medication was successfully removed.", "success");
      setRefresh(true);
    }
  }

  const onMedicationModalConfirmHandler = async (_medication: MedicationsModel) => {
    if (medicationType === 'create') {
      if (medications.some((element: MedicationsModel) => element.catalogName?.toLowerCase() === _medication.catalogName?.toLowerCase())) {
        showAlertSnack("The medication already exists, nothing added.", "info");
        return;
      }

      await postAsync('Medication/AddMedication', _medication);

      if (axiosSuccess) {
        setRefresh(true);
        showAlertSnack("The medication was added successfully.", "success");
      }
      else {
        manageErrorAlert(axiosError?.Messages[0]);
      }
      return;
    }

    const updateResult = await updateAsync<MedicationsModel>('Medication/UpdateMedication', _medication?.catalogId || '', _medication);
    if (updateResult && axiosSuccess) {
      showAlertSnack("Medication updated successfully.", "success");
      setRefresh(true);      
    }
    else {
      manageErrorAlert(axiosError?.Messages[0]);
    }
  }

  //#endregion

  //#endregion

  //#endregion


  //#region UI - JSX Components

  const ui_confirm = <ConfirmationModalComponent
    open={openDeleteConfirmation}
    handleClose={onCloseConfirmModalHandler}
    type='deletion'
    onConfirm={onDeleteConfirmationHandler}
  />;

  const ui_medication_modal = <MedicationModal
    open={openMedicationModal}
    handleClose={onCloseMedicationModalHandler}
    type={medicationType}
    onConfirm={onMedicationModalConfirmHandler}
    medicationParam={currentMedication}
  />

  const ui_addBtn = <Button sx={{ mb: 2 }} variant="contained" color="success" onClick={addNewMedication}>Add medication</Button>;


  //#endregion


  return (
    <PageWrapper title={"Medication List"}>
      {ui_addBtn}
      <StripedDataGrid
        sx={{ height: 700 }}
        loading={axiosLoading}
        disableColumnSelector
        disableColumnMenu
        disableSelectionOnClick
        paginationMode="server"
        sortingMode="server"
        rows={medications}
        getRowId={(row) => row.catalogId}
        columns={definedColumns}
        rowsPerPageOptions={[5, 10, 20, 50]}
        pageSize={paginationModel.pageSize}
        page={paginationModel.page}
        rowCount={rowCountState}
        onPageSizeChange={onPageSizeChangeHandler}
        onPageChange={onPageChangeHandler}
        components={{ Footer: () => CustomPagination(Math.ceil(rowCountState / (paginationModel ? paginationModel.pageSize! : 1)), paginationModel.page + 1, onPageChangeHandler) }}
        sortModel={sortModel}
        onSortModelChange={setSortModel}
        sortingOrder={["asc", "desc"]}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
        }
      />
      {ui_confirm}
      {ui_medication_modal}
    </PageWrapper>
  );
};

export default MedicationList;