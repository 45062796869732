import { AnalyticsOutlined, Replay } from '@mui/icons-material';
import { Box, Button, Grid, TextField } from '@mui/material';
import { usePatientReadings } from '../../../hooks/usePatientReadings';
import PrismLoading from '../../Shared/Loading/Loading.component';
import { PatientReadingsGraphSection } from './PatientReadingsGraphSection.component';
import { PatientReadingsTabSection } from './PatientReadingsTabSection.component';
import "./PatientReadingsSection.component.css";
import { useOutletContext } from 'react-router-dom';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateDisplayFormat } from '../../../constants/global.constants';
import { useEffect } from 'react';
import { Dayjs } from 'dayjs';
import { isEmpty, isUndefined } from 'lodash';

type PatientReadingsType = {
  patientId: string | undefined,
  isInUse: boolean
}

export const PatientReadingsSection = (props: PatientReadingsType) => {
  const { manageErrorAlert} = useOutletContext<{
    manageErrorAlert: Function;
    showAlertSnack: Function;
  }>();

  const {
    loading,
    hasBloodPressureRecords, 
    hasSpO2Records, 
    hasGlucoseRecords, 
    hasWeightRecords,
    fetchForAllReadings,
    downloadReadingsFile,
    axiosError,
    reportFilterModel,
    setReportFilterModel,
    datesErrors,
    setDatesErrors,
    realReportFilterModel,
    setRealReportFilterModel
  } = usePatientReadings({PatientId: props.patientId ?? ''}); 

  const handleSubmitButtonClick = () => {
    setRealReportFilterModel(reportFilterModel);
    fetchForAllReadings(reportFilterModel.startDate, reportFilterModel.endDate);
  };

  const handleRefreshButtonClick = () => fetchForAllReadings(realReportFilterModel.startDate, realReportFilterModel.endDate);

  const downloadReadingsFileClick = async () => {
    downloadReadingsFile();

    if(axiosError){
      manageErrorAlert(axiosError.Messages[0]);
      return;
    }
  };

  const loadingComponent = <PrismLoading Size={80} Text={'Loading sections'} />

  useEffect(() => {
    setDatesErrors(reportFilterModel.endDate && reportFilterModel.startDate && (reportFilterModel.endDate < reportFilterModel.startDate) || false);
  }, [reportFilterModel.startDate, reportFilterModel.endDate]);

  const validationErrors = {
    ...((isUndefined(reportFilterModel.startDate))) && {
      StartDate: "Start Date is required",
    },
    ...((reportFilterModel.startDate?.toString() === "Invalid Date")) && {
      StartDate: "Start Date must be a valid date",
    },
    ...((isUndefined(reportFilterModel.endDate))) && {
      EndDate: "End Date is required",
    },
    ...((reportFilterModel.endDate?.toString() === "Invalid Date")) && {
      EndDate: "End Date must be a valid date",
    },
    ...(datesErrors) && {
      StartDate: "Date cannot be greater than End Date",
      EndDate: "Date cannot be less than Start Date",
    }
  };

  const buttons_section = <Box
    sx={{
      display: 'flex',
      flexDirection: 'row',
      borderRadius: 1,
      justifyContent: 'space-between'
    }}
  >
    <Grid container spacing={2} rowSpacing={2} width={"100%"} marginLeft={0}>
      <Grid item xs={2} style={{textAlign: 'left', paddingLeft: '0px'}}>
        <Button aria-label="edit" size='large' variant='contained' color='inherit' sx={{ fontWeight: 900, paddingTop: '15px', paddingBottom: '15px' }} onClick={handleRefreshButtonClick} disabled={loading}>
          <Replay sx={{ marginRight: '5px' }} />
          REFRESH DATA
        </Button> 
      </Grid>
      <Grid item xs={2}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            disableFuture
            onChange={(newValue: Dayjs | null) =>
              setReportFilterModel({
                ...reportFilterModel,
                startDate: newValue ? newValue.toDate() : undefined,
              })
            }
            value={reportFilterModel.startDate || null}
            label="Start Date"
            inputFormat={DateDisplayFormat}
            renderInput={(params) => (
              <TextField
                required
                inputProps={{
                  tabIndex: 1,
                }}
                {...params}
                error={!!validationErrors.StartDate}
                helperText={validationErrors.StartDate}
                fullWidth
              />
            )}
            PopperProps={{
              placement: "bottom-start",
              sx: {zIndex: 1200, paddingBottom: 2}
            }}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={2}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            onChange={(newValue: Dayjs | null) =>
              setReportFilterModel({
                ...reportFilterModel,
                endDate: newValue ? newValue.toDate() : undefined,
              })
            }
            value={reportFilterModel.endDate || null}
            label="End Date"
            inputFormat={DateDisplayFormat}
            renderInput={(params) => (
              <TextField
                required
                inputProps={{
                  tabIndex: 2,
                }}
                {...params}
                error={!!validationErrors.EndDate}
                helperText={validationErrors.EndDate}
                fullWidth
              />
            )}
            PopperProps={{
              placement: "bottom-start",
              sx: {zIndex: 1200, paddingBottom: 2}
            }}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={2}>
        <Button aria-label="edit" color="primary" size='large' variant='contained' sx={{ fontWeight: 900, paddingTop: '15px', paddingBottom: '15px' }} onClick={handleSubmitButtonClick} disabled={loading || !isEmpty(validationErrors)}>
          SUBMIT
        </Button>
      </Grid>
      <Grid item xs={4} style={{textAlign: 'right'}}>
        {(hasBloodPressureRecords || hasSpO2Records || hasGlucoseRecords || hasWeightRecords) &&
        <Button aria-label="edit" color="primary" size='large' variant='contained' sx={{ fontWeight: 900, paddingTop: '15px', paddingBottom: '15px' }} onClick={downloadReadingsFileClick} disabled={loading}>
          <AnalyticsOutlined sx={{ marginRight: '5px' }} />
          EXPORT TO EXCEL
        </Button>
        }
      </Grid>
    </Grid>    
  </Box>

  const grid_section = <Grid container spacing={2} sx={{ height: '670px', pt: 2 }}>
    <Grid item xs={12} md={6}>
      <Box sx={{ height: '100%' }}>
        {loading ? loadingComponent : <PatientReadingsTabSection 
          patientId={props.patientId}
          hasBloodPressureRecords={hasBloodPressureRecords}
          hasSpO2Records={hasSpO2Records}
          hasGlucoseRecords={hasGlucoseRecords}
          hasWeightRecords={hasWeightRecords}
          initialDate={realReportFilterModel?.startDate}
          endDate={realReportFilterModel?.endDate}
        />}
      </Box>
    </Grid>
    <Grid item xs={12} md={6}>
      <Box sx={{
        height: '100%'
      }}>{loading ? loadingComponent : <PatientReadingsGraphSection 
        patientId={props.patientId}
        hasBloodPressureRecords={hasBloodPressureRecords}
        hasSpO2Records={hasSpO2Records}
        hasGlucoseRecords={hasGlucoseRecords}
        hasWeightRecords={hasWeightRecords}
        initialDate={realReportFilterModel?.startDate}
        endDate={realReportFilterModel?.endDate}
      />}</Box>
    </Grid>
  </Grid>

  return (<>
    {buttons_section}
    {grid_section}
  </>
  )
}