import { useState } from "react";
import PropTypes from "prop-types";
import { Collapse, Divider, List, ListItemButton } from "@mui/material";
import IconExpandLess from "@mui/icons-material/ExpandLess";
import IconExpandMore from "@mui/icons-material/ExpandMore";
import {
  Logout,
  PermIdentity,
  HelpOutline,
  Chat,
  BarChart,
  Settings,
  Construction,
  ManageAccounts
} from "@mui/icons-material";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate } from "react-router-dom";
import { colorsPalette } from "../../constants/styles.constants";

export const SideBarItemPropTypes = {
  index: PropTypes.number,
  name: PropTypes.string.isRequired,
  Icon: PropTypes.string,
  uri: PropTypes.string,
  items: PropTypes.array,
};

type SideBarItemPropTypes = PropTypes.InferProps<typeof SideBarItemPropTypes>;
type SideBarItemPropsWithoutItems = Omit<SideBarItemPropTypes, "items">;

export type SideBarItemProps = SideBarItemPropsWithoutItems & {
  items?: SideBarItemProps[];
};

const SideBarItem = (props: any) => {
  const { name, icon, items } = props;

  const isExpandable = items && items.length > 0;
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();

  const handleClick = (event: any, props: any) => {
    setOpen(!open);
    if (props.uri === undefined) {
      return;
    }

    navigate(props.uri);
  };

  function getComponentIcon(icon: string) {
    switch (icon) {
      case "Logout":
        return <Logout />;
      case "PermIdentity":
        return <PermIdentity />;
      case "HelpOutline":
        return <HelpOutline />;
      case "Chat":
        return <Chat />;
      case "BarChart":
        return <BarChart />;
      case "Settings":
        return <ManageAccounts />;
      case "ConfigSettings":
        return <Settings />;
      case "Construction":
        return <Construction />;
      default:
        return "";
    }
  }

  const sideBarItemRoot = (
    <ListItemButton
      onClick={(event) => {
        handleClick(event, props);
      }}
      key="name"
      {...props}
      className="cursorPointer"
    >
      {!!icon && (
        <ListItemIcon className="spaceIconSideBar" style={{ color: colorsPalette.secondary }}>
          {getComponentIcon(icon)}
        </ListItemIcon>
      )}
      <ListItemText
        primary={name}
        inset={!icon}
        sx={{ fontWeight: "bold", ...(!icon && { pl: 4 }) }}
        className="newFontFamily menuTextFormat"
      />
      {isExpandable && !open && <IconExpandMore />}
      {isExpandable && open && <IconExpandLess />}
    </ListItemButton>
  );

  const sideBarItemChildren = isExpandable ? (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <Divider />
      <List
        component="div"
        disablePadding
        sx={{ bgcolor: colorsPalette.secondary, color: colorsPalette.darkGrayAlt }}
      >
        {items.map((item: any, index: number) => {
          return (
            <SideBarItem
              {...item}
              key={index}
              sx={{
                bgcolor:
                  item.uri === window.location.pathname ? colorsPalette.lightGray : colorsPalette.secondary,
                color: colorsPalette.darkGrayAlt,
                textDecoder: "none",
                fontsize: ".9rem",
              }}
            />
          );
        })}
      </List>
    </Collapse>
  ) : null;

  return (
    <>
      {sideBarItemRoot}
      {sideBarItemChildren}
    </>
  );
};

export default SideBarItem;
